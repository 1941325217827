/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom/client";
// import axios from "axios";
// import { mockAxios, setupAxios } from "./_metronic";
import App from "./App";
import "./App.css";
import "./index.scss"; // Standard version

// import "./sass/style.react.rtl.css"; // RTL version
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import store, { persistor } from "./app/store/store";

/**
 * Base URL of the website.
 *
 */
const { PUBLIC_URL } = process.env;
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

/* const mock = */
//  mockAxios(axios);

// setupAxios(axios, store);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <App store={store} persistor={persistor} basename={PUBLIC_URL} />

  // <React.StrictMode>
  //   <App store={store} persistor={persistor} basename={PUBLIC_URL} />
  // </React.StrictMode>
);

// ReactDOM.render(
//   <App store={store} persistor={persistor} basename={PUBLIC_URL} />,
//   document.getElementById("root")
// );
