import React, { Component } from "react";
import { Typography } from "@material-ui/core"; // import { Button } from "@material-ui/core";import { Container } from "react-bootstrap";
import "../mentor.css";
import SummaryTable from "./summaryTable";
import { getMentorSummary } from "../../../crud/auth.crud";
import swal from "sweetalert";
import { Container } from "react-bootstrap";
import withRouter from "../../../router/withRouter";
// import Pagination from "react-js-pagination";

class Summary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [],
    };
    console.log("🚀 ~ Summary ~ constructor ~ props:", props);
  }

  getSummary = async () => {
    try{

      let result = await getMentorSummary(
        this.state.current_page,
        this.props.location.state.detail
      );
      console.log("result.status", result.status);
      if (result.status === 200) {
        this.setState({
          tableData: result.data.data,
          mentorName: result.data.mentor_name,
        });
      } else {
        swal("Oops!", "Network Error!", "error");
      }
    }catch(err){
        swal("Oops!", "Network Error!", "error");

    }
  };

  async componentDidMount() {
    await this.getSummary();
  }

  render() {
    let id = this.props.location.state.detail;
    return (
      <>
        <div className="kt-portlet kt-portlet--height-fluid">
          <div className="kt-portlet__body">
            <div className="row">
              <div className="col-xl-4">
                <div className="d-flex justify-content-between">
                  <span>
                    <Typography variant="h6" id="modal-title">
                      Summary of {this.state.mentorName}
                    </Typography>
                  </span>
                </div>
              </div>
            </div>
            <Container className="tableMaxWidth">
              <SummaryTable id={id} tableData={this.state.tableData} />
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Summary);
