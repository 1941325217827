import React, { Component } from "react";
import { Button, Row } from "react-bootstrap";
import { Form, Col } from "react-bootstrap";
import SubjectTable from "./subjectTable";
import { callApi, fetchData } from "../../../crud/auth.crud";
import swal from "sweetalert";
import withRouter from "../../../router/withRouter";

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

const emptyState = {
  subject_name: "",
};
 class AddSubject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subject_name: "",
      tableData: [],
    };
  }

  onChangeHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  async componentDidMount() {
    await this.getAllSubjects();
  }

  getAllSubjects = async () => {
    try {
      let result = await fetchData("get-subjects");
      if (result.status === 200) {
        this.setState({ tableData: result.data.date });
      } else {
        swal("Oops!", "Please try again!", "error");
      }
    } catch (err) {
      if (err.response.status == 401) {
        swal("", "Unauthorized!", "error");
        localStorage.clear();
        this.props.navigate("/auth/login");
        swal.close();
      } else {
        swal("Oops!", "Network Error!", "error");
      }
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { subject_name } = this.state;
    try {
      let result = await callApi("save-subject", { subject_name });
      if (result.status === 200) {
        swal("subject added Successfully!", "", "success");
        this.setState(emptyState, () => this.getAllSubjects());
      } else {
        swal("Oops!", "Program already exist!", "error");
      }
    } catch {
      swal("Oops!", "Program already exist!", "error");
    }
  };

  render() {
    const { subject_name, tableData } = this.state;
    return (
      <>
        <div className="kt-portlet kt-portlet--height-fluid">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">Add Subject</h3>
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="row">
              <div className="col-xl-4">
                <Form onSubmit={this.handleSubmit}>
                  <Row>
                    <Form.Group as={Col} md="12" className="form-group">
                      {/* <Form.Label>Subject Name</Form.Label> */}
                      <Form.Control
                        required
                        name="subject_name"
                        type="text"
                        placeholder="Subject Name"
                        value={subject_name}
                        onChange={this.onChangeHandle}
                      />
                    </Form.Group>
                  </Row>
                  <div className="text-center mb-4">
                    <Button type="submit" style={btnColor}>
                      Add Subject
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            {tableData.length ? (
              <SubjectTable
                tableData={tableData}
                getAllSubjects={this.getAllSubjects}
              />
            ) : null}
          </div>
        </div>
      </>
    );
  }
}



export default withRouter(AddSubject);
