/* eslint-disable jsx-a11y/anchor-has-content,no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import "./dashboard.css";

const maxWidth = {
  maxWidth: "800px",
};

export default function PerspectiveBarrier(props) {
  const { hours_perspective_build, number_perspective_build } = props.obj;
  return (
    <>
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              Perspectives, Barriers and Program Hours
            </h3>
          </div>
          {/* <PortletHeaderDropdown /> */}
        </div>
        <div className="kt-portlet__body">
          <Typography
            variant="caption"
            display="block"
            className="mb-4 dashboardText"
          >
            If a child can think it we generally believe they can do it.
            However, if the thought never occurs, then the output will rarely
            happen. For this reason, Urban impact is constantly encouraging kids
            to see beyond their environment, to think BIGGER.
          </Typography>

          <Container style={maxWidth}>
            <div className="kt-widget4">
              <div className="kt-widget4__item ">
                <div className="kt-widget4__info ">
                  <p className="kt-widget4__text fw-bold">
                    &middot; &nbsp; Hours of perspective building
                  </p>
                </div>
                {hours_perspective_build}
              </div>
              <div className="kt-widget4__item ">
                <div className="kt-widget4__info ">
                  <p className="kt-widget4__text fw-bold">
                    &middot; &nbsp; Number of perspective building events
                  </p>
                </div>
                {number_perspective_build}
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}
