import React, { Component } from "react";
import PrimeTimeAttendanceTable from "./primeTimeAttendanceTable";
import "./reports.css";
import { fetchData } from "../../crud/auth.crud";
import swal from "sweetalert";
import Pagination from "react-js-pagination";
import { Form } from "react-bootstrap";
import withRouter from "../../router/withRouter";

class PrimeTimeAttendanceReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seeFull: false,
      tableData: [],
      total: null,
      per_page: null,
      current_page: 1,
      tableDataFull: [],
      dates: [],
      weekID: "",
      loader: true,
    };
  }

  async componentDidMount() {
    await this.getDates();
    await this.getAttendance();
  }

  getDates = async () => {
    try {
      let result = await fetchData("list-dates-pt");
      if (result.status === 200) {
        this.setState({ dates: result.data.data });
      } else {
        swal("Oops!", "Please try again!", "error");
      }
    } catch (err) {
      this.setState({ loading: false });
      if (err.response.status == 401) {
        swal("", "Unauthorized!", "error");
        localStorage.clear();
        this.props.navigate("/auth/login");
        swal.close();
      } else {
        swal("Oops!", "Network Error!", "error");
      }
    }
  };

  getAttendance = async () => {
    const { current_page, weekID } = this.state;
    try {
      let result = await fetchData(
        `primetime-attendance?date_id=${weekID}&page=${current_page}`
      );
      if (result.status === 200) {
        this.setState({
          tableData: result.data.data.data,
          current_page: result.data.data.current_page
            ? result.data.data.current_page
            : 1,
          total: result.data.data.total,
          per_page: result.data.data.per_page,
          loader: false,
        });
      } else {
        this.setState({ loader: false });
        swal("Oops!", "Please try again!", "error");
      }
    } catch (err) {
      this.setState({ loading: false });
      if (err.response.status == 401) {
        swal("", "Unauthorized!", "error");
        localStorage.clear();
        this.props.navigate("/auth/login");
        swal.close();
      } else {
        swal("Oops!", "Network Error!", "error");
      }
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ current_page: pageNumber });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.current_page !== this.state.current_page) {
      window.scrollTo(0, 0);
      this.getAttendance();
    }
  }

  onChangeHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value, current_page: 1 }, () =>
      this.getAttendance()
    );
  };

  render() {
    const { tableData, dates, weekID } = this.state;
    return (
      <>
        <div className="kt-portlet kt-portlet--height-fluid">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">Weekly Attendance</h3>
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="row">
              <div className="col-md-12">
                <div className="kt-section">
                  <div className="d-flex ">
                    <span className="py-2">Date</span>
                    <span className="px-4">
                      <Form.Group>
                        <Form.Control
                          as="select"
                          name="weekID"
                          value={weekID}
                          onChange={this.onChangeHandle}
                        >
                          <option value="">None</option>
                          {dates && dates.length ? (
                            dates.map((el) => (
                              <option key={el.id} value={el.weekID}>
                                {el.weekDate}
                              </option>
                            ))
                          ) : (
                            <option>Loading Dates...</option>
                          )}
                        </Form.Control>
                      </Form.Group>
                    </span>
                  </div>
                  <div className="kt-section__content">
                    {this.state.loader ? (
                      <div
                        className="spinner-border text-warning loaderStyleReport"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <PrimeTimeAttendanceTable tableData={tableData} />
                    )}
                  </div>
                </div>
                {this.state.total > 10 ? (
                  <Pagination
                    activePage={this.state.current_page}
                    itemsCountPerPage={10}
                    totalItemsCount={this.state.total}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)}
                    itemclassName="page-item"
                    linkclassName="page-link"
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}


export default withRouter(PrimeTimeAttendanceReport)