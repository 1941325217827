import ProfileInfo from "../../Components/childProfile/profileInfo";
import MentorProfile from "../../Components/mentorProfile/mentorProfile";
import AddMentor from "../../Components/addMentor/addMentor";
import Report from "../../Components/reports/report";
import KidReport from "../../Components/reports/kidReport";
import LiteracyReportList from "../../Components/reports/literacyReportList";
import Programs from "../../Components/programs/KidPower/programs";
import Mitera from "../../Components/programs/Mitera/index";
import SpecialEvents from "../../Components/programs/specialEvents/index";
import AttendanceForRPM from "../../Components/programs/specialEvents/attendanceForRPM";
import UserRegistration from "../../Components/admin/user-registration/index";
import SessionManagement from "../../Components/admin/session-management/index";
import ProgramDates from "../../Components/admin/program-dates/index";
import AdminTool from "../../Components/admin/admin-tool/index";
import AddProgram from "../../Components/admin/add-program/index";
import AddPermissionSlip from "../../Components/admin/add-permission-slip/index";
import ChangeDate from "../../Components/dashboardPages/changeDate";
import Mentoring from "../../Components/dashboardPages/mentoring";
import Tutoring from "../../Components/dashboardPages/tutoring";
import Hours from "../../Components/dashboardPages/hours";
import Perspective from "../../Components/dashboardPages/perspective";
import Feeding from "../../Components/dashboardPages/feeding";
import Summary from "../../Components/mentorProfile/Summary/summary";
import Notes from "../../Components/mentorProfile/Notes/notes";
import MentorPersonalInfo from "../../Components/mentorProfile/mentorPersonalInfo";
import TutoringAnalysis from "../../Components/reports/tutorigAnalysis";
import AddSubject from "../../Components/admin/add-subject/index";
import PrimeTimeAttendanceReport from "../../Components/reports/primeTimeAttendance";
import KidReportTable from "../../Components/reports/kidReportTable";
import AddedToCDI from "../../Components/programs/KidPower/addedToCDI";
import ChildProfile from "../../Components/childProfile/index";
import ChildSearchByProgram from "../../Components/childProfile/childSearchByProgram";
import ChildSearchByAddress from "../../Components/childProfile/childSearchByAddress";
import ChildSearchBySlip from "../../Components/childProfile/childSearchBySlip";
import ChildSearchByDate from "../../Components/childProfile/childSearchByDate";
import AddChild from "../../Components/addChild/addChild";
import Dashboard from "./Dashboard";
import SendChangeCDI from "../../Components/programs/Mitera/sendChangeCDI";
import SubjectReports from "../../Components/programs/KidPower/subjectReports";
import SubjectReportsDetails from "../../Components/programs/KidPower/subjectReportsDetails";
import ActiveMentorsReport from "../../Components/reports/activeMentorsReport";
const myRoutes = [
  // {
  //   path: "/dashboard",
  //   component: Dashboard,
  // },
  // {
  //   path: "/",
  //   component: Dashboard,
  // },
  // {
  //   path: "/dashboard/*",
  //   component: Dashboard,
  // },
  {
    path: "/childProfile",
    component: ChildProfile,
  },
  {
    path: "/childProfile-2",
    component: ChildSearchByProgram,
  },
  {
    path: "/childProfile-3",
    component: ChildSearchByAddress,
  },
  {
    path: "/childProfile-4",
    component: ChildSearchBySlip,
  },
  {
    path: "/childProfile-5",
    component: ChildSearchByDate,
  },
  {
    path: "/profileInfo",
    component: ProfileInfo,
  },
  {
    path: "/addChild",
    component: AddChild,
  },
  {
    path: "/mentorProfile",
    component: MentorProfile,
  },
  {
    path: "/addMentor",
    component: AddMentor,
  },
  {
    path: "/kid-reports",
    component: KidReport,
  },
  {
    path: "/literacy-reports",
    component: LiteracyReportList,
  },
  {
    path: "/reports",
    component: Report,
  },
  {
    path: "/programs/:tab",
    component: Programs,
  },
  {
    path: "/progList/:tab",
    component: Mitera,
  },
  {
    path: "/specialEvents",
    component: SpecialEvents,
  },
  {
    path: "/attendance-RPM",
    component: AttendanceForRPM,
  },
  {
    path: "/adminUserRegistration",
    component: UserRegistration,
  },
  {
    path: "/adminProgDates/:tab",
    component: ProgramDates,
  },
  {
    path: "/adminTool",
    component: AdminTool,
  },
  {
    path: "/addAdminProgram",
    component: AddProgram,
  },
  {
    path: "/progList/:tab",
    component: AddProgram,
  },
  {
    path: "/addSlip",
    component: AddPermissionSlip,
  },
  {
    path: "/addSubject",
    component: AddSubject,
  },
  {
    path: "/sessionManagement",
    component: SessionManagement,
  },
  {
    path: "/mentoring",
    component: Mentoring,
  },
  {
    path: "/changeDate/:tab",
    component: ChangeDate,
  },
  {
    path: "/tutoring",
    component: Tutoring,
  },
  {
    path: "/perspective",
    component: Perspective,
  },
  {
    path: "/tutoringAnalysis",
    component: TutoringAnalysis,
  },
  {
    path: "/kid-report-table",
    component: KidReportTable,
  },
  {
    path: "/feeding",
    component: Feeding,
  },
  {
    path: "/hours",
    component: Hours,
  },
  {
    path: "/mentorProfileSummary",
    component: Summary,
  },
  {
    path: "/mentorProfileNotes",
    component: Notes,
  },
  {
    path: "/mentorPersonalInfo",
    component: MentorPersonalInfo,
  },
  {
    path: "/primeTimeAttendanceReport",
    component: PrimeTimeAttendanceReport,
  },
  {
    path: "/adddedToCDI",
    component: AddedToCDI,
  },
  {
    path: "/sendChanges",
    component: SendChangeCDI,
  },
  {
    path: "/subject-reports",
    component: SubjectReports,
  },
  {
    path: "/subject-reports-details",
    component: SubjectReportsDetails,
  },
  {
    path: "/active-mentors",
    component: ActiveMentorsReport,
  },
];

export default myRoutes;
