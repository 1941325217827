import React, { Component } from "react";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import ResetDate from "./resetAction";
import withRouter from "../../router/withRouter";
import { validateInput } from "../childProfile/clone";

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

class ChangeDate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      start_date: "",
      end_date: "",
    };
  }
  setStartDate = (date) => {
    this.setState({ start_date: date });
  };

  setEndDate = (date) => {
    this.setState({ end_date: date });
  };

  changeDateClick = () => {
    console.log("🚀 ~ ChangeDate ~ constructor ~ props:", this.props.params);

    this.props.ResetDate(
      this.state.start_date ? convert(this.state.start_date) : "",
      this.state.end_date ? convert(this.state.end_date) : ""
    );
    if (this.props.params.tab === "summary") {
      this.props.navigate("/dashboard");
    }
    if (this.props.params.tab === "mentoring") {
      this.props.navigate("/mentoring");
    }
    if (this.props.params.tab === "tutoring") {
      this.props.navigate("/tutoring");
    }
    if (this.props.params.tab === "perspective") {
      this.props.navigate("/perspective");
    }
    if (this.props.params.tab === "feeding") {
      this.props.navigate("/feeding");
    }
    if (this.props.params.tab === "hours") {
      this.props.navigate("/hours");
    }
  };

  render() {
    const { start_date, end_date } = this.state;
    return (
      <>
        <div className="kt-portlet kt-portlet--height-fluid">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">
                Change Date of Summary Report
              </h3>
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="row">
              <div className="col-md-12">
                <div className="kt-section">
                  <div className="kt-section__content ms-4">
                    <div className="d-flex">
                      <span className="py-2">Begining of Date Range</span>

                      <span className="col-md-4 ms-3">
                        <DatePicker
                          selected={start_date}
                          onChange={(date) => this.setStartDate(date)}
                          selectsStart
                          startDate={start_date}
                          endDate={end_date}
                          placeholderText="Start Date"
                          className="form-control"
                          fullWidth
                          showYearDropdown
                          scrollableMonthYearDropdown
                          onChangeRaw={(event) => {
                            if (
                              event.target.value &&
                              !validateInput(event.target.value)
                            ) {
                              event.preventDefault();
                            }
                          }}
                          // maxDate={new Date()}
                        />
                      </span>
                    </div>
                    <div className="d-flex py-2">
                      <span className="py-2">End of Date Range</span>

                      <span className="col-md-4 ms-3">
                        <DatePicker
                          selected={end_date}
                          onChange={(date) => this.setEndDate(date)}
                          selectsEnd
                          startDate={start_date}
                          endDate={end_date}
                          minDate={start_date}
                          // maxDate={new Date()}
                          placeholderText="End Date"
                          className="form-control"
                          disabled={start_date ? false : true}
                          showYearDropdown
                          scrollableMonthYearDropdown
                          onChangeRaw={(event) => {
                            if (
                              event.target.value &&
                              !validateInput(event.target.value)
                            ) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <Button
                type="submit"
                style={btnColor}
                onClick={this.changeDateClick}
              >
                Change Dates
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  resetDate: state.resetDate.date,
});

export default withRouter(connect(mapStateToProps, { ResetDate })(ChangeDate));
