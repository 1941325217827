import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import { Modal } from "@material-ui/core"; // import { Button } from "@material-ui/core";import { Button, Form } from "react-bootstrap";
import { editUser } from "../../../crud/auth.crud";
import swal from "sweetalert";
import { Form, Col, Button } from "react-bootstrap";

const paper = {
  position: "absolute",
  width: 400,
  backgroundColor: "#fff",
  boxShadow: 5,
  padding: 25,
  outline: "none",
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`,
};

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

export default class EditUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      email: this.props.editData.email,
      firstName: this.props.editData.firstName,
      lastName: this.props.editData.lastName,
      role: this.props.editData.role,
      userid: this.props.editData.userid,
      password: "",
    };
  }
  setStartDate = (date) => {
    this.setState({ start_date: date });
  };

  setEndDate = (date) => {
    this.setState({ end_date: date });
  };

  onChangeHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  callbacks = () => {
    this.props.handleClose();
    this.props.getUser();
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { userid, firstName, lastName, password, role } = this.state;
    let result = await editUser(userid, firstName, lastName, password, role);
    if (result.status === 200) {
      swal("User's Details updated Successfully!", "", "success");
      this.setState({ password: "" }, this.callbacks);
    } else {
      swal("Oops!", "Please try again!", "error");
    }
  };

  render() {
    const { email, firstName, lastName, role, password } = this.state;
    return (
      <div>
        {/* <Typography gutterBottom>
          Click to get the full Modal experience!
        </Typography> */}
        {/* <Button onClick={this.props.handleOpen}>Open Modal</Button> */}
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.props.open}
          onClose={this.props.handleClose}
        >
          <div style={paper}>
            <div className="d-flex justify-content-between">
              <span>
                {" "}
                <Typography variant="h6" id="modal-title">
                  Edit User's Details
                </Typography>
              </span>
              <span>
                {" "}
                <i
                  className="fas fa-times"
                  onClick={this.props.handleClose}
                ></i>
              </span>
            </div>

            <Form className="mt-4" onSubmit={this.handleSubmit}>
              <Form.Group className="form-group">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter First Name"
                  name="firstName"
                  value={firstName}
                  onChange={this.onChangeHandle}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Last Name"
                  name="lastName"
                  value={lastName}
                  onChange={this.onChangeHandle}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="Enter Email"
                  name="email"
                  value={email}
                  disabled
                  onChange={this.onChangeHandle}
                />
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter Password"
                  name="password"
                  value={password}
                  onChange={this.onChangeHandle}
                />
                <Form.Text className="text-muted">
                  Leave blank if you do not want to change password.
                </Form.Text>
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label column sm={4}>
                  Role
                </Form.Label>
                <Form.Control
                  required
                  as="select"
                  name="role"
                  value={role}
                  onChange={this.onChangeHandle}
                >
                  <option>AllAccess</option>
                  <option>SuperAdmin</option>
                  <option>User</option>
                </Form.Control>{" "}
              </Form.Group>
              <div className="text-center form-group">
                <Button type="submit" style={btnColor}>
                  Update
                </Button>
              </div>
            </Form>

            {/* <AddSpecialEvent /> */}
          </div>
        </Modal>
      </div>
    );
  }
}
