import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Container, Dropdown } from "react-bootstrap";
import ShepheredTable from "./shepheredTable";
import {
  callApi,
  fetchData,
  fetchDataWithPagination,
} from "../../../crud/auth.crud";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import withRouter from "../../../router/withRouter";

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

const containWidth = {
  maxWidth: "600px",
};

const textStyle = {
  textDecoration: "underline",
  textDecorationColor: "#f4b53f",
};
function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}
class Shepherd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sessions: [],
      sessionName: "",
      session_dateId: "",
      start_date: "",
      total: null,
      per_page: null,
      current_page: 1,
      loader: true,
    };
  }

  setStartDate = (date) => {
    this.setState({ start_date: date });
  };
  async componentDidMount() {
    await this.getAllSessions();
  }

  getAllSessions = async () => {
    try {
      let result = await fetchData("get-session");
      if (result.status === 200) {
        this.setState(
          {
            sessions: result.data.data,
            sessionName:
              result.data.data.length && result.data.data[0].dateName,
            session_dateId:
              result.data.data.length && result.data.data[0].dateID,
          },
          () => this.getAllDates()
        );
      } else {
        swal("Oops!", "Please try again!", "error");
      }
    } catch (err) {
      if (err.response.status == 401) {
        swal("", "Unauthorized!", "error");
        localStorage.clear();
        this.props.navigate("/auth/login");
        swal.close();
      } else {
        swal("Oops!", "Network Error!", "error");
      }
    }
  };

  getAllDates = async (page) => {
    const { session_dateId } = this.state;
    if (session_dateId)
      try {
        let result = await fetchDataWithPagination(
          "shepherd-set-dates",
          page ? page : 1,
          { session_id: session_dateId }
        );
        if (result.status === 200) {
          this.setState({
            tableData: result.data.data.data,
            current_page: result.data.data.current_page,
            total: result.data.data.total,
            per_page: result.data.data.per_page,
            loader: false,
          });
        } else {
          this.setState({ loader: false });
          swal("Oops!", "Please try again!", "error");
        }
      } catch {
        this.setState({ loader: false });
        swal("Oops!", "Network Error!", "error");
      }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ current_page: pageNumber });
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.current_page !== this.state.current_page) {
      window.scrollTo(0, 0);
      this.getAllDates(this.state.current_page);
    }
  }

  onChangeHandle = (e) => {
    let obj = JSON.parse(e);
    this.setState({ sessionName: obj.name, session_dateId: obj.id }, () =>
      this.getAllDates()
    );
  };

  addDateHandle = async () => {
    const { start_date } = this.state;
    if (start_date)
      try {
        let result = await callApi("shepherd-add-date", {
          date: convert(start_date),
        });
        if (result.status === 200) {
          swal("Successfully Added!", "", "success");
          this.setState({ start_date: "" }, () => this.getAllDates());
        } else {
          this.setState({ loader: false });
          swal("Oops!", "Please try again!", "error");
        }
      } catch (err) {
        this.setState({ loader: false });
        if (err && err.response && err.response.status === 401) {
          swal("Dublicate Entry!", "Please try with another date!", "");
        } else swal("Oops!", "Network Error!", "error");
      }
  };

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    const { sessions, sessionName, start_date } = this.state;

    return (
      <>
        <div className="kt-portlet kt-portlet--height-fluid">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">
                Sunday Management (for Shepherd)
              </h3>
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="row">
              <div className="col-md-12">
                <div className="kt-section">
                  <div className="kt-section__content">
                    <p className="fw-bold" style={textStyle}>
                      Add Date Range
                    </p>
                    <p>Date of Event</p>
                    <div className="d-flex">
                      <span className="col-md-4">
                        <div>
                          <DatePicker
                            selected={start_date}
                            onChange={(date) => this.setStartDate(date)}
                            selectsStart
                            placeholderText="Start Date"
                            className="form-control"
                            fullWidth
                            showYearDropdown
                            scrollableMonthYearDropdown
                            // maxDate={new Date()}
                          />
                        </div>
                      </span>
                      <span className="px-4">
                        <Button
                          type="submit"
                          style={btnColor}
                          onClick={this.addDateHandle}
                        >
                          Add to CDI
                        </Button>
                      </span>
                    </div>
                    <div className="d-flex py-4">
                      <span className="py-2">Semester</span>
                      <span className="px-4">
                        <Dropdown onSelect={this.onChangeHandle}>
                          <Dropdown.Toggle
                            variant="warning"
                            id="dropdown-basic"
                            size="sm"
                            style={{
                              color: "#fff",
                              backgroundColor: "#ffb822",
                            }}
                          >
                            {sessionName}
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            // onSelect={this.onChangeHandle}
                            style={{
                              overflowY: "scroll",
                              maxHeight: "240px",
                            }}
                          >
                            {sessions &&
                              sessions.map((el) => (
                                <Dropdown.Item
                                  eventKey={JSON.stringify({
                                    name: el.dateName,
                                    id: el.dateID,
                                  })}
                                  key={el.dateID}
                                >
                                  {el.dateName}
                                </Dropdown.Item>
                              ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.loader ? (
                <div
                  className="spinner-border text-warning progDates"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <div className="col-md-12">
                  <div className="kt-section">
                    <div className="kt-section__content">
                      <Container style={containWidth}>
                        <ShepheredTable
                          tableData={this.state.tableData}
                          getAllSessions={this.getAllSessions}
                        />
                      </Container>
                    </div>
                  </div>
                </div>
              )}
              {this.state.total > 10 ? (
                <Pagination
                  activePage={this.state.current_page}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.total}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  itemclassName="page-item"
                  linkclassName="page-link"
                />
              ) : null}
            </div>
            {/* <div className="text-center">
                <Button type="submit" style={btnColor}>
                            Add Date Range
                          </Button>
                </div> */}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Shepherd);
