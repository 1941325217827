/* eslint-disable jsx-a11y/anchor-has-content,no-script-url,jsx-a11y/anchor-is-valid */
//import { toAbsoluteUrl } from "../../_metronic/utils/utils";
//import PortletHeaderDropdown from "../partials/content/CustomDropdowns/PortletHeaderDropdown";
import React, { Component } from "react";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import withRouter from "../../router/withRouter";

class TutoringAnalysisTable extends Component {
  seeProfile = () => {
    this.props.navigate("/profileInfo");
  };
  render() {
    const { tableData } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <div className="kt-section">
              <div className="kt-section__content">
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>First Name</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell>Tutor Sessions</TableCell>
                        <TableCell>Total Tutoring Time</TableCell>
                        <TableCell>Kid Power Hours Tutored</TableCell>
                        <TableCell>XLR8 Hours Tutored</TableCell>
                        {/* <TableCell align="right">Fat&nbsp;(g)</TableCell>
                              <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                              <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData && tableData.length > 0 ? (
                        tableData.map((row) => (
                          <TableRow key={row.childID}>
                            <TableCell>{row.firstName}</TableCell>
                            <TableCell>{row.lastName}</TableCell>
                            <TableCell>{row.count + "hour"}</TableCell>
                            <TableCell>{row.total_time + "hour"}</TableCell>
                            <TableCell>{row.kp_time + "hour"}</TableCell>
                            <TableCell>{row.xlr_time + "hour"}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <p>No Data</p>
                      )}
                    </TableBody>
                  </Table>
                </Paper>
              </div>
            </div>
            {/* </CodeExample> */}
          </div>
        </div>
        {/* </Container> */}
      </>
    );
  }
}

export default withRouter(TutoringAnalysisTable);
