/* eslint-disable jsx-a11y/anchor-has-content,no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import "./dashboard.css";

const maxWidth = {
  maxWidth: "800px",
};

export default function FoodInsecurity(props) {
  const {
    price_pineapple,
    six_fruit_cup,
    chicken_three_pound,
    eight_piece_of_chicken,
  } = props.obj;
  return (
    <>
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              Food Insecurity at PT Barnum{" "}
            </h3>
          </div>
          {/* <PortletHeaderDropdown /> */}
        </div>
        <div className="kt-portlet__body">
          <Typography
            variant="caption"
            display="block"
            className="mb-4 dashboardText"
          >
            According to the Bridgeport Food Action Plan, food insecurity is
            defined as a person or family lacking consistent source of funds to
            purchase food. Recent Surveys have indicated this rate is over 90%
            in the PT Barnum Apartments. <br />
            This funding issue creates a downstream problem of food choices,
            Fresh food is more expensive and spoils. Down the street are fast
            food alternatives(Wendy's, Popeye's, Subway) that are inexpensive
            and convinent.
          </Typography>

          <Container style={maxWidth}>
            <div className="kt-widget4">
              <div className="kt-widget4__item ">
                <div className="kt-widget4__info ">
                  <p className="kt-widget4__text fw-bold">
                    &middot; Price of a pineapple
                  </p>
                </div>
                {price_pineapple}
              </div>
              <div className="kt-widget4__item ">
                <div className="kt-widget4__info ">
                  <p className="kt-widget4__text fw-bold">
                    &middot; Price of 6 fruit cups
                  </p>
                </div>
                {six_fruit_cup}
              </div>
              <div className="kt-widget4__item ">
                <div className="kt-widget4__info ">
                  <p className="kt-widget4__text fw-bold">
                    &middot; 3 pounds of chicken from <br />
                    stop and shop(6 breasts)
                  </p>
                </div>
                {chicken_three_pound}
              </div>
              <div className="kt-widget4__item ">
                <div className="kt-widget4__info ">
                  <p className="kt-widget4__text fw-bold">
                    &middot; 8 pieces of chicken at Popeye's
                  </p>
                </div>
                {eight_piece_of_chicken}
              </div>
            </div>
          </Container>
          <Typography
            variant="caption"
            display="block"
            className="mt-4 dashboardText"
          >
            This contributes to a core problem with kids, that are overwhelmed
            by sugar and heavily processed foods. Making concentration and focus
            that much more difficult.
          </Typography>
        </div>
      </div>
    </>
  );
}
