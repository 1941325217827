import React, { Component } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  withStyles,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { IconButton } from "@material-ui/core";
import axios from "axios";
import { serverUrl } from "../../../../_metronic/utils/api";
import swal from "sweetalert";
import moment from "moment";
import withRouter from "../../../router/withRouter";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

class SessionTable extends Component {
  deleteSession = async (id) => {
    try {
      const result = await axios.post(
        `${serverUrl}delete-session`,
        { session_id: id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (result.status === 200) {
        swal("Session Deleted Successfully!", "", "success");
        this.props.getAllSessions();
        // this.setState({ tableData: result.data.data });
      } else {
        swal("Oops!", "Please Try Again!!", "error");
      }
    } catch (err) {
      if (err.response.status == 401) {
        swal("", "Unauthorized!", "error");
        localStorage.clear();
        this.props.navigate("/auth/login");
        swal.close();
      } else {
        swal("Oops!", "Network Error!", "error");
      }
    }
  };
  deleteHandlerConfirm = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.deleteSession(id);
        // swal("Data has been deleted!", {
        //   icon: "success",
        // });
      }
    });
  };
  moveToAdjPage = (pageName) => {
    this.props.n("/adminProgDates/1");
  };
  render() {
    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Date Range Name</StyledTableCell>
              <StyledTableCell>Begining of Range</StyledTableCell>
              <StyledTableCell>End of Range</StyledTableCell>
              <StyledTableCell>Remove Entry</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.sessions &&
              this.props.sessions.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell
                    style={{ color: "red" }}
                    className="fw-bold"
                    onClick={this.moveToAdjPage}
                  >
                    {row.dateName}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.dateStart
                      ? moment(row.dateStart).format("MM-DD-YYYY")
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.dateEnd
                      ? moment(row.dateEnd).format("MM-DD-YYYY")
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {" "}
                    <IconButton
                      aria-label="Delete"
                      onClick={() => this.deleteHandlerConfirm(row.dateID)}
                    >
                      <DeleteOutlineIcon style={{ color: "red" }} />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default withRouter(SessionTable);
