/* eslint-disable jsx-a11y/anchor-has-content,no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
//import { toAbsoluteUrl } from "../../_metronic/utils/utils";
//import PortletHeaderDropdown from "../partials/content/CustomDropdowns/PortletHeaderDropdown"
import {
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
} from "@material-ui/core";

const useStyles1 = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: "100%",
  },
}));

export default function CoreProgram(props) {
  const classes1 = useStyles1();
  return (
    <>
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              Data Collected: Core Programs
            </h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-section">
                <div className="kt-section__content">
                  <Paper className={classes1.root}>
                    <Table className={classes1.table} rules="cols">
                      <TableBody>
                        {props.program_core &&
                          props.program_core.map((row, i) => (
                            <TableRow key={i}>
                              <TableCell>{row}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </Paper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
