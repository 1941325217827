import React, { Component } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../partials/content/Portlet";
import SalesBarChart from "../../widgets/SalesBarChart";
import NewUsers from "../../widgets/NewUsers";
import CoreProgram from "../../Components/dashboard/coreProgram";
import PerspectiveProgram from "../../Components/dashboard/perspectiveProgram";
import { Card } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { callApi } from "../../crud/auth.crud";
import swal from "sweetalert";
import "./dashboard.css";
import moment from "moment";
import { connect } from "react-redux";
import ResetDate from "./resetAction";
import "./dashboard.css";
import withRouter from "../../router/withRouter";

const fontWeight = {
  fontWeight: "bold",
};

class Summary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      graphData: "",
      kids_hours: "",
      kids_reached: "",
      leaders_mentors: "",
      volunteer_mentor: "",
      loading: true,
      program_core: [],
      program_onetime: [],
    };
  }

  async componentDidMount() {
    this.getstats();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.resetDate !== this.props.resetDate) {
      window.scrollTo(0, 0);
      this.getstats();
    }
  }

  getstats = async () => {
    if (!this.state.loading) {
      await this.setState({ loading: true });
    }

    let payload = this.props.resetDate;
    if (localStorage.getItem("token"))
      try {
        let result = await callApi("get-stats", payload);
        if (result.status === 200) {
          this.setState({
            graphData: result.data.data.graph,
            kids_hours: result.data.data.kids_hours,
            kids_reached: result.data.data.kids_reached,
            leaders_mentors: result.data.data.leaders_mentors,
            volunteer_mentor: result.data.data.volunteer_mentor,
            loading: false,
            program_core: result.data.data.program_core,
            program_onetime: result.data.data.program_onetime,
          });
        } else {
          this.setState({ loading: false });
        }
      } catch (err) {
        this.setState({ loading: false });
        if (err.response.status == 401) {
          swal("", "Unauthorized!", "error");
          localStorage.clear();
          this.props.navigate("/auth/login");
          swal.close();
        } else {
          swal("Oops!", "Network Error!", "error");

        }
      }
  };

  moveToAdjPage = (pageName) => {
    this.props.navigate("/" + pageName);
  };

  reset_Date = () => {
    if (this.props.resetDate.start_date && this.props.resetDate.end_date)
      this.props.ResetDate("", "");
  };

  render() {
    const {
      kids_hours,
      kids_reached,
      leaders_mentors,
      volunteer_mentor,
      loading,
    } = this.state;

    return (
      <>
        {loading ? (
          <div
            className="spinner-border text-warning dashboardLoading"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <>
            {" "}
            <div className="row">
              <div className="col-xl-12">
                <Portlet fluidHeight={true}>
                  <PortletHeader title="Date Range" />
                  <PortletBody>
                    <div className="row">
                      <div className="col-md-3">
                        <Card
                          style={{
                            width: "13rem",
                            borderLeft: "3px solid #f4b53f",
                            borderWidthLeft: "5px",
                            fontSize: "10px",
                          }}
                        >
                          <Card.Body>
                            <Card.Text>Begining of date range</Card.Text>
                            <Card.Text style={fontWeight}>
                              {this.props.resetDate.start_date
                                ? moment(
                                    this.props.resetDate.start_date
                                  ).format("MMM Do YYYY")
                                : moment(
                                    new Date(
                                      new Date().setFullYear(
                                        new Date().getFullYear() - 1
                                      )
                                    )
                                  ).format("MMM Do YYYY")}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                      <div className="col-md-4">
                        <Card
                          style={{
                            width: "13rem",
                            fontSize: "10px",
                            borderLeft: "3px solid #f4b53f",
                          }}
                        >
                          <Card.Body>
                            <Card.Text>End of date range</Card.Text>
                            <Card.Text style={fontWeight}>
                              {this.props.resetDate.end_date
                                ? moment(this.props.resetDate.end_date).format(
                                    "MMM Do YYYY"
                                  )
                                : moment(new Date()).format("MMM Do YYYY")}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                      <div className="d-flex">
                        <span>
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            className="mt-4"
                            onClick={() =>
                              this.moveToAdjPage("changeDate/summary")
                            }
                          >
                            Change Date Range
                          </Button>
                        </span>
                        <span className="px-4">
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            className="mt-4"
                            onClick={this.reset_Date}
                          >
                            Reset Date
                          </Button>
                        </span>
                      </div>
                    </div>
                  </PortletBody>
                </Portlet>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <NewUsers
                  obj={{
                    kids_hours,
                    kids_reached,
                    leaders_mentors,
                    volunteer_mentor,
                  }}
                />
              </div>
            </div>
            <Portlet>
              <PortletBody fit={true}>
                <div className="row row-no-padding row-col-separator-xl">
                  <div className="col-xl-12">
                    <SalesBarChart
                      title="Engaging the kids from different directions"
                      graphData={this.state.graphData}
                    />
                  </div>
                </div>
              </PortletBody>
            </Portlet>
            <div className="row">
              <div className="col-xl-12">
                <CoreProgram program_core={this.state.program_core} />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <PerspectiveProgram
                  program_onetime={this.state.program_onetime}
                />
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  resetDate: state.resetDate.date,
});

const HOC = connect(mapStateToProps, { ResetDate })(Summary);

export default withRouter(HOC);
