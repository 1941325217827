import React, { Component } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import ChildProfileTable from "./childProfileTable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { serverUrl } from "../../../_metronic/utils/api";
import axios from "axios";
import Pagination from "react-js-pagination";
import withRouter from "../../router/withRouter";
import { validateInput } from "./clone";

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
  color: "#fff",
};
function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

class ChildProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: false,
      birthDate: "",
      fname: "",
      lname: "",
      tableData: [],
      loader: true,
      total: null,
      per_page: null,
      current_page: 1,
      current_pageSearch: 1,
    };
  }

  async componentDidMount() {
    await this.getChild(this.state.current_page);
  }

  getChild = async (page) => {
    try {
      if (!this.state.loader) {
        this.setState({ loader: true });
      }
      const result = await axios.get(
        `${serverUrl}child?page=${page ? page : 1}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      let details = result.data.data;
      if (result.status === 200) {
        this.setState({
          tableData: details.data,
          current_page: details.current_page,
          total: details.total,
          per_page: details.per_page,
          loader: false,
        });
      }
    } catch(err) {
     this.setState({ loading: false });
        if (err.response.status == 401) {
          swal("", "Unauthorized!", "error");
          localStorage.clear();
          this.props.navigate("/auth/login");
          swal.close();
        } else {
          swal("Oops!", "Network Error!", "error");

        }
  };
}

  getChildSearch = async (page) => {
    const { fname, lname, birthDate } = this.state;
    let DOB = birthDate ? convert(birthDate) : "";
    if (fname || lname || DOB)
      try {
        if (!this.state.loader) {
          this.setState({ loader: true });
        }
        const result = await axios.post(
          `${serverUrl}child-name?page=${page ? page : 1}`,
          { first_name: fname, last_name: lname, dob: DOB },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        let details = result.data.data;
        if (result.status === 200) {
          this.setState({
            tableData: details.data,
            current_page: details.current_page,
            total: details.total,
            per_page: details.per_page,
            loader: false,
          });
        }
      } catch {
        this.setState({ loader: false });
        swal("Oops!", "Network Error!", "error");
      }
  };

  handlePageChange = (pageNumber) => {
    if (this.state.search) {
      this.setState({ current_pageSearch: pageNumber });
    } else {
      this.setState({ current_page: pageNumber });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.current_page !== this.state.current_page &&
      !this.state.search
    ) {
      window.scrollTo(0, 0);
      this.getChild(this.state.current_page);
    }
    if (prevState.current_pageSearch !== this.state.current_pageSearch) {
      window.scrollTo(0, 0);
      this.getChildSearch(this.state.current_pageSearch);
    }
  }

  searchHandler = (e) => {
    e.preventDefault();
    const { fname, lname, birthDate } = this.state;

    let DOB = birthDate ? convert(birthDate) : "";
    if (fname || lname || DOB) {
      this.setState({ search: true }, () =>
        this.getChildSearch(this.state.current_pageSearch)
      );
    } else {
      swal("Oops!", "Please fill atleast one field!", "error");
    }
  };
  resetSearch = (e) => {
    e.preventDefault();
    this.setState({ search: false, birthDate: "", fname: "", lname: "" }, () =>
      this.getChild(1)
    );
  };
  setBirthDate = (date) => {
    if (this.state.current_page !== 1) {
      return this.setState({ birthDate: date, current_page: 1 });
    }
    this.setState({ birthDate: date });
  };
  onChangeHandle = (e) => {
    if (this.state.current_page !== 1) {
      return this.setState({
        [e.target.name]: e.target.value,
        current_page: 1,
        [e.target.name + "Error"]: false,
      });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { birthDate } = this.state;
    return (
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__body">
          <div className="row">
            {" "}
            <div className="col-xl-4">
              <Form onSubmit={this.searchHandler}>
                <Row>
                  <Col md={4}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>First name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="First name"
                        className={""}
                        name="fname"
                        onChange={(e) => this.onChangeHandle(e)}
                        value={this.state.fname}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Last name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Last name"
                        name="lname"
                        value={this.state.lname}
                        onChange={this.onChangeHandle}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Date</Form.Label>
                      <DatePicker
                        selected={birthDate || ""}
                        onChange={(date) => this.setBirthDate(date)}
                        maxDate={new Date()}
                        onChangeRaw={(event) => {
                          if (
                            event.target.value &&
                            !validateInput(event.target.value)
                          ) {
                            event.preventDefault();
                          }
                        }}
                        startDate={birthDate || ""}
                        // endDate={end_date}
                        placeholderText="DOB"
                        className="form-control childDateName"
                        showYearDropdown
                        scrollableMonthYearDropdown
                        // fullWidth
                        //  maxDate={new Date()}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div className="text-center mb-4">
                  {this.state.search ? (
                    <Button style={btnColor} onClick={this.resetSearch}>
                      Reset
                    </Button>
                  ) : (
                    <Button type="submit" style={btnColor}>
                      Search
                    </Button>
                  )}
                </div>
              </Form>
            </div>
            {/* <div className="col-xl-4">
              <Form onSubmit={this.searchHandler}>
                <Form.Row>
                  <Form.Group as={Col} md="4">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First name"
                      name="fname"
                      value={this.state.fname}
                      onChange={this.onChangeHandle}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last name"
                      name="lname"
                      value={this.state.lname}
                      onChange={this.onChangeHandle}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>Date</Form.Label>
                    <div>
                      <DatePicker
                        selected={birthDate || ""}
                        onChange={(date) => this.setBirthDate(date)}
                        selectsStart
                        startDate={birthDate || ""}
                        // endDate={end_date}
                        placeholderText="DOB"
                        className="form-control childDateName"
                        showYearDropdown
                        scrollableMonthYearDropdown
                        // fullWidth
                        //  maxDate={new Date()}
                      />
                    </div>
                  </Form.Group>
                </Form.Row>
                <div className="text-center mb-4">
                  {this.state.search ? (
                    <Button style={btnColor} onClick={this.resetSearch}>
                      Reset
                    </Button>
                  ) : (
                    <Button type="submit" style={btnColor}>
                      Search
                    </Button>
                  )}
                </div>
              </Form>
            </div> */}
          </div>
          {this.state.loader ? (
            <div
              className="spinner-border text-warning loaderStyle"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <ChildProfileTable
              tableData={this.state.tableData}
              activePage={this.state.current_page}
              itemsCountPerPage={10}
              totalItemsCount={this.state.total}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange}
              getChild={this.getChild}
            />
          )}
          {this.state.total > 10 && !this.state.loader ? (
            <Pagination
              activePage={this.state.current_page}
              itemsCountPerPage={10}
              totalItemsCount={this.state.total}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange.bind(this)}
              itemclass="page-item"
              linkclass="page-link"
              innerClass="pagination"
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(ChildProfile);
