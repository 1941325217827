import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { Form } from "react-bootstrap";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

export default class EditMentorTable extends Component {
  render() {
    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              {/* <StyledTableCell align="center">Attended</StyledTableCell> */}
              <StyledTableCell align="center">Attendent</StyledTableCell>
              <StyledTableCell align="center">
                Mentor First Name
              </StyledTableCell>
              <StyledTableCell align="center">Mentor Last Name</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.tableData &&
              this.props.tableData.map((row, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell align="center">
                    <Form.Check
                      defaultChecked={
                        this.props.checked_mentor.includes(row.mentorID)
                          ? true
                          : false
                      }
                      onChange={() => this.props.onChangeHandle(row.mentorID)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.firstName ? row.firstName : "-"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.lastName ? row.lastName : "-"}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}
