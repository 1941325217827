import React, { Component } from "react";
import { Button, Form, Container, Col, Row } from "react-bootstrap";
import EditIcon from "@material-ui/icons/Edit";
import { IconButton } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DemoLogo from "../../images/demoDp.jpeg";
import { addMentor, getChruchListRequest } from "../../crud/auth.crud";
import swal from "sweetalert";
import "./addmentor.css";
const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

const ethnicityArray = [
  "African American",
  "Latino/Hispanic",
  "Asian",
  "Caucasian",
  "Other",
];

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

const emptyState = {
  male: false,
  female: false,
  birthDate: "",
  ethnicity: "African Americans",
  first_name: "",
  last_name: "",
  password: "",
  c_password: "",
  file: null,
};

export default class AddMentor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      male: false,
      female: false,
      birthDate: "",
      ethnicity: "African Americans",
      first_name: "",
      last_name: "",
      password: "",
      c_password: "",
      file: null,
      churchList: [],
      church: null,
    };
  }

  setBirthDate = (date) => {
    this.setState({ birthDate: date });
  };

  onChangeHandle = (e) => {
    console.log("🚀 ~ AddMentor ~ e:", e);
    this.setState({ [e.target.name]: e.target.value });
  };

  imageUpload = (e) => {
    this.setState({
      file: URL.createObjectURL(e.target.files[0]),
      fileData: e.target.files[0],
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const {
      male,
      birthDate,
      ethnicity,
      first_name,
      last_name,
      fileData,
      church,
    } = this.state;
    try {
      let result = await addMentor(
        male ? "Male" : "Female",
        convert(birthDate),
        ethnicity,
        first_name,
        last_name,
        fileData,
        church
      );
      if (result && result.status === 200) {
        swal("Mentor Added Successfully!", "", "success");
        this.setState(emptyState);
      } else {
        swal("Oops!", "Network Error!", "error");
      }
    } catch (err) {
      swal("Oops!", err.response.data.error, "error");
    }
  };

  async componentDidMount() {
    await this.getChruchList();
  }

  getChruchList = async () => {
    try {
      const result = await getChruchListRequest();
      if (result.status === 200) {
        this.setState({ churchList: result.data.data, loader: false });
      }
    } catch {
      this.setState({ loader: false });
      swal("Oops!", "Network Error!", "error");
    }
  };

  render() {
    const { birthDate, ethnicity, first_name, last_name, church } = this.state;
    return (
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__body">
          <div className="row">
            {/* <h5>Mentor Personal Information</h5>
            <div className="kt-separator kt-separator--dashed"></div> */}
            <div className="col-xl-4">
              <Container style={{ maxWidth: "550px" }}>
                <Form onSubmit={this.onSubmit}>
                  <Form.Group
                    as={Row}
                    controlId="formHorizontalEmail"
                    className="form-group"
                  >
                    <input
                      id="myInput"
                      type="file"
                      onChange={this.imageUpload}
                      accept="image/*"
                      ref={(ref) => (this.upload = ref)}
                      style={{ display: "none" }}
                    />
                    <Form.Label column sm={4}>
                      Photo
                    </Form.Label>
                    <Col sm={8}>
                      <div className="photoAddMentor">
                        <img
                          src={this.state.file ? this.state.file : DemoLogo}
                          width="100"
                          height="100"
                          alt="logo"
                        ></img>
                        <div className="iconAddMentor">
                          <IconButton
                            aria-label="Delete"
                            onClick={(e) => this.upload.click()}
                          >
                            <EditIcon color="primary" />
                          </IconButton>
                        </div>
                      </div>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="formHorizontalEmail"
                    className="form-group"
                  >
                    <Form.Label column sm={4}>
                      First Name
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        required
                        type="text"
                        name="first_name"
                        value={first_name}
                        onChange={this.onChangeHandle}
                        placeholder="First Name"
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    controlId="formHorizontalPassword"
                    className="form-group"
                  >
                    <Form.Label column sm={4}>
                      Last Name
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        type="text"
                        required
                        name="last_name"
                        value={last_name}
                        onChange={this.onChangeHandle}
                        placeholder="Last Name"
                      />
                    </Col>
                  </Form.Group>
                  <fieldset>
                    <Form.Group
                      as={Row}
                      className="form-group  align-items-center"
                    >
                      <Col sm={4}>
                        <Form.Label column>Gender</Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Row className="ml-2">
                          <Col sm={3}>
                            <Form.Check
                              required
                              type="radio"
                              label="Male"
                              name="formHorizontalRadios"
                              id="formHorizontalRadios1"
                              checked={this.state.male}
                              onChange={() =>
                                this.setState({ male: !this.state.male })
                              }
                            />
                          </Col>
                          <Col sm={3}>
                            <Form.Check
                              required
                              type="radio"
                              label="Female"
                              name="formHorizontalRadios"
                              id="formHorizontalRadios2"
                              checked={this.state.female}
                              onChange={() =>
                                this.setState({ female: !this.state.female })
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Form.Group>
                  </fieldset>
                  <Form.Group
                    as={Row}
                    controlId="formHorizontalPassword"
                    className="form-group"
                  >
                    <Form.Label column sm={4}>
                      Date of Birth
                    </Form.Label>
                    <Col sm={8}>
                      <DatePicker
                        required
                        selected={birthDate}
                        onChange={(date) => this.setBirthDate(date)}
                        selectsStart
                        startDate={birthDate}
                        // endDate={end_date}
                        placeholderText="DOB"
                        className="form-control dateWidthMentor"
                        // fullWidth
                        maxDate={new Date()}
                        showYearDropdown
                        scrollableMonthYearDropdown
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="exampleForm.ControlSelect1"
                    className="form-group"
                  >
                    <Form.Label column sm={4}>
                      Ethnicity
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        as="select"
                        required
                        value={ethnicity}
                        name="ethnicity"
                        onChange={this.onChangeHandle}
                        placeholder="Ethnicity"
                      >
                        {ethnicityArray.map((eth, i) => (
                          <option value={eth} key={i}>
                            {eth}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="exampleForm.ControlSelect1"
                    className="form-group"
                  >
                    <Form.Label column sm={4}>
                      Church
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        as="select"
                        required
                        name="church"
                        placeholder="Select Church"
                        value={church}
                        onChange={this.onChangeHandle}
                      >
                        <option value="" selected disabled>
                          Select Church
                        </option>
                        {this.state.churchList &&
                          this.state.churchList.map((el, i) => (
                            <option key={el.id} value={el.church}>
                              {el.church}
                            </option>
                          ))}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  {/* <Form.Group as={Row} controlId="formHorizontalPassword">
                    <Form.Label column sm={4}>
                    Password
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="password"
                      name="password" required
                      value={password} onChange={this.onChangeHandle}
                      placeholder="password"
                      pattern=".{6,}" title="Six or more characters"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formHorizontalPassword">
                    <Form.Label column sm={4}>
                    Confirm Password
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control required
                      name="c_password"
                      value={c_password} onChange={this.onChangeHandle}
                      type="password"   pattern=".{6,}" title="Six or more characters" placeholder="confirm password" />
                    </Col>
                  </Form.Group> */}
                  <Form.Group as={Row} className="text-center form-group">
                    <Col sm={{ span: 10, offset: 2 }}>
                      <Button type="submit" style={btnColor}>
                        Submit
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
              </Container>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
