import React from "react";
import { Bar } from "react-chartjs-2";
import { Typography } from "@material-ui/core";
import "./dashboard.css";
import "chartjs-plugin-datalabels";

export default class ConcentratedFocusTutoringChart extends React.Component {
  render() {
    return (
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              Urban Impact gets kids out of PT to grow perspective
            </h3>
          </div>
          {/* <PortletHeaderDropdown /> */}
        </div>
        <div className="kt-portlet__body">
          <Typography
            variant="caption"
            display="block"
            className="mb-4 dashboardText"
          >
            Urban Impact encourages its volunteers to foster personal
            relationships with the kids, taking the kids with them on errands,
            to farms, to help with lawn work, anything to get them out of PT
            Barnum. <br /> <br />
            In addition, Urban Impact engages in several events to encourage fun
            and to change the scenery a bit :
          </Typography>
          <Bar
            data={{
              labels:
                this.props.pt_grow_perspective &&
                this.props.pt_grow_perspective.length > 0
                  ? this.props.pt_grow_perspective.map((val) => val.programs)
                  : [
                      "Writing Club at FU",
                      "Writing Club at FU",
                      "Writing Club at FU",
                      "Writing Club at FU",
                      "Writing Club at FU",
                      "Revolution RPM night",
                      "RPM Revolution offsite",
                      "Drama Club at FU",
                      "Drama Club at FU",
                      "Drama Club at FU",
                      "Test",
                      "Week of Hope",
                    ],
              datasets: [
                {
                  label: "Kids in program",
                  backgroundColor: "#f4b53f",
                  data:
                    this.props.pt_grow_perspective &&
                    this.props.pt_grow_perspective.length > 0
                      ? this.props.pt_grow_perspective.map(
                          (val) => val.kidcount
                        )
                      : [],
                },
                {
                  label: "Total hours in program",
                  backgroundColor: "#a09999",
                  data:
                    this.props.pt_grow_perspective &&
                    this.props.pt_grow_perspective.length > 0
                      ? this.props.pt_grow_perspective.map((val) => val.hours)
                      : [],
                },
              ],
            }}
            options={{
              plugins: {
                datalabels: {
                  display: true,
                  color: "#5c5a6d",
                  align: "end",
                  clamp: true,
                },
              },
              title: {
                display: true,
                // text:'Average Rainfall per month',
                fontSize: 10,
              },
              legend: {
                display: true,
                position: "right",
              },
            }}
          />
        </div>
      </div>
    );
  }
}
