/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import { LayoutContextConsumer } from "../LayoutContext";
import * as builder from "../../ducks/builder";
// import BreadCrumbs from "./components/BreadCrumbs";

function subHeaderMenu(path) {
  if (path === "/mentoring") {
    return "Mentoring";
  }
  if (path === "/tutoring") {
    return "Tutoring";
  }
  if (path === "/perspective") {
    return "Perspective";
  }
  if (path === "/feeding") {
    return "Feeding";
  }
  if (path === "/hours") {
    return "Hours";
  }
  if (path === "/childProfile-2") {
    return "Search By Program";
  }
  if (path === "/childProfile-3") {
    return "Search By Address";
  }
  if (path === "/childProfile-4") {
    return "Search By Permission Slip";
  }
  if (path === "/childProfile-5") {
    return "Search By Date Range";
  }

  if (path === "/programs/scoreboard") {
    return "Scoreboard";
  }
  if (path === "/programs/activity") {
    return "Activity";
  }
  if (path === "/programs/progReports") {
    return "Reports";
  }
  if (path === "/programs/programSetting") {
    return "Setting";
  }
  if (path === "/programs/KPK") {
    return "KPK";
  }
  if (path === "/progList/scoreboard") {
    return "Scoreboard";
  }
  if (path === "/progList/activity") {
    return "Activity";
  }
  if (path === "/progList/all-Reports") {
    return "Reports";
  }
  if (path === "/progList/all-Setting") {
    return "Setting";
  }
  if (path === "/XLRB/scoreboard") {
    return "XLRB Scoreboard";
  }
  if (path === "/XLRB/activity") {
    return "XLRB Activity";
  }
  if (path === "/XLRB/all-Reports") {
    return "XLRB Reports";
  }
  if (path === "/XLRB/all-Setting") {
    return "XLRB Setting";
  }
  if (path === "/specialEvents") {
    return "Special Events";
  }
  if (path === "/programs/permissionSlipTracking") {
    return "Permission Slip Tracking";
  }
  if (path === "/programs/permissionSlipTrackingGrades") {
    return "Permission Slip Tracking-Grades";
  }
  if (path === "/programs/mentorSetup") {
    return "Mentor Setup";
  }
  if (path === "/programs/mentorToChildConfig") {
    return "Mentor to kid Relationship for kid power";
  }
  if (path === "/adminUserRegistration") {
    return "User Registration";
  }
  if (path === "/sessionManagement") {
    return "Session Management";
  }
  if (path === "/programDates") {
    return "Program Dates";
  }
  if (path === "/adminTool") {
    return "Admin Tool";
  }
  if (path === "/addAdminProgram") {
    return "Add Admin Program";
  }
  if (path === "/addSlip") {
    return "Add Slip";
  }
  if (path === "/profileInfo") {
    return "Profile Information";
  }
  if (path === "/mentorProfileSummary") {
    return "Summary";
  }
  if (path === "/mentorProfileNotes") {
    return "Notes";
  }
  if (path === "/mentorPersonalInfo") {
    return "Mentor Personal Information";
  }
  if (path === "/adminProgDates/list") {
    return "Program Dates";
  }
  if (path === "/adminProgDates/1") {
    return "PrimeTime and 5 loaves";
  }
  if (path === "/adminProgDates/2") {
    return "XLRB";
  }
  if (path === "/adminProgDates/3") {
    return "Shepherd";
  }
  if (path === "/adminProgDates/4") {
    return "Kid Power";
  }
  if (path === "/kid-reports") {
    return "Kid Reports";
  }
  if (path === "/progList/permissionSlipTracking") {
    return "Permission Slip Tracking";
  }
  if (path === "/progList/permissionSlipTrackingGrades") {
    return "Permission Slip Tracking - Grades";
  }
  if (path === "/progList/editWeeklyUpdate") {
    return "Edit Weekly Update";
  }
  if (path === "/progList/editWeeklyUpdate") {
    return "Edit Weekly Update";
  }
  if (path === "/progList/mentorSetup") {
    return "Mentor Setup";
  }
  if (path === "/progList/mentorToChildConfig") {
    return "Mentor to Kid Relationship";
  }
  if (path === "/literacy-reports") {
    return "Literacy Report";
  }
  if (path === "/tutoringAnalysis") {
    return "Tutoring Analysis Report";
  }
  if (path === "/programs/subject-reports") {
    return "Subject Reports";
  }
  if (path === "/programs/subject-reports-details") {
    return "Subject Report Details";
  }
  if (path === "/addSubject") {
    return "Add Subject";
  }
  if (path === "/progList/graduates") {
    return "Graduates";
  }
  if (path === "/active-mentors") {
    return "Active Mentors Report";
  }
    if (path === "/church") {
      return "Add Church";
    }
}

class SubHeader extends React.Component {
  render() {
    const {
      subheaderCssClasses,
      subheaderContainerCssClasses,
      subheaderMobileToggle,
      currentPath,
    } = this.props;
    let tab = this.props.currentPath;

    return (
      <div
        id="kt_subheader"
        className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}
      >
        <div className={`kt-container ${subheaderContainerCssClasses}`}>
          <div className="kt-subheader__main">
            {subheaderMobileToggle && (
              <button
                className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                id="kt_subheader_mobile_toggle"
              >
                <span />
              </button>
            )}

            <LayoutContextConsumer>
              {/*{({ subheader: { title, breadcrumb } }) => (*/}

              {({ subheader: { title } }) => (
                <>
                  <h3 className="kt-subheader__title">{title}</h3>
                  {/*<BreadCrumbs items={breadcrumb} />*/}
                </>
              )}
            </LayoutContextConsumer>

            <span className="kt-subheader__separator kt-subheader__separator--v" />
            <span className="kt-subheader__desc">{subHeaderMenu(tab)}</span>
            <span className="kt-subheader__separator kt-subheader__separator--v" />
            {/* <span className="kt-subheader__desc">Summery</span> */}

            {/* <a
              href="#"
              className="btn btn-label-warning btn-bold btn-sm btn-icon-h kt-margin-l-10"
            >
              Summery
            </a> */}
          </div>

          {/* <div className="kt-subheader__toolbar">
            <div className="kt-subheader__wrapper">
              <button type="button" className="btn kt-subheader__btn-primary">
                Actions &nbsp;
                <SortNum1Icon className="kt-svg-icon kt-svg-icon--sm" />
              </button>
              <QuickActions />
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  subheaderMobileToggle: objectPath.get(
    store.builder.layoutConfig,
    "subheader.mobile-toggle"
  ),
  subheaderCssClasses: builder.selectors.getClasses(store, {
    path: "subheader",
    toString: true,
  }),
  subheaderContainerCssClasses: builder.selectors.getClasses(store, {
    path: "subheader_container",
    toString: true,
  }),
});

export default connect(mapStateToProps)(SubHeader);
