/* eslint-disable jsx-a11y/anchor-has-content,no-script-url,jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
//import { toAbsoluteUrl } from "../../_metronic/utils/utils";
//import PortletHeaderDropdown from "../partials/content/CustomDropdowns/PortletHeaderDropdown";

import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import { Badge } from "react-bootstrap";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { IconButton } from "@material-ui/core";
import swal from "sweetalert";
import { callApi } from "../../crud/auth.crud";
import AddGrades from "./addGrades";
import Notes from "./notes";
import "./childProfile.css";
import withRouter from "../../router/withRouter";
// import "bootstrap/dist/css/bootstrap.min.css";

const cursorStyle = {
  cursor: "grab",
};

class ChildProfileTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      tableData: [],
      loader: true,
      total: null,
      per_page: null,
      current_page: 1,
      id: "",
      openGrade: false,
      childID: "",
    };
  }

  seeProfile = (name, id) => {
    this.props.navigate(name ? `/profileInfo?${name}` : `/profileInfo`, {
      state: { childId: id },
    });
    // this.props.history.push("/profileInfo?"+name);
  };

  handleOpen = (id) => {
    this.setState({ open: true, id: id });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpenGrade = (id) => {
    this.setState({ openGrade: true, childID: id });
  };

  handleCloseGrade = () => {
    this.setState({ openGrade: false });
  };

  deleteChild = async (child_id) => {
    try {
      const result = await callApi("delete-child", { child_id });
      if (result.status === 200) {
        swal("Child Deleted Successfully!", "", "success");
        this.props.getChild();
      } else {
        swal("Oops!", "Please try again!!", "error");
      }
    } catch {
      swal("Oops!", "Network Error!!", "error");
    }
  };
  deleteHandlerConfirm = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.deleteChild(id);
      }
    });
  };

  renderTable = () => {
    const { tableData } = this.props;
    return (
      tableData &&
      tableData.map((row, index) => (
        <TableRow key={index}>
          <TableCell>{row.firstName}</TableCell>
          <TableCell>{row.lastName}</TableCell>
          <TableCell align="center">
            <Badge
              pill
              bg="blue"
              // className="bg-primary"
              onClick={() => this.seeProfile("seeProfile", row.childID)}
              style={{
                backgroundColor: "#606edf",
                color: "#fff",
                cursor: "grab",
              }}
            >
              See Profile
            </Badge>
          </TableCell>
          <TableCell align="center">
            {row.grades_exist ? (
              <Badge
                pill
                bg="danger"
                className=""
                style={cursorStyle}
                onClick={() => this.seeProfile("grades", row.childID)}
              >
                See Grades
              </Badge>
            ) : (
              <Badge
                pill
                bg="dark"
                className=""
                style={cursorStyle}
                onClick={() => this.handleOpenGrade(row.childID)}
              >
                Add Grades
              </Badge>
            )}
          </TableCell>
          <TableCell align="center">
            <Badge
              pill
              bg="warning"
              style={{
                color: "#000",
                cursor: "grab",
              }}
              onClick={() => this.seeProfile("addInfo", row.childID)}
            >
              Add Info
            </Badge>
          </TableCell>
          <TableCell align="center">
            <Badge
              pill
              bg="success"
              style={cursorStyle}
              onClick={() => this.handleOpen(row.childID)}
            >
              Notes
            </Badge>
          </TableCell>
          {localStorage.getItem("role") === "SuperAdmin" && (
            <TableCell align="center">
              <IconButton
                aria-label="Delete"
                onClick={() => this.deleteHandlerConfirm(row.childID)}
              >
                <DeleteOutlineIcon style={{ color: "red" }} />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      ))
    );
  };
  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="kt-section">
            <div className="kt-section__content">
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>First Name</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        {localStorage.getItem("role") === "SuperAdmin" && (
                          <TableCell></TableCell>
                        )}
                        {/* <TableCell align="right">Fat&nbsp;(g)</TableCell>
                        <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                        <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.tableData.length > 0 ? (
                        this.renderTable()
                      ) : (
                        <TableRow>
                          <TableCell className="text-center" colSpan={7}>
                            No Records
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
        </div>
        <div className="float-right"></div>
        {this.state.open ? (
          <Notes
            handleOpen={this.handleOpen}
            open={this.state.open}
            handleClose={this.handleClose}
            id={this.state.id}
          />
        ) : null}

        {this.state.openGrade ? (
          <AddGrades
            child_id={this.state.childID ? this.state.childID : ""}
            handleOpen={this.handleOpenGrade}
            open={this.state.openGrade}
            handleClose={this.handleCloseGrade}
            key="search_table"
            getChild={this.props.getChild}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(ChildProfileTable);
