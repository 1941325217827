import React, { Component } from "react";
import PrimeTimeAndLoaves from "./primeTime&5Loaves";
import List from "./list";
import XLRB from "./XLRB";
import Shepherd from "./shephered";
import KidPower from "./kidPower";
import withRouter from "../../../router/withRouter";

class ProgramDates extends Component {
  moveToAdjPage = (pageName) => {
    this.props.navigate("/adminProgDates/" + pageName);
  };
  render() {
    let tab = this.props.params.tab;
    return (
      <>
        {tab === "list" ? <List {...this.props} /> : null}
        {tab === "1" ? <PrimeTimeAndLoaves /> : null}
        {tab === "2" ? <XLRB /> : null}
        {tab === "3" ? <Shepherd /> : null}
        {tab === "4" ? <KidPower /> : null}
      </>
    );
  }
}

export default withRouter(ProgramDates);
