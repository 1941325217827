import React, { Component } from "react";
import { Card, Form, Row } from "react-bootstrap";
import { Button } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import RoomIcon from "@material-ui/icons/Room";
import ComputerIcon from "@material-ui/icons/Computer";
import StarRateIcon from "@material-ui/icons/StarRate";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import PersonalInfo from "./personalInfo";
import LocationInfo from "./locationInfo";
import ProgramInvolvemment from "./programInvolvement";
import EmergencyAndLiability from "./emergencyAndLiability";
import GradesData from "./gradesData";
import PermissionSlip from "./permissionSlip";
import Clone from "./clone";
import Notes from "./notes";
import { fetchData } from "../../crud/auth.crud";
import swal from "sweetalert";
import DemoLogo from "../../images/demoDp.jpeg";
import moment from "moment";
import withRouter from "../../router/withRouter";

const btnText = { textTransform: "unset", fontSize: "9px" };
// const btnColor={
//   backgroundColor:"#f4b53f",
//   border:'none'
// }

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

class ProfileInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      personalInfoShow: true,
      locationInfoShow: false,
      progInvolveShow: false,
      emergencyAndLiability: false,
      gradesData: false,
      permissionSlip: false,
      openClone: false,
      open: false,
      birthDate: "",
      grade: "",
      building: "",
      street: "",
      state: "",
      zip: "",
      city: "",
      fname: "",
      lname: "",
      school: "",
      Information: "",
      file: null,
    };
  }
  handleOpenClone = () => {
    this.setState({ openClone: true });
  };

  handleCloseClone = () => {
    this.setState({ openClone: false });
  };
  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  async componentDidMount() {
    await this.seeProfile();

    if (this.props.location.search === "?grades") {
      this.setState({
        gradesData: true,
        emergencyAndLiability: false,
        progInvolveShow: false,
        locationInfoShow: false,
        personalInfoShow: false,
        permissionSlip: false,
      });
    }
    if (this.props.location.search === "?addInfo") {
      this.setState({
        emergencyAndLiability: true,
        progInvolveShow: false,
        locationInfoShow: false,
        personalInfoShow: false,
        gradesData: false,
        permissionSlip: false,
      });
    }
  }

  changeInfoView = (name) => {
    if (name === "personal") {
      this.setState({
        personalInfoShow: true,
        locationInfoShow: false,
        progInvolveShow: false,
        emergencyAndLiability: false,
        gradesData: false,
      });
    }
    if (name === "location") {
      this.setState({
        locationInfoShow: true,
        personalInfoShow: false,
        progInvolveShow: false,
        emergencyAndLiability: false,
        gradesData: false,
        permissionSlip: false,
      });
    }
    if (name === "progInvolve") {
      this.setState({
        progInvolveShow: true,
        locationInfoShow: false,
        personalInfoShow: false,
        emergencyAndLiability: false,
        gradesData: false,
        permissionSlip: false,
      });
    }
    if (name === "emergencyAndLiability") {
      this.setState({
        emergencyAndLiability: true,
        progInvolveShow: false,
        locationInfoShow: false,
        personalInfoShow: false,
        gradesData: false,
        permissionSlip: false,
      });
    }
    if (name === "gradesData") {
      this.setState({
        gradesData: true,
        emergencyAndLiability: false,
        progInvolveShow: false,
        locationInfoShow: false,
        personalInfoShow: false,
        permissionSlip: false,
      });
    }
    if (name === "permissionSlip") {
      this.setState({
        permissionSlip: true,
        gradesData: false,
        emergencyAndLiability: false,
        progInvolveShow: false,
        locationInfoShow: false,
        personalInfoShow: false,
      });
    }
  };

  seeProfile = async () => {
    let id = this.props.location.state.childId
      ? this.props.location.state.childId
      : "";
    if (id)
      try {
        const result = await fetchData(`seeprofile?child_id=${id}`);
        let details = result.data.data;
        if (result.status === 200) {
          this.setState({
            fname: details.child.firstName,
            lname: details.child.lastName,
            birthDate: details.child.dateOfBirth,
            grade: details.child.grade,
            building: details.child.building,
            street: details.child.street,
            state: details.child.state,
            zip: details.child.zipCode,
            city: details.child.city,
            school: details.school.schoolName,
            Information: result.data.data,
            file: details.child.child_image,
          });
        }
      } catch {
        swal("Oops!", "Network Error!", "error");
      }
  };

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    const {
      fname,
      lname,
      birthDate,
      grade,
      building,
      street,
      city,
      state,
      zip,
      school,
    } = this.state;
    console.log(this.state, this.props, "***********");

    return (
      <>
        <div className="row">
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4">
            <div className="kt-section">
              <span className="kt-section__sub"></span>
              <Card style={{ width: "21rem" }}>
                <Card.Body>
                  <Card.Title>
                    <Row>
                      <span>
                        <img
                          src={this.state.file ? this.state.file : DemoLogo}
                          width="50"
                          height="50"
                          alt="logo_img"
                        ></img>
                      </span>
                      <span className="px-2 mt-4">
                        <p>{fname + " " + lname}</p>
                      </span>
                    </Row>
                  </Card.Title>

                  <Card.Text>
                    <Form
                    // onSubmit={handleSubmit}
                    >
                      <Row>
                        {/* <Form.Group as={Col} md="12"> */}
                        <div className="d-flex justify-content-between col-md-12">
                          <span className="font-weight-bold">Age :</span>
                          <span>
                            {getAge(birthDate) && getAge(birthDate) > 0
                              ? getAge(birthDate)
                              : "-"}
                          </span>
                        </div>

                        {/* </Form.Group> */}
                        {/* <Form.Group as={Col} md="12" > */}
                        <div className="d-flex justify-content-between col-md-12">
                          <span className="font-weight-bold">Birthday :</span>
                          <span>
                            {birthDate
                              ? moment(birthDate).format("MM-DD-YYYY")
                              : "-"}
                          </span>
                        </div>
                        {/* </Form.Group> */}
                        <div className="d-flex justify-content-between col-md-12">
                          <span className="font-weight-bold">Grade :</span>
                          <span>{grade}</span>
                        </div>
                        <div className="d-flex justify-content-between col-md-12">
                          <span className="font-weight-bold">School :</span>
                          <span>{school}</span>
                        </div>

                        <div className="d-flex justify-content-between col-md-12 mt-4 mb-2">
                          <span>Location</span>
                        </div>
                        <div className="d-flex justify-content-between col-md-12">
                          <span className="font-weight-bold">Building :</span>
                          <span>{building}</span>
                        </div>
                        <div className="d-flex justify-content-between col-md-12">
                          <span className="font-weight-bold">Street :</span>
                          <span>{street}</span>
                        </div>

                        <div className="d-flex justify-content-between col-md-12">
                          <span className="font-weight-bold">City :</span>
                          <span>{city}</span>
                        </div>
                        <div className="d-flex justify-content-between col-md-12">
                          <span className="font-weight-bold">State :</span>
                          <span>{state}</span>
                        </div>
                        <div className="d-flex justify-content-between col-md-12">
                          <span className="font-weight-bold">Zip Code :</span>
                          <span>{zip}</span>
                        </div>
                        <div className="d-flex justify-content-around col-md-12 mt-2">
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={this.handleOpenClone}
                          >
                            Clone
                          </Button>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={this.handleOpen}
                          >
                            Notes
                          </Button>
                        </div>
                      </Row>
                    </Form>

                    <Form
                      // onSubmit={handleSubmit}
                      className="mt-4"
                    >
                      <div className="d-flex items-center">
                        <div>
                          <PersonIcon />
                        </div>
                        <div className="px-2">
                          <Button
                            color={
                              this.state.personalInfoShow
                                ? "primary"
                                : "inherit"
                            }
                            size="small"
                            onClick={() => this.changeInfoView("personal")}
                            style={btnText}
                          >
                            Personal Information
                          </Button>
                        </div>
                      </div>
                      <div className="d-flex items-center">
                        <div>
                          <RoomIcon />
                        </div>
                        <div className="px-2">
                          <Button
                            color={
                              this.state.locationInfoShow
                                ? "primary"
                                : "inherit"
                            }
                            size="small"
                            style={btnText}
                            onClick={() => this.changeInfoView("location")}
                          >
                            Location Information
                          </Button>
                        </div>
                      </div>
                      <div className="d-flex items-center">
                        <div>
                          <ComputerIcon />
                        </div>
                        <div className="px-2">
                          <Button
                            size="small"
                            color={
                              this.state.progInvolveShow ? "primary" : "inherit"
                            }
                            onClick={() => this.changeInfoView("progInvolve")}
                            style={btnText}
                          >
                            Program Involvement
                          </Button>
                        </div>
                      </div>
                      <div className="d-flex items-center">
                        <div>
                          <ComputerIcon />
                        </div>
                        <div className="px-2">
                          <Button
                            color={
                              this.state.emergencyAndLiability
                                ? "primary"
                                : "inherit"
                            }
                            size="small"
                            style={btnText}
                            onClick={() =>
                              this.changeInfoView("emergencyAndLiability")
                            }
                          >
                            Emergency and Liability Information
                          </Button>
                        </div>
                      </div>
                      <div className="d-flex items-center">
                        <div>
                          <StarRateIcon />
                        </div>
                        <div className="px-2">
                          <Button
                            color={
                              this.state.gradesData ? "primary" : "inherit"
                            }
                            size="small"
                            style={btnText}
                            onClick={() => this.changeInfoView("gradesData")}
                          >
                            Grades Data
                          </Button>
                        </div>
                      </div>
                      <div className="d-flex items-center">
                        {" "}
                        <div>
                          <BookmarksIcon />
                        </div>
                        <div className="px-2">
                          <Button
                            color={
                              this.state.permissionSlip ? "primary" : "inherit"
                            }
                            size="small"
                            style={btnText}
                            onClick={() =>
                              this.changeInfoView("permissionSlip")
                            }
                          >
                            Permission Slip
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </Card.Text>
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
            </div>
            {this.state.openClone ? (
              <Clone
                handleOpen={this.handleOpenClone}
                open={this.state.openClone}
                handleClose={this.handleCloseClone}
                id={
                  this.props.location.state && this.props.location.state.childId
                    ? this.props.location.state.childId
                    : ""
                }
              />
            ) : null}
            {this.state.open ? (
              <Notes
                handleOpen={this.handleOpen}
                open={this.state.open}
                handleClose={this.handleClose}
                id={
                  this.props.location.state && this.props.location.state.childId
                    ? this.props.location.state.childId
                    : ""
                }
              />
            ) : null}
          </div>
          {this.state.locationInfoShow ? (
            <LocationInfo
              childId={
                this.props.location.state && this.props.location.state.childId
                  ? this.props.location.state.childId
                  : ""
              }
              Information={this.state.Information}
              seeProfile={this.seeProfile}
            />
          ) : null}
          {this.state.personalInfoShow ? (
            <PersonalInfo
              childId={
                this.props.location.state && this.props.location.state.childId
                  ? this.props.location.state.childId
                  : ""
              }
              // Information={this.state.Information.child}
              seeProfile={this.seeProfile}
            />
          ) : null}
          {this.state.progInvolveShow ? (
            <ProgramInvolvemment
              childId={
                this.props.location.state && this.props.location.state.childId
                  ? this.props.location.state.childId
                  : ""
              }
            />
          ) : null}
          {this.state.emergencyAndLiability ? (
            <EmergencyAndLiability
              childId={
                this.props.location.state && this.props.location.state.childId
                  ? this.props.location.state.childId
                  : ""
              }
            />
          ) : null}
          {this.state.gradesData ? (
            <GradesData
              childId={
                this.props.location.state && this.props.location.state.childId
                  ? this.props.location.state.childId
                  : ""
              }
            />
          ) : null}
          {this.state.permissionSlip ? (
            <PermissionSlip
              childId={
                this.props.location.state && this.props.location.state.childId
                  ? this.props.location.state.childId
                  : ""
              }
            />
          ) : null}
        </div>
      </>
    );
  }
}

export default withRouter(ProfileInfo);
