/* eslint-disable jsx-a11y/anchor-has-content,no-script-url,jsx-a11y/anchor-is-valid */
//import { toAbsoluteUrl } from "../../_metronic/utils/utils";
import React, { Component } from "react";
import axios from "axios";
import { serverUrl } from "../../../_metronic/utils/api";
import swal from "sweetalert";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TableContainer,
} from "@material-ui/core";
import { Badge } from "react-bootstrap";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import "./mentor.css";
import withRouter from "../../router/withRouter";

const badgeInfo = {
  backgroundColor: "#606edf",
  color: "#fff",
  cursor: "grab",
};

const badgeNotes = {
  backgroundColor: "#44bb87",
  color: "#fff",
  cursor: "grab",
};

class MentorProfileTable extends Component {
  moveToAdjPage = (pageName, id) => {
    this.props.navigate("/" + pageName, { state: { detail: id } });
  };

  deleteProgram = async (id) => {
    try {
      const result = await axios.post(
        `${serverUrl}delete-mentor`,
        { mentor_id: id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (result.status === 200) {
        swal("Mentor Deleted Successfully!", "", "success");
        this.props.getMentor();
        // this.setState({ tableData: result.data.data });
      } else {
        swal("Oops!", "Please try again!!", "error");
      }
    } catch {
      swal("Oops!", "Network Error!!", "error");
    }
  };
  deleteHandlerConfirm = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.deleteProgram(id);
        // swal("Data has been deleted!", {
        //   icon: "success",
        // });
      }
    });
  };
  render() {
    return (
      <>
        {/* <Container style={maxWidth}> */}

        <div className="row">
          <div className="col-md-12">
            {/* <CodeExample> */}
            <div className="kt-section">
              {/* <span className="kt-section__sub">
                A simple example with no frills.
              </span> */}
              {/* <div className="kt-separator kt-separator--dashed"></div> */}
              <div className="kt-section__content">
                <Paper>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>First Name</TableCell>
                          <TableCell>Last Name</TableCell>
                          <TableCell>Assigned Program</TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          {/* <TableCell align="right">Fat&nbsp;(g)</TableCell>
                        <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                        <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.mentors.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell>{row.firstName}</TableCell>
                            <TableCell>{row.lastName}</TableCell>
                            <TableCell>{row.assigned_program}</TableCell>
                            <TableCell>

                              <Badge
                                pill
                                // className="BadgeStyleCursor"
                                bg="blue"
                                style={badgeInfo}
                                onClick={() =>
                                  this.moveToAdjPage(
                                    "mentorPersonalInfo",
                                    row.mentorID
                                  )
                                }
                              >
                                See Profile
                              </Badge>
                            </TableCell>
                            <TableCell>
                              <Badge
                                pill
                                className="BadgeStyleCursor"
                                bg="primary"
                                style={badgeInfo}
                                onClick={() =>
                                  this.moveToAdjPage(
                                    "mentorProfileSummary",
                                    row.mentorID
                                  )
                                }
                              >
                                Summary
                              </Badge>
                            </TableCell>
                            <TableCell>
                              <Badge
                                pill
                                style={badgeNotes}
                                bg="success"
                                onClick={() =>
                                  this.moveToAdjPage(
                                    "mentorProfileNotes",
                                    row.mentorID
                                  )
                                }
                              >
                                Notes
                              </Badge>
                            </TableCell>
                            <TableCell align="right">
                              {" "}
                              <IconButton
                                aria-label="Delete"
                                onClick={() =>
                                  this.deleteHandlerConfirm(row.mentorID)
                                }
                              >
                                <DeleteOutlineIcon style={{ color: "red" }} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </div>
            </div>
            {/* </CodeExample> */}
          </div>
        </div>
        {/* </Container> */}
      </>
    );
  }
}

export default withRouter(MentorProfileTable);
