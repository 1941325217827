import React, { Component } from "react";
import { Card, Form, Row } from "react-bootstrap";
import { Button } from "@material-ui/core";
import Scoreboard from "./scoreboard";
import Activity from "./activity";
import ProgReports from "./progReports";
import AttendancePerWeek from "./attendancePerWeek";
import PrintoutsAttendance from "./printoutsAttendance";
import ProgramSetting from "./programSetting";
import PermissionSlipTracking from "./permissionSlipTracking";
import MentorSetup from "./mentorSetup";
import MentorToChildConfig from "./mentorToChildConfig";
import EditWeeklyUpdate from "./editWeekelyUpdate";
import WalkList from "./walkList";
import ProgramGraduate from "./programGraduates";
import PrinoutSheet from "./prinoutSheet";
import KPK from "./KPK";
import AddedToCDI from "./addedToCDI";
import SubjectReports from "./subjectReports";
import SubjectReportsDetails from "./subjectReportsDetails";
import "./programs.css";
import withRouter from "../../../router/withRouter";

const btnText = { textTransform: "unset", fontSize: "12px" };

const KP_list = [
  { program: "KPK", value: "0" },
  { program: "KP1", value: "1" },
  { program: "KP2", value: "2" },
  { program: "KP3", value: "3" },
  { program: "KP4", value: "4" },
  { program: "KP5", value: "5" },
  { program: "KP6", value: "6" },
  { program: "KP7", value: "7" },
  { program: "KP8", value: "8" },
  { program: "KP9", value: "9" },
  { program: "KP10", value: "10" },
  { program: "KP11", value: "11" },
  { program: "KP12", value: "12" },
];
class Programs extends Component {
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    let obj = { name: "Kid Power", id: 6 };
    localStorage.setItem("progName", JSON.stringify(obj));
  }
  moveToTab = (tabName) => {
    this.props.navigate("/programs/" + tabName);
  };

  moveToKPK = (tabName, name, valueofKP) => {
    console.log("🚀 ~ Programs ~ valueofKP:", valueofKP);
    // this.props.navigate({
    //   pathname: "/programs/" + tabName,
    //   search: "?" + name,
    //   state: { grade: valueofKP },
    // });
    this.props.navigate(
      name ? `/programs/${tabName}?${name}` : `/programs/${tabName}`,
      {
        state: { grade: valueofKP },
      }
    );
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    let tab = this.props.location.pathname;
    console.log(tab, "*****", tab.includes("scoreboard"));
    let query_param = this.props?.location?.search?.replace("?", "");
    console.log("props",this.props.params.tab)
    return (
      <>
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 ">
            <div className="kt-section">
              <span className="kt-section__sub"></span>
              <Card style={{ width: "20rem" }} className="sidebar-card">
                <Card.Body>
                  <Card.Text>
                    <Form
                      // onSubmit={handleSubmit}
                      className="mt-4 ms-4"
                    >
                      <div className="py-2">
                        <span
                          className={
                            tab.includes("scoreboard") ? "borderLeftStyle" : ""
                          }
                        ></span>
                        <span className="">
                          <Button
                            color={
                              tab.includes("scoreboard") ? "primary" : "inherit"
                            }
                            size="small"
                            style={btnText}
                            onClick={() => this.moveToTab("scoreboard")}
                          >
                            Scorecard
                          </Button>
                        </span>
                      </div>
                      <div className="py-2">
                        <span
                          className={
                            tab.includes("activity") ||
                            tab.includes("adddedToCDI")
                              ? "borderLeftStyle"
                              : ""
                          }
                        ></span>
                        <span className="">
                          <Button
                            color={
                              tab.includes("activity") ||
                              tab.includes("adddedToCDI")
                                ? "primary"
                                : "inherit"
                            }
                            size="small"
                            style={btnText}
                            onClick={() => this.moveToTab("activity")}
                          >
                            Activity
                          </Button>
                        </span>
                      </div>
                      <div className="py-2">
                        <span className=""></span>
                        <span
                          className={
                            tab.includes("progReports") ||
                            tab.includes("attendancePerWeek") ||
                            tab.includes("printoutAttendance") ||
                            tab.includes("walkList") ||
                            tab.includes("graduates") ||
                            tab.includes("sheet") ||
                            tab.includes("subject-reports") ||
                            tab.includes("subject-reports-details")
                              ? "borderLeftStyle"
                              : ""
                          }
                        >
                          <Button
                            color={
                              tab.includes("progReports") ||
                              tab.includes("attendancePerWeek") ||
                              tab.includes("sheet") ||
                              tab.includes("printoutAttendance") ||
                              tab.includes("walkList") ||
                              tab.includes("graduates") ||
                              tab.includes("subject-reports") ||
                              tab.includes("subject-reports-details")
                                ? "primary"
                                : "inherit"
                            }
                            size="small"
                            style={btnText}
                            onClick={() => this.moveToTab("progReports")}
                          >
                            Reports
                          </Button>
                        </span>
                      </div>
                      <div className="py-2">
                        <span className=""></span>
                        <span
                          className={
                            tab.includes("programSetting") ||
                            tab.includes("permissionSlipTracking") ||
                            tab.includes("permissionSlipTrackingGrades") ||
                            tab.includes("mentorSetup") ||
                            tab.includes("mentorToChildConfig") ||
                            tab.includes("editWeeklyUpdate")
                              ? "borderLeftStyle"
                              : ""
                          }
                        >
                          <Button
                            color={
                              tab.includes("programSetting") ||
                              tab.includes("permissionSlipTracking") ||
                              tab.includes("permissionSlipTrackingGrades") ||
                              tab.includes("mentorSetup") ||
                              tab.includes("mentorToChildConfig") ||
                              tab.includes("editWeeklyUpdate")
                                ? "primary"
                                : "inherit"
                            }
                            size="small"
                            style={btnText}
                            onClick={() => this.moveToTab("programSetting")}
                          >
                            Setting
                          </Button>
                        </span>
                      </div>
                      {KP_list.map((el) => (
                        <Row className="py-2" key={el.value}>
                          <span className="">
                            <Button
                              color={
                                el.program === query_param
                                  ? "primary"
                                  : "inherit"
                              }
                              size="small"
                              style={btnText}
                              onClick={() =>
                                this.moveToKPK("KPK", el.program, el.value)
                              }
                            >
                              {el.program}
                            </Button>
                          </span>
                        </Row>
                      ))}
                    </Form>
                  </Card.Text>
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
            </div>
          </div>

          {tab.includes("scoreboard") ? <Scoreboard /> : null}
          {tab.includes("activity") ? (
            <Activity {...this.props.match} {...this.props.history} />
          ) : null}
          {tab.includes("progReports") ? (
            <ProgReports {...this.props.history} />
          ) : null}
          {tab.includes("attendancePerWeek") ? <AttendancePerWeek /> : null}
          {tab.includes("printoutAttendance") ? (
            <PrintoutsAttendance {...this.props.history} />
          ) : null}
          {tab.includes("programSetting") ? (
            <ProgramSetting {...this.props.history} />
          ) : null}
          {tab.includes("permissionSlipTracking") ? (
            <PermissionSlipTracking />
          ) : null}
          {tab.includes("mentorSetup") ? <MentorSetup /> : null}
          {tab.includes("mentorToChildConfig") ? <MentorToChildConfig /> : null}
          {tab.includes("KPK") ? <KPK /> : null}
          {tab.includes("editWeeklyUpdate") ? (
            <EditWeeklyUpdate {...this.props.history} />
          ) : null}
          {tab.includes("walkList") ? (
            <WalkList {...this.props.history} />
          ) : null}
          {tab.includes("graduates") ? (
            <ProgramGraduate {...this.props.history} />
          ) : null}
          {tab.includes("sheet") ? (
            <PrinoutSheet {...this.props.history} />
          ) : null}
          {tab.includes("adddedToCDI") ? <AddedToCDI /> : null}
          {tab===("/programs/subject-reports") ? (
            <SubjectReports {...this.props} />
          ) : null}
          {tab.includes("subject-reports-details") ? (
            <SubjectReportsDetails />
          ) : null}
        </div>
      </>
    );
  }
}

export default withRouter(Programs);
