import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { callApi } from "../../crud/auth.crud";
import swal from "sweetalert";
import withRouter from "../../router/withRouter";

class ForgotPassword extends Component {
  state = {
    email: "",
  };
  submitOTP = async (e) => {
    const { email } = this.state;
    e.preventDefault();
    try {
      let result = await callApi("forgot-password", { email });
      if (result.status === 200) {
        this.props.navigate("/auth/reset-password", {
          state: { message: result.data.success },
        });
      }
    } catch {
      swal("", "Email Not Found!", "error");
    }
  };
  render() {
    return (
      <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
        <div className="kt-login__body">
          <div className="kt-login__form">
            <div className="kt-login__title">
              <h3>Forgotten Password?</h3>
            </div>
            <Form onSubmit={this.submitOTP}>
              <div className="form-group">
                <TextField
                  type="email"
                  label="Email"
                  margin="normal"
                  fullWidth={true}
                  name="email"
                  required
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>

              <div className="kt-login__actions">
                <Link to="/auth">
                  <button
                    type="button"
                    className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                  >
                    Back
                  </button>
                </Link>

                <button
                  type="submit"
                  style={{ backgroundColor: "#f4b53f" }}
                  className="btn btn-primary btn-elevate kt-login__btn-primary"
                  // onClick={this.submitOTP}
                >
                  Submit
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ForgotPassword);
