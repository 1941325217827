// import { configureStore } from "@reduxjs/toolkit";
// import createSagaMiddleware from "redux-saga";
// import { persistStore, persistReducer } from "redux-persist"; // imports from redux-persist
// import { rootSaga, rootReducer } from "./rootDuck";
// import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// const sagaMiddleware = createSagaMiddleware();
// const middlewares = [sagaMiddleware];

// const persistConfig = {
//   // configuration object for redux-persist
//   key: "root",
//   storage, // define which storage to use
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer); // create a persisted reducer

// export const store = () =>
//   configureStore({
//     reducer: persistedReducer,
//     middleware: (getDefaultMiddleware) => [
//       ...getDefaultMiddleware({ thunk: false }),
//       sagaMiddleware,
//     ],

//     // middleware: (getDefaultMiddleware) =>
//     //   getDefaultMiddleware().concat(sagaMiddleware),
//     devTools: process.env.NODE_ENV !== "production",
//   });

// // const store = configureStore({
// //   reducer: rootReducer,
// //   middleware: (getDefaultMiddleware) =>
// //     getDefaultMiddleware().concat(sagaMiddleware),
// //   devTools: process.env.NODE_ENV !== "production",
// // });

// // export const persistor = persistStore(store);

// sagaMiddleware.run(rootSaga);

// export const persistor = persistStore(store); // used to create the persisted store, persistor will be used in the next step




import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";

import { rootReducer, rootSaga } from "./rootDuck";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export default store;
