import React, { Component } from "react";
import { Form, Row } from "react-bootstrap";
import withRouter from "../../../router/withRouter";

class List extends Component {
  moveToAdjPage = (pageName) => {
    this.props.navigate("/adminProgDates/" + pageName);
  };
  render() {
    return (
      <>
        <div className="kt-portlet kt-portlet--height-fluid">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">
                Click on the Program to update dates{" "}
              </h3>
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="row">
              <div className="col-md-12">
                <div className="kt-section">
                  <div className="kt-section__content ms-4">
                    <Form
                    // onSubmit={handleSubmit}
                    >
                      <div className="d-flex">
                        <span>PrimeTime and 5 loaves</span>
                        <span className="px-2">
                          <i
                            className="kt-nav__link-icon flaticon2-contract icon-color"
                            onClick={() => this.moveToAdjPage("1")}
                          />
                        </span>
                      </div>
                      <div className="d-flex">
                        <span>XLRB</span>
                        <span className="px-2">
                          <i
                            className="kt-nav__link-icon flaticon2-contract icon-color"
                            onClick={() => this.moveToAdjPage("2")}
                          />
                        </span>
                      </div>
                      <div className="d-flex">
                        <span>Shepherd</span>
                        <span className="px-2">
                          <i
                            className="kt-nav__link-icon flaticon2-contract icon-color"
                            onClick={() => this.moveToAdjPage("3")}
                          />
                        </span>
                      </div>
                      <div className="d-flex">
                        <span>Kid Power</span>
                        <span className="px-2">
                          <i
                            className="kt-nav__link-icon flaticon2-contract icon-color"
                            onClick={() => this.moveToAdjPage("4")}
                          />
                        </span>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(List);
