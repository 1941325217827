import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { InputGroup, FormControl, Form } from "react-bootstrap";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

export default class ActivityTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      minutes: "",
      subjects: "",
    };
  }

  render() {
    const { data } = this.props;

    const { minutes } = this.state;

    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Tutored</StyledTableCell>
              <StyledTableCell>First Name</StyledTableCell>
              <StyledTableCell>Last Name</StyledTableCell>
              <StyledTableCell>Subjects</StyledTableCell>
              {/* <StyledTableCell >Mentor Hours</StyledTableCell> */}
              <StyledTableCell>Minutes Tutored</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, i) => (
                <StyledTableRow key={i}>
                  {row.mentor_id && (
                    <StyledTableCell component="th" scope="row">
                      {row.copy ? null : (
                        <Form.Check
                          onChange={(e) =>
                            this.props.onChangeAttendend(
                              row.child_id,
                              this.props.tableIndex,
                              e.target.value
                            )
                          }
                        />
                      )}
                    </StyledTableCell>
                  )}
                  <StyledTableCell>{row.firstName}</StyledTableCell>
                  <StyledTableCell>{row.lastName}</StyledTableCell>
                  {row.mentor_id && (
                    <StyledTableCell>
                      <Form.Control
                        as="select"
                        name="subjects"
                        size="md"
                        onChange={(e) =>
                          this.props.onChangeSubject(
                            row.child_id,
                            this.props.tableIndex,
                            e.target.value,
                            i,
                            row.copy,
                            row.uid
                          )
                        }
                        style={{ width: "100px" }}
                        disabled={
                          !this.props.attended.includes(row.child_id)
                            ? // && this.props.tableIndex === row.mentor_id
                              true
                            : false
                        }
                      >
                        <option>Select</option>
                        {row.subject && row.subject.length > 0
                          ? row.subject.map((sub, i) => (
                              <option key={sub.id} value={sub.id}>
                                {sub.subject}
                              </option>
                            ))
                          : null}
                        }
                      </Form.Control>
                    </StyledTableCell>
                  )}
                  {row.mentor_id && (
                    <StyledTableCell>
                      <InputGroup>
                        <FormControl
                          //   placeholder="30"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          className="parentBox"
                          name="minutes"
                          type="number"
                          defaultValue={minutes}
                          disabled={
                            !this.props.attended.includes(row.child_id)
                              ? // && this.props.tableIndex === row.mentor_id
                                true
                              : false
                          }
                          onChange={(e) =>
                            this.props.triggerTextChange(
                              row.child_id,
                              this.props.tableIndex,
                              e.target.value,
                              i,
                              row.copy,
                              row.uid
                            )
                          }
                        />
                      </InputGroup>
                    </StyledTableCell>
                  )}
                  {row.mentor_id ? (
                    <>
                      {row.copy ? (
                        <StyledTableCell>
                          {" "}
                          <i
                            className="flaticon2-cross kt-font-warning"
                            onClick={() =>
                              this.props.deleteRow(
                                row.child_id,
                                this.props.tableIndex,
                                i,
                                row.uid
                              )
                            }
                          />
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell>
                          <i
                            className="flaticon2-copy kt-font-warning"
                            onClick={() =>
                              this.props.copyRow(
                                row.child_id,
                                this.props.tableIndex,
                                i,
                                row.copy,
                                row.uid
                              )
                            }
                          />
                        </StyledTableCell>
                      )}
                    </>
                  ) : null}
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}
