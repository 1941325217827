import React, { useState, useEffect } from "react";
import { callApi } from "../../../crud/auth.crud";
import swal from "sweetalert";
import SubjectReportDetailTable from "./subjectReportDetailTable";
import withRouter from "../../../router/withRouter";

const SubjectReportsDetails = (props) => {
  const [loader, setLoader] = useState(true);
  const [tableData, setTableData] = useState([]);

  console.log("🚀 ~ SubjectReportsDetails ~ props:", props);

  useEffect(() => {
    const getDetails = async () => {
      let payload = props.location.state;
      if (payload.child_id) {
        try {
          let result = await callApi("subject-report-detail", payload);
          if (result.status === 200) {
            let DATA = result.data.success;
            setTableData(DATA);
            setLoader(false);
          } else {
            setLoader(false);
            swal("Oops!", "Please try again!", "error");
          }
        } catch {
          setLoader(false);
          swal("Oops!", "Network Error!", "error");
        }
      }
    };

    getDetails();
  }, [props.location.state]);

  return (
    <div className="col-md-8">
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Subject Minutes</h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-section">
                <div className="kt-section__content text-center">
                  {loader ? (
                    <div
                      className="spinner-border text-warning loaderStylegraduatePdf"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <SubjectReportDetailTable tableData={tableData} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SubjectReportsDetails);
