import React from "react";
import SwipeableViews from "react-swipeable-views";
import { AppBar, Tab, Tabs, makeStyles, useTheme } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Form, Col, Row } from "react-bootstrap";

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 800,
  },
}));
export default function BarrierFromSchool(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }
  return (
    <>
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              Perspectives, Barriers and Program Hours
            </h3>
          </div>
          {/* <PortletHeaderDropdown /> */}
        </div>
        <div className="kt-portlet__body">
          <Typography
            variant="caption"
            display="block"
            className="mb-4 dashboardText"
          >
            Each child is heavily influenced by their environment as they
            establish their own view of the world. How can a child dream of
            being an architect or engineer if they are not aware that these
            occupations exist? One of the greatest outside influences a child
            experiences can be found in their school environment, yet the
            Bridgeeport schools are struggling. <br />
            <br />
            The following data from the Connecticut Coalition for Achievement
            Now(ConnCAN) helps demonstrate how limiting the school environment
            may be having an student perspectives :
          </Typography>

          {/* <Container style={maxWidth}> */}

          <div className={classes.root}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab label="Surrounding Town School" />
                <Tab label="School that PT-Barnum kids attend" />
                {/* <Tab label="Item Three" /> */}
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabContainer dir={theme.direction}>
                {props.surrounding_t_schools &&
                  props.surrounding_t_schools.map((el, i) => (
                    <Row key={i}>
                      <Col md={6}>
                        <Typography
                          variant="body2"
                          display="block"
                          gutterBottom
                          className="dashboardText"
                        >
                          {el.title}
                        </Typography>{" "}
                      </Col>
                      <Col md={2}>
                        <Typography
                          variant="body2"
                          display="block"
                          gutterBottom
                          className="dashboardText"
                        >
                          {el.grade}
                        </Typography>
                      </Col>
                      <Col md={4}>
                        <Typography
                          variant="body2"
                          display="block"
                          gutterBottom
                          className="dashboardText"
                        >
                          {el.score}
                        </Typography>
                      </Col>
                    </Row>
                  ))}
              </TabContainer>
              <TabContainer dir={theme.direction}>
                {" "}
                {props.pt_barnum_kids &&
                  props.pt_barnum_kids.map((el, i) => (
                    <Row key={i}>
                      <Col md={6}>
                        <Typography
                          variant="body2"
                          display="block"
                          gutterBottom
                          className="dashboardText"
                        >
                          {el.title}
                        </Typography>{" "}
                      </Col>
                      <Col md={2}>
                        <Typography
                          variant="body2"
                          display="block"
                          gutterBottom
                          className="dashboardText"
                        >
                          {el.grade}
                        </Typography>
                      </Col>
                      <Col md={4}>
                        <Typography
                          variant="body2"
                          display="block"
                          gutterBottom
                          className="dashboardText"
                        >
                          {el.score}
                        </Typography>
                      </Col>
                    </Row>
                  ))}
              </TabContainer>
              {/* <TabContainer dir={theme.direction}>Item Three</TabContainer> */}
            </SwipeableViews>
          </div>
          {/* </Container> */}
        </div>
      </div>
    </>
  );
}
