import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import { Modal } from "@material-ui/core"; // import { Button } from "@material-ui/core";import { Button, Form, Col } from "react-bootstrap";
import "./childProfile.css";
import { getChildNotes, addChildNotes, callApi } from "../../crud/auth.crud";
import swal from "sweetalert";
import axios from "axios";
import { serverUrl } from "../../../_metronic/utils/api";
import { Form, Col, Button, Row } from "react-bootstrap";
import withRouter from "../../router/withRouter";

const paper = {
  position: "absolute",
  width: 600,
  backgroundColor: "#fff",
  boxShadow: 5,
  padding: 25,
  outline: "none",
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`,
};

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

const Header = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + localStorage.getItem("token"),
};

class Notes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      notes: [],
      noteText: "",
      childID: this.props.id,
      note_id: "",
      user_id: "",
      editNote: "",
      isEdit: false,
      loading: false,
    };
  }
  async componentDidMount() {
    await this.getNote();
  }

  getNote = async () => {
    const { childID } = this.state;
    if (childID)
      try {
        const result = await getChildNotes(childID);
        if (result.status === 200) {
          this.setState({ notes: result.data.data.data });
        } else {
          swal("Oops!", "Please try again!", "error");
        }
      } catch (err) {
        this.setState({ loading: false });
        if (err.response.status == 401) {
          swal("", "Unauthorized!", "error");
          localStorage.clear();
          this.props.navigate("/auth/login");
          swal.close();
        } else {
          swal("Oops!", "Network Error!", "error");
        }
      }
  };

  onChangeHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitNote = async (e) => {
    e.preventDefault();
    const { childID, noteText } = this.state;
    if (noteText.length >= 10) {
      this.setState({ loading: true });
      try {
        let result = await addChildNotes(childID, noteText);
        if (result.status === 200) {
          this.setState({ noteText: "" }, () => this.getNote());
          swal("Note Added Successfully!", "", "success");
          this.setState({ loading: false });
        } else {
          swal("Oops!", "Please try again!", "error");
          this.setState({ loading: false });
        }
      } catch {
        this.setState({ noteText: "" }, () => this.getNote());
        swal("Oops!", "Network Error!", "error");
        this.setState({ loading: false });
      }
    } else {
      swal("Oops!", "Please select atleast 10 character!", "error");
    }
  };

  deleteUser = async (id, userId) => {
    try {
      const result = await callApi("delete-note", {
        note_id: id,
        user_id: userId,
      });
      if (result.status === 200) {
        swal("Note Deleted Successfully!", "", "success");
        this.getNote();
      } else {
        swal("Oops!", "Please Try Again!!", "error");
      }
    } catch {
      swal("Oops!", "Network Error!", "error");
    }
  };
  deleteNoteConfirm = (id, userId) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.deleteUser(id, userId);
        // swal("Data has been deleted!", {
        //   icon: "success",
        // });
      }
    });
  };

  editNote = async (note_id, user_id) => {
    try {
      const result = await axios.post(
        `${serverUrl}edit-note`,
        { note_id, user_id },
        {
          headers: Header,
        }
      );
      if (result.status === 200) {
        let data = result.data.date;
        this.setState({
          note_id: data.id,
          user_id: data.inserted_by_id,
          editNote: data.note,
          isEdit: true,
        });
      } else {
        swal("Oops!", "Please Try Again!!", "error");
      }
    } catch {
      swal("Oops!", "Network Error!", "error");
    }
  };

  updateNote = async (e) => {
    e.preventDefault();
    const { note_id, user_id, editNote } = this.state;
    try {
      const result = await callApi("update-note", {
        note_id: note_id,
        user_id: user_id,
        note: editNote,
      });
      if (result.status === 200) {
        swal("Note Updated Successfully!", "", "success");
        this.setState({ isEdit: false }, () => this.getNote());
      } else {
        swal("Oops!", "Please Try Again!!", "error");
      }
    } catch {
      swal("Oops!", "Network Error!", "error");
    }
  };

  render() {
    const { isEdit } = this.state;
    return (
      <div className="modalParent">
        {/* <Typography gutterBottom>
          Click to get the full Modal experience!
        </Typography> */}
        {/* <Button onClick={this.props.handleOpen}>Open Modal</Button> */}
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.props.open}
          onClose={this.props.handleClose}
        >
          <div style={paper}>
            <div className="d-flex justify-content-between borderBottom">
              <span>
                {" "}
                <Typography variant="h6" id="modal-title">
                  Note List
                </Typography>
              </span>
              <span>
                {" "}
                <i
                  className="fas fa-times"
                  onClick={this.props.handleClose}
                ></i>
              </span>
            </div>

            <Form
              className="mt-4"
              onSubmit={!isEdit ? this.submitNote : this.updateNote}
            >
              {this.state.notes &&
                this.state.notes.map((val, index) => (
                  <Form.Group className="borderBottom form-group">
                    <Form.Label> {val.note}</Form.Label>
                    <div>
                      <span>
                        <i className="fas fa-sticky-note iconColor"></i>&nbsp;
                        <span className="iconColor">
                          {val.day},{val.date}
                        </span>{" "}
                      </span>
                      &nbsp; &nbsp;{" "}
                      <span>
                        {" "}
                        <i className="fas fa-clock iconColor"></i>&nbsp;{" "}
                        <span className="iconColor">{val.time}</span>{" "}
                      </span>
                      &nbsp; &nbsp;{" "}
                      <span className="iconColor">
                        {" "}
                        Name : &nbsp; {val.inserted_by}{" "}
                      </span>
                      {localStorage.getItem("userId") ===
                        val.inserted_by_id.toString() && (
                        <span className="iconColor">
                          <i
                            className="fas fa-trash iconColor ms-2"
                            title="Delete note"
                            onClick={() =>
                              this.deleteNoteConfirm(val.id, val.inserted_by_id)
                            }
                          ></i>
                        </span>
                      )}
                      {localStorage.getItem("userId") ===
                        val.inserted_by_id.toString() && (
                        <span className="iconColor">
                          <i
                            className="fas fa-edit iconColor ms-2"
                            title="Edit note"
                            onClick={() =>
                              this.editNote(val.id, val.inserted_by_id)
                            }
                          ></i>
                        </span>
                      )}
                    </div>
                  </Form.Group>
                ))}
              <Form.Group className="form-group">
                <Row>
                  <Col md={10}>
                    {!isEdit ? (
                      <Form.Control
                        type="text"
                        required
                        placeholder="Write new note to add (minimum 10 characters)"
                        name="noteText"
                        onChange={this.onChangeHandle}
                        value={this.state.noteText}
                        minLength="10"
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        required
                        placeholder="Write new note to add (minimum 10 characters)"
                        name="editNote"
                        onChange={this.onChangeHandle}
                        value={this.state.editNote}
                        minLength="10"
                      />
                    )}
                  </Col>
                  <Col md={2} className="my-3">
                    <Button
                      type="submit"
                      style={btnColor}
                      disabled={this.state.loading}
                    >
                      {!isEdit ? "Save" : "Update"}
                    </Button>{" "}
                  </Col>
                </Row>
              </Form.Group>
            </Form>

            {/* <AddSpecialEvent /> */}
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Notes);
