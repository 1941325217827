/* eslint-disable jsx-a11y/anchor-has-content,no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import "./dashboard.css";

const maxWidth = {
  maxWidth: "800px",
};

export default function MentorshipAndVolunteerHours(props) {
  const { hours_mentored, kids_mentored, ratio, date } = props.obj;
  return (
    <>
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              Mentorship and volunteer Hours
            </h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <Typography
            variant="caption"
            display="block"
            className="mb-4 dashboardText"
          >
            Urban Impact is unique in that nearly all of the engagements with
            kids is driven by volunteers consider the following statistics.
          </Typography>

          <Container style={maxWidth}>
            <div className="kt-widget4">
              <div className="kt-widget4__item ">
                <div className="kt-widget4__info ">
                  <p className="kt-widget4__text fw-bold">
                    &middot; &nbsp; Hours of mentorship
                  </p>
                </div>
                {hours_mentored} hours
              </div>
              <div className="kt-widget4__item ">
                <div className="kt-widget4__info ">
                  <p className="kt-widget4__text fw-bold">
                    &middot; &nbsp; Number of kids mentored
                  </p>
                </div>
                {/* <a className="btn btn-sm btn-label-dark">Follow</a> */}
                {kids_mentored} children
              </div>
              <div className="kt-widget4__item ">
                <div className="kt-widget4__info ">
                  <p className="kt-widget4__text fw-bold">
                    &middot; &nbsp; Overall mentor to child ratio
                  </p>
                </div>
                {ratio} children per mentor
              </div>
              <div className="kt-widget4__item ">
                <div className="kt-widget4__info ">
                  <p className="kt-widget4__text fw-bold">
                    &middot; &nbsp; Date range of Data shown
                  </p>
                </div>
                {/* <a className="btn btn-sm btn-label-dark">Follow</a> */}
                {date}
              </div>
            </div>
          </Container>
          <Typography
            variant="caption"
            display="block"
            className="mt-4 dashboardText"
          >
            Mentor hours have only been tracked since Feb 2017. Data shown below
            only goes back that for.
          </Typography>
        </div>
      </div>
    </>
  );
}
