import React, { Component } from 'react'
import { Button } from "react-bootstrap";
 import SessionTable from './sessionTable'
 import AddDateRange from './addDateRange'
 import { getSessions } from "../../../crud/auth.crud";
 import swal from "sweetalert";

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};
export default class SessionManagement extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      open:false,sessions:[]

    }
  }

  getAllSessions=async()=>{
  try { let result = await getSessions()
    if(result.status===200){
      this.setState({sessions:result.data.data})
    }
    else{
      swal("Oops!", "Please try again!", "error");
    }}
    catch{
      swal("Oops!", "Network Error!", "error");
    }
  }
  

     async componentDidMount() {
        await this.getAllSessions();
     }
  

  handleOpen = () => {
    this.setState({open:true})
   };
 
    handleClose = () => {
     this.setState({open:false},()=>this.getAllSessions())
   };

   
  render() {
    return (
      <>
        <div className="kt-portlet kt-portlet--height-fluid">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">
               Data Management
              </h3>
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="row">
              <div className="col-md-12">
                <div className="kt-section">
                  <div className="kt-section__content">
                 <SessionTable {...this.props.history} sessions={this.state.sessions} getAllSessions={this.getAllSessions}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
            <Button type="submit" style={btnColor} onClick={this.handleOpen}>
                        Add Date Range
                      </Button>
            </div>
            {this.state.open ? <AddDateRange handleOpen={this.handleOpen} open={this.state.open}
                handleClose={this.handleClose} 
                getAllSessions={this.getAllSessions}
                /> : null}
          </div>
        </div>

      </>
    );
  }
}

