/* eslint-disable jsx-a11y/anchor-has-content,no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
//import { toAbsoluteUrl } from "../../_metronic/utils/utils";
//import PortletHeaderDropdown from "../partials/content/CustomDropdowns/PortletHeaderDropdown";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core";

const totalBorder = {
  borderTop: "2px solid black",
  borderBottom: "2px solid black",
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: "100px",
  },
}));

export default function MentorHoursTAble(props) {
  const classes = useStyles();

  return (
    <>
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              Data Collected: Core Programs
            </h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-section">
                <div className="kt-section__content">
                  <Paper className={classes.root}>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">
                            Program
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Mentor Hours
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.mentor_hours &&
                          props.mentor_hours.map((row, i) => (
                            <StyledTableRow key={i}>
                              <StyledTableCell align="center">
                                {row.program}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.mentor_hour}{" "}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        <StyledTableRow>
                          <StyledTableCell
                            align="center"
                            className="fw-bold"
                            style={totalBorder}
                          >
                            Total
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className="fw-bold"
                            style={totalBorder}
                          >
                            {props.total_hours_mentored}{" "}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </Paper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
