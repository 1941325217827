import React from "react";
import { withStyles, makeStyles, TableCell } from "@material-ui/core";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@material-ui/core";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
}));

export default function PrimeTimeAttendanceTable(props) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell>Total</StyledTableCell>
            <StyledTableCell>PrimeTime Junior</StyledTableCell>
            <StyledTableCell>PrimeTime</StyledTableCell>
            <StyledTableCell>PrimeTimeSenior</StyledTableCell>
            <StyledTableCell>Five Loaves</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableData && props.tableData.length ? (
            props.tableData.map((row, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell>
                  {row.weekDate ? row.weekDate : "-"}
                </StyledTableCell>
                <StyledTableCell>{row.total ? row.total : "-"}</StyledTableCell>
                <StyledTableCell>
                  {row.prime_time_junior ? row.prime_time_junior : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {row.prime_time ? row.prime_time : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {row.prime_time_senior ? row.prime_time_senior : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {row.five_loaves ? row.five_loaves : "-"}
                </StyledTableCell>
              </StyledTableRow>
            ))
          ) : (
            <p>No Data</p>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
}
