import React, { Component } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../partials/content/Portlet";
import ConcentratedInfluenceChart from "../../widgets/concentratedInfluenceChart";
import KidsBeingInfluencedChart from "../../widgets/kidsBeingInfluencedChart";
import MentorshipAndVolunteerHours from "./mentorshipAndVolunteerHours";
import ConcentratedFocusChart from "./concentratedFocusChart";
import { Card } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { callApi } from "../../crud/auth.crud";
import moment from "moment";
import swal from "sweetalert";
import { connect } from "react-redux";
import ResetDate from "./resetAction";
import withRouter from "../../router/withRouter";

const fontWeight = {
  fontWeight: "bold",
};

class Mentoring extends Component {
  constructor(props) {
    super(props);

    this.state = {
      graphData: [],
      hours_mentored: "",
      kids_mentored: "",
      ratio: "",
      date: "",
      loading: true,
      program_chart: [],
      highest_level: [],
      concentrated_focus: [],
    };
  }

  async componentDidMount() {
    this.getstats();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.resetDate !== this.props.resetDate) {
      window.scrollTo(0, 0);
      this.getstats();
    }
  }

  getstats = async () => {
    if (!this.state.loading) {
      await this.setState({ loading: true });
    }
    let payload = this.props.resetDate;
    try {
      let result = await callApi("get-mentoring", payload);
      if (result.status === 200) {
        this.setState({
          graphData: result.data.data.chartData,
          hours_mentored: result.data.data.hours_mentored,
          kids_mentored: result.data.data.kids_mentored,
          ratio: result.data.data.ratio,
          date: result.data.data.date,
          loading: false,
          program_chart: result.data.data.programChart,
          highest_level: result.data.data.highest_level,
          concentrated_focus: result.data.data.concentrated_focus,
        });
      } else {
        this.setState({ loading: false });
      }
    } catch {
      this.setState({ loading: false });
      swal("Oops!", "Network Error!", "error");
    }
  };

  moveToAdjPage = (pageName) => {
    this.props.navigate("/" + pageName);
  };

  reset_Date = () => {
    if (this.props.resetDate.start_date && this.props.resetDate.end_date)
      this.props.ResetDate("", "");
  };

  render() {
    const { hours_mentored, kids_mentored, ratio, date, loading } = this.state;
    return (
      <>
        {loading ? (
          <div
            className="spinner-border text-warning dashboardLoading"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-xl-12">
                <Portlet fluidHeight={true}>
                  <PortletHeader title="Date Range" />

                  <PortletBody>
                    <div className="row">
                      <div className="col-md-3">
                        <Card
                          style={{
                            width: "13rem",
                            borderLeft: "3px solid #f4b53f",
                            borderWidthLeft: "5px",
                            fontSize: "10px",
                          }}
                        >
                          <Card.Body>
                            <Card.Text>Begining of date range</Card.Text>
                            <Card.Text style={fontWeight}>
                              {this.props.resetDate.start_date
                                ? moment(
                                    this.props.resetDate.start_date
                                  ).format("MMM Do YYYY")
                                : moment(
                                    new Date(
                                      new Date().setFullYear(
                                        new Date().getFullYear() - 1
                                      )
                                    )
                                  ).format("MMM Do YYYY")}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                      <div className="col-md-4">
                        <Card
                          style={{
                            width: "13rem",
                            fontSize: "10px",
                            borderLeft: "3px solid #f4b53f",
                          }}
                        >
                          <Card.Body>
                            <Card.Text>End of date range</Card.Text>
                            <Card.Text style={fontWeight}>
                              {this.props.resetDate.end_date
                                ? moment(this.props.resetDate.end_date).format(
                                    "MMM Do YYYY"
                                  )
                                : moment(new Date()).format("MMM Do YYYY")}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                      <div className="d-flex">
                        <span>
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            className="mt-4"
                            onClick={() =>
                              this.moveToAdjPage("changeDate/mentoring")
                            }
                          >
                            Change Date Range
                          </Button>
                        </span>
                        <span className="px-4">
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            className="mt-4"
                            onClick={this.reset_Date}
                          >
                            Reset Date
                          </Button>
                        </span>
                      </div>
                    </div>
                  </PortletBody>
                </Portlet>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <MentorshipAndVolunteerHours
                  obj={{ hours_mentored, kids_mentored, ratio, date }}
                />
              </div>
            </div>

            <Portlet>
              <PortletBody fit={true}>
                <div className="row row-no-padding row-col-separator-xl">
                  <div className="col-xl-12">
                    <ConcentratedInfluenceChart
                      graphData={this.state.graphData}
                      program_chart={this.state.program_chart}
                    />
                  </div>
                </div>
              </PortletBody>
            </Portlet>

            <Portlet>
              <PortletBody fit={true}>
                <div className="row row-no-padding row-col-separator-xl">
                  <div className="col-xl-12">
                    <ConcentratedFocusChart
                      concentrated_focus={this.state.concentrated_focus}
                    />
                  </div>
                </div>
              </PortletBody>
            </Portlet>

            <Portlet>
              <PortletBody fit={true}>
                <div className="row row-no-padding row-col-separator-xl">
                  <div className="col-xl-12">
                    <KidsBeingInfluencedChart
                      highest_level={this.state.highest_level}
                    />
                  </div>
                </div>
              </PortletBody>
            </Portlet>
          </>
        )}{" "}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  resetDate: state.resetDate.date,
});

const HOC = connect(mapStateToProps, { ResetDate })(Mentoring);

export default withRouter(HOC);
