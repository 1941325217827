import React, { Component } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../partials/content/Portlet";
import MentorHoursTAble from "./mentorHoursTable";
import { Card } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { callApi } from "../../crud/auth.crud";
import moment from "moment";
import swal from "sweetalert";
import { connect } from "react-redux";
import ResetDate from "./resetAction";
import withRouter from "../../router/withRouter";

const fontWeight = {
  fontWeight: "bold",
};

class Hours extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      total_hours_mentored: [],
      mentor_hours: "",
    };
  }

  async componentDidMount() {
    this.getstats();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.resetDate !== this.props.resetDate) {
      window.scrollTo(0, 0);
      this.getstats();
    }
  }

  getstats = async () => {
    if (!this.state.loading) {
      await this.setState({ loading: true });
    }
    let payload = this.props.resetDate;
    try {
      let result = await callApi("get-mentor-hours", payload);
      if (result.status === 200) {
        this.setState({
          mentor_hours: result.data.data.mentor_hours,
          total_hours_mentored: result.data.data.total_hours_mentored,
          loading: false,
        });
      } else {
        this.setState({ loading: false });
      }
    } catch {
      this.setState({ loading: false });
      swal("Oops!", "Network Error!", "error");
    }
  };

  moveToAdjPage = (pageName) => {
    this.props.navigate("/" + pageName);
  };

  reset_Date = () => {
    if (this.props.resetDate.start_date && this.props.resetDate.end_date)
      this.props.ResetDate("", "");
  };
  render() {
    const { loading, mentor_hours, total_hours_mentored } = this.state;
    return (
      <>
        {loading ? (
          <div
            className="spinner-border text-warning dashboardLoading"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-xl-12">
                <Portlet fluidHeight={true}>
                  <PortletHeader title="Date Range" />

                  <PortletBody>
                    <div className="row">
                      <div className="col-md-3">
                        <Card
                          style={{
                            width: "13rem",
                            borderLeft: "3px solid #f4b53f",
                            borderWidthLeft: "5px",
                            fontSize: "10px",
                          }}
                        >
                          <Card.Body>
                            <Card.Text>Begining of date range</Card.Text>
                            <Card.Text style={fontWeight}>
                              {this.props.resetDate.start_date
                                ? moment(
                                    this.props.resetDate.start_date
                                  ).format("MMM Do YYYY")
                                : moment(
                                    new Date(
                                      new Date().setFullYear(
                                        new Date().getFullYear() - 1
                                      )
                                    )
                                  ).format("MMM Do YYYY")}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                      <div className="col-md-4">
                        <Card
                          style={{
                            width: "13rem",
                            fontSize: "10px",
                            borderLeft: "3px solid #f4b53f",
                          }}
                        >
                          <Card.Body>
                            <Card.Text>End of date range</Card.Text>
                            <Card.Text style={fontWeight}>
                              {" "}
                              {this.props.resetDate.end_date
                                ? moment(this.props.resetDate.end_date).format(
                                    "MMM Do YYYY"
                                  )
                                : moment(new Date()).format("MMM Do YYYY")}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                      <div className="d-flex">
                        <span>
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            className="mt-4"
                            onClick={() =>
                              this.moveToAdjPage("changeDate/hours")
                            }
                          >
                            Change Date Range
                          </Button>
                        </span>
                        <span className="px-4">
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            className="mt-4"
                            onClick={this.reset_Date}
                          >
                            Reset Date
                          </Button>
                        </span>
                      </div>
                    </div>
                  </PortletBody>
                </Portlet>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <MentorHoursTAble
                  total_hours_mentored={total_hours_mentored}
                  mentor_hours={mentor_hours}
                />
              </div>
            </div>
          </>
        )}{" "}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  resetDate: state.resetDate.date,
});

const HOC = connect(mapStateToProps, { ResetDate })(Hours);

export default withRouter(HOC);
