import React, { Component } from "react";
import SummaryReportTable from "./summaryReportTable";
import { Button, Row } from "react-bootstrap";
import { Form } from "react-bootstrap";
import SummaryFullTable from "./summaryFullTable";
import "./reports.css";
import { fetchData } from "../../crud/auth.crud";
import swal from "sweetalert";
import Pagination from "react-js-pagination";
import withRouter from "../../router/withRouter";

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};
class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seeFull: false,
      tableData: [],
      total: null,
      per_page: null,
      current_page: 1,
      tableDataFull: [],
    };
  }

  async componentDidMount() {
    await this.getAllReports();
  }

  getAllReports = async () => {
    try {
      let result = await fetchData("get-reports");
      if (result.status === 200) {
        this.setState({ tableData: result.data.data });
      } else {
        swal("Oops!", "Please try again!", "error");
      }
    } catch (err) {
      this.setState({ loading: false });
      if (err.response.status == 401) {
        swal("", "Unauthorized!", "error");
        localStorage.clear();
        this.props.navigate("/auth/login");
        swal.close();
      } else {
        swal("Oops!", "Network Error!", "error");
      }
    }
  };

  moveToAdjacentProg = (page, setProg) => {
    let obj = setProg;
    localStorage.setItem("progName", JSON.stringify(obj));
    this.props.navigate("/" + page);
  };

  moveToAdjacentPage = (page) => {
    this.props.navigate("/" + page);
  };

  seeFullList = async () => {
    const { current_page } = this.state;
    try {
      let result = await fetchData(
        `get-reports?full_list=1&page=${current_page}`
      );
      if (result.status === 200) {
        this.setState((prevState) => ({
          seeFull: true,
          tableDataFull: result.data.data.data,
          current_page: result.data.data.current_page
            ? result.data.data.current_page
            : 1,
          total: result.data.data.total,
          per_page: result.data.data.per_page,
        }));
      } else {
        swal("Oops!", "Please try again!", "error");
      }
    } catch (err) {
      this.setState({ loading: false });
      if (err.response.status == 401) {
        swal("", "Unauthorized!", "error");
        localStorage.clear();
        this.props.navigate("/auth/login");
        swal.close();
      } else {
        swal("Oops!", "Network Error!", "error");
      }
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ current_page: pageNumber });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.current_page !== this.state.current_page &&
      this.state.seeFull
    ) {
      window.scrollTo(0, 0);
      this.seeFullList();
    }
  }

  seeMainList = () => {
    this.setState({
      seeFull: false,
      total: null,
      per_page: null,
      current_page: 1,
      tableDataFull: [],
    });
  };

  render() {
    const { tableData, tableDataFull } = this.state;
    return (
      <>
        <div className="kt-portlet">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">
                Summary Report(more below)
              </h3>
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="row">
              <div className="col-md-12">
                <div className="kt-section">
                  <div className="kt-section__content">
                    {this.state.seeFull ? (
                      <SummaryFullTable tableData={tableDataFull} />
                    ) : (
                      <SummaryReportTable tableData={tableData} />
                    )}
                  </div>
                </div>
                {this.state.total > 10 && this.state.seeFull ? (
                  <Pagination
                    activePage={this.state.current_page}
                    itemsCountPerPage={10}
                    totalItemsCount={this.state.total}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)}
                    itemclassName="page-item"
                    linkclassName="page-link"
                  />
                ) : null}
              </div>
              <div className="col-md-12">
                <div className="kt-section">
                  <div className="kt-section__content text-center">
                    <Button
                      type="submit"
                      style={btnColor}
                      onClick={
                        !this.state.seeFull
                          ? this.seeFullList
                          : this.seeMainList
                      }
                    >
                      {this.state.seeFull ? "See Main List" : "See Full List"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="row">
              <div className="col-md-12">
                <div className="kt-section">
                  <div className="kt-section__content">
                    <h5 className="borderDown">Custom Report Builder</h5>
                  </div>
                </div>
              </div>
              <div className="col-md-12 ms-4">
                <div className="kt-section">
                  <div className="kt-section__content">
                    <row>
                      <span>&middot;&nbsp; Student Report Builder</span>
                      <span className="px-2">
                        <i
                          className="kt-nav__link-icon flaticon2-contract icon-color"
                          onClick={() => this.moveToAdjacentPage("kid-reports")}
                        />
                      </span>
                    </row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="row">
              <div className="col-md-12">
                <div className="kt-section">
                  <div className="kt-section__content">
                    <h5 className="borderDown">Attendance Report</h5>
                  </div>
                </div>
              </div>
              <div className="col-md-12 ms-4">
                <div className="kt-section">
                  <div className="kt-section__content">
                    <row>
                      <span>
                        &middot;&nbsp; See PrimeTime Attendance By Week
                      </span>
                      <span className="px-2">
                        <i
                          className="kt-nav__link-icon flaticon2-contract icon-color"
                          onClick={() =>
                            this.moveToAdjacentPage("primeTimeAttendanceReport")
                          }
                        />
                      </span>
                    </row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="row">
              <div className="col-md-12">
                <div className="kt-section">
                  <div className="kt-section__content">
                    <h5 className="borderDown">Program Specific Report</h5>
                  </div>
                </div>
              </div>
              <div className="col-md-12 ms-4">
                <div className="kt-section">
                  <div className="kt-section__content">
                    <Form
                    // onSubmit={handleSubmit}
                    >
                      <div className="d-flex">
                        <span>&middot;&nbsp; See PrimeTime Junior Report</span>
                        <span className="px-2">
                          <i
                            className="kt-nav__link-icon flaticon2-contract icon-color"
                            onClick={() => {
                              this.moveToAdjacentProg("progList/all-Reports", {
                                name: "PrimeTime Junior",
                                id: 3,
                              });
                            }}
                          />
                        </span>
                      </div>
                      <div className="d-flex">
                        <span>&middot;&nbsp; See PrimeTime Report</span>
                        <span className="px-2">
                          <i
                            className="kt-nav__link-icon flaticon2-contract icon-color"
                            onClick={() => {
                              this.moveToAdjacentProg("progList/all-Reports", {
                                name: "PrimeTime",
                                id: 2,
                              });
                            }}
                          />
                        </span>
                      </div>
                      <div className="d-flex">
                        <span>&middot;&nbsp; See PrimeTime Senior Report</span>
                        <span className="px-2">
                          <i
                            className="kt-nav__link-icon flaticon2-contract icon-color"
                            onClick={() => {
                              this.moveToAdjacentProg("progList/all-Reports", {
                                name: "PrimeTime Hoops",
                                id: 4,
                              });
                            }}
                          />
                        </span>
                      </div>
                      <div className="d-flex">
                        <span>&middot;&nbsp; See XLR8 Report</span>
                        <span className="px-2">
                          <i
                            className="kt-nav__link-icon flaticon2-contract icon-color"
                            onClick={() => {
                              this.moveToAdjacentProg("progList/all-Reports", {
                                name: "XLR8",
                                id: 11,
                              });
                            }}
                          />
                        </span>
                      </div>
                      <div className="d-flex">
                        <span>&middot;&nbsp; See Sunday Shephered Report</span>
                        <span className="px-2">
                          <i
                            className="kt-nav__link-icon flaticon2-contract icon-color"
                            onClick={() => {
                              this.moveToAdjacentProg("progList/all-Reports", {
                                name: "Sunday Shepherd",
                                id: 7,
                              });
                            }}
                          />
                        </span>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="row">
              <div className="col-md-12">
                <div className="kt-section">
                  <div className="kt-section__content">
                    <h5 className="borderDown">Grants working Area</h5>
                  </div>
                </div>
              </div>
              <div className="col-md-12 ms-4">
                <div className="kt-section">
                  <div className="kt-section__content">
                    <row>
                      <span>
                        &middot;&nbsp; People's Bank Tutoring Analysis
                      </span>
                      <span className="px-2">
                        <i
                          className="kt-nav__link-icon flaticon2-contract icon-color"
                          onClick={() => {
                            this.moveToAdjacentPage("tutoringAnalysis");
                          }}
                        />
                      </span>
                    </row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="row">
              <div className="col-md-12">
                <div className="kt-section">
                  <div className="kt-section__content">
                    <h5 className="borderDown">
                      Kid Power Report - Work in Progress
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-md-12 ms-4">
                <div className="kt-section">
                  <div className="kt-section__content">
                    <row>
                      <span>&middot;&nbsp; Grade Report</span>
                      <span className="px-2">
                        <i
                          className="kt-nav__link-icon flaticon2-contract icon-color"
                          onClick={() =>
                            this.moveToAdjacentPage("literacy-reports")
                          }
                        />
                      </span>
                    </row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="row">
              <div className="col-md-12">
                <div className="kt-section">
                  <div className="kt-section__content">
                    <h5 className="borderDown">List of All Active Mentors</h5>
                  </div>
                </div>
              </div>
              <div className="col-md-12 ms-4">
                <div className="kt-section">
                  <div className="kt-section__content">
                    <row>
                      <span>&middot;&nbsp; Active Mentors Report</span>
                      <span className="px-2">
                        <i
                          className="kt-nav__link-icon flaticon2-contract icon-color"
                          onClick={() =>
                            this.moveToAdjacentPage("active-mentors")
                          }
                        />
                      </span>
                    </row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Report);
