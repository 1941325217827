import React, { useState, useEffect, useCallback } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import MentorProfileTable from "./mentorProfileTable";
import MentorPersonalInfo from "./mentorPersonalInfo";
import { getMentors, getPrograms } from "../../crud/auth.crud";
import swal from "sweetalert";
import Pagination from "react-js-pagination";
import withRouter from "../../router/withRouter";
import { useNavigate } from "react-router";

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

const debounce = (fn, delay) => {
  let timer = null;
  return function (...args) {
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

const MentorProfile = (props) => {
  const [open, setOpen] = useState(false);
  const [mentors, setMentors] = useState([]);
  const [total, setTotal] = useState(null);
  const [perPage, setPerPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [programs, setPrograms] = useState([]);
  const [pid, setPid] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState(false);
  const navigate=useNavigate();

  const getMentor = useCallback(
    debounce(async () => {
      try {
        const result = await getMentors(currentPage, fname, lname, pid);
        if (result.status === 200) {
          const data = result.data.data;
          setMentors(data.data);
          setCurrentPage(data.current_page);
          setTotal(data.total);
          setPerPage(data.per_page);
          setLoader(false);
        }
      } catch (err) {
        if (err.response.status == 401) {
          swal("", "Unauthorized!", "error");
          localStorage.clear();
          navigate("/auth/login");
          swal.close();
        }
        setLoader(false);

        // swal("Oops!", "Network Error!", "error");
      }
    }, 200),
    [currentPage, fname, lname, pid]
  );

  const getProgram = async () => {
    try {
      const result = await getPrograms();
      if (result.status === 200) {
        setPrograms(result.data.data);
      } else {
        setPrograms([]);
        // swal("Oops!", "Network Error!", "error");
      }
    } catch (e) {
      setPrograms([]);
      // swal("Oops!", "Network Error!", "error");
    }
  };

  useEffect(() => {
    getMentor();
    getProgram();
  }, [currentPage, search]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getMentor();
  }, [currentPage]);

  const showMentorInfo = () => {
    props.navigate("/mentorPersonalInfo");
  };

  const searchHandle = (e) => {
    const { name, value } = e.target;
    if (name === "fname") setFname(value);
    if (name === "lname") setLname(value);
    if (name === "pid") setPid(value);
    setSearch(false);
  };

  const handleSubmit = () => {
    if ((fname || lname || pid) && !search) {
      console.log("***", fname || lname || pid);
      setSearch(true);
      getMentor();
      getProgram();
    } else {
      console.log("reset");
      setSearch(false);
      setFname("");
      setPid("");
      setFname("");
      setLname("");
      setCurrentPage(1);
    }
  };

  return (
    <>
      {open ? (
        <MentorPersonalInfo />
      ) : (
        <>
          <div className="kt-portlet kt-portlet--height-fluid">
            <div className="kt-portlet__body">
              <div className="row">
                <div className="col-xl-4">
                  <Row>
                    <Col md={4}>
                      <Form.Group className="mb-4">
                        <Form.Label>Search By First Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="fname"
                          value={fname}
                          onChange={searchHandle}
                          placeholder="First name"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-4">
                        <Form.Label>Search By Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="lname"
                          value={lname}
                          onChange={searchHandle}
                          placeholder="Last name"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-4">
                        <Form.Label>Search By Program</Form.Label>
                        <Form.Control
                          as="select"
                          name="pid"
                          value={pid}
                          onChange={searchHandle}
                        >
                          <option value="">Select Program</option>
                          {programs &&
                            programs.map((el, i) => (
                              <option key={el.id} value={el.programID}>
                                {el.program}
                              </option>
                            ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="text-center">
                    <Button
                      className="mb-4"
                      style={btnColor}
                      onClick={handleSubmit}
                    >
                      {!search ? "Search" : "Reset"}
                    </Button>
                  </div>
                </div>
              </div>
              {loader ? (
                <div
                  className="spinner-border text-warning loaderStyle"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <MentorProfileTable
                  {...props.history}
                  mentors={mentors}
                  getMentor={getMentor}
                />
              )}
              {total > 10 ? (
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={setCurrentPage}
                  itemclassName="page-item"
                  linkclassName="page-link"
                />
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default withRouter(MentorProfile);
