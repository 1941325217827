import React, { Component } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { Button } from "@material-ui/core";
import AttendanceRPMTAble from "./attendanceForRPMTable";
import { getAttendance } from "../../../crud/auth.crud";
import swal from "sweetalert";
import Pagination from "react-js-pagination";
import withRouter from "../../../router/withRouter";

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
  color: "#fff",
};

const debounce = (fn, delay) => {
  let timer = null;
  return function (...args) {
    const context = this;
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
};

function removeDuplicates(arr) {
  var i, tmp;
  for (i = 0; i < arr.length; i++) {
    tmp = arr.lastIndexOf(arr[i]);
    if (tmp === i) {
      //Only one of this number
    } else {
      //More than one
      arr.splice(tmp, 1);
      arr.splice(i, 1);
    }
  }
}

class AttendanceForRPM extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.location.state.id,
      total: null,
      per_page: null,
      current_page: 1,
      program_name: "",
      child_id_arr: [],
      loading: true,
    };
    console.log("🚀 ~ AttendanceForRPM ~ constructor ~ props:", props);
    this.attendanceList = debounce(this.attendanceList, 200);
  }

  async componentDidMount() {
    this.attendanceList();
  }

  attendanceList = async () => {
    if (!this.state.loading) {
      await this.setState({ loading: true });
    }
    const { id, searchTxt, current_page } = this.state;
    try {
      let result = await getAttendance(current_page, id, searchTxt);
      if (result.status === 200) {
        this.setState({
          tableData: result.data.data.data,
          child_id_arr: result.data.child_list,
          current_page: result.data.data.current_page,
          total: result.data.data.total,
          per_page: result.data.data.per_page,
          program_name: result.data.program_name,
          loading: false,
        });
      }
    } catch {
      this.setState({ loading: false });
      swal("Oops!", "Network Error!!", "error");
    }
  };

  searchHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () =>
      this.attendanceList()
    );
  };

  handlePageChange = (pageNumber) => {
    this.setState({ current_page: pageNumber });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.current_page !== this.state.current_page) {
      window.scrollTo(0, 0);
      this.attendanceList();
    }
  }

  onChangeHandle = (child) => {
    let cArr = [...this.state.child_id_arr];
    cArr.push(child);
    removeDuplicates(cArr);
    this.setState({ child_id_arr: cArr });
  };

  render() {
    const { program_name, loading } = this.state;
    return (
      <>
        <div className="kt-portlet kt-portlet--height-fluid">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">
                Attendance for {program_name}
              </h3>
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="row">
              <div className="col-md-12">
                <div className="kt-section">
                  <div className="kt-section__content text-center">
                    <Row>
                      <Col md={10}>
                        <div className="input-group ">
                          <input
                            className="form-control"
                            type="text"
                            name="searchTxt"
                            onChange={this.searchHandle}
                            placeholder="Search  by name"
                            aria-label="Search"
                          />
                          <div className="input-group-append">
                            <span
                              className="input-group-text h-100 amber lighten-3"
                              id="basic-text1"
                            >
                              <i
                                className="fas fa-search text-grey"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col md={2}>
                        <Button type="submit" style={btnColor}>
                          Search
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="kt-section">
                  <div className="kt-section__content text-center">
                    {loading ? (
                      <div
                        className="spinner-border text-warning dashboardLoading"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <AttendanceRPMTAble
                        tableData={this.state.tableData}
                        attendanceList={this.attendanceList}
                        onChangeHandle={this.onChangeHandle}
                        child_id_arr={this.state.child_id_arr}
                        program_id={this.state.id}
                      />
                    )}
                  </div>
                </div>
              </div>
              <Pagination
                activePage={this.state.current_page}
                itemsCountPerPage={10}
                totalItemsCount={this.state.total}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange.bind(this)}
                itemclassName="page-item"
                linkclassName="page-link"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(AttendanceForRPM);
