import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Form, Container, Col, Row } from "react-bootstrap";
import UserTable from "./userTable";
import { getUsers, addUsers } from "../../../crud/auth.crud";
import swal from "sweetalert";

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};
const emptyState = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  role: "AllAccess",
};
export default class UserRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      role: "AllAccess",
    };
  }

  async componentDidMount() {
    await this.getUser();
  }
  onChangeHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  getUser = async () => {
    try {
      const result = await getUsers();
      if (result.status === 200) {
        this.setState({ tableData: result.data.data, loader: false });
      }
    } catch {
      this.setState({ loader: false });
      swal("Oops!", "Network Error!", "error");
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { first_name, last_name, email, password, role } = this.state;
    let result = await addUsers(first_name, last_name, email, password, role);
    if (result.status === 200) {
      swal("User added Successfully!", "", "success");
      this.setState(emptyState, () => this.getUser());
    } else {
      swal("Oops!", "Please try again!", "error");
    }
  };
  render() {
    const { first_name, last_name, email, password, role } = this.state;
    return (
      <>
        <div className="kt-portlet kt-portlet--height-fluid">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">
                Urban Impact User Registration
              </h3>
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="row">
              <div className="col-md-12">
                <div className="kt-section">
                  <div className="kt-section__content">
                    <Container style={{ maxWidth: "550px" }}>
                      <Form onSubmit={this.handleSubmit}>
                        <Form.Group as={Row} className="form-group">
                          <Form.Label column sm={4}>
                            First Name
                          </Form.Label>
                          <Col sm={8}>
                            <Form.Control
                              required
                              type="text"
                              name="first_name"
                              value={first_name}
                              onChange={this.onChangeHandle}
                              placeholder="First Name"
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="form-group">
                          <Form.Label column sm={4}>
                            Last Name
                          </Form.Label>
                          <Col sm={8}>
                            <Form.Control
                              required
                              type="text"
                              name="last_name"
                              value={last_name}
                              onChange={this.onChangeHandle}
                              placeholder="Last Name"
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="form-group">
                          <Form.Label column sm={4}>
                            Email Address
                          </Form.Label>
                          <Col sm={8}>
                            <Form.Control
                              required
                              type="email"
                              name="email"
                              value={email}
                              onChange={this.onChangeHandle}
                              placeholder="Email"
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="form-group">
                          <Form.Label column sm={4}>
                            Password
                          </Form.Label>
                          <Col sm={8}>
                            <Form.Control
                              required
                              type="password"
                              name="password"
                              value={password}
                              onChange={this.onChangeHandle}
                              placeholder="password"
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="form-group">
                          <Form.Label column sm={4}>
                            Role
                          </Form.Label>
                          <Col sm={8}>
                            <Form.Control
                              required
                              as="select"
                              name="role"
                              onChange={this.onChangeHandle}
                              value={role}
                            >
                              <option>AllAccess</option>
                              <option>SuperAdmin</option>
                              <option>User</option>
                            </Form.Control>{" "}
                          </Col>
                        </Form.Group>
                        {/* <Form.Group as={Row} className="form-group" controlId="formHorizontalPassword">
                    <Form.Label column sm={4}>
                    Confirm Password
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="password" placeholder="confirm password" />
                    </Col>
                  </Form.Group> */}
                        <Form.Group
                          as={Row}
                          className="form-group"
                          className="text-center"
                        >
                          <Col sm={{ span: 10, offset: 2 }}>
                            <Button type="submit" style={btnColor}>
                              Register
                            </Button>
                          </Col>
                        </Form.Group>
                      </Form>
                    </Container>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="kt-section">
                  <div className="kt-section__content text-center">
                    <Container style={{ maxWidth: "750px" }}>
                      <UserTable
                        tableData={this.state.tableData}
                        getUser={this.getUser}
                      />
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
