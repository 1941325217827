import React, { Component } from "react";

import Summary from "../../Components/dashboardPages/summary";
import withRouter from "../../router/withRouter";

 class Dashboard extends Component {
  render() {
    return (
      <div>
        <Summary />
      </div>
    );
  }
}


export default withRouter(Dashboard);