/* eslint-disable jsx-a11y/anchor-has-content,no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import "./dashboard.css";

const maxWidth = {
  maxWidth: "800px",
};

export default function TutoringAndVolunteerHours(props) {
  const { hour_tutoring, kids_mentored, paid_leader, volunteer } = props.obj;
  return (
    <>
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              Tutoring and volunteer Hours
            </h3>
          </div>
          {/* <PortletHeaderDropdown /> */}
        </div>
        <div className="kt-portlet__body">
          <Typography
            variant="caption"
            display="block"
            className="mb-4 dashboardText"
          >
            Urban Impact is unique in that nearly all of the engagements with
            kids is driven by volunteers consider the following statistics.
          </Typography>

          <Container style={maxWidth}>
            <div className="kt-widget4">
              <div className="kt-widget4__item ">
                <div className="kt-widget4__info ">
                  <p className="kt-widget4__text fw-bold">
                    &middot; &nbsp; Hours of tutoring
                  </p>
                </div>
                {/* <a className="btn btn-sm btn-label-dark">Follow</a> */}
                {hour_tutoring} hours
              </div>
              <div className="kt-widget4__item ">
                <div className="kt-widget4__info ">
                  <p className="kt-widget4__text fw-bold">
                    &middot; &nbsp; Number of kids mentored
                  </p>
                </div>
                {/* <a className="btn btn-sm btn-label-dark">Follow</a> */}
                {kids_mentored}
              </div>
              <div className="kt-widget4__item ">
                <div className="kt-widget4__info ">
                  <p className="kt-widget4__text fw-bold">
                    &middot; &nbsp; Nuber of paid leaders and mentors
                  </p>
                </div>
                {paid_leader}
              </div>
              <div className="kt-widget4__item ">
                <div className="kt-widget4__info ">
                  <p className="kt-widget4__text fw-bold">
                    &middot; &nbsp; Number of volunteer tutors
                  </p>
                </div>
                {volunteer}
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}
