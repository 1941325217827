import React from "react";
import { withStyles, makeStyles } from "@material-ui/core";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
}));

export default function SummaryReportTable(props) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell>Top 10 List</StyledTableCell>
            <StyledTableCell>First Name</StyledTableCell>
            <StyledTableCell>Last Name</StyledTableCell>
            <StyledTableCell>Age</StyledTableCell>
            <StyledTableCell>Mentor Hours</StyledTableCell>
            <StyledTableCell>2015 - 16 GPA</StyledTableCell>
            <StyledTableCell>Primary Emergency Contact</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableData &&
            props.tableData.map((row, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell component="th" scope="row">
                  {i + 1}
                </StyledTableCell>
                <StyledTableCell>{row.firstName}</StyledTableCell>
                <StyledTableCell>{row.lastName}</StyledTableCell>
                <StyledTableCell>{row.age ? row.age : "-"}</StyledTableCell>
                <StyledTableCell>{row.hours ? row.hours : "-"}</StyledTableCell>
                <StyledTableCell>
                  {row.grades ? row.grades : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {row.contact ? row.contact : "-"}
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
