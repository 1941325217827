/* eslint-disable jsx-a11y/anchor-has-content,no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import "./dashboard.css";

const maxWidth = {
  maxWidth: "800px",
};

export default function FoodFocus(props) {
  const { meal_served, kids_recieved_meal, volunteer_prep } = props.obj;
  return (
    <>
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              Food, Focus and Engagement
            </h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <Typography
            variant="caption"
            display="block"
            className="mb-4 dashboardText"
          >
            Early in a month a family will have money for groceries from food
            stamps. Towards the end of the month food will become much more
            scarce. Urban impact has learned that bringing food not only
            provides a needed source of nutrition, but also helps create a
            stronger bonding environment between the kids and their mentors.
          </Typography>

          <Container style={maxWidth}>
            <div className="kt-widget4">
              <div className="kt-widget4__item ">
                <div className="kt-widget4__info ">
                  <p className="kt-widget4__text fw-bold">
                    &middot; Number of meal served
                  </p>
                </div>
                {meal_served}
              </div>
              <div className="kt-widget4__item ">
                <div className="kt-widget4__info ">
                  <p className="kt-widget4__text fw-bold">
                    &middot; Number of kids receiving meal
                  </p>
                </div>
                {kids_recieved_meal}
              </div>
              <div className="kt-widget4__item ">
                <div className="kt-widget4__info ">
                  <p className="kt-widget4__text fw-bold">
                    &middot; Number of volunteers preparing meals <br />
                    (we also get from BRM)
                  </p>
                </div>
                {volunteer_prep}
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}
