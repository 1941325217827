import React, { Component } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import ChildProfileTable from "./childProfileTable";
import swal from "sweetalert";
import { serverUrl } from "../../../_metronic/utils/api";
import axios from "axios";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
  color: "#fff",
};

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

export default class ChildSearchByDate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [],
      loader: true,
      total: null,
      per_page: null,
      current_page: 1,
      start_date: "",
      end_date: "",
      current_pageSearch: 1,
    };
  }

  setStartDate = (date) => {
    if (this.state.current_page !== 1) {
      return this.setState({ start_date: date, current_page: 1 });
    }
    this.setState({ start_date: date });
  };

  setEndDate = (date) => {
    if (this.state.current_page !== 1) {
      return this.setState({ end_date: date, current_page: 1 });
    }
    this.setState({ end_date: date });
  };

  async componentDidMount() {
    await this.getChild();
  }

  getChild = async () => {
    if (!this.state.loader) {
      this.setState({ loader: true });
    }
    try {
      const result = await axios.get(
        `${serverUrl}child?page=${this.state.current_page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      let details = result.data.data;
      if (result.status === 200) {
        this.setState({
          tableData: details.data,
          current_page: details.current_page,
          total: details.total,
          per_page: details.per_page,
          loader: false,
        });
      }
    } catch {
      this.setState({ loader: false });
      swal("Oops!", "Network Error!", "error");
    }
  };

  getChildSearch = async () => {
    if (!this.state.loader) {
      this.setState({ loader: true });
    }
    const { start_date, end_date } = this.state;
    let SD = start_date ? convert(start_date) : "";
    let ED = end_date ? convert(end_date) : "";
    try {
      const result = await axios.post(
        `${serverUrl}child-date?page=${this.state.current_pageSearch}`,
        { start_date: SD, end_date: ED },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      let details = result.data.data;
      if (result.status === 200) {
        this.setState({
          tableData: details.data,
          current_page: details.current_page,
          total: details.total,
          per_page: details.per_page,
          loader: false,
        });
      }
    } catch {
      this.setState({ loader: false });
      swal("Oops!", "Network Error!", "error");
    }
  };

  handlePageChange = (pageNumber) => {
    if (this.state.search) {
      this.setState({ current_pageSearch: pageNumber });
    } else {
      this.setState({ current_page: pageNumber });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.current_page !== this.state.current_page &&
      !this.state.search
    ) {
      window.scrollTo(0, 0);
      this.getChild();
    }
    if (prevState.current_pageSearch !== this.state.current_pageSearch) {
      window.scrollTo(0, 0);
      this.getChildSearch();
    }
  }

  searchHandler = (e) => {
    e.preventDefault();
    if (this.state.start_date && this.state.end_date) {
      this.setState({ search: true }, () => this.getChildSearch());
    } else {
      swal("Oops!", "Please Select Start and End Date!", "error");
    }
  };

  resetSearch = (e) => {
    e.preventDefault();
    e.preventDefault();
    this.setState(
      { search: false, start_date: "", end_date: "", current_page: 1 },
      () => this.getChild(1)
    );
  };
  render() {
    const { start_date, end_date } = this.state;
    return (
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__body">
          <div className="row">
            <div className="col-xl-4 col-lg-6">
              <Form onSubmit={this.searchHandler}>
                <Row>
                  <Col sm={6}>
                    <Form.Group className="form-group">
                      <Form.Label>Start Date</Form.Label>
                      <div className="w-100">
                        {" "}
                        <DatePicker
                          required
                          selected={start_date}
                          onChange={(date) => this.setStartDate(date)}
                          selectsStart
                          startDate={start_date}
                          endDate={end_date}
                          placeholderText="Start Date"
                          className="form-control ChildDateChange w-100"
                          fullWidth
                          showYearDropdown
                          scrollableMonthYearDropdown
                          maxDate={new Date()}

                          // maxDate={new Date()}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group className="form-group">
                      <Form.Label>End Date</Form.Label>
                      <div className="w-100">
                        <DatePicker
                          required
                          selected={end_date}
                          onChange={(date) => this.setEndDate(date)}
                          selectsEnd
                          startDate={start_date}
                          endDate={end_date}
                          minDate={start_date}
                          maxDate={new Date()}
                          placeholderText="End Date"
                          className="form-control ChildDateChange w-100"
                          disabled={start_date ? false : true}
                          showYearDropdown
                          scrollableMonthYearDropdown
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="text-center mb-4">
                  {this.state.search ? (
                    <Button onClick={this.resetSearch} style={btnColor}>
                      Reset
                    </Button>
                  ) : (
                    <Button type="submit" style={btnColor}>
                      Search
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </div>
          {this.state.loader ? (
            <div
              className="spinner-border text-warning loaderStyle"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <ChildProfileTable
              tableData={this.state.tableData}
              activePage={this.state.current_page}
              itemsCountPerPage={10}
              totalItemsCount={this.state.total}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange}
              getChild={this.getChild}
            />
          )}
          {this.state.total > 10 && !this.state.loader ? (
            <Pagination
              activePage={this.state.current_page}
              itemsCountPerPage={10}
              totalItemsCount={this.state.total}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange.bind(this)}
              itemclassName="page-item"
              linkclassName="page-link"
            />
          ) : null}
        </div>
      </div>
    );
  }
}
