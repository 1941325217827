/* eslint-disable jsx-a11y/anchor-has-content,no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import "./dashboard.css";

const maxWidth = {
  maxWidth: "800px",
};

export default function BarrierFromPverty() {
  return (
    <>
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              Perspective Barriers from Poverty
            </h3>
          </div>
          {/* <PortletHeaderDropdown /> */}
        </div>
        <div className="kt-portlet__body">
          <Typography
            variant="caption"
            display="block"
            className="mb-4 dashboardText"
          >
            Nearly every family is below the poverty line. Rent is either over
            $1,110/month or 30% of gross income , whichever is lower.
            Electricity is not covered. Transportation is often a challange.
            <br />
            <br />
            While the school environment is struggling with basic levels of
            mathematical and reading proficiency, the families living in PT
            Barnum are struggling at poverty line. For this reason, there are
            rarely trips to museums or tourist destination. There are rarely
            trips out of Bridgeport.
          </Typography>

          <Container style={maxWidth}>
            <div className="d-flex">
              <div className="col-cus">
                Poverty Level for mother and two children
              </div>
              <div className="col-cus">{"< $2,000 per month"}</div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}
