import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import { Modal } from "@material-ui/core"; // import { Button } from "@material-ui/core";import { Button, Form } from "react-bootstrap";
import swal from "sweetalert";
import { Form, Col, Button } from "react-bootstrap";
import { editChurchRequest } from "../../crud/auth.crud";
import withRouter from "../../router/withRouter";

const paper = {
  position: "absolute",
  width: 400,
  backgroundColor: "#fff",
  boxShadow: 5,
  padding: 25,
  outline: "none",
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`,
};

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

 class EditChurch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      church: this.props.editData.church,
      id: this.props.editData.id,
    };
  }
  setStartDate = (date) => {
    this.setState({ start_date: date });
  };

  setEndDate = (date) => {
    this.setState({ end_date: date });
  };

  onChangeHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  callbacks = () => {
    this.props.handleClose();
    this.props.getChruchList();
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { id,church } = this.state;
    let result = await editChurchRequest(id,church);
    if (result.status === 200) {
      swal("Chruch Details Updated Successfully!", "", "success");
      this.setState({ password: "" }, this.callbacks);
    } else {
      swal("Oops!", "Please try again!", "error");
    }
  };

  render() {
    const { church } = this.state;
    return (
      <div>
        {/* <Typography gutterBottom>
          Click to get the full Modal experience!
        </Typography> */}
        {/* <Button onClick={this.props.handleOpen}>Open Modal</Button> */}
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.props.open}
          onClose={this.props.handleClose}
        >
          <div style={paper}>
            <div className="d-flex justify-content-between">
              <span>
                {" "}
                <Typography variant="h6" id="modal-title">
                  Edit Church
                </Typography>
              </span>
              <span>
                {" "}
                <i
                  className="fas fa-times"
                  onClick={this.props.handleClose}
                ></i>
              </span>
            </div>

            <Form className="mt-4" onSubmit={this.handleSubmit}>
              <Form.Group className="form-group">
                <Form.Label>Church Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Church Name"
                  name="church"
                  value={church}
                  onChange={this.onChangeHandle}
                />
              </Form.Group>


              <div className="text-center form-group">
                <Button type="submit" style={btnColor}>
                  Update
                </Button>
              </div>
            </Form>

            {/* <AddSpecialEvent /> */}
          </div>
        </Modal>
      </div>
    );
  }
}


export default  withRouter(EditChurch);