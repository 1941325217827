/* eslint-disable jsx-a11y/anchor-has-content,no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import "../Components/dashboardPages/dashboard.css";

const maxWidth = {
  maxWidth: "800px",
};

export default function NewUsers(props) {
  const { kids_hours, kids_reached, leaders_mentors, volunteer_mentor } =
    props.obj;
  return (
    <>
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              Lots of Mentors,Tons of hours,Tight Focus
            </h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <Typography
            variant="caption"
            display="block"
            className="mb-4 dashboardText"
          >
            Urban Impact power comes from a huge volunteer base and a focused
            target of kids in a single community.
          </Typography>

          <Container style={maxWidth}>
            <div className="kt-widget4">
              <div className="kt-widget4__item ">
                <div className="kt-widget4__info ">
                  <p className="kt-widget4__text fw-bold">
                    &middot; &nbsp; Number of kids reached
                  </p>
                </div>
                {kids_reached ? kids_reached : "-"}
              </div>
              <div className="kt-widget4__item ">
                <div className="kt-widget4__info ">
                  <p className="kt-widget4__text  fw-bold">
                    &middot; &nbsp; Number of hours kids were engaged
                  </p>
                </div>
                {kids_hours ? kids_hours : "-"}
              </div>
              <div className="kt-widget4__item ">
                <div className="kt-widget4__info ">
                  <p className="kt-widget4__text  fw-bold">
                    &middot; &nbsp; Number of paid leaders and mentors
                  </p>
                </div>
                {leaders_mentors ? leaders_mentors : "-"}
              </div>
              <div className="kt-widget4__item ">
                <div className="kt-widget4__info ">
                  <p className="kt-widget4__text  fw-bold">
                    &middot; &nbsp; Number of volunteer mentors
                  </p>
                </div>
                {volunteer_mentor ? volunteer_mentor : "-"}
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}
