import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import objectPath from "object-path";
// import HMenuItem from "./HMenuItem";
import * as builder from "../../../ducks/builder";
import { toAbsoluteUrl } from "../../..";
import KTMenu from "../../../_assets/js/menu";
import KTOffcanvas from "../../../_assets/js/offcanvas";
// import PortletHeaderDropdown from "../../../../app/partials/content/CustomDropdowns/PortletHeaderDropdown";
import { Dropdown } from "react-bootstrap";
import DashboardMenu from "./components/dashboardMenu";
import { Button } from "@material-ui/core";
import { fetchData } from "../../../../app/crud/auth.crud";
import withRouter from "../../../../app/router/withRouter";

const offcanvasOptions = {
  overlay: true,
  baseClass: "kt-header-menu-wrapper",
  closeBy: "kt_header_menu_mobile_close_btn",
  toggleBy: {
    target: "kt_header_mobile_toggler",
    state: "kt-header-mobile__toolbar-toggler--active",
  },
};

const btnText = { textTransform: "unset", fontSize: "11px" };

const menuJson = [
  {
    name: "Kid Power",
    id: 6,
    level_2: [],
  },
  {
    name: "PrimeTime Programs",
    id: 109,
    level_2: [
      {
        name: "PrimeTime",
        id: 2,
      },
      {
        name: "PrimeTime Junior",
        id: 3,
      },
      {
        name: "PrimeTime Hoops",
        id: 4,
      },
      {
        name: "Revolution",
        id: 10,
      },
    ],
  },

  {
    name: "Shepherd Programs",
    id: 110,
    level_2: [
      {
        name: "Sunday Shepherd",
        id: 7,
      },
      {
        name: "Wednesday Shepherd",
        id: 16,
      },
    ],
  },
  {
    name: "XLR8",
    id: 11,
    level_2: [],
  },
  {
    name: "Mitera",
    id: 15,
    level_2: [],
  },
  {
    name: "Special Events",
    id: "",
    level_2: [],
  },
];

function getItem(key) {
  return JSON.parse(localStorage.getItem(key));
}

class HMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: [],
    };
  }

  offCanvasCommonRef = React.createRef();
  ktMenuCommonRef = React.createRef();

  getHeaderLogo() {
    let result = "logo-light.png";
    if (this.props.headerSelfSkin && this.props.headerSelfSkin !== "dark") {
      result = "logo-dark.png";
    }
    return toAbsoluteUrl(`/media/logos/${result}`);
  }

  get currentUrl() {
    return this.props.currentPath;
  }

  getMenu = async () => {
    try {
      let menu = await fetchData("get-program-menu");
      if (menu.status === 200) {
        this.setState({ menu: menu.data.data });
      }
    } catch(err) {
      this.setState({ menu: menuJson });
    }
  };

  componentDidMount() {
    // Canvas
    this.initOffCanvas();
    // Menu
    this.initKTMenu();

    // program menu
    this.getMenu();
  }

  initOffCanvas = () => {
    // eslint-disable-next-line no-undef
    new KTOffcanvas(this.offCanvasCommonRef.current, offcanvasOptions);
  };

  initKTMenu = () => {
    let menuOptions = {
      submenu: {
        desktop: "dropdown",
        tablet: "accordion",
        mobile: "accordion",
      },
      accordion: {
        slideSpeed: 200, // accordion toggle slide speed in milliseconds
        expandAll: false, // allow having multiple expanded accordions in the menu
      },
      dropdown: {
        timeout: 50,
      },
    };

    let menuDesktopMode = "accordion";
    if (
      this.ktMenuCommonRef.current.getAttribute("data-ktmenu-dropdown") === "1"
    ) {
      menuDesktopMode = "dropdown";
    }

    if (typeof objectPath.get(menuOptions, "submenu.desktop") === "object") {
      menuOptions.submenu.desktop = {
        default: menuDesktopMode,
      };
    }

    // eslint-disable-next-line no-undef
    new KTMenu(this.ktMenuCommonRef.current, menuOptions);
  };

  moveToPage = (page, name, id) => {
    let obj = { name: name, id: id };
    localStorage.setItem("progName", JSON.stringify(obj));
    this.props.navigate("/" + page);
  };

  linkData = (name, id) => {
    let obj = { name: name, id: id };
    localStorage.setItem("progName", JSON.stringify(obj));
  };

  render() {
    const {
      disabledAsideSelfDisplay,
      ktMenuClasses,
      ulClasses,
      // rootArrowEnabled,
    } = this.props;
    // const items = this.props.menuConfig.header.items;
    const { menu } = this.state;
    if (this.currentUrl === "/programs/scoreboard") {
      let obj = { name: "Kid Power", id: 6 };
      localStorage.setItem("progName", JSON.stringify(obj));
    }
    return (
      <>
        <button
          className="kt-header-menu-wrapper-close"
          id="kt_header_menu_mobile_close_btn"
        >
          <i className="la la-close" />
        </button>
        <div
          className="kt-header-menu-wrapper"
          id="kt_header_menu_wrapper"
          ref={this.offCanvasCommonRef}
        >
          {disabledAsideSelfDisplay && (
            <div className="kt-header-logo">
              <Link to="/">
                <img alt="logo" src={this.getHeaderLogo()} />
              </Link>
            </div>
          )}

          <div
            id="kt_header_menu"
            className={`kt-header-menu kt-header-menu-mobile ${ktMenuClasses}`}
            ref={this.ktMenuCommonRef}
          >
            <ul className={`kt-menu__nav ${ulClasses}`}>
              {this.currentUrl === "/childProfile" ||
              this.currentUrl === "/childProfile-2" ||
              this.currentUrl === "/childProfile-3" ||
              this.currentUrl === "/childProfile-4" ||
              this.currentUrl === "/childProfile-5" ||
              this.currentUrl === "/profileInfo" ? (
                <React.Fragment>
                  <Button
                    color={
                      this.currentUrl === "/childProfile"
                        ? "primary"
                        : "inherit"
                    }
                    size="small"
                    onClick={() => this.moveToPage("childProfile")}
                    style={btnText}
                  >
                    Child Search By Name
                  </Button>
                  <Button
                    color={
                      this.currentUrl === "/childProfile-2"
                        ? "primary"
                        : "inherit"
                    }
                    size="small"
                    onClick={() => this.moveToPage("childProfile-2")}
                    style={btnText}
                  >
                    Child Search By Program
                  </Button>
                  <Button
                    color={
                      this.currentUrl === "/childProfile-3"
                        ? "primary"
                        : "inherit"
                    }
                    size="small"
                    onClick={() => this.moveToPage("childProfile-3")}
                    style={btnText}
                  >
                    Child Search By Address
                  </Button>
                  <Button
                    color={
                      this.currentUrl === "/childProfile-4"
                        ? "primary"
                        : "inherit"
                    }
                    size="small"
                    onClick={() => this.moveToPage("childProfile-4")}
                    style={btnText}
                  >
                    Child Search By Permission Slip
                  </Button>
                  <Button
                    color={
                      this.currentUrl === "/childProfile-5"
                        ? "primary"
                        : "inherit"
                    }
                    size="small"
                    onClick={() => this.moveToPage("childProfile-5")}
                    style={btnText}
                  >
                    Child Search By Date Range
                  </Button>
                </React.Fragment>
              ) : null}
              {this.currentUrl === "/addChild" ? (
                <React.Fragment>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => this.moveToPage("addChild")}
                    style={btnText}
                  >
                    Add Child
                  </Button>
                </React.Fragment>
              ) : null}

              {this.currentUrl === "/mentorProfile" ||
              this.currentUrl === "/mentorProfileSummary" ||
              this.currentUrl === "/mentorProfileNotes" ||
              this.currentUrl === "/mentorPersonalInfo" ? (
                <React.Fragment>
                  <Button
                    size="small"
                    color={
                      this.currentUrl === "/mentorProfile" ||
                      this.currentUrl === "/mentorPersonalInfo"
                        ? "primary"
                        : "inherit"
                    }
                    style={btnText}
                    onClick={() => this.moveToPage("mentorProfile")}
                  >
                    Mentor Profile
                  </Button>
                </React.Fragment>
              ) : null}

              {this.currentUrl === "/addMentor" ? (
                <React.Fragment>
                  <Button color="primary" size="small" style={btnText}>
                    Add Mentor
                  </Button>
                </React.Fragment>
              ) : null}
              {this.currentUrl === "/reports" ||
              this.currentUrl === "/tutoringAnalysis" ||
              this.currentUrl === "/primeTimeAttendanceReport" ||
              this.currentUrl === "/kid-report-table" ||
              this.currentUrl === "/active-mentors" ? (
                <React.Fragment>
                  <Button color="primary" size="small" style={btnText}>
                    Reports
                  </Button>
                </React.Fragment>
              ) : null}
              {this.currentUrl === "/kid-reports" ? (
                <React.Fragment>
                  <Button color="primary" size="small" style={btnText}>
                    Kid Reports
                  </Button>
                </React.Fragment>
              ) : null}

              {this.currentUrl === "/literacy-reports" ? (
                <React.Fragment>
                  <Button color="primary" size="small" style={btnText}>
                    Reports
                  </Button>
                </React.Fragment>
              ) : null}

              {this.currentUrl.includes("/programs") ||
              this.currentUrl.includes("/progList") ||
              this.currentUrl === "/specialEvents" ||
              this.currentUrl === "/attendance-RPM" ||
              this.currentUrl === "/adddedToCDI" ? (
                <React.Fragment>
                  {menu
                    .filter((men) => men.name !== "Special Events")
                    .map((el, i) =>
                      el.level_2.length === 0 ? (
                        <Button
                          color={
                            getItem("progName").name === el.name
                              ? "primary"
                              : "inherit"
                          }
                          key={i}
                          size="small"
                          className="mt-2"
                          style={btnText}
                          onClick={
                            el.name === "Kid Power"
                              ? () =>
                                  this.moveToPage(
                                    "programs/scoreboard",
                                    el.name,
                                    el.id
                                  )
                              : () =>
                                  this.moveToPage(
                                    "progList/scoreboard",
                                    el.name,
                                    el.id
                                  )
                          }
                        >
                          {el.name}
                        </Button>
                      ) : (
                        <Dropdown
                          className="kt-portlet__head-toolbar mt-4 ml-2"
                          drop="down"
                          key={i}
                        >
                          <Dropdown.Toggle
                            variant="transparent"
                            className={
                              el.level_2
                                .map((val) => val.name)
                                .includes(getItem("progName").name)
                                ? "btn btn-label-warning btn-sm btn-bold dropdown-toggle"
                                : "btn  btn-sm  dropdown-toggle"
                            }
                            id="dropdown-toggle-top"
                          >
                            {el.name}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right">
                            {el.level_2.map((val) => (
                              <ul className="kt-nav" key={val.id}>
                                <li className="kt-nav__item">
                                  <Link
                                    // to="/mitera/scoreboard"
                                    to={{
                                      pathname: "/progList/scoreboard",
                                    }}
                                    onClick={() =>
                                      this.linkData(val.name, val.id)
                                    }
                                    className="kt-nav__link"
                                  >
                                    {/* <i className="kt-nav__link-icon flaticon2-line-chart" /> */}
                                    <span className="kt-nav__link-text">
                                      {val.name}
                                    </span>
                                  </Link>{" "}
                                </li>
                              </ul>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      )
                    )}
                  <Button
                    color={
                      this.currentUrl === "/specialEvents" ||
                      this.currentUrl === "/attendance-RPM"
                        ? "primary"
                        : "inherit"
                    }
                    className="mt-2"
                    size="small"
                    style={btnText}
                    onClick={() => this.moveToPage("specialEvents")}
                  >
                    Special Events
                  </Button>
                </React.Fragment>
              ) : null}

              {this.currentUrl === "/adminUserRegistration" ||
              this.currentUrl === "/sessionManagement" ||
              this.currentUrl === "/programDates" ||
              this.currentUrl === "/adminTool" ||
              this.currentUrl.includes("adminProgDates") ||
              this.currentUrl === "/addAdminProgram" ||
              this.currentUrl === "/addSlip" ||
              this.currentUrl === "/addSubject" ||
              this.currentUrl === "/church" ? (
                <React.Fragment>
                  <Button
                    color={
                      this.currentUrl === "/adminUserRegistration"
                        ? "primary"
                        : "inherit"
                    }
                    size="small"
                    className="mt-2"
                    style={btnText}
                    onClick={() => this.moveToPage("adminUserRegistration")}
                  >
                    User Registration
                  </Button>
                  <Button
                    color={
                      this.currentUrl === "/sessionManagement"
                        ? "primary"
                        : "inherit"
                    }
                    className="mt-2"
                    size="small"
                    style={btnText}
                    onClick={() => this.moveToPage("sessionManagement")}
                  >
                    Session Management
                  </Button>
                  <Button
                    color={
                      this.currentUrl.includes("adminProgDates")
                        ? "primary"
                        : "inherit"
                    }
                    className="mt-2"
                    size="small"
                    style={btnText}
                    onClick={() => this.moveToPage("adminProgDates/list")}
                  >
                    Program Dates
                  </Button>
                  <Button
                    color={
                      this.currentUrl === "/adminTool" ? "primary" : "inherit"
                    }
                    className="mt-2"
                    size="small"
                    style={btnText}
                    onClick={() => this.moveToPage("adminTool")}
                  >
                    Admin Tool
                  </Button>
                  <Button
                    color={
                      this.currentUrl === "/addAdminProgram"
                        ? "primary"
                        : "inherit"
                    }
                    className="mt-2"
                    size="small"
                    style={btnText}
                    onClick={() => this.moveToPage("addAdminProgram")}
                  >
                    Add Program
                  </Button>
                  <Button
                    color={
                      this.currentUrl === "/addSlip" ? "primary" : "inherit"
                    }
                    className="mt-2"
                    size="small"
                    style={btnText}
                    onClick={() => this.moveToPage("addSlip")}
                  >
                    Add Permission Slip
                  </Button>
                  <Button
                    color={
                      this.currentUrl === "/addSubject" ? "primary" : "inherit"
                    }
                    className="mt-2"
                    size="small"
                    style={btnText}
                    onClick={() => this.moveToPage("addSubject")}
                  >
                    Add Subject
                  </Button>
                  <Button
                    color={
                      this.currentUrl === "/church" ? "primary" : "inherit"
                    }
                    className="mt-2"
                    size="small"
                    style={btnText}
                    onClick={() => this.moveToPage("church")}
                  >
                    Add Church
                  </Button>
                </React.Fragment>
              ) : null}

              {this.currentUrl === "/dashboard" ||
              this.currentUrl === "/changeDate" ||
              this.currentUrl === "/mentoring" ||
              this.currentUrl === "/tutoring" ||
              this.currentUrl === "/hours" ||
              this.currentUrl === "/perspective" ||
              this.currentUrl === "/feeding" ||
              this.currentUrl === "/changeDate/summary" ||
              this.currentUrl === "/changeDate/mentoring" ||
              this.currentUrl === "/changeDate/tutoring" ||
              this.currentUrl === "/changeDate/perspective" ||
              this.currentUrl === "/changeDate/feeding" ||
              this.currentUrl === "/changeDate/hours" ? (
                <React.Fragment>
                  <DashboardMenu {...this.props} />
                </React.Fragment>
              ) : null}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  ktMenuClasses: builder.selectors.getClasses(store, {
    path: "header_menu",
    toString: true,
  }),
  rootArrowEnabled: builder.selectors.getConfig(
    store,
    "header.menu.self.root-arrow"
  ),
  headerSelfSkin: builder.selectors.getConfig(store, "header.self.skin"),
  ulClasses: builder.selectors.getClasses(store, {
    path: "header_menu_nav",
    toString: true,
  }),
  disabledAsideSelfDisplay:
    objectPath.get(store.builder.layoutConfig, "aside.self.display") === false,
});

export default withRouter(connect(mapStateToProps)(HMenu));
