import axios from "axios";
import authAxios from "./authAxios";

export const BASE_URL = " https://www.urbanimpactprojects.com/api/public/api/";
// export const BASE_URL = "http://128.199.13.36/api/public/api/";

export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";

// const accessToken = localStorage.getItem("token");

// const authAxios = axios.create({
//   baseURL: BASE_URL,
//   headers: {
//     Authorization: `Bearer ${accessToken ? accessToken : "Hello acces token is not defined"}`,
//   },
// });

export function login(email, password) {
  return authAxios
    .post(`login`, { email, password })
    .catch((er) => console.log(er));
}

export function contactUs(values) {
  return authAxios.post(`contact`, values).catch((er) => console.log(er));
}

export function addSchool(school_name) {
  return authAxios.post(
    `school`,
    { school_name },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
}

export function addPrograms(program_name, program_parent) {
  return authAxios.post(
    `add-program`,
    { program_name, program_parent },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
}

export function addSession(session_name, date_start, date_end, type) {
  return authAxios.post(
    `add-session`,
    {
      session_name,
      date_start,
      date_end,
      type,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
}
export function addPermissionSlip(slip_name, start_date, end_date, program_id) {
  return authAxios.post(
    `add-permissionslip`,
    {
      slip_name,
      start_date,
      end_date,
      program_id,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
}
export function addChurch(church) {
  return authAxios.post(
    `church`,
    {
      church,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
}

export function addUsers(first_name, last_name, email, password, role) {
  return authAxios.post(
    `register`,
    {
      first_name,
      last_name,
      email,
      password,
      role,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
}

export function addMentor(
  gender,
  dob,
  ethnicity,
  first_name,
  last_name,
  mentor_image,
  church
) {
  let formData = new FormData();
  formData.append("mentor_image", mentor_image);
  formData.append("gender", gender);
  formData.append("dob", dob);
  formData.append("ethnicity", ethnicity);
  formData.append("first_name", first_name);
  formData.append("last_name", last_name);
  formData.append("church", church);
  return authAxios.post(`add-mentor`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export function addMentorNotes(mentor_id, note) {
  return authAxios.post(
    `save-mentor-note`,
    { mentor_id, note },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
}

export function addChildNotes(child_id, note) {
  return authAxios.post(
    `save-note`,
    { child_id, note },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
}

export function addSpecialEvents(
  program_name,
  start_date,
  end_date,
  leader,
  hours
) {
  return authAxios.post(
    `add-specialevent`,
    {
      program_name,
      start_date,
      end_date,
      leader,
      hours,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
}

export function updateLocation(
  building,
  apartment,
  street,
  city,
  zip_code,
  current_school,
  moved_or_not,
  child_id
) {
  return authAxios.post(
    `update-location`,
    {
      building,
      apartment,
      street,
      city,
      zip_code,
      current_school,
      moved_or_not,
      child_id,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
}

export function updateEmergencyInfo(
  child_id,
  e_contact,
  e_phone,
  e_relation,
  e_address,
  b_contact,
  b_phone,
  b_relation,
  b_address,
  sb_contact,
  sb_phone,
  sb_relation,
  sb_address
) {
  return authAxios.post(
    `update-emergencyinfo`,
    {
      child_id,
      e_contact,
      e_phone,
      e_relation,
      e_address,
      b_contact,
      b_phone,
      b_relation,
      b_address,
      sb_contact,
      sb_phone,
      sb_relation,
      sb_address,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
}

export function updatePersonalInfo(
  first_name,
  last_name,
  adult_or_child,
  date_of_birth,
  grade,
  image,
  ethnicity,
  child_id
) {
  let formData = new FormData();
  formData.append("first_name", first_name);
  formData.append("last_name", last_name);
  formData.append("adult_or_child", adult_or_child);
  formData.append("date_of_birth", date_of_birth);
  formData.append("grade", grade);
  formData.append("child_image", image);
  formData.append("child_id", child_id);
  formData.append("ethnicity", ethnicity);
  return authAxios.post(`updateinfo`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export function updateMentorInfo(
  mentor_id,
  firstName,
  lastName,
  gender,
  dateOfBirth,
  ethnicity,
  mentor_image,
  permission_slip,
  slip_date,
  church
) {
  console.log("🚀 ~ ethnicity:", ethnicity);
  let formData = new FormData();
  formData.append("mentor_id", mentor_id);
  formData.append("firstName", firstName);
  formData.append("lastName", lastName);
  formData.append("gender", gender);
  formData.append("dateOfBirth", dateOfBirth);
  formData.append("ethnicity", ethnicity);
  formData.append("mentor_image", mentor_image);
  formData.append("permission_slip", permission_slip);
  formData.append("slip_date", slip_date);
  formData.append("church", church);
  return authAxios.post(`mentor-update`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export function editPermissionSlip(
  slip_name,
  start_date,
  end_date,
  program_id,
  slip_id
) {
  return authAxios.post(
    `update-permissionslip`,
    {
      slip_name,
      start_date,
      end_date,
      program_id,
      slip_id,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
}

export function editUser(user_id, first_name, last_name, password, role) {
  return authAxios.post(
    `update-user`,
    {
      user_id,
      first_name,
      last_name,
      password,
      role,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
}

export function editChurchRequest(id, church) {
  return authAxios.post(
    `church-update`,
    {
      id,
      church,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
}

export function updateSpecialEvent(
  program_name,
  start_date,
  end_date,
  leader,
  hours,
  program_id
) {
  return authAxios.post(
    `update-specialevent`,
    {
      program_name,
      start_date,
      end_date,
      leader,
      hours,
      program_id,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
}

export function updateAttendance(program_id, child_id) {
  return authAxios.post(
    `update-attendance`,
    { program_id, child_id },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
}

export function getPrograms() {
  return authAxios.get(`programs`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export function getSchools() {
  return authAxios.get(`school`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export function getSessions() {
  return authAxios.get(`get-session`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export function getUsers() {
  return authAxios.get(`users`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export function getPermissionSlip() {
  return authAxios.get(`permissionslip`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export function getChruchListRequest() {
  return authAxios.get(`churches`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export function getMentors(page, first_name, last_name, program) {
  return authAxios.get(
    `mentors?page=${page}&first_name=${first_name}&
  last_name=${last_name}&program=${program}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
}

export function getMentorSummary(page, mentor_id) {
  return authAxios.post(
    `mentor-summary?page=${page}`,
    { mentor_id },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
}

export function getMentorNotes(mentorID) {
  return authAxios.get(`mentor-notes?mentor_id=${mentorID}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export function getChildNotes(childID) {
  return authAxios.get(`get-notes?child_id=${childID}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export function getSpecialEvents(page, searchTxt) {
  return authAxios.get(
    `special-events?page=${page}&program_name=${searchTxt}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
}

export function getAttendance(page, id, searchTxt) {
  return authAxios.post(
    `get-attendance?page=${page}`,
    {
      program_id: id,
      child_name: searchTxt,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
}

export function callApi(url, payload) {
  return authAxios.post(`${url}`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export function fetchDataWithPagination(url, page, payload) {
  return authAxios.post(`${url}?page=${page}`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export function fetchData(url) {
  return authAxios.get(`${url}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
