import React from "react";
import { withStyles, makeStyles } from "@material-ui/core";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    // minWidth: 700,
  },
}));

export default function UpdateParticipantsTable(props) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {/* <StyledTableCell align="center">Attended</StyledTableCell> */}
            <StyledTableCell align="center">Behavior</StyledTableCell>
            <StyledTableCell align="center">Bible</StyledTableCell>
            <StyledTableCell align="center">First Name</StyledTableCell>
            <StyledTableCell align="center">Last Name</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableData &&
            props.tableData.map((row, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell align="center">
                  {row.behavior ? row.behavior : "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.bible ? row.bible : "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.firstName ? row.firstName : "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.lastName ? row.lastName : "-"}
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
