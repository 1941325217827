import React from "react";
import { Bar } from "react-chartjs-2";
import { Typography } from "@material-ui/core";
import "../Components/dashboardPages/dashboard.css";
import "chartjs-plugin-datalabels";

export default class ConcentratedInfluenceChart extends React.Component {
  render() {
    return (
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              Concentrated Influence of Mentorship
            </h3>
          </div>
          {/* <PortletHeaderDropdown /> */}
        </div>
        <div className="kt-portlet__body">
          <Typography
            variant="caption"
            display="block"
            className="mb-4 dashboardText"
          >
            Urban Impact of Black Rock's objective is to immerse the kids from
            PT Barnum Apartments in several programs simultaneously to increase
            the breadth of influence. <br />
            The following summary shows the breadth of influence currently
            engaged:
          </Typography>
          <Bar
            data={{
              labels:
                this.props.graphData && this.props.graphData.length > 0
                  ? this.props.graphData.map((val) => val.event)
                  : [
                      "Bridgeport Class(7th grade)",
                      "Sports Team",
                      "Sunday School",
                      "Urban Impact",
                    ],
              datasets: [
                {
                  label: "Children",
                  backgroundColor: "#f4b53f",
                  // borderColor: 'rgba(0,0,0,1)',
                  // borderWidth: 2,
                  data:
                    this.props.graphData && this.props.graphData.length > 0
                      ? this.props.graphData.map((val) => val.children)
                      : [],
                },
              ],
            }}
            options={{
              plugins: {
                datalabels: {
                  display: true,
                  color: "#5c5a6d",
                  align: "end",
                  clamp: true,
                },
              },
              title: {
                display: true,
                // text:'Average Rainfall per month',
                fontSize: 10,
              },
              legend: {
                display: true,
                position: "right",
              },
            }}
          />
        </div>
        <div className="kt-portlet__body">
          <Typography
            variant="caption"
            display="block"
            className="mb-4 dashboardText"
          >
            Below are the specific ratios per program :
          </Typography>
          <Bar
            data={{
              labels:
                this.props.program_chart && this.props.program_chart.length > 0
                  ? this.props.program_chart.map((val) => val.progname)
                  : [
                      "PrimeTime Junior",
                      "PrimeTime",
                      "PrimeTime Senior",
                      "XLRB",
                      "Camp Spofford",
                    ],
              datasets: [
                {
                  label: "Program",
                  backgroundColor: "#a09999",
                  // borderColor: 'rgba(0,0,0,1)',
                  // borderWidth: 2,
                  data:
                    this.props.program_chart &&
                    this.props.program_chart.length > 0
                      ? this.props.program_chart.map((val) => val.childcounter)
                      : [],
                },
              ],
            }}
            options={{
              plugins: {
                datalabels: {
                  display: true,
                  color: "#5c5a6d",
                  align: "end",
                  clamp: true,
                },
              },
              title: {
                display: true,
                // text:'Average Rainfall per month',
                fontSize: 10,
              },
              legend: {
                display: true,
                position: "right",
              },
            }}
          />
        </div>
      </div>
    );
  }
}
