/* eslint-disable jsx-a11y/anchor-has-content,no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
//import { toAbsoluteUrl } from "../../_metronic/utils/utils";
//import PortletHeaderDropdown from "../partials/content/CustomDropdowns/PortletHeaderDropdown";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core";

const totalBorder = {
  borderTop: "2px solid #a09999",
  borderBottom: "2px solid #a09999",
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: "100px",
  },
}));

export default function MealServedTable(props) {
  const classes = useStyles();

  return (
    <>
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Meal Served Per Program</h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-section">
                <div className="kt-section__content">
                  <Paper className={classes.root}>
                    <Table className={classes.table}>
                      <TableBody>
                        {props.meal_per_program &&
                          props.meal_per_program.map((row, i) => (
                            <StyledTableRow key={i}>
                              <StyledTableCell align="left">
                                {" "}
                                &middot; &nbsp;{row.Program}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.meal_count}{" "}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        <StyledTableRow>
                          <StyledTableCell
                            align="center"
                            className="fw-bold"
                            style={totalBorder}
                          >
                            Total
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className="fw-bold"
                            style={totalBorder}
                          >
                            {props.total_meal_served}{" "}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </Paper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
