import React, { Component } from "react";
import PrintoutSheetTable from "./printoutSheetTable";
import { Form, Container, Col, Button, Row } from "react-bootstrap";
import { callApi } from "../../../crud/auth.crud";
import swal from "sweetalert";

export default class PrinoutSheet extends Component {
  constructor(props) {
    super(props);
    console.log("🚀 ~ PrinoutSheet ~ constructor ~ props:", props)

    this.state = {
      tableData: [],
      loader: true,
    };
  }

  async componentDidMount() {
    this.getData();
  }

  download_PDF = async () => {
    let payload = this.props?.location?.state;
    if (payload.program_id) this.setState({ loader: true });
    try {
      let result = await callApi("weekly-printout-pdf", payload);
      if (result.status === 200) {
        this.setState({ loader: false });
        window.open(result.data.data, "_blank");
      } else {
        this.setState({ loader: false });
        swal("Oops!", "Please try again!", "error");
      }
    } catch {
      this.setState({ loader: false });
      swal("Oops!", "Network Error!", "error");
    }
  };

  getData = async () => {
    let payload = this.props?.location?.state;
    console.log("🚀 ~ PrinoutSheet ~ getData= ~ payload:", payload)
    try {
      let result = await callApi("session-semester-printout", payload);
      if (result.status === 200) {
        let DATA = result.data.data;
        if (DATA && DATA.length) {
          this.setState({ tableData: result.data.data, loader: false });
        } else {
          this.setState({ loader: false });
        }
      } else {
        this.setState({ loader: false });
        swal("Oops!", "Please try again!", "error");
      }
    } catch {
      this.setState({ loader: false });
      swal("Oops!", "Network Error!", "error");
    }
  };

  render() {
    const { tableData } = this.state;
    return (
      <>
        {this.state.loader ? (
          <div
            className="spinner-border text-warning loaderStyleSessionSem"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <div className="col-md-8">
            <div className="text-right py-2">
              <Button onClick={this.download_PDF}>Print</Button>
            </div>
            {tableData && tableData.length > 0 ? (
              tableData.map((el) => (
                <div className="kt-portlet">
                  <div className="kt-portlet__body">
                    <div className="row" key={el.mentor_id}>
                      <div className="col-md-12">
                        <div className="kt-section">
                          <div className="kt-section__content">
                            <h5 className="borderDown">{el.mentor_name}</h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="kt-section">
                          <div className="kt-section__content">
                            <PrintoutSheetTable data={el.kids} />
                          </div>
                        </div>
                      </div>
                      <div className="kt-section">
                        <div className="kt-section__content">
                          <Container>
                            <Row>
                              <Col md={4}>
                                <span className="fw-bold">A-</span>
                                <span className="">Student is Present</span>
                              </Col>
                              <Col md={4}>
                                <span className="fw-bold">P-</span>
                                <span className="">
                                  Student is Participating
                                </span>
                              </Col>
                              <Col md={4}>
                                <span className="fw-bold">B1-</span>
                                <span className="">fair behaviour</span>
                              </Col>
                              <Col md={4}>
                                <span className="fw-bold">B2-</span>
                                <span className="">Good behaviour</span>
                              </Col>
                              <Col md={4}>
                                <span className="fw-bold">B3-</span>
                                <span className="">EXcellent Behaviour</span>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-md-8">
                <div className="kt-portlet">
                  <div className="kt-portlet__body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="kt-section">
                          <div className="kt-section__content">
                            <h5 className="borderDown">No Data</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}
