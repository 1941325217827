import React, { Component } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  withStyles,
} from "@material-ui/core";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

export default class GradeSubjectTable extends Component {
  render() {
    const { tableData } = this.props;
    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Subject</StyledTableCell>
              <StyledTableCell>M1</StyledTableCell>
              <StyledTableCell>M2</StyledTableCell>
              <StyledTableCell>M3</StyledTableCell>
              <StyledTableCell>M4</StyledTableCell>
              <StyledTableCell>Final</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData &&
              tableData.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>
                    {row.subject ? row.subject : "-"}
                  </StyledTableCell>
                  <StyledTableCell>{row.M1 ? row.M1 : "-"}</StyledTableCell>
                  <StyledTableCell>{row.M2 ? row.M2 : "-"}</StyledTableCell>
                  <StyledTableCell> {row.M3 ? row.M3 : "-"}</StyledTableCell>
                  <StyledTableCell> {row.M4 ? row.M4 : "-"}</StyledTableCell>
                  <StyledTableCell>
                    {" "}
                    {row.Final ? row.Final : "-"}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
          <TableHead>
            <TableRow>
              <StyledTableCell>Attendance</StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <div>
            <span className="fw-bold">Late Days : </span> {this.props.late}{" "}
            day(s) absent during school year
          </div>
          <div>
            <span className="fw-bold">Missed Days : </span>
            {this.props.missed} day(s) tardy during school year
          </div>
        </Table>
      </Paper>
    );
  }
}
