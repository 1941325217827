import React, { Component } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import ChildProfileTable from "./childProfileTable";
import swal from "sweetalert";
import { serverUrl } from "../../../_metronic/utils/api";
import axios from "axios";
import Pagination from "react-js-pagination";
import { fetchData } from "../../crud/auth.crud";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}
export default class ChildSearchByProgram extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [],
      loader: true,
      total: null,
      per_page: null,
      current_page: 1,
      dob: "",
      start_date: "",
      end_date: "",
      programs: [],
      program_id: "",
      current_pageSearch: 1,
    };
  }

  setBirthDate = (date) => {
    if (this.state.current_page !== 1) {
      return this.setState({ dob: date, current_page: 1 });
    }
    this.setState({ dob: date });
  };

  setStartDate = (date) => {
    if (this.state.current_page !== 1) {
      return this.setState({ start_date: date, current_page: 1 });
    }
    this.setState({ start_date: date });
  };

  setEndDate = (date) => {
    if (this.state.current_page !== 1) {
      return this.setState({ end_date: date, current_page: 1 });
    }
    this.setState({ end_date: date });
  };

  async componentDidMount() {
    await this.getPrograms();
    await this.getChild();
  }

  getPrograms = async () => {
    try {
      const result = await fetchData("get-program-dropdown");
      if (result.status === 200) {
        this.setState({
          programs: result.data.data,
          program_id: result.data.data.length && result.data.data[0].id,
          current_page: 1,
        });
      }
    } catch {
      this.setState({ loader: false });
      swal("Oops!", "Network Error!", "error");
    }
  };

  getChild = async () => {
    if (!this.state.loader) {
      this.setState({ loader: true });
    }
    try {
      const result = await axios.get(
        `${serverUrl}child?page=${this.state.current_page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      let details = result.data.data;
      if (result.status === 200) {
        this.setState({
          tableData: details.data,
          current_page: details.current_page,
          total: details.total,
          per_page: details.per_page,
          loader: false,
        });
      }
    } catch {
      this.setState({ loader: false });
      swal("Oops!", "Network Error!", "error");
    }
  };

  getChildSearch = async () => {
    const { program_id, dob, start_date, end_date } = this.state;
    let DOB = dob ? convert(dob) : "";
    let SD = start_date ? convert(start_date) : "";
    let ED = end_date ? convert(end_date) : "";
    if (!this.state.loader) {
      this.setState({ loader: true });
    }
    try {
      const result = await axios.post(
        `${serverUrl}child-program?page=${this.state.current_pageSearch}`,
        { program_id, dob: DOB, start_date: SD, end_date: ED },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      let details = result.data.data;
      if (result.status === 200) {
        this.setState({
          tableData: details.data,
          current_page: details.current_page,
          total: details.total,
          per_page: details.per_page,
          loader: false,
        });
      }
    } catch {
      this.setState({ loader: false });
      swal("Oops!", "Network Error!", "error");
    }
  };

  handlePageChange = (pageNumber) => {
    if (this.state.search) {
      this.setState({ current_pageSearch: pageNumber });
    } else {
      this.setState({ current_page: pageNumber });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.current_page !== this.state.current_page &&
      !this.state.search
    ) {
      window.scrollTo(0, 0);
      this.getChild();
    }
    if (prevState.current_pageSearch !== this.state.current_pageSearch) {
      window.scrollTo(0, 0);
      this.getChildSearch();
    }
  }

  searchHandler = (e) => {
    e.preventDefault();
    this.setState({ search: true }, () => this.getChildSearch());
  };

  callback = () => {
    this.getPrograms();
    this.getChild();
  };

  resetSearch = (e) => {
    e.preventDefault();
    this.setState(
      { search: false, dob: "", start_date: "", end_date: "", current_page: 1 },
      this.callback
    );
  };

  onChangeHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    const { dob, start_date, end_date, programs, program_id } = this.state;
    return (
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__body">
          <div className="row">
            <div className="col-xxl-4 col-xl-6 col-lg-7">
              <Form onSubmit={this.searchHandler}>
                <Row>
                  <Form.Group as={Col} md="6" className="mb-4 form-group">
                    <Form.Label>Choose the targeted program</Form.Label>
                    <Form.Control
                      as="select"
                      value={program_id}
                      name="program_id"
                      onChange={this.onChangeHandle}
                    >
                      {programs &&
                        programs.map((el, i) => (
                          <option value={el.id} key={el.id}>
                            {el.program}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md="6" className="mb-4 form-group">
                    <Form.Label>Birth Date</Form.Label>
                    <div>
                      <DatePicker
                        selected={dob}
                        onChange={(date) => this.setBirthDate(date)}
                        selectsStart
                        startDate={dob}
                        // endDate={end_date}
                        placeholderText="DOB"
                        className="form-control childDateProgram"
                        showYearDropdown
                        scrollableMonthYearDropdown
                      />
                    </div>
                  </Form.Group>
                  <Form.Group as={Col} md="6" className="mb-4 form-group">
                    <Form.Label>Start Date</Form.Label>
                    <div>
                      {" "}
                      <DatePicker
                        selected={start_date}
                        onChange={(date) => this.setStartDate(date)}
                        selectsStart
                        startDate={start_date}
                        endDate={end_date}
                        placeholderText="Start Date"
                        className="form-control childDateProgram"
                        fullWidth
                        showYearDropdown
                        scrollableMonthYearDropdown
                        // maxDate={new Date()}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group as={Col} md="6" className="mb-4">
                    <Form.Label>End Date</Form.Label>
                    <div>
                      <DatePicker
                        selected={end_date}
                        onChange={(date) => this.setEndDate(date)}
                        selectsEnd
                        startDate={start_date}
                        endDate={end_date}
                        minDate={start_date}
                        // maxDate={new Date()}
                        placeholderText="End Date"
                        className="form-control childDateProgram"
                        disabled={start_date ? false : true}
                        showYearDropdown
                        scrollableMonthYearDropdown
                      />
                    </div>
                  </Form.Group>
                </Row>
                <div className="text-center mb-4">
                  {this.state.search ? (
                    <Button onClick={this.resetSearch} style={btnColor}>
                      Reset
                    </Button>
                  ) : (
                    <Button type="submit" style={btnColor}>
                      Search
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </div>
          {this.state.loader ? (
            <div
              className="spinner-border text-warning loaderStyle"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <ChildProfileTable
              tableData={this.state.tableData}
              activePage={this.state.current_page}
              itemsCountPerPage={10}
              totalItemsCount={this.state.total}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange}
              getChild={this.getChild}
            />
          )}
          {this.state.total > 10 && !this.state.loader ? (
            <Pagination
              activePage={this.state.current_page}
              itemsCountPerPage={10}
              totalItemsCount={this.state.total}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange.bind(this)}
              itemclassName="page-item"
              linkclassName="page-link"
            />
          ) : null}
        </div>
      </div>
    );
  }
}
