import React, { Component } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../partials/content/Portlet";
import { Form, Col, Row } from "react-bootstrap";
import ActiveMentorTable from "./activeMentorTable";
import swal from "sweetalert";
import Pagination from "react-js-pagination";
import axios from "axios";
import { serverUrl } from "../../../_metronic/utils/api";
import { fetchData, callApi } from "../../crud/auth.crud";
import { Button } from "@material-ui/core";

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
  color: "#fff",
};

const debounce = (fn, delay) => {
  let timer = null;
  return function (...args) {
    const context = this;
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
};

class ActiveMentorsReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [],
      total: null,
      per_page: null,
      current_page: 1,
      loader: true,
      total_reports: "",
      searchTxt: "",
      programs: [],
      pid: "",
      sessions: [],
      session_dateId: "",
      pdfLoad: false,
    };
    this.getReports = debounce(this.getReports, 400);
  }

  async componentDidMount() {
    await this.getProgram();
    await this.getAllSessions();
    await this.getReports();
  }

  getReports = async () => {
    if (!this.state.loader) {
      this.setState({ loader: true });
    }
    const { current_page, searchTxt, pid, session_dateId } = this.state;
    try {
      let result = await axios.get(
        `${serverUrl}active-mentor-list?page=${current_page}&program_id=${pid}&session=${session_dateId}&first_name=${searchTxt}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (result.status === 200) {
        this.setState({
          tableData: result.data.data.data,
          current_page: result.data.data.current_page
            ? result.data.data.current_page
            : 1,
          total: result.data.data.total,
          per_page: result.data.data.per_page,
          loader: false,
          total_reports: result.data.total_number,
        });
      } else {
        this.setState({ loader: false });
        swal("Oops!", "Please try again!", "error");
      }
    } catch {
      this.setState({ loader: false });
      swal("Oops!", "Network Error!", "error");
    }
  };

  getProgram = async () => {
    let result = await fetchData("programs");
    if (result.status === 200) {
      this.setState({
        programs: result.data.data,
        pid: result.data.data && result.data.data[0].programID,
      });
    } else {
      swal("Oops!", "Network Error!", "error");
    }
  };

  getAllSessions = async () => {
    try {
      let result = await fetchData("session-semester");
      if (result.status === 200) {
        this.setState({
          sessions: result.data.data,
          session_dateId:
            result.data.data.length && result.data.data[0].dateName,
        });
      } else {
        swal("Oops!", "Please try again!", "error");
      }
    } catch (err) {
      this.setState({ loading: false });
      if (err.response.status == 401) {
        swal("", "Unauthorized!", "error");
        localStorage.clear();
        this.props.navigate("/auth/login");
        swal.close();
      } else {
        swal("Oops!", "Network Error!", "error");
      }
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => this.getReports());
  };

  handlePageChange = (pageNumber) => {
    this.setState({ current_page: pageNumber });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.current_page !== this.state.current_page) {
      window.scrollTo(0, 0);
      this.getReports();
    }
  }

  searchHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => this.getReports());
  };

  download_PDF_Report = async () => {
    const { pid, session_dateId } = this.state;
    if (!this.state.pdfLoad) {
      this.setState({ pdfLoad: true });
    }

    if (pid && session_dateId)
      try {
        let result = await callApi("active-mentor-list-pdf", {
          program_id: pid,
          session: session_dateId,
        });
        if (result.status === 200) {
          this.setState({ pdfLoad: false });
          window.open(result.data.data, "_blank");
        } else {
          this.setState({ pdfLoad: false });
          swal("Oops!", "Please try again!", "error");
        }
      } catch {
        this.setState({ pdfLoad: false });
        swal("Oops!", "Network Error!", "error");
      }
  };

  render() {
    const { tableData, programs, pid, sessions, session_dateId } = this.state;
    return (
      <>
        <div className="row">
          <div className="col-xl-12">
            <Portlet fluidHeight={true}>
              <PortletHeader title="Active Mentors Report" />

              <PortletBody>
                <div className="row">
                  <div className="col-xl-4">
                    <Form>
                      <Row>
                        <Form.Group as={Col} md="6" className="form-group">
                          <Form.Label>Select Program</Form.Label>
                          <Form.Control
                            as="select"
                            required
                            name="pid"
                            value={pid}
                            onChange={this.handleChange}
                          >
                            {programs &&
                              programs.map((el, i) => (
                                <option key={el.id} value={el.programID}>
                                  {el.program}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="form-group">
                          <Form.Label>Select Session</Form.Label>
                          <Form.Control
                            as="select"
                            required
                            name="session_dateId"
                            value={session_dateId}
                            onChange={this.handleChange}
                          >
                            {sessions &&
                              sessions.map((el) => (
                                <option key={el.id} value={el.dateName}>
                                  {el.dateName}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>
                      </Row>
                    </Form>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="kt-section">
                    <div className="kt-section__content text-center">
                      <Row>
                        <Col md={12}>
                          <div className="input-group">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Search by mentor name"
                              aria-label="Search"
                              name="searchTxt"
                              onChange={this.searchHandle}
                            />
                            <span
                              className="input-group-text amber lighten-3"
                              id="basic-text1"
                            >
                              <i
                                className="fas fa-search text-grey"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  {this.state.loader ? (
                    <div
                      className="spinner-border text-warning loaderStyleReport"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <ActiveMentorTable tableData={tableData} />
                  )}
                  {this.state.total > 10 && !this.state.loader ? (
                    <Pagination
                      activePage={this.state.current_page}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.total}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(this)}
                      itemclassName="page-item"
                      linkclassName="page-link"
                    />
                  ) : null}
                </div>

                {this.state.pdfLoad ? (
                  <div
                    className="spinner-border text-warning loaderStylegraduatePdf"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <div className="text-center">
                    {this.state.tableData.length ? (
                      <Button
                        style={btnColor}
                        onClick={this.download_PDF_Report}
                      >
                        Print Pdf
                      </Button>
                    ) : null}
                  </div>
                )}
              </PortletBody>
            </Portlet>
          </div>
        </div>
      </>
    );
  }
}

export default ActiveMentorsReport;
