const initialState={
    date:{}
 };
 
 export default function(state=initialState,action){
     switch (action.type){
         case 'RESET_DATE':
         return {
           ...state,
           date:action.payload
         }
         default:
         return state;
     }
 }
 