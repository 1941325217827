import React, { Component } from "react";
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { Modal } from "@material-ui/core";
import { callApi, fetchData } from "../../crud/auth.crud";
import swal from "sweetalert";
import "./childProfile.css";
import { Form } from "react-bootstrap";
import withRouter from "../../router/withRouter";

const paper = {
  position: "absolute",
  width: 500,
  backgroundColor: "#fff",
  boxShadow: 5,
  padding: 25,

  outline: "none",
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`,
};

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

 class UploadPdf extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      years: [],
      gradeVal: "",
      schoolYearVal: "",
      file: "",
      fileName: "",
    };
  }

  async componentDidMount() {
    await this.getAllSchoolYears();
  }

  getAllSchoolYears = async () => {
    try {
      let result = await fetchData("get-school-years");
      if (result.status === 200) {
        this.setState({ years: result.data.data });
      } else {
        swal("Oops!", "Please try again!", "error");
      }
    } catch (err) {
      if (err.response.status == 401) {
        swal("", "Unauthorized!", "error");
        localStorage.clear();
        this.props.navigate("/auth/login");
        swal.close();
      } else {
        swal("Oops!", "Network Error!", "error");
      }
    }
  };

  handleChange = (event, value) => {
    this.setState({ [event.target.name]: value });
  };

  uploadHandle = (e) => {
    console.log("🚀 ~ UploadPdf ~ e:", e.target.files[0]);
    let fName = e.target.files[0].name;
    this.setState({ file: e.target.files[0], fileName: fName });
  };

  onSubmit = async () => {
    // e.preventDefault();
    let child_id = this.props.child_id;
    const { gradeVal, schoolYearVal, file } = this.state;
    console.log("🚀 ~ UploadPdf ~ onSubmit= ~ file:", this.state.file)
    if (child_id && gradeVal && schoolYearVal && file) {
      try {
        let formData = new FormData();
        formData.append("child_id", child_id);
        formData.append("grade", gradeVal);
        formData.append("school_year", schoolYearVal);
        formData.append("file", file);
        let result = await callApi("upload-child-grade", formData);
        if (result && result.status === 200) {
          swal("File Uploaded Successfully!", "", "success");
          this.props.handleClose();
          this.props.getGradeData();
        }
      } catch {
        swal(
          "Oops!",
          "Network Error! Please try again after some time.",
          "error"
        );
      }
    } else {
      swal("Required!", " Please Select All Fields!", "");
    }
  };

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    let gradeArr = [
      { grade: "K", val: "0" },
      { grade: "1", val: "1" },
      { grade: "2", val: "2" },
      { grade: "3", val: "3" },
      { grade: "4", val: "4" },
      { grade: "5", val: "5" },
      { grade: "6", val: "6" },
      { grade: "7", val: "7" },
      { grade: "8", val: "8" },
      { grade: "9", val: "9" },
      { grade: "10", val: "10" },
      { grade: "11", val: "11" },
      { grade: "12", val: "12" },
    ];
    return (
      <div>
        {/* <Typography gutterBottom>
          Click to get the full Modal experience!
        </Typography> */}
        {/* <Button onClick={this.props.handleOpen}>Open Modal</Button> */}
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.props.open}
          onClose={this.props.handleClose}
        >
          <div style={paper}>
            <div className="d-flex justify-content-between">
              <span>
                {" "}
                <Typography variant="h6" id="modal-title">
                  Upload file of Grades
                </Typography>
              </span>
              <span>
                {" "}
                <i
                  className="fas fa-times"
                  onClick={this.props.handleClose}
                ></i>
              </span>
            </div>

            {/* <Form className="mt-4"> */}
            <Form.Group className="form-group" controlId="formBasicEmail">
              <Form.Label className="fw-bold">Grade</Form.Label>
              <RadioGroup
                value={this.state.gradeVal}
                name="gradeVal"
                className="radioButtonInline"
                onChange={this.handleChange}
              >
                {gradeArr.map((choice, i) => (
                  <FormControlLabel
                    value={choice.val}
                    key={i}
                    control={<Radio />}
                    label={choice.grade}
                  />
                ))}
              </RadioGroup>
            </Form.Group>

            <Form.Group controlId="formBasicPassword" className="form-group">
              <Form.Label className="fw-bold">School Year</Form.Label>
              <RadioGroup
                value={this.state.schoolYearVal}
                name="schoolYearVal"
                className="radioButtonInline"
                onChange={this.handleChange}
              >
                {this.state.years &&
                  this.state.years.map((choice, i) => (
                    <FormControlLabel
                      value={choice}
                      key={i}
                      control={<Radio />}
                      label={choice}
                    />
                  ))}
              </RadioGroup>
            </Form.Group>
            <Form.Group controlId="formBasicPassword" className="form-group">
              <Form.Label className="fw-bold px-4">
                {this.state.fileName ? this.state.fileName : "Upload"}
              </Form.Label>
              <input
                id="myInput"
                accept=".csv,.xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                type="file"
                onChange={this.uploadHandle}
                ref={(ref) => (this.upload = ref)}
                style={{ display: "none" }}
              />

              <Button
                type="file"
                size="sm"
                onClick={(e) => this.upload.click()}
                style={btnColor}

              >
                Attach File
              </Button>
            </Form.Group>

            <div className="text-center form-group">
              <Button style={btnColor} onClick={this.onSubmit}>
                Submit
              </Button>
            </div>
            {/* </Form> */}

            {/* <AddSpecialEvent /> */}
          </div>
        </Modal>
      </div>
    );
  }
}


export default withRouter(UploadPdf);