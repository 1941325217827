import axios from "axios";
export const BASE_URL = " https://www.urbanimpactprojects.com/api/public/api/";
// export const BASE_URL = "http://128.199.13.36/api/public/api/";
const accessToken = localStorage.getItem("token");

const authAxios = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${accessToken ? accessToken : "Hello acces token is not defined"}`,
  },
});

export default authAxios;
