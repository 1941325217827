import React, { Component } from "react";
import { Card, Form, Col, Row, Container } from "react-bootstrap";
import { Button } from "@material-ui/core";
import swal from "sweetalert";
import { getSchools, updateLocation } from "../../crud/auth.crud";
import withRouter from "../../router/withRouter";

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

 class LocationInfo extends Component {
  constructor(props) {
    super(props);
    const {
      building,
      apartment,
      street,
      city,
      zipCode,
      // school,
      moved,
      school_id,
    } = this.props.Information.child;

    this.state = {
      school: "",
      schools: [],
      building: building,
      apartment: apartment,
      street: street,
      city: city,
      zipCode: zipCode,
      moved: moved,
      schoolID: school_id,
    };
  }

  async componentDidMount() {
    await this.getSchools();
  }

  getSchools = async () => {
    try {
      const result = await getSchools();
      if (result.status === 200) {
        this.setState({
          schools: result.data.data,
          school: result.data.data.length && result.data.data[0].schoolName,
        });
      }
    } catch (err) {
      this.setState({ loading: false });
      if (err.response.status == 401) {
        swal("", "Unauthorized!", "error");
        localStorage.clear();
        this.props.navigate("/auth/login");
        swal.close();
      } else {
        swal("Oops!", "Network Error!", "error");
      }
    }
  };

  onChangeHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { building, apartment, street, city, zipCode, schoolID, moved } =
      this.state;
    let result = await updateLocation(
      building,
      apartment,
      street,
      city,
      zipCode,
      schoolID,
      moved ? moved : "0",
      this.props.childId
    );
    if (result && result.status === 200) {
      swal("Updated Successfully!", "", "success");
      this.props.seeProfile();
      // this.setState(emptyState);
    } else {
      swal("Oops!", "Network Error!", "error");
    }
  };
  render() {
    const {
      building,
      apartment,
      street,
      city,
      zipCode,
      // school,
      moved,
      // school_id,
    } = this.props.Information.child;
    return (
      <div className="col-xl-9 col-lg-8 col-md-8 col-sm-8 col-8">
        <div className="kt-section">
          <>
            <Card>
              <Card.Body>
                <Card.Title>
                  <Row>
                    <span>Location Information</span>
                  </Row>
                </Card.Title>

                <Container style={{ maxWidth: "400px" }}>
                  <Form onSubmit={this.onSubmit}>
                    <Form.Group
                      as={Row}
                      controlId="formHorizontalEmail"
                      className="form-group"
                    >
                      {/* <Form.Label column sm={2}> */}
                      <span className="col-md-4">Building</span>
                      {/* </Form.Label> */}
                      <Col sm={8}>
                        <Form.Control
                          type="number"
                          name="building"
                          required
                          onChange={this.onChangeHandle}
                          placeholder="Building"
                          defaultValue={building}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="formHorizontalPassword"
                      className="form-group"
                    >
                      <span className="col-md-4">Apartment</span>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          placeholder="Apartment"
                          required
                          name="apartment"
                          onChange={this.onChangeHandle}
                          defaultValue={apartment}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId="formHorizontalPassword"
                      className="form-group"
                    >
                      <Form.Label column sm={4}>
                        Street
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          placeholder="Street"
                          name="street"
                          required
                          onChange={this.onChangeHandle}
                          defaultValue={street}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId="formHorizontalPassword"
                      className="form-group"
                    >
                      <Form.Label column sm={4}>
                        City
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          name="city"
                          onChange={this.onChangeHandle}
                          placeholder="City"
                          defaultValue={city}
                          required
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId="formHorizontalPassword"
                      className="form-group"
                    >
                      <Form.Label column sm={4}>
                        Zip Code
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="number"
                          name="zipCode"
                          onChange={this.onChangeHandle}
                          placeholder="Zip"
                          defaultValue={zipCode}
                          required
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId="formBasicEmail"
                      className="form-group"
                    >
                      <Form.Label column sm={4}>
                        Current School
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          required
                          as="select"
                          name="schoolID"
                          value={this.state.schoolID}
                          onChange={this.onChangeHandle}

                        >
                          {this.state.schools &&
                            this.state.schools.map((sc, i) => (
                              <option value={sc.schoolID} key={sc.id}>
                                {sc.schoolName}
                              </option>
                            ))}
                        </Form.Control>{" "}
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId="exampleForm.ControlSelect1"
                      className="form-group"
                    >
                      <Form.Label column sm={4}>
                        Moved and no longer in contact
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          as="select"
                          name="moved"
                          onChange={this.onChangeHandle}
                          defaultValue={moved ? moved : "0"}
                        >
                          <option value="Moved">Moved</option>
                          <option value="0">Not Moved</option>
                        </Form.Control>{" "}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="text-center form-group">
                      <Col sm={{ span: 10, offset: 2 }}>
                        <Button type="submit" style={btnColor}>
                          Submit
                        </Button>
                      </Col>
                    </Form.Group>
                  </Form>
                </Container>
              </Card.Body>
            </Card>
          </>
        </div>
      </div>
    );
  }
}


export default withRouter(LocationInfo)