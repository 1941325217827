import React, { useState, useEffect } from "react";
import { Form, Row } from "react-bootstrap";
import { callApi } from "../../../crud/auth.crud";
import swal from "sweetalert";
import "./programs.css";
import withRouter from "../../../router/withRouter";

function getItem(key) {
  return JSON.parse(localStorage.getItem(key));
}

const ProgReports = (props) => {
  const [stats, setStats] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const getReportStats = async () => {
      let program_id = getItem("progName").id;
      if (program_id) {
        try {
          let result = await callApi("program-report", { program_id });
          if (result.status === 200 && isMounted) {
            setStats(result.data.data);
          } else {
            swal("Oops!", "Please try again!", "error");
          }
        } catch {
          swal("Oops!", "Network Error!", "error");
        }
      }
    };

    getReportStats();

    return () => {
      isMounted = false;
    };
  }, []);

  const moveToAdjPage = (pageName) => {
    console.log("🚀 ~ ProgReports ~ pageName:", props);
    props.navigate("/programs/" + pageName);
  };

  return (
    <div className="col-xl-8 col-lg-7 col-md-7 col-sm-8 col-8">
      <div className="kt-portlet ">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Kids Power Reports</h3>
          </div>
        </div>
      </div>

      <div className="kt-portlet">
        <div className="kt-portlet__body">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-section">
                <div className="kt-section__content">
                  <h5 className="borderDown">Key Stats</h5>
                </div>
              </div>
            </div>
            <div className="col-md-12 ms-4">
              <div className="kt-section">
                <div className="kt-section__content">
                  <Form>
                    <Row>
                      {stats &&
                        stats.map((el, i) => (
                          <React.Fragment key={i}>
                            <span className="col-md-6">{el.details}</span>
                            <span className="col-md-6 px-5">{el.count}</span>
                          </React.Fragment>
                        ))}
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="kt-portlet">
        <div className="kt-portlet__body">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-section">
                <div className="kt-section__content">
                  <h5 className="borderDown">Custom Reports</h5>
                </div>
              </div>
            </div>
            <div className="col-md-12 ms-4">
              <div className="kt-section">
                <div className="kt-section__content">
                  <Form>
                    <div className="d-flex">
                      <div>
                        &middot;&nbsp; Attendance Per Week for kid Power
                      </div>
                      <div className="px-2">
                        <i
                          className="kt-nav__link-icon flaticon2-contract icon-color"
                          onClick={() => moveToAdjPage("attendancePerWeek")}
                        />
                      </div>
                    </div>
                    <div className="d-flex">
                      <span>
                        &middot;&nbsp; Weekly Printouts for kid Power (mostly
                        used by PrimeTime junior)
                      </span>
                      <span className="px-2">
                        <i
                          className="kt-nav__link-icon flaticon2-contract icon-color"
                          onClick={() => moveToAdjPage("printoutAttendance")}
                        />
                      </span>
                    </div>
                    <div className="d-flex">
                      <span>&middot;&nbsp; Walk list for kid power in PT</span>
                      <span className="px-2">
                        <i
                          className="kt-nav__link-icon flaticon2-contract icon-color"
                          onClick={() => moveToAdjPage("walkList")}
                        />
                      </span>
                    </div>
                    <div className="d-flex">
                      <span>&middot;&nbsp; Upcoming kid Power Graduates</span>
                      <span className="px-2">
                        <i
                          className="kt-nav__link-icon flaticon2-contract icon-color"
                          onClick={() => moveToAdjPage("graduates")}
                        />
                      </span>
                    </div>
                    <div className="d-flex">
                      <span>&middot;&nbsp; Subject Reports</span>
                      <span className="px-2">
                        <i
                          className="kt-nav__link-icon flaticon2-contract icon-color"
                          onClick={() => moveToAdjPage("subject-reports")}
                        />
                      </span>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ProgReports);
