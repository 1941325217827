import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Form, Container, Col, Row } from "react-bootstrap";
import PermissionSlipTable from "./permissionSlipTable";
import { getPrograms, addPermissionSlip } from "../../../crud/auth.crud";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getPermissionSlip } from "../../../crud/auth.crud";
import "../admin.css";
const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

const emptyState = {
  slip_name: "",
  program_id: "0",
  start_date: "",
  end_date: "",
};

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

export default class AddPermissionSlip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      programs: [],
      slip_name: "",
      program_id: "0",
      start_date: "",
      end_date: "",
      tableData: [],
    };
  }
  setStartDate = (date) => {
    this.setState({ start_date: date });
  };

  setEndDate = (date) => {
    this.setState({ end_date: date });
  };

  async componentDidMount() {
    await this.getAllPrograms();
    await this.getPermissionSlips();
  }

  getAllPrograms = async () => {
    let result = await getPrograms();
    if (result.status === 200) {
      this.setState({ programs: result.data.data });
    } else {
      swal("Oops!", "Network Error!", "error");
    }
  };

  getPermissionSlips = async () => {
    try {
      const result = await getPermissionSlip();
      if (result.status === 200) {
        this.setState({ tableData: result.data.data, loader: false });
      }
    } catch {
      this.setState({ loader: false });
      swal("Oops!", "Network Error!", "error");
    }
  };
  onChangeHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { slip_name, start_date, end_date, program_id } = this.state;
    let result = await addPermissionSlip(
      slip_name,
      convert(start_date),
      convert(end_date),
      program_id
    );
    if (result.status === 200) {
      swal("Permission Slip added Successfully!", "", "success");
      this.setState(emptyState, () => this.getPermissionSlips());
    } else {
      swal("Oops!", "Please try again!", "error");
    }
  };
  render() {
    const { programs, slip_name, program_id, start_date, end_date } =
      this.state;
    return (
      <>
        <div className="kt-portlet kt-portlet--height-fluid">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">Add Permission Slip</h3>
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="row">
              {/* <h5>Mentor Personal Information</h5>
            <div className="kt-separator kt-separator--dashed"></div> */}
              <div className="col-xl-4">
                <Container style={{ maxWidth: "550px" }}>
                  <Form onSubmit={this.handleSubmit}>
                    <Form.Group as={Row} className="form-group">
                      <Form.Label column sm={4}>
                        Permission Slip Name
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          required
                          name="slip_name"
                          value={slip_name}
                          onChange={this.onChangeHandle}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="form-group"
                      controlId="exampleForm.ControlSelect1"
                    >
                      <Form.Label column sm={4}>
                        Programs
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          as="select"
                          name="program_id"
                          value={program_id}
                          required
                          onChange={this.onChangeHandle}
                        >
                          <option>0</option>
                          {programs &&
                            programs.map((el, i) => (
                              <option value={el.programID} key={el.id}>
                                {el.program}
                              </option>
                            ))}
                        </Form.Control>{" "}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="form-group">
                      <Form.Label column sm={4}>
                        Start Date
                      </Form.Label>
                      <Col sm={8}>
                        <DatePicker
                          selected={start_date}
                          onChange={(date) => this.setStartDate(date)}
                          selectsStart
                          startDate={start_date}
                          endDate={end_date}
                          placeholderText="Start Date"
                          className="form-control dateWidth"
                          fullWidth
                          required
                          showYearDropdown
                          scrollableMonthYearDropdown
                          // maxDate={new Date()}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="form-group">
                      <Form.Label column sm={4}>
                        End Date
                      </Form.Label>
                      <Col sm={8}>
                        <DatePicker
                          selected={end_date}
                          onChange={(date) => this.setEndDate(date)}
                          selectsEnd
                          startDate={start_date}
                          endDate={end_date}
                          minDate={start_date}
                          // maxDate={new Date()}
                          required
                          placeholderText="End Date"
                          className="form-control dateWidth"
                          disabled={start_date ? false : true}
                          showYearDropdown
                          scrollableMonthYearDropdown
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="text-center form-group">
                      <Col sm={{ span: 10, offset: 2 }}>
                        <Button type="submit" style={btnColor}>
                          Add
                        </Button>
                      </Col>
                    </Form.Group>
                  </Form>
                </Container>
              </div>
            </div>
            <PermissionSlipTable
              tableData={this.state.tableData}
              programs={this.state.programs}
              getPermissionSlips={this.getPermissionSlips}
            />
          </div>
        </div>
      </>
    );
  }
}
