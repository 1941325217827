import React, { Component } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,withStyles,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { IconButton } from "@material-ui/core";
import axios from "axios";
import { serverUrl } from "../../../../_metronic/utils/api";
import swal from "sweetalert";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

export default class AddProgramTable extends Component {
  deleteProgram = async (id) => {
    try {
      const result = await axios.post(
        `${serverUrl}delete-program`,
        { program_id: id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (result.status === 200) {
        swal("Program Deleted Successfully!", "", "success");
        this.props.getAllPrograms();
        // this.setState({ tableData: result.data.data });
      } else {
        swal("Oops!", "Please try again!!", "error");
      }
    } catch {
      swal("Oops!", "Network Error!!", "error");
    }
  };
  deleteHandlerConfirm = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.deleteProgram(id);
        // swal("Data has been deleted!", {
        //   icon: "success",
        // });
      }
    });
  };
  render() {
    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">S. No</StyledTableCell>
              <StyledTableCell align="center">Program Name</StyledTableCell>
              <StyledTableCell align="center">Assign To Menu</StyledTableCell>
              <StyledTableCell align="center">Remove Entry</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.programs &&
              this.props.programs.map((row, index) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell align="center">{index + 1}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.program}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.parent_name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {" "}
                    <IconButton
                      aria-label="Delete"
                      onClick={() => this.deleteHandlerConfirm(row.programID)}
                    >
                      <DeleteOutlineIcon style={{ color: "red" }} />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}
