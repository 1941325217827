import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function CustomPhoneInput({ value, setValue }) {
  return (
    <div>
      <PhoneInput
        country={"us"}
        onChange={(value, data, event, formattedValue) => {
          setValue(formattedValue);
        }}
        value={value}
        showDropdown={false}
        inputClass="form-control"
      />
    </div>
  );
}
