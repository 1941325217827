import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import { Modal } from "@material-ui/core"; // import { Button } from "@material-ui/core";import { Button, Form } from "react-bootstrap";
import { addSession } from "../../../crud/auth.crud";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form,  Button } from "react-bootstrap";

const paper = {
  position: "absolute",
  width: 400,
  backgroundColor: "#fff",
  boxShadow: 5,
  padding: 25,
  outline: "none",
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`,
};

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

const emptyState = {
  session_name: "",
  date_start: "",
  date_end: "",
  type: "Semester",
};
function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}
export default class AddDateRange extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      session_name: "",
      start_date: "",
      end_date: "",
      type: "Semester",
    };
  }

  setStartDate = (date) => {
    this.setState({ start_date: date });
  };

  setEndDate = (date) => {
    this.setState({ end_date: date });
  };
  onChangeHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { session_name, start_date, end_date, type } = this.state;
    let result = await addSession(
      session_name,
      convert(start_date) ? convert(start_date) : "",
      convert(end_date) ? convert(end_date) : "",
      type
    );
    if (result.status === 200) {
      swal("Session added Successfully!", "", "success");
      this.props.getAllSessions();
      this.setState(emptyState, () => this.props.handleClose());
    } else {
      swal("Oops!", "Network Error!", "error");
    }
  };

  render() {
    const { session_name, start_date, end_date } = this.state;
    return (
      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.props.open}
          onClose={this.props.handleClose}
        >
          <div style={paper}>
            <div className="d-flex justify-content-between">
              <span>
                {" "}
                <Typography variant="h6" id="modal-title">
                  Add Date Range
                </Typography>
              </span>
              <span>
                {" "}
                <i
                  className="fas fa-times"
                  onClick={this.props.handleClose}
                ></i>
              </span>
            </div>

            <Form className="mt-4" onSubmit={this.handleSubmit}>
              <Form.Group>
                <Form.Label>Name of a Date Range</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="session_name"
                  value={session_name}
                  onChange={this.onChangeHandle}
                  placeholder="Enter Name"
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Start Date</Form.Label>
                <DatePicker
                  selected={start_date}
                  onChange={(date) => this.setStartDate(date)}
                  selectsStart
                  startDate={start_date}
                  endDate={end_date}
                  placeholderText="Start Date"
                  className="form-control dateWidth"
                  fullWidth
                  showYearDropdown
                  scrollableMonthYearDropdown
                  required
                  // maxDate={new Date()}
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>End Date</Form.Label>
                <DatePicker
                  selected={end_date}
                  onChange={(date) => this.setEndDate(date)}
                  selectsEnd
                  startDate={start_date}
                  endDate={end_date}
                  minDate={start_date}
                  // maxDate={new Date()}
                  required
                  placeholderText="End Date"
                  className="form-control dateWidth"
                  disabled={start_date ? false : true}
                  showYearDropdown
                  scrollableMonthYearDropdown
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Date Range Type</Form.Label>
                <Form.Control
                  required
                  as="select"
                  name="type"
                  onChange={this.onChangeHandle}
                >
                  <option value="Semester">Semester</option>
                  <option value="Year"> Year</option>
                </Form.Control>
              </Form.Group>
              <div className="text-center">
                <Button type="submit" style={btnColor}>
                  Add to CDI
                </Button>
              </div>
            </Form>

            {/* <AddSpecialEvent /> */}
          </div>
        </Modal>
      </div>
    );
  }
}
