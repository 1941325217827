import React, { Component } from "react";
import { Card } from "react-bootstrap";
import swal from "sweetalert";
import ProgramInvolvementTable from "./programInvolvementTable";
import { fetchDataWithPagination } from "../../crud/auth.crud";
import Pagination from "react-js-pagination";
import SummaryTable from "./summaryTable";
import SpecialEventTable from "./specialEventsTable";

// const btnColor={
//   backgroundColor:"#f4b53f",
//   border:'none'
// }
export default class ProgramInvolvement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      total: null,
      per_page: null,
      current_page: 1,
      special_events: [],
      participation: [],
      summary: [],
    };
  }

  async componentDidMount() {
    await this.getData();
  }

  getData = async () => {
    if (this.props.childId)
      try {
        let result = await fetchDataWithPagination(
          "programs-involved",
          this.state.current_page,
          { child_id: this.props.childId }
        );
        if (result.status === 200) {
          const DATA = result.data;
          this.setState({
            special_events: DATA.special_events,
            summary: DATA.summary,
            participation: DATA.participation.data,
            current_page: DATA.participation.current_page
              ? DATA.participation.current_page
              : 1,
            total: DATA.participation.total,
            per_page: DATA.participation.per_page,
          });
        } else {
          swal("Oops!", "Please try again!", "error");
        }
      } catch (err) {
        swal("Oops!", "Network Error!", "error");
      }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ current_page: pageNumber });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.current_page !== this.state.current_page) {
      window.scrollTo(0, 0);
      this.getData();
    }
  }

  render() {
    return (
      <div className="col-xl-9 col-lg-8 col-md-8 col-sm-8 col-8">
        <div className="kt-section">
          <>
            <Card>
              <Card.Body>
                <Card.Title className="text-center pt-2">
                  <span>Participation</span>
                </Card.Title>

                <ProgramInvolvementTable tableData={this.state.participation} />
                {this.state.total > 10 ? (
                  <div className="py-2">
                    <Pagination
                      activePage={this.state.current_page}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.total}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(this)}
                      itemclassName="page-item"
                      linkclassName="page-link"
                    />
                  </div>
                ) : null}
                <Card.Title className="text-center pt-2">
                  <span>Summary Data</span>
                </Card.Title>
                <SummaryTable tableData={this.state.summary} />
                <Card.Title className="text-center pt-2">
                  <span>Special Events</span>
                </Card.Title>
                <SpecialEventTable tableData={this.state.special_events} />
              </Card.Body>
            </Card>
          </>
        </div>
      </div>
    );
  }
}
