import React from "react";
import { Bar } from "react-chartjs-2";
import { Typography } from "@material-ui/core";
import "../Components/dashboardPages/dashboard.css";
import "chartjs-plugin-datalabels";

export default class KidsBeingInfluencedChart extends React.Component {
  render() {
    return (
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              Kids Being Influenced at the Highest Levels
            </h3>
          </div>
          {/* <PortletHeaderDropdown /> */}
        </div>
        <div className="kt-portlet__body">
          <Typography
            variant="caption"
            display="block"
            className="mb-4 dashboardText"
          >
            For Urban Impact's most active participants. The level of influence
            can be quite substantial. Consider the concentrated hours for the
            kids with the highest engagement.
          </Typography>
          <Bar
            data={{
              labels:
                this.props.highest_level && this.props.highest_level.length > 0
                  ? this.props.highest_level.map((val) => val.kidname)
                  : [
                      "Jaanae Fleming",
                      "Julissa Sanchez",
                      "Tajinay Scott",
                      "Aleysha Babifonia",
                      "Sade Scott",
                      "Nye Hooks",
                      "Jaden Babifonia",
                      "Maurico Castro De La Luz",
                      "Serenity Seliness",
                      "Diana Castro",
                    ],
              datasets: [
                {
                  label: "Hours getting mentored",
                  backgroundColor: "#f4b53f",
                  // borderColor: 'rgba(0,0,0,1)',
                  // borderWidth: 2,
                  data:
                    this.props.highest_level &&
                    this.props.highest_level.length > 0
                      ? this.props.highest_level.map((val) => val.mhours)
                      : [],
                },
              ],
            }}
            options={{
              plugins: {
                datalabels: {
                  display: "auto",
                  color: "#5c5a6d",
                  align: "end",
                  clamp: true,
                },
              },
              title: {
                display: true,
                // text:'Average Rainfall per month',
                fontSize: 10,
              },
              legend: {
                display: true,
                position: "right",
              },
            }}
          />
        </div>
      </div>
    );
  }
}
