import React, { useState, useEffect } from "react";
import PrintoutSheetTable from "./printoutSheetTable";
import { Form, Container, Col, Row } from "react-bootstrap";
import { callApi } from "../../../crud/auth.crud";
import swal from "sweetalert";
import withRouter from "../../../router/withRouter";

const PrinoutSheet = (props) => {
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const getData = async () => {
      let payload = props?.location?.state;
      console.log("🚀 ~ PrinoutSheet ~ getData= ~ payload:", props);
      try {
        let result = await callApi("session-semester-printout", payload);
        if (result.status === 200) {
          let DATA = result.data.data;
          if (DATA && DATA.length) {
            setTableData(DATA);
          }
          setLoader(false);
        } else {
          setLoader(false);
          swal("Oops!", "Please try again!", "error");
        }
      } catch {
        setLoader(false);
        swal("Oops!", "Network Error!", "error");
      }
    };

    getData();
  }, [props]);

  return (
    <>
      {loader ? (
        <div
          className="spinner-border text-warning loaderStyleSessionSem"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div className="col-md-8">
          {tableData && tableData.length > 0 ? (
            tableData.map((el) => (
              <div className="kt-portlet" key={el.mentor_id}>
                <div className="kt-portlet__body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="kt-section">
                        <div className="kt-section__content">
                          <h5 className="borderDown">{el.mentor_name}</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="kt-section">
                        <div className="kt-section__content">
                          <PrintoutSheetTable data={el.kids} />
                        </div>
                      </div>
                    </div>
                    <div className="kt-section">
                      <div className="kt-section__content">
                        <Container>
                          <Row>
                            <Col md={4}>
                              <span className="fw-bold">A-</span>
                              <span className="">Student is Present</span>
                            </Col>
                            <Col md={4}>
                              <span className="fw-bold">P-</span>
                              <span className="">Student is Participating</span>
                            </Col>
                            <Col md={4}>
                              <span className="fw-bold">B1-</span>
                              <span className="">Fair Behaviour</span>
                            </Col>
                            <Col md={4}>
                              <span className="fw-bold">B2-</span>
                              <span className="">Good Behaviour</span>
                            </Col>
                            <Col md={4}>
                              <span className="fw-bold">B3-</span>
                              <span className="">Excellent Behaviour</span>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="col-md-8">
              <div className="kt-portlet">
                <div className="kt-portlet__body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="kt-section">
                        <div className="kt-section__content">
                          <h5 className="borderDown">No Data</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default withRouter(PrinoutSheet);
