import React, { Component } from "react";
import { Button, Form, Container, Col, Row } from "react-bootstrap";
import { getSchools } from "../../crud/auth.crud";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./addChild.css";
import { callApi } from "../../crud/auth.crud";
import withRouter from "../../router/withRouter";
const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

const emptyState = {
  fname: "",
  lname: "",
  birthDate: "",
  ethnicity: "",
  building: "",
  apartment: "",
  city: "",
  state: "",
  zip: "",
  grade: "1",
  adult: false,
  child: false,
  school: "1",
  street:""
};

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

const gradeArray = [
  "K",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];
const ethnicityArray = [
  "African American",
  "Latino/Hispanic",
  "Asian",
  "Caucasian",
  "Other",
];
class AddChild extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fname: "",
      lname: "",
      age: "",
      birthDate: "",
      ethnicity: "African Americans",
      building: "",
      apartment: "",
      city: "",
      state: "",
      zip: "",
      grade: "1",
      school: "",
      schools: [],
      adult: false,
      child: false,
      street:""
    };
  }
  setBirthDate = (date) => {
    this.setState({ birthDate: date });
  };

  async componentDidMount() {
    await this.getSchools();
  }

  getSchools = async () => {
    try {
      const result = await getSchools();
      if (result.status === 200) {
        this.setState({
          schools: result.data.data,
          school: result.data.data.length && result.data.data[0].schoolName,
        });
      }
    } catch (err) {
      if (err.response.status == 401) {
        swal("", "Unauthorized!", "error");
        localStorage.clear();
        this.props.navigate("/auth/login");
        swal.close();
      } else {
        swal("Oops!", "Network Error!", "error");
      }
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const {
      fname,
      lname,
      adult,
      birthDate,
      ethnicity,
      building,
      apartment,
      city,
      state,
      zip,
      grade,
      school,street
    } = this.state;
    if (fname && lname) {
      try {

        console.log("🚀 ~ AddChild ~ onSubmit= ~ adult:", adult)
        const result = await callApi("addChild", {
          first_name: fname,
          last_name: lname,
          adult_or_child: adult ? "adult" : "child",
          date_of_birth: convert(birthDate),
          ethinicity: ethnicity,
          building: building,
          apartment: apartment,
          city: city,
          state: state,
          zip_code: zip,
          grade: grade,
          school: school,
          street:street
        });
        if (result.status === 200) {
          swal("Child Added Successfully!", "", "success");
          this.setState(emptyState);
        }
      } catch (err) {
        this.setState({ loading: false });
        if (err.response.status == 401) {
          swal("", "Unauthorized!", "error");
          localStorage.clear();
          this.props.navigate("/auth/login");
          swal.close();
        } else {
          swal("Oops!", "Network Error!", "error");
        }
      }
    }
  };

  isNumber = (e) => {
    e = e || window.event;
    console.log("🚀 ~ AddChild ~ e:", e);
    var charCode = e.which ? e.which : e.keyCode;
    console.log("&&", /\d/.test(String.fromCharCode(charCode)));
    return /\d/.test(String.fromCharCode(charCode));
  };

  onChangeHandle = (e, radioOption) => {
    const { value, name } = e.target;
    if (radioOption) {
      this.setState({ [name]: radioOption });
    } else {
      this.isNumber(e);
      if (name == "zip" || name == "building") {
        if (/^\d+$/.test(value)) {
          this.setState({ [name]: value });
        } else {
          this.setState({ [name]: "" });
        }
      } else {
        this.setState({ [name]: value });
      }
    }
  };
  render() {
    const {
      fname,
      lname,
      birthDate,
      ethnicity,
      building,
      apartment,
      city,
      state,
      zip,
      grade,
      school,
      street
    } = this.state;
    return (
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__body">
          <div className="row">
            <div className="col-xl-4">
              <Container style={{ maxWidth: "550px" }}>
                <Form onSubmit={this.onSubmit}>
                  <Form.Group
                    as={Row}
                    controlId="formHorizontalEmail"
                    className="form-group"
                  >
                    <Form.Label column sm={4}>
                      First Name
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        required
                        type="text"
                        value={fname}
                        name="fname"
                        onChange={this.onChangeHandle}
                        placeholder="First Name"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    className="form-group"
                    as={Row}
                    controlId="formHorizontalPassword"
                  >
                    <Form.Label column sm={4}>
                      Last Name
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        required
                        value={lname}
                        type="text"
                        name="lname"
                        onChange={this.onChangeHandle}
                        placeholder="Last Name"
                      />
                    </Col>
                  </Form.Group>
                  <fieldset>
                    <Row className="align-items-center form-group">
                      <Form.Label column sm={4}>
                        Adult or Child &nbsp;
                      </Form.Label>
                      <Col>
                        <Row className="ml-2">
                          <Col sm={3}>
                            <Form.Check
                              type="radio"
                              label="Adult"
                              name="age"
                              value="adult"
                              checked={this.state.adult}
                              required
                              id="formHorizontalRadios1"
                              onChange={() =>
                                this.setState({ adult: !this.state.adult })
                              }
                            />
                          </Col>
                          <Col sm={3}>
                            <Form.Check
                              type="radio"
                              label="Child"
                              name="age"
                              value="child"
                              checked={this.state.child}
                              id="formHorizontalRadios2"
                              required
                              onChange={() =>
                                this.setState({ child: !this.state.child })
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </fieldset>
                  <Form.Group as={Row} className="form-group">
                    <Form.Label column sm={4}>
                      Date of Birth
                    </Form.Label>
                    <Col sm={8}>
                      <DatePicker
                        selected={birthDate}
                        onChange={(date) => this.setBirthDate(date)}
                        selectsStart
                        startDate={birthDate}
                        // endDate={end_date}
                        placeholderText="DOB"
                        className="dateWidthChild form-control"
                        // fullWidth
                        maxDate={new Date()}
                        showYearDropdown
                        // scrollableMonthYearDropdown
                        // showMonthYearDropdown
                        showFullMonthYearPicker
                        required
                      />

                      {/* <Form.Control required value={birthDate} type="date" name="birthDate" onChange={this.onChangeHandle} placeholder="DOB" /> */}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="form-group">
                    <Form.Label column sm={4}>
                      Ethnicity
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        as="select"
                        value={ethnicity}
                        name="ethnicity"
                        onChange={this.onChangeHandle}
                        placeholder="Ethnicity"
                        required
                      >
                        {ethnicityArray.map((eth, i) => (
                          <option value={eth} key={i}>
                            {eth}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="form-group">
                    <Form.Label column sm={4}>
                      Building
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        value={building}
                        type="text"
                        name="building"
                        onChange={this.onChangeHandle}
                        placeholder="Building"
                        required
                      />{" "}
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="formHorizontalEmail"
                    className="form-group"
                  >
                    <Form.Label column sm={4}>
                      Apartment
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        value={apartment}
                        type="text"
                        placeholder="Apartment"
                        name="apartment"
                        onChange={this.onChangeHandle}
                        required
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="formHorizontalEmail"
                    className="form-group"
                  >
                    <Form.Label column sm={4}>
                      Street
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        value={street}
                        type="text"
                        placeholder="Street"
                        name="street"
                        onChange={this.onChangeHandle}
                        required
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="formHorizontalEmail"
                    className="form-group"
                  >
                    <Form.Label column sm={4}>
                      City
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        value={city}
                        type="text"
                        placeholder="City"
                        name="city"
                        onChange={this.onChangeHandle}
                        required
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="formHorizontalEmail"
                    className="form-group"
                  >
                    <Form.Label column sm={4}>
                      State
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        type="text"
                        value={state}
                        placeholder="State"
                        name="state"
                        onChange={this.onChangeHandle}
                        required
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="formHorizontalEmail"
                    className="form-group"
                  >
                    <Form.Label column sm={4}>
                      Zip Code
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        type="text"
                        value={zip}
                        placeholder="Zip Code"
                        name="zip"
                        // onChange={this.onChangeHandle}
                        onChange={this.onChangeHandle}
                        required
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="exampleForm.ControlSelect1"
                    className="form-group"
                  >
                    <Form.Label column sm={4}>
                      Grade
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        as="select"
                        value={grade}
                        name="grade"
                        onChange={this.onChangeHandle}
                        required
                      >
                        {gradeArray.map((el, i) => (
                          <option value={el} key={i}>
                            {el}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="exampleForm.ControlSelect1"
                    className="form-group"
                  >
                    <Form.Label column sm={4}>
                      School
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        as="select"
                        value={school}
                        name="school"
                        onChange={this.onChangeHandle}
                        required
                      >
                        {this.state.schools &&
                          this.state.schools.map((sc, i) => (
                            <option value={sc.schoolID} key={sc.id}>
                              {sc.schoolName}
                            </option>
                          ))}
                      </Form.Control>{" "}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="form-group text-center">
                    <Col sm={{ span: 10, offset: 2 }}>
                      <Button type="submit" style={btnColor}>
                        Add Child
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
              </Container>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default withRouter(AddChild);
