import React, { Component } from "react";
import { Card, Form, Col, Row } from "react-bootstrap";

import GradeSubjectTable from "./gradeSubjectTable";
// import GradeAttendanceTable from "./gradeAttendanceTable";
import { Button } from "@material-ui/core";
import AddGrades from "./addGrades";
import UploadPdf from "./uploadPDF";
import { serverUrl } from "../../../_metronic/utils/api";
import { callApi } from "../../crud/auth.crud";
import axios from "axios";
import swal from "sweetalert";

const btnText = { textTransform: "unset", color: "#fff" };
export default class GradesData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openPDF: false,
      gradeArray: [],
      attendanceArray: [],
      openGrade: false,
    };
  }
  handleOpenGrade = (id) => {
    this.setState({ openGrade: true, childID: id });
  };

  handleCloseGrade = () => {
    this.setState({ openGrade: false });
  };

  handleOpenPDF = () => {
    this.setState({ openPDF: true });
  };

  handleClosePDF = () => {
    this.setState({ openPDF: false });
  };

  async componentDidMount() {
    await this.getGradeData();
  }

  getGradeData = async () => {
    let id = this.props.childId;
    try {
      const result = await axios.get(`${serverUrl}grade?child_id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (result.status === 200) {
        this.setState({
          gradeArray: result.data.data,
          attendanceArray: result.data.data.attendance,
        });
      }
    } catch (err) {
      if (err && err.response && err.response.status === 400) {
        swal("Oops!", "No Record with this ID!", "");
      } else swal("Oops!", "Network Error!", "error");
    }
  };

  deleteGrade = async (school_year, grade_year) => {
    let child_id = this.props.childId;
    if (child_id)
      try {
        const result = await callApi("delete-grade", {
          child_id,
          school_year,
          grade_year,
        });
        if (result.status === 200) {
          swal("Grade Deleted Successfully!", "", "success");
          this.getGradeData();
        } else {
          swal("Oops!", "Please try again!!", "error");
        }
      } catch {
        swal("Oops!", "Network Error!!", "error");
      }
  };
  deleteHandlerConfirm = (school_year, grade_year) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.deleteGrade(school_year, grade_year);
      }
    });
  };

  render() {
    return (
      <div className="col-xl-9 col-lg-8 col-md-8 col-sm-8 col-8">
        <div className="kt-section">
          <>
            <Card>
              <Card.Body>
                <Card.Title>
                  <Row>
                    <span>Grades Data</span>
                  </Row>
                </Card.Title>

                {this.state.gradeArray &&
                  this.state.gradeArray.map((el, i) => (
                    <React.Fragment key={i}>
                      <div className="d-flex justify-content-between">
                        <span>
                          <p className="mt-4 mb-4 fw-bold">{el.heading}</p>
                        </span>
                        <span className="py-4">
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              backgroundColor: "#ef1f2f",
                              color: "#fff",
                            }}
                            onClick={() =>
                              this.deleteHandlerConfirm(el.school, el.grade)
                            }
                          >
                            Delete Grade
                          </Button>
                        </span>
                      </div>
                      <GradeSubjectTable
                        tableData={el.grade_data}
                        late={el.late}
                        missed={el.missed}
                      />
                      {/* <GradeAttendanceTable attendanceArray={el.data}/> */}
                    </React.Fragment>
                  ))}

                <div className="text-center form-group mt-4">
                  <Row>
                    <Col md={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={btnText}
                        onClick={this.handleOpenGrade}
                      >
                        Add grades to profile
                      </Button>
                    </Col>
                    <Col md={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={btnText}
                        onClick={this.handleOpenPDF}
                      >
                        Upload file of grades
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </>
          {this.state.openGrade ? (
            <AddGrades
              child_id={this.props.childId ? this.props.childId : ""}
              handleOpen={this.handleOpenGrade}
              open={this.state.openGrade}
              handleClose={this.handleCloseGrade}
              getGradeData={this.getGradeData}
              key_type="grade_table"
            />
          ) : null}
          {this.state.openPDF ? (
            <UploadPdf
              child_id={this.props.childId ? this.props.childId : ""}
              handleOpen={this.handleOpenPDF}
              open={this.state.openPDF}
              handleClose={this.handleClosePDF}
              getGradeData={this.getGradeData}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
