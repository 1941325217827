import React, { Component } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../partials/content/Portlet";
import FoodFocus from "../../Components/dashboardPages/foodFocus";
import FoodInsecurity from "../../Components/dashboardPages/foodinsecurity";
import MealServedTable from "./mealServedTable";
import { Card } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { callApi } from "../../crud/auth.crud";
import moment from "moment";
import swal from "sweetalert";
import { connect } from "react-redux";
import ResetDate from "./resetAction";
import withRouter from "../../router/withRouter";

const fontWeight = {
  fontWeight: "bold",
};

class Feeding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      six_fruit_cup: "",
      chicken_three_pound: "",
      kids_recieved_meal: "",
      meal_per_program: [],
      meal_served: "",
      loading: false,
      price_pineapple: "",
      total_meal_served: "",
      volunteer_prep: "",
      eight_piece_of_chicken: "",
    };
    console.log("🚀 ~ Feeding ~ constructor ~ props:", this.props);
  }

  async componentDidMount() {
    this.getstats();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.resetDate !== this.props.resetDate) {
      window.scrollTo(0, 0);
      this.getstats();
    }
  }

  getstats = async () => {
    if (!this.state.loading) {
      await this.setState({ loading: true });
    }
    let payload = this.props.resetDate;
    try {
      let result = await callApi("get-feeding", payload);
      if (result.status === 200) {
        this.setState({
          six_fruit_cup: result.data.data.six_fruit_cup,
          chicken_three_pound: result.data.data.chicken_three_pound,
          kids_recieved_meal: result.data.data.kids_recieved_meal,
          meal_per_program: result.data.data.meal_per_program,
          meal_served: result.data.data.meal_served,
          loading: false,
          price_pineapple: result.data.data.price_pineapple,
          total_meal_served: result.data.data.total_meal_served,
          volunteer_prep: result.data.data.volunteer_prep,
          eight_piece_of_chicken: result.data.data.eight_piece_of_chicken,
        });
      } else {
        this.setState({ loading: false });
      }
    } catch {
      this.setState({ loading: false });
      swal("Oops!", "Network Error!", "error");
    }
  };

  moveToAdjPage = (pageName) => {
    this.props.navigate("/" + pageName);
  };

  reset_Date = () => {
    console.log("**--*", this.props);
    if (this.props.resetDate.start_date && this.props.resetDate.end_date)
      this.props.ResetDate("", "");
  };
  render() {
    const {
      six_fruit_cup,
      chicken_three_pound,
      kids_recieved_meal,
      meal_per_program,
      meal_served,
      loading,
      price_pineapple,
      total_meal_served,
      volunteer_prep,
      eight_piece_of_chicken,
    } = this.state;
    return (
      <>
        {loading ? (
          <div
            className="spinner-border text-warning dashboardLoading"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-xl-12">
                <Portlet fluidHeight={true}>
                  <PortletHeader title="Date Range" />

                  <PortletBody>
                    <div className="row">
                      <div className="col-md-3">
                        <Card
                          style={{
                            width: "13rem",
                            borderLeft: "3px solid #f4b53f",
                            borderWidthLeft: "5px",
                            fontSize: "10px",
                          }}
                        >
                          <Card.Body>
                            <Card.Text>Begining of date range</Card.Text>
                            <Card.Text style={fontWeight}>
                              {this.props.resetDate.start_date
                                ? moment(
                                    this.props.resetDate.start_date
                                  ).format("MMM Do YYYY")
                                : moment(
                                    new Date(
                                      new Date().setFullYear(
                                        new Date().getFullYear() - 1
                                      )
                                    )
                                  ).format("MMM Do YYYY")}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                      <div className="col-md-4">
                        <Card
                          style={{
                            width: "13rem",
                            fontSize: "10px",
                            borderLeft: "3px solid #f4b53f",
                          }}
                        >
                          <Card.Body>
                            <Card.Text>End of date range</Card.Text>
                            <Card.Text style={fontWeight}>
                              {" "}
                              {this.props.resetDate.end_date
                                ? moment(this.props.resetDate.end_date).format(
                                    "MMM Do YYYY"
                                  )
                                : moment(new Date()).format("MMM Do YYYY")}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                      <div className="d-flex">
                        <span>
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            className="mt-4"
                            onClick={() =>
                              this.moveToAdjPage("changeDate/feeding")
                            }
                          >
                            Change Date Range
                          </Button>
                        </span>
                        <span className="px-4">
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            className="mt-4"
                            onClick={this.reset_Date}
                          >
                            Reset Date
                          </Button>
                        </span>
                      </div>
                    </div>
                  </PortletBody>
                </Portlet>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <FoodFocus
                  obj={{ meal_served, kids_recieved_meal, volunteer_prep }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <FoodInsecurity
                  obj={{
                    price_pineapple,
                    six_fruit_cup,
                    chicken_three_pound,
                    eight_piece_of_chicken,
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <MealServedTable
                  meal_per_program={meal_per_program}
                  total_meal_served={total_meal_served}
                />
              </div>
            </div>
          </>
        )}{" "}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  resetDate: state.resetDate.date,
});

const HOC = connect(mapStateToProps, { ResetDate })(Feeding);

export default withRouter(HOC);
