import React from "react";
import { Bar } from "react-chartjs-2";
import { Typography } from "@material-ui/core";
import "../Components/dashboardPages/dashboard.css";
import "chartjs-plugin-datalabels";

export default class ConcentratedInfluenceTutoringChart extends React.Component {
  render() {
    return (
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              Concentrated Influence of Urban Impact
            </h3>
          </div>
          {/* <PortletHeaderDropdown /> */}
        </div>
        <div className="kt-portlet__body">
          <Typography
            variant="caption"
            display="block"
            className="mb-4 dashboardText"
          >
            Urban Impact focuses its tutoring on the early years for reading and
            math, between K-3.
          </Typography>
          <Bar
            data={{
              labels:
                this.props.concentrated_inf_ui &&
                this.props.concentrated_inf_ui.length > 0
                  ? this.props.concentrated_inf_ui.map((val) => val.event)
                  : [
                      "K-3 Teachers at Columbus",
                      "Spk-3 Teachers at Columbus Annexorts Team",
                      "Urban Impact volunteers",
                    ],
              datasets: [
                {
                  label: "Teachers or Volunteers",
                  backgroundColor: "#f4b53f",
                  // borderColor: 'rgba(0,0,0,1)',
                  // borderWidth: 2,
                  data:
                    this.props.concentrated_inf_ui &&
                    this.props.concentrated_inf_ui.length > 0
                      ? this.props.concentrated_inf_ui.map(
                          (val) => val.children
                        )
                      : [],
                },
              ],
            }}
            options={{
              plugins: {
                datalabels: {
                  display: true,
                  color: "#5c5a6d",
                  align: "end",
                  clamp: true,
                },
              },
              title: {
                display: true,
                // text:'Average Rainfall per month',
                fontSize: 10,
              },
              legend: {
                display: true,
                position: "right",
              },
            }}
          />
        </div>
      </div>
    );
  }
}
