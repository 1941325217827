
export default function ResetDate(SD,ED)
{
    return {
        type: "RESET_DATE",
        payload: {
            start_date:SD,
            end_date:ED
        }
      };
}
