import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 12,
  },
}))(TableCell);

export default class ActiveMentorTable extends Component {
  render() {
    return (
      <Paper className="mb-4">
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>First Name</StyledTableCell>
              <StyledTableCell>Last Name</StyledTableCell>
              <StyledTableCell>Session</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.tableData && this.props.tableData ? (
              this.props.tableData.map((row, i) => (
                <TableRow key={i}>
                  <TableCell component="th" scope="row">
                    {row.firstName ? row.firstName : "-"}
                  </TableCell>
                  <TableCell>{row.lastName ? row.lastName : "-"}</TableCell>
                  <TableCell>{row.session ? row.session : "-"}</TableCell>
                </TableRow>
              ))
            ) : (
              <p>No Data</p>
            )}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}
