import React from "react";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { Typography } from "@material-ui/core";
import "../Components/dashboardPages/dashboard.css";
import "chartjs-plugin-datalabels";

export default class SalesBarChart extends React.Component {
  render() {
    let propsData = this.props.graphData || "";
    return (
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              Engaging the kids from different directions
            </h3>
          </div>
          {/* <PortletHeaderDropdown /> */}
        </div>
        <div className="kt-portlet__body">
          <Typography
            variant="caption"
            display="block"
            className="mb-4 dashboardText"
          >
            Urban Impact of Black Rock's objective is to immerse the kids from
            PT Barnum Apartments in several programs simultaneously to increase
            the breadth of influence. <br />
            The following summary shows the breadth of influence currently
            engaged:
          </Typography>
          {propsData ? (
            <Bar
              data={{
                labels: [
                  "Kids in 4+ Core Programs",
                  "Kids in 3+ Core Programs",
                  "Kids in 2+ Core Programs",
                  "Kids in 1+ Core Programs",
                  "Kids Reached",
                ],
                datasets: [
                  {
                    label: "Kids",
                    backgroundColor: "#f4b53f",
                    // borderColor: 'rgba(0,0,0,1)',
                    // borderWidth: 2,
                    data: [
                      this.props.graphData.kids_4plus
                        ? this.props.graphData.kids_4plus
                        : "",
                      this.props.graphData.kids_3plus
                        ? this.props.graphData.kids_3plus
                        : "",
                      this.props.graphData.kids_2plus
                        ? this.props.graphData.kids_2plus
                        : "",
                      this.props.graphData.kids_1plus
                        ? this.props.graphData.kids_1plus
                        : "",
                      this.props.graphData.kids_reached
                        ? this.props.graphData.kids_reached
                        : "",
                    ],
                  },
                ],
              }}
              options={{
                plugins: {
                  datalabels: {
                    display: true,
                    color: "#5c5a6d",
                    align: "end",
                  },
                },
                // title:{
                //   display:true,
                //    text:'Average Rainfall per month',
                //   fontSize:10,
                // },
                legend: {
                  display: true,
                  position: "right",
                },
              }}
            />
          ) : (
            <p>No Data</p>
          )}
        </div>
      </div>
    );
  }
}
