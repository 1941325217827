import React, { Component } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { Button } from "@material-ui/core";
import SpecialEventTable from "./specialEventTable";
import AddSpecialEvent from "./addSpecialEvent";
import { getSpecialEvents } from "../../../crud/auth.crud";
import swal from "sweetalert";
import Pagination from "react-js-pagination";

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
  color: "#fff",
};
const debounce = (fn, delay) => {
  let timer = null;
  return function (...args) {
    const context = this;
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
};

export default class SpecialEvents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      tableData: [],
      total: null,
      per_page: null,
      current_page: 1,
      searchTxt: "",
    };
    this.getEvents = debounce(this.getEvents, 200);
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  async componentDidMount() {
    await this.getEvents();
  }

  getEvents = async (page) => {
    const { searchTxt, current_page } = this.state;
    try {
      const result = await getSpecialEvents(
        page ? page : current_page,
        searchTxt
      );
      if (result.status === 200) {
        this.setState({
          tableData: result.data.data.data,
          current_page: result.data.data.current_page,
          total: result.data.data.total,
          per_page: result.data.data.per_page,
        });
      }
    } catch (e) {
      swal("Oops!", "Error in Fetching Events!", "error");
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ current_page: pageNumber });
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.current_page !== this.state.current_page) {
      window.scrollTo(0, 0);
      this.getEvents();
    }
  }

  searchHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => this.getEvents());
  };

  render() {
    const { tableData } = this.state;
    return (
      <div className="col-md-12">
        {/* <div className="kt-section"> */}
        <>
          <div className="kt-portlet kt-portlet--height-fluid">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">Special Events</h3>
              </div>
            </div>
            <div className="kt-portlet__body">
              <div className="row">
                <div className="col-md-12">
                  <div className="kt-section">
                    <div className="kt-section__content">
                      <div className="d-flex ">
                        <span>
                          <Button
                            type="submit"
                            style={btnColor}
                            onClick={this.handleOpen}
                          >
                            Add a special Event
                          </Button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="kt-section">
                    <div className="kt-section__content text-center">
                      <Row>
                        <Col md={10}>
                          <div className="input-group pl-0">
                            <input
                              className="form-control"
                              type="text"
                              name="searchTxt"
                              onChange={this.searchHandle}
                              placeholder="Search by program name"
                              aria-label="Search"
                            />
                              <span
                                className="input-group-text amber lighten-3"
                                id="basic-text1"
                              >
                                <i
                                  className="fas fa-search text-grey"
                                  aria-hidden="true"
                                ></i>
                              </span>
                          </div>
                        </Col>
                        <Col md={2}>
                          <Button type="submit" style={btnColor}>
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="kt-section">
                    <div className="kt-section__content text-center">
                      <SpecialEventTable
                        tableData={tableData}
                        getEvents={this.getEvents}
                      />
                    </div>
                  </div>
                </div>
                {this.state.open ? (
                  <AddSpecialEvent
                    handleOpen={this.handleOpen}
                    getEvents={this.getEvents}
                    open={this.state.open}
                    handleClose={this.handleClose}
                  />
                ) : null}
              </div>
              <Pagination
                activePage={this.state.current_page}
                itemsCountPerPage={10}
                totalItemsCount={this.state.total}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange.bind(this)}
                itemclassName="page-item"
                linkclassName="page-link"
              />
            </div>
          </div>
        </>
      </div>
      // </div>
    );
  }
}
