import React from "react";
import { withStyles, makeStyles } from "@material-ui/core";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
} from "@material-ui/core";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    // minWidth: 800,
  },
}));

export default function EmergencyContactTable(props) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Emergency Contact</StyledTableCell>
              <StyledTableCell>Emergency Phone</StyledTableCell>
              <StyledTableCell>Relationship</StyledTableCell>
              <StyledTableCell>Address</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {rows.map(row => ( */}
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                {props.details.emergencyContact}
              </StyledTableCell>
              <StyledTableCell> {props.details.emergencyPhone}</StyledTableCell>
              <StyledTableCell>
                {" "}
                {props.details.emergencyRelationship}
              </StyledTableCell>
              <StyledTableCell>
                {" "}
                {props.details.emergencyAddress}
              </StyledTableCell>
            </StyledTableRow>
            {/* ))} */}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
