import React from "react";
import { Link, Routes, Route, Navigate, Outlet } from "react-router-dom";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import Login from "./Login";
// import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import Logo from "../../images/Logo.png";
import ResetPassword from "../auth/resetPassword";

export default function AuthPage() {
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
              style={{
                //  backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`
                backgroundColor: "#f4b53f",
              }}
            >
              <div className="kt-grid__item">
                <Link to="/" className="kt-login__logo">
                  <img alt="Logo" src={Logo} />
                </Link>
              </div>
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <div className="kt-grid__item kt-grid__item--middle">
                  <h3 className="kt-login__title">Welcome to Urban Impact!</h3>
                  <h4 className="kt-login__subtitle">
                    The ultimate platform for students and mentors.
                  </h4>
                </div>
              </div>
              <div className="kt-grid__item">
                <div className="kt-login__info">
                  <div className="kt-login__copyright">
                    &copy; 2020 Urban Impact
                  </div>
                  <div className="kt-login__menu">
                    {/* <Link to="/terms" className="kt-link">
                      Privacy
                    </Link>
                    <Link to="/legal" className="kt-link">
                      Legal
                    </Link>
                    <Link to="/auth/contact-us" className="kt-link">
                      Contact
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
              {/* <Routes> */}
              {/* <Route path="/auth/login" component={Login} /> */}
              {/* <Route path="/auth/registration" component={Registration} /> */}
              {/* <Route
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                /> */}
              {/* <Route path="/auth/reset-password" component={ResetPassword} /> */}
              {/* <Navigate from="/auth" exact={true} to="/auth/login" />
                <Navigate to="/auth/login" /> */}
              {/* </Routes> */}
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
