import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ResetDate from "../../../../../app/Components/dashboardPages/resetAction";

class DashboardMenu extends Component {
  reset_Date = () => {
    this.props.ResetDate("", "");
  };
  render() {
    let tab = this.props.currentPath;
    return (
      <>
        <Dropdown className="kt-portlet__head-toolbar mt-4" drop="down">
          <Dropdown.Toggle
            variant="transparent"
            className={
              tab !== "/hours"
                ? "btn btn-label-warning btn-sm btn-bold dropdown-toggle"
                : "btn dropdown-toggle"
            }
            id="dropdown-toggle-top"
          >
            Student Data
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right">
            <ul className="kt-nav">
              <li className="kt-nav__item">
                <Link
                  to="/dashboard"
                  onClick={this.reset_Date}
                  className="kt-nav__link"
                >
                  {/* <i className="kt-nav__link-icon flaticon2-line-chart" /> */}
                  <span className="kt-nav__link-text">Summary</span>
                </Link>
              </li>
              <li className="kt-nav__item">
                <Link
                  to="/mentoring"
                  onClick={this.reset_Date}
                  className="kt-nav__link"
                >
                  {/* <i className="kt-nav__link-icon flaticon2-line-chart" /> */}
                  <span className="kt-nav__link-text">Mentoring</span>
                </Link>
              </li>
              <li className="kt-nav__item">
                <Link
                  to="/tutoring"
                  onClick={this.reset_Date}
                  className="kt-nav__link"
                >
                  {/* <i className="kt-nav__link-icon flaticon2-line-chart" /> */}
                  <span className="kt-nav__link-text">Tutoring</span>
                </Link>
              </li>
              <li className="kt-nav__item">
                <Link
                  to="/perspective"
                  onClick={this.reset_Date}
                  className="kt-nav__link"
                >
                  {/* <i className="kt-nav__link-icon flaticon2-line-chart" /> */}
                  <span className="kt-nav__link-text">Perspective</span>
                </Link>
              </li>
              <li className="kt-nav__item">
                <Link
                  to="/feeding"
                  onClick={this.reset_Date}
                  className="kt-nav__link"
                >
                  {/* <i className="kt-nav__link-icon flaticon2-line-chart" /> */}
                  <span className="kt-nav__link-text">Feeding</span>
                </Link>
              </li>
            </ul>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown className="kt-portlet__head-toolbar mt-4 ml-2" drop="down">
          <Dropdown.Toggle
            variant="transparent"
            className={
              tab === "/hours"
                ? "btn btn-label-warning btn-sm btn-bold dropdown-toggle"
                : "btn  dropdown-toggle"
            }
            id="dropdown-toggle-top"
          >
            Mentor Data
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right">
            <ul className="kt-nav">
              <li className="kt-nav__item">
                <Dropdown.Item
                  // href="/hours"
                  className="kt-nav__link"
                >
                  <Link
                    to="/hours"
                    onClick={this.reset_Date}
                    className="kt-nav__link"
                  >
                    {/* <i className="kt-nav__link-icon flaticon2-line-chart" /> */}
                    <span className="kt-nav__link-text">Hours</span>
                  </Link>
                </Dropdown.Item>
              </li>
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }
}

export default connect(null, { ResetDate })(DashboardMenu);
