import React, { Component } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import ChildProfileTable from "./childProfileTable";
import swal from "sweetalert";
import { serverUrl } from "../../../_metronic/utils/api";
import axios from "axios";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
  color: "#fff",
};

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}
export default class ChildSearchByAddress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [],
      loader: true,
      total: null,
      per_page: null,
      current_page: 1,
      building: "",
      apartment: "",
      street: "",
      city: "",
      state: "",
      dob: "",
      current_pageSearch: 1,
    };
  }
  async componentDidMount() {
    await this.getChild();
  }

  getChild = async () => {
    if (!this.state.loader) {
      this.setState({ loader: true });
    }
    try {
      const result = await axios.get(
        `${serverUrl}child?page=${this.state.current_page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      let details = result.data.data;
      if (result.status === 200) {
        this.setState({
          tableData: details.data,
          current_page: details.current_page,
          total: details.total,
          per_page: details.per_page,
          loader: false,
        });
      }
    } catch {
      this.setState({ loader: false });
      swal("Oops!", "Network Error!", "error");
    }
  };

  getChildSearch = async () => {
    if (!this.state.loader) {
      this.setState({ loader: true });
    }
    const { building, apartment, street, city, state, dob } = this.state;
    let DOB = dob ? convert(dob) : "";
    try {
      const result = await axios.post(
        `${serverUrl}child-address?page=${this.state.current_pageSearch}`,
        { building, apartment, street, city, state, dob: DOB },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      let details = result.data.data;
      if (result.status === 200) {
        this.setState({
          tableData: details.data,
          current_pageSearch: details.current_page,
          total: details.total,
          per_page: details.per_page,
          loader: false,
        });
      }
    } catch {
      this.setState({ loader: false });
      swal("Oops!", "Network Error!", "error");
    }
  };

  handlePageChange = (pageNumber) => {
    if (this.state.search) {
      this.setState({ current_pageSearch: pageNumber });
    } else {
      this.setState({ current_page: pageNumber });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.current_page !== this.state.current_page &&
      !this.state.search
    ) {
      window.scrollTo(0, 0);
      this.getChild(this.state.current_page);
    }
    if (prevState.current_pageSearch !== this.state.current_pageSearch) {
      window.scrollTo(0, 0);
      this.getChildSearch(1);
    }
  }

  searchHandler = (e) => {
    e.preventDefault();
    this.setState({ search: true }, () => this.getChildSearch());
  };
  resetSearch = (e) => {
    e.preventDefault();
    this.setState(
      {
        search: false,
        building: "",
        apartment: "",
        street: "",
        city: "",
        state: "",
        dob: "",
        current_page: 1,
      },
      () => this.getChild()
    );
  };
  setBirthDate = (date) => {
    if (this.state.current_page !== 1) {
      return this.setState({ dob: date, current_page: 1 });
    }

    this.setState({ dob: date });
  };
  onChangeHandle = (e) => {
    if (this.state.current_page !== 1) {
      return this.setState({
        [e.target.name]: e.target.value,
        current_page: 1,
      });
    }

    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { building, apartment, street, city, state, dob } = this.state;
    return (
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__body">
          <div className="row">
            <div className="col-xl-6 col-lg-12">
              <Form onSubmit={this.searchHandler}>
                <Row>
                  <Form.Group as={Col} xl="4" lg="4" md="6" className="form-group">
                    <Form.Label>Building</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Building"
                      defaultValue="Building"
                      name="building"
                      value={building}
                      onChange={this.onChangeHandle}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl="4" lg="4" md="6" className="form-group">
                    <Form.Label>Apartment</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Apartment"
                      name="apartment"
                      value={apartment}
                      onChange={this.onChangeHandle}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl="4" lg="4" md="6" className="form-group">
                    <Form.Label>Street</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Street"
                      name="street"
                      value={street}
                      onChange={this.onChangeHandle}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl="4" lg="4" md="6" className="form-group">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="City"
                      name="city"
                      value={city}
                      onChange={this.onChangeHandle}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl="4" lg="4" md="6" className="form-group">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="State"
                      name="state"
                      value={state}
                      onChange={this.onChangeHandle}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl="4" lg="4" md="6" className="form-group">
                    <Form.Label>Birth Date</Form.Label>
                    <div>
                      <DatePicker
                        selected={dob}
                        onChange={(date) => this.setBirthDate(date)}
                        selectsStart
                        startDate={dob}
                        // endDate={end_date}
                        placeholderText="DOB"
                        className="form-control childDateAddress"
                        showYearDropdown
                        scrollableMonthYearDropdown
                        maxDate={new Date()}
                      />
                    </div>
                  </Form.Group>
                </Row>
                <div className="text-center mb-4">
                  {this.state.search ? (
                    <Button onClick={this.resetSearch} style={btnColor}>
                      Reset
                    </Button>
                  ) : (
                    <Button type="submit" style={btnColor}>
                      Search
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </div>
          {this.state.loader ? (
            <div
              className="spinner-border text-warning loaderStyle"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <ChildProfileTable
              tableData={this.state.tableData}
              activePage={this.state.current_page}
              itemsCountPerPage={10}
              totalItemsCount={this.state.total}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange}
              getChild={this.getChild}
            />
          )}
          {this.state.total > 10 && !this.state.loader ? (
            <Pagination
              activePage={
                this.state.search
                  ? this.state.current_pageSearch
                  : this.state.current_page
              }
              itemsCountPerPage={10}
              totalItemsCount={this.state.total}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange.bind(this)}
              itemclassName="page-item"
              linkclassName="page-link"
            />
          ) : null}
        </div>
      </div>
    );
  }
}
