import React, { Component } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../partials/content/Portlet";
import PerspectiveBarriersChart from "../../widgets/perspectiveBarriersChart";
import PerspectiveBarrier from "../../Components/dashboardPages/perspectiveBarrier";
import BarrierFromSchool from "../../Components/dashboardPages/barriersFromSchool";
import BarrierFromPverty from "../../Components/dashboardPages/barriersFromPoverty";
import { Card } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { callApi } from "../../crud/auth.crud";
import GrowPerspectiveChart from "./growPerspectiveChart";
import moment from "moment";
import swal from "sweetalert";
import { connect } from "react-redux";
import ResetDate from "./resetAction";
import withRouter from "../../router/withRouter";

const fontWeight = {
  fontWeight: "bold",
};

class Perspective extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hours_perspective_build: "",
      number_perspective_build: "",
      loading: true,
      pers_barrier_crime_voilence: [],
      pt_barnum_kids: [],
      pt_grow_perspective: [],
      surrounding_t_schools: [],
    };
  }

  async componentDidMount() {
    this.getstats();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.resetDate !== this.props.resetDate) {
      window.scrollTo(0, 0);
      this.getstats();
    }
  }

  getstats = async () => {
    if (!this.state.loading) {
      await this.setState({ loading: true });
    }
    let payload = this.props.resetDate;
    try {
      let result = await callApi("get-perspective", payload);
      if (result.status === 200) {
        this.setState({
          hours_perspective_build: result.data.data.hours_perspective_build,
          number_perspective_build: result.data.data.number_perspective_build,
          pers_barrier_crime_voilence:
            result.data.data.pers_barrier_crime_voilence,
          pt_barnum_kids: result.data.data.pt_barnum_kids,
          pt_grow_perspective: result.data.data.pt_grow_perspective,
          loading: false,
          surrounding_t_schools: result.data.data.surrounding_t_schools,
        });
      } else {
        this.setState({ loading: false });
      }
    } catch {
      this.setState({ loading: false });
      swal("Oops!", "Network Error!", "error");
    }
  };

  moveToAdjPage = (pageName) => {
    this.props.navigate("/" + pageName);
  };

  reset_Date = () => {
    if (this.props.resetDate.start_date && this.props.resetDate.end_date)
      this.props.ResetDate("", "");
  };

  render() {
    const {
      hours_perspective_build,
      number_perspective_build,
      loading,
      pers_barrier_crime_voilence,
      pt_barnum_kids,
      pt_grow_perspective,
      surrounding_t_schools,
    } = this.state;
    return (
      <>
        {loading ? (
          <div
            className="spinner-border text-warning dashboardLoading"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-xl-12">
                <Portlet fluidHeight={true}>
                  <PortletHeader title="Date Range" />

                  <PortletBody>
                    <div className="row">
                      <div className="col-md-3">
                        <Card
                          style={{
                            width: "13rem",
                            borderLeft: "3px solid #f4b53f",
                            borderWidthLeft: "5px",
                            fontSize: "10px",
                          }}
                        >
                          <Card.Body>
                            <Card.Text>Begining of date range</Card.Text>
                            <Card.Text style={fontWeight}>
                              {this.props.resetDate.start_date
                                ? moment(
                                    this.props.resetDate.start_date
                                  ).format("MMM Do YYYY")
                                : moment(
                                    new Date(
                                      new Date().setFullYear(
                                        new Date().getFullYear() - 1
                                      )
                                    )
                                  ).format("MMM Do YYYY")}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                      <div className="col-md-4">
                        <Card
                          style={{
                            width: "13rem",
                            fontSize: "10px",
                            borderLeft: "3px solid #f4b53f",
                          }}
                        >
                          <Card.Body>
                            <Card.Text>End of date range</Card.Text>
                            <Card.Text style={fontWeight}>
                              {" "}
                              {this.props.resetDate.end_date
                                ? moment(this.props.resetDate.end_date).format(
                                    "MMM Do YYYY"
                                  )
                                : moment(new Date()).format("MMM Do YYYY")}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                      <div className="d-flex">
                        <span>
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            className="mt-4"
                            onClick={() =>
                              this.moveToAdjPage("changeDate/perspective")
                            }
                          >
                            Change Date Range
                          </Button>
                        </span>
                        <span className="px-4">
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            className="mt-4"
                            onClick={this.reset_Date}
                          >
                            Reset Date
                          </Button>
                        </span>
                      </div>
                    </div>
                  </PortletBody>
                </Portlet>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <PerspectiveBarrier
                  obj={{ hours_perspective_build, number_perspective_build }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <BarrierFromSchool
                  pt_barnum_kids={pt_barnum_kids}
                  surrounding_t_schools={surrounding_t_schools}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <BarrierFromPverty />
              </div>
            </div>
            <Portlet>
              <PortletBody fit={true}>
                <div className="row row-no-padding row-col-separator-xl">
                  <div className="col-xl-12">
                    <PerspectiveBarriersChart
                      pers_barrier_crime_voilence={pers_barrier_crime_voilence}
                    />
                  </div>
                </div>
              </PortletBody>
            </Portlet>
            <Portlet>
              <PortletBody fit={true}>
                <div className="row row-no-padding row-col-separator-xl">
                  <div className="col-xl-12">
                    <GrowPerspectiveChart
                      pt_grow_perspective={pt_grow_perspective}
                    />
                  </div>
                </div>
              </PortletBody>
            </Portlet>
          </>
        )}{" "}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  resetDate: state.resetDate.date,
});

const HOC = connect(mapStateToProps, { ResetDate })(Perspective);

export default withRouter(HOC);
