import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField, TextareaAutosize } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { contactUs, login } from "../../crud/auth.crud";
import swal from "sweetalert";

function ContactUs(props) {
  console.log("🚀 ~ Login ~ props:", props);
  const { intl } = props;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
    backgroundColor: "#f4b53f",
    border: "none",
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  async function conatctUsHandle(values, resetForm) {
    let result = await contactUs(values);
    if (result && result.status === 200) {
      disableLoading();
      resetForm();
    } else {
      swal("Oops!", "Something went wrong!", "error");
      disableLoading();
    }
    // return result
  }

  return (
    <>
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
              <FormattedMessage id="AUTH.GENERAL.CONTACT_US" />
            </h3>
          </div>

          <Formik
            initialValues={{
              email: "",
              message: "",
              name: "",
            }}
            validate={(values) => {
              const errors = {};

              if (!values.email) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_FIELD",
                });
              }

              if (!values.message) {
                errors.message = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              }
              if (!values.name) {
                errors.name = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting, resetForm }) => {
              enableLoading();
              // setTimeout(() => {
              console.log(
                "login -->>",
                conatctUsHandle(values, resetForm)
              );
              // .then(({ res }) => {
              //   console.log('res',res)
              // disableLoading();
              // props.login(accessToken);
              // })
              // .catch(() => {
              //   disableLoading();
              //   setSubmitting(false);
              //   setStatus(
              //     intl.formatMessage({
              //       id: "AUTH.VALIDATION.INVALID_LOGIN"
              //     })
              //   );
              // });
              //  }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                <div className="form-group text-success">
                  {props?.location?.state
                    ? props?.location?.state?.password_change_msg
                    : null}
                </div>
                <div className="form-group">
                  <TextField
                    type="text"
                    label="Name"
                    margin="normal"
                    className="kt-width-full"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    helperText={touched.name && errors.name}
                    error={Boolean(touched.name && errors.name)}
                  />
                </div>
                <div className="form-group">
                  <TextField
                    type="email"
                    label="Email"
                    margin="normal"
                    className="kt-width-full"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    id="standard-multiline-static"
                    label="Message"
                    multiline
                    rows={10}
                    maxRows={5}
                    className="w-100"
                    variant="standard"
                    name="message"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.message}
                    helperText={touched.message && errors.message}
                    error={Boolean(touched.message && errors.message)}
                  />
                </div>

                <div className="kt-login__actions">
                  <div></div>
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    // disabled={isSubmitting}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                          loading,
                      }
                    )}`}
                    style={loadingButtonStyle}
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ContactUs));
