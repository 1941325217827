import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import { metronic } from "../../_metronic";
import myReducer from './ducks/myReducer'
import global_loader_reducer from './ducks/global_loader_reducer'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  resetDate:myReducer,
  loader : global_loader_reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
