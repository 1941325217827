import React, { Component } from "react";
import { Card, Form, Row } from "react-bootstrap";
import EmergencyContactTable from "./emergencyContactTable";
import BackupContactTable from "./backupContactTable";
import { Button } from "@material-ui/core";
import EditEmergencyInfo from "./editEmergencyInfo";
import { serverUrl } from "../../../_metronic/utils/api";
import axios from "axios";
import swal from "sweetalert";
import SecondBackupContactTable from "./secondBackupContactTable";
const btnText = { textTransform: "unset", color: "#fff" };

export default class EmergencyAndLiability extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      details: "",
    };
  }
  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  async componentDidMount() {
    await this.getContactData();
  }

  getContactData = async () => {
    let id = this.props.childId;
    try {
      const result = await axios.get(`${serverUrl}emergency?child_id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (result.status === 200) {
        this.setState({ details: result.data.data });
      }
    } catch {
      swal("Blank Field!", "No Emergency Contact Found!", "");
    }
  };

  render() {
    return (
      <div className="col-xl-9 col-lg-8 col-md-8 col-sm-8 col-8">
        <div className="kt-section">
          <>
            <Card>
              <Card.Body>
                <Card.Title>
                  <Row>
                    <span>Emergency And Liability Information</span>
                  </Row>
                </Card.Title>

                <EmergencyContactTable details={this.state.details} />
                <BackupContactTable details={this.state.details} />
                <SecondBackupContactTable details={this.state.details} />
                <div className="text-center mt-4">
                  <Button
                    variant="contained"
                    color="primary"
                    style={btnText}
                    onClick={this.handleOpen}
                  >
                    Edit Emergency Information
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </>
          {this.state.open ? (
            <EditEmergencyInfo
              child_id={this.props.childId}
              details={this.state.details}
              handleOpen={this.handleOpen}
              open={this.state.open}
              handleClose={this.handleClose}
              getContactData={this.getContactData}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
