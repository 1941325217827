import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import { Modal } from "@material-ui/core"; // import { Button } from "@material-ui/core";import { Button, Form } from "react-bootstrap";
import { editPermissionSlip } from "../../../crud/auth.crud";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form,Button } from "react-bootstrap";
// import moment from 'moment'
const paper = {
  position: "absolute",
  width: 400,
  backgroundColor: "#fff",
  boxShadow: 5,
  padding: 25,
  outline: "none",
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`,
};

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

const emptyState = {
  slip_name: "",
  program_id: "0",
  start_date: "",
  end_date: "",
};
function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}
// function GetDateFormat(date) {
//            var month = (date.getMonth() + 1).toString();
//            month = month.length > 1 ? month : '0' + month;
//            var day = date.getDate().toString();
//            day = day.length > 1 ? day : '0' + day;
//            return date.getFullYear() + '-' + month + '-' + day;
//        }
export default class EditPermissionSlip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      slip_name: props.editData.name,
      program_id: props.editData.program_id,
      start_date: new Date(props.editData.start_date),
      end_date: new Date(props.editData.end_date),
    };
  }
  setStartDate = (date) => {
    this.setState({ start_date: date });
  };

  setEndDate = (date) => {
    this.setState({ end_date: date });
  };

  onChangeHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  callbacks = () => {
    this.props.handleClose();
    this.props.getPermissionSlips();
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { slip_name, start_date, end_date, program_id } = this.state;
    let result = await editPermissionSlip(
      slip_name,
      convert(start_date),
      convert(end_date),
      program_id,
      this.props.editData.id
    );
    if (result.status === 200) {
      swal("Permission Slip updated Successfully!", "", "success");
      this.setState(emptyState, this.callbacks);
    } else {
      swal("Oops!", "Please try again!", "error");
    }
  };

  render() {
    const { program_id, start_date, end_date } = this.state;
    const { programs } = this.props;
    return (
      <div>
        {/* <Typography gutterBottom>
          Click to get the full Modal experience!
        </Typography> */}
        {/* <Button onClick={this.props.handleOpen}>Open Modal</Button> */}
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.props.open}
          onClose={this.props.handleClose}
        >
          <div style={paper}>
            <div className="d-flex justify-content-between">
              <span>
                {" "}
                <Typography variant="h6" id="modal-title">
                  Edit Permission Slip
                </Typography>
              </span>
              <span>
                {" "}
                <i
                  className="fas fa-times"
                  onClick={this.props.handleClose}
                ></i>
              </span>
            </div>

            <Form className="mt-4" onSubmit={this.handleSubmit}>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>Permission Slip Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Name"
                  name="slip_name"
                  defaultValue={
                    this.props.editData
                      ? this.props.editData.name
                      : "Enter Name"
                  }
                  onChange={this.onChangeHandle}
                />
              </Form.Group>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>Program Name</Form.Label>
                <Form.Control
                  required
                  as="select"
                  name="program_id"
                  defaultValue={
                    this.props.editData
                      ? this.props.editData.program_id
                      : program_id
                  }
                  onChange={this.onChangeHandle}
                >
                  <option>0</option>
                  {programs &&
                    programs.map((el, i) => (
                      <option value={el.programID} key={el.id}>
                        {el.program}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>

              <Form.Group className="form-group" controlId="formBasicPassword">
                <Form.Label>Start Date</Form.Label>
                <div>
                  {" "}
                  <DatePicker
                    selected={start_date}
                    selectsStart
                    startDate={start_date}
                    endDate={end_date}
                    placeholderText="Start Date"
                    className="form-control dateWidth"
                    onChange={(date) => this.setStartDate(date)}
                    fullWidth
                    showYearDropdown
                    scrollableMonthYearDropdown
                    // maxDate={new Date(this.props.editData.start_date)}
                  />
                </div>
              </Form.Group>
              <Form.Group className="form-group" controlId="formBasicPassword">
                <Form.Label>End Date</Form.Label>
                <div>
                  <DatePicker
                    selected={end_date}
                    selectsEnd
                    startDate={start_date}
                    // endDate={new Date(this.props.editData.end_date)}
                    // minDate={new Date(this.props.editData.start_date)}
                    minDate={start_date}
                    onChange={(date) => this.setEndDate(date)}
                    placeholderText="End Date"
                    className="form-control dateWidth"
                    disabled={start_date ? false : true}
                    showYearDropdown
                    scrollableMonthYearDropdown
                  />
                </div>
              </Form.Group>
              <div className="text-center form-group">
                <Button type="submit" style={btnColor}>
                  Update Permission Slip
                </Button>
              </div>
            </Form>

            {/* <AddSpecialEvent /> */}
          </div>
        </Modal>
      </div>
    );
  }
}
