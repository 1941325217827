import React, { Component } from "react";
import { Typography } from "@material-ui/core"; // import { Modal } from "@material-ui/core";// import { Button } from "@material-ui/core";import { Button, Form, Col } from "react-bootstrap";
import "../mentor.css";
import {
  getMentorNotes,
  addMentorNotes,
  callApi,
} from "../../../crud/auth.crud";
import swal from "sweetalert";
import axios from "axios";
import { serverUrl } from "../../../../_metronic/utils/api";
import { Button, Col, Form, Row } from "react-bootstrap";
import withRouter from "../../../router/withRouter";

const Header = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + localStorage.getItem("token"),
};

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};
class Notes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notes: [],
      noteText: "",
      mentorID: this.props.location.state.detail,
      note_id: "",
      user_id: "",
      editNote: "",
    };
  }

  async componentDidMount() {
    await this.getNote();
  }

  getNote = async () => {
    const { mentorID } = this.state;
    try {
      const result = await getMentorNotes(mentorID);
      if (result.status === 200) {
        this.setState({
          notes: result.data.data.data,
          mentorName: result.data.mentor_name,
        });
      } else {
        swal("Oops!", "Please try again!", "error");
      }
    } catch (err) {
      if (err.response.status == 401) {
        swal("", "Unauthorized!", "error");
        localStorage.clear();
        this.props.navigate("/auth/login");
        swal.close();
      } else {
        swal("Oops!", "Network Error!", "error");
      }
    }
  };

  onChangeHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitNote = async (e) => {
    e.preventDefault();
    const { mentorID, noteText } = this.state;
    if (noteText.length >= 10) {
      try {
        let result = await addMentorNotes(mentorID, noteText);
        if (result.status === 200) {
          this.setState({ noteText: "" }, () => this.getNote());
          swal("Note Added Successfully!", "", "success");
        } else {
          swal("Oops!", "Please try again!", "error");
        }
      } catch {
        this.setState({ noteText: "" }, () => this.getNote());
        swal("Oops!", "Network Error!", "error");
      }
    } else {
      swal("Oops!", "Please select atleast 10 character!", "error");
    }
  };

  deleteUser = async (id, userId) => {
    try {
      const result = await callApi("delete-mentor-note", {
        note_id: id,
        user_id: userId,
      });
      if (result.status === 200) {
        swal("Note Deleted Successfully!", "", "success");
        this.getNote();
      } else {
        swal("Oops!", "Please Try Again!!", "error");
      }
    } catch {
      swal("Oops!", "Network Error!", "error");
    }
  };
  deleteNoteConfirm = (id, userId) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.deleteUser(id, userId);
        // swal("Data has been deleted!", {
        //   icon: "success",
        // });
      }
    });
  };

  editNote = async (note_id, user_id) => {
    try {
      const result = await axios.post(
        `${serverUrl}edit-mentor-note`,
        { note_id, user_id },
        {
          headers: Header,
        }
      );
      if (result.status === 200) {
        let data = result.data.date;
        this.setState({
          note_id: data.id,
          user_id: data.inserted_by_id,
          editNote: data.note,
          isEdit: true,
        });
      } else {
        swal("Oops!", "Please Try Again!!", "error");
      }
    } catch {
      swal("Oops!", "Network Error!", "error");
    }
  };

  updateNote = async (e) => {
    e.preventDefault();
    const { note_id, user_id, editNote } = this.state;
    try {
      const result = await callApi("update-mentor-note", {
        note_id: note_id,
        user_id: user_id,
        note: editNote,
      });
      if (result.status === 200) {
        swal("Note Updated Successfully!", "", "success");
        this.setState({ isEdit: false }, () => this.getNote());
      } else {
        swal("Oops!", "Please Try Again!!", "error");
      }
    } catch {
      swal("Oops!", "Network Error!", "error");
    }
  };

  render() {
    const { isEdit } = this.state;

    return (
      <>
        <div className="kt-portlet kt-portlet--height-fluid">
          <div className="kt-portlet__body">
            <div className="row">
              <div className="col-xl-4">
                <div className="d-flex justify-content-between borderBottom">
                  <span>
                    <Typography variant="h6" id="modal-title">
                      Note List of {this.state.mentorName}
                    </Typography>
                  </span>
                </div>

                <Form
                  className="mt-4"
                  onSubmit={!isEdit ? this.submitNote : this.updateNote}
                >
                  {this.state.notes &&
                    this.state.notes.map((val, index) => (
                      <Form.Group
                        className={
                          index + 1 === this.state.notes.length
                            ? "borderBottom marginBottom"
                            : "borderBottom"
                        }
                        key={val.id}
                      >
                        <Form.Label>
                          {/* Here are the Notes. Please have a look */}
                          {val.note}
                        </Form.Label>
                        <div>
                          <span>
                            <i className="fas fa-sticky-note iconColor"></i>
                            &nbsp;
                            <span className="iconColor">
                              {val.day},{val.date}
                            </span>
                          </span>
                          &nbsp; &nbsp;
                          <span>
                            {" "}
                            <i className="fas fa-clock iconColor"></i>&nbsp;{" "}
                            <span className="iconColor">{val.time}</span>{" "}
                          </span>
                          &nbsp; &nbsp;{" "}
                          <span className="iconColor">
                            {" "}
                            Name : &nbsp; {val.inserted_by}
                          </span>
                          {localStorage.getItem("userId") ===
                            val.inserted_by_id.toString() && (
                            <span className="iconColor">
                              <i
                                className="fas fa-trash iconColor ml-2"
                                title="Delete note"
                                onClick={() =>
                                  this.deleteNoteConfirm(
                                    val.id,
                                    val.inserted_by_id
                                  )
                                }
                              ></i>
                            </span>
                          )}
                          {localStorage.getItem("userId") ===
                            val.inserted_by_id.toString() && (
                            <span className="iconColor">
                              <i
                                className="fas fa-edit iconColor ml-2"
                                title="Edit note"
                                onClick={() =>
                                  this.editNote(val.id, val.inserted_by_id)
                                }
                              ></i>
                            </span>
                          )}
                        </div>
                      </Form.Group>
                    ))}

                  <Form.Group>
                    <Row>
                      <Col md={10}>
                        {!isEdit ? (
                          <Form.Control
                            type="text"
                            required
                            placeholder="Write new note to add (minimum 10 characters)"
                            name="noteText"
                            onChange={this.onChangeHandle}
                            value={this.state.noteText}
                            minLength="10"
                          />
                        ) : (
                          <Form.Control
                            type="text"
                            required
                            placeholder="Write new note to add (minimum 10 characters)"
                            name="editNote"
                            onChange={this.onChangeHandle}
                            value={this.state.editNote}
                            minLength="10"
                          />
                        )}
                      </Col>
                      <Col md={2}>
                        <Button type="submit" style={btnColor}>
                          {!isEdit ? "Save" : "Update"}
                        </Button>
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
        </div>{" "}
      </>
    );
  }
}

export default withRouter(Notes);
