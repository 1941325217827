import React, { Component } from "react";
import { FormControlLabel, Modal, Radio } from "@material-ui/core"; // import { Button } from "@material-ui/core";import { Button, Form } from "react-bootstrap";
import { callApi, fetchData } from "../../crud/auth.crud";
import swal from "sweetalert";
import "./childProfile.css";
import { RadioGroup, Typography } from "@material-ui/core";
import { Form, Button } from "react-bootstrap";
import withRouter from "../../router/withRouter";

const paper = {
  position: "absolute",
  width: "80%",
  backgroundColor: "#fff",
  boxShadow: 5,
  padding: 25,
  overflow: "auto",
  height: "100%",
  display: "block",
  outline: "none",
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`,
};

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

const days = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
];

class AddGrades extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      gradeVal: "",
      subjectArr: [],
      markingPeriodVal: "",
      schoolYearVal: "",
      missed: "0",
      late: "0",
      subjects: [],
      years: [],
    };
  }

  async componentDidMount() {
    await this.getAllSubjects();
    await this.getAllSchoolYears();
  }

  getAllSubjects = async () => {
    try {
      let result = await fetchData("get-subjects");
      if (result.status === 200) {
        this.setState({ subjects: result.data.date });
      } else {
        swal("Oops!", "Please try again!", "error");
      }
    } catch (err) {
      if (err.response.status == 401) {
        swal("", "Unauthorized!", "error");
        localStorage.clear();
        this.props.navigate("/auth/login");
        swal.close();
      } else {
        swal("Oops!", "Network Error!", "error");
      }
    }
  };

  getAllSchoolYears = async () => {
    try {
      let result = await fetchData("get-school-years");
      if (result.status === 200) {
        this.setState({ years: result.data.data });
      } else {
        swal("Oops!", "Please try again!", "error");
      }
    } catch (err) {
      if (err.response.status == 401) {
        swal("", "Unauthorized!", "error");
        localStorage.clear();
        this.props.navigate("/auth/login");
        swal.close();
      } else {
        swal("Oops!", "Network Error!", "error");
      }
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();
    let child_id = this.props.child_id;
    const {
      gradeVal,
      subjectArr,
      markingPeriodVal,
      schoolYearVal,
      missed,
      late,
    } = this.state;
    if (
      child_id &&
      gradeVal &&
      markingPeriodVal &&
      schoolYearVal &&
      subjectArr.length > 0
    ) {
      try {
        let grades_data = JSON.stringify(subjectArr);
        const payload = {
          child_id,
          grade: gradeVal,
          grades_data,
          marking_period: markingPeriodVal,
          school_year: schoolYearVal,
          missed,
          late,
        };
        let result = await callApi("add-grade", payload);
        if (result && result.status === 200) {
          swal("Added Successfully!", "", "success");
          this.props.handleClose();
          if (this.props.key_type === "grade_table") {
            this.props.getGradeData();
          } else {
            this.props.getChild();
          }
          // this.setState(emptyState);
        }
      } catch {
        swal(
          "Oops!",
          "Network Error! Please try again after some time.",
          "error"
        );
      }
    } else {
      swal("Required!", " Please Select All Fields!", "");
    }
  };

  onChangeHandle = (e) => {
    this.setState({ gradeVal: e.target.value });
  };

  handleChange = (event, value) => {
    this.setState({ [event.target.name]: value });
  };

  handleChangeMissed = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeSubject = (event, value) => {
    let newArr = [...this.state.subjectArr];
    let finalArr = newArr.filter((ele) => ele.Subject !== event.target.name);
    finalArr.push({ Subject: event.target.name, Value: value });
    this.setState({ subjectArr: finalArr });
  };

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    const { missed, late } = this.state;
    let gradeArr = [
      { grade: "K", val: "0" },
      { grade: "1", val: "1" },
      { grade: "2", val: "2" },
      { grade: "3", val: "3" },
      { grade: "4", val: "4" },
      { grade: "5", val: "5" },
      { grade: "6", val: "6" },
      { grade: "7", val: "7" },
      { grade: "8", val: "8" },
      { grade: "9", val: "9" },
      { grade: "10", val: "10" },
      { grade: "11", val: "11" },
      { grade: "12", val: "12" },
    ];
    let markingPeriod = ["M1", "M2", "M3", "M4", "Final"];
    let subjectGrade = [
      "A+",
      "A",
      "A-",
      "B+",
      "B",
      "B-",
      "C+",
      "C",
      "C-",
      "D+",
      "D",
      "D-",
      "F",
      "Pass",
    ];
    return (
      <div>
        {/* <Typography gutterBottom>
          Click to get the full Modal experience!
        </Typography> */}
        {/* <Button onClick={this.props.handleOpen}>Open Modal</Button> */}
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.props.open}
          onClose={this.props.handleClose}
        >
          <div style={paper}>
            <div className="d-flex justify-content-between">
              <span>
                <Typography variant="h6" id="modal-title">
                  Add Grades to Profile
                </Typography>
              </span>
              <span>
                <i
                  className="fas fa-times"
                  onClick={this.props.handleClose}
                ></i>
              </span>
            </div>

            <Form className="mt-4" onSubmit={this.onSubmit}>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label className="fw-bold">Grade</Form.Label>
                <RadioGroup
                  value={this.state.gradeVal}
                  name="gradeVal"
                  className="radioButtonInline"
                  onChange={this.handleChange}
                >
                  {gradeArr.map((choice, i) => (
                    <FormControlLabel
                      value={choice.val}
                      key={i}
                      control={<Radio />}
                      label={choice.grade}
                    />
                  ))}
                </RadioGroup>
              </Form.Group>

              <Form.Group controlId="formBasicPassword" className="form-group">
                <Form.Label className="fw-bold">Marking Period</Form.Label>
                <RadioGroup
                  value={this.state.markingPeriodVal}
                  name="markingPeriodVal"
                  className="radioButtonInline"
                  onChange={this.handleChange}
                >
                  {markingPeriod.map((choice, i) => (
                    <FormControlLabel
                      value={choice}
                      key={i}
                      control={<Radio />}
                      label={choice}
                    />
                  ))}
                </RadioGroup>
              </Form.Group>
              <Form.Group controlId="formBasicPassword" className="form-group">
                <Form.Label className="fw-bold">School Year</Form.Label>
                <RadioGroup
                  value={this.state.schoolYearVal}
                  name="schoolYearVal"
                  className="radioButtonInline"
                  onChange={this.handleChange}
                >
                  {this.state.years &&
                    this.state.years.map((choice, i) => (
                      <FormControlLabel
                        value={choice}
                        key={i}
                        control={<Radio />}
                        label={choice}
                      />
                    ))}
                </RadioGroup>
              </Form.Group>
              {this.state.subjects &&
                this.state.subjects.map((sub, i) => (
                  <Form.Group controlId="formBasicPassword" key={i}>
                    <Form.Label className="fw-bold">{sub.subject}</Form.Label>
                    <RadioGroup
                      value={this.state.subjectVal}
                      name={sub.subject}
                      key={i}
                      className="radioButtonInline"
                      onChange={this.handleChangeSubject}
                    >
                      {subjectGrade.map((choice, i) => (
                        <FormControlLabel
                          value={choice}
                          key={i}
                          control={<Radio />}
                          label={choice}
                        />
                      ))}
                    </RadioGroup>
                  </Form.Group>
                ))}
              <Form.Group controlId="formBasicPassword" className="form-group">
                <Form.Label className="fw-bold">
                  Days Missed This Year
                </Form.Label>
                <Form.Control
                  as="select"
                  value={missed}
                  name="missed"
                  onChange={this.handleChangeMissed}
                >
                  {days.map((day, i) => (
                    <option value={day} key={i}>
                      {day}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formBasicPassword" className="form-group">
                <Form.Label className="fw-bold">
                  Days Tardy This Year
                </Form.Label>
                <Form.Control
                  as="select"
                  value={late}
                  name="late"
                  onChange={this.handleChangeMissed}
                >
                  {days.map((day, i) => (
                    <option value={day} key={i}>
                      {day}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <div className="text-center form-group">
                <Button type="submit" style={btnColor}>
                  Add Grades!
                </Button>
              </div>
            </Form>

            {/* <AddSpecialEvent /> */}
          </div>
        </Modal>
      </div>
    );
  }
}


export default withRouter(AddGrades);