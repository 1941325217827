import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Form, Container, Col, Row } from "react-bootstrap";
import "./reports.css";
import { fetchData, callApi } from "../../crud/auth.crud";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import withRouter from "../../router/withRouter";
import { validateInput } from "../childProfile/clone";

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

const maxWidth = {
  maxWidth: "750px",
};

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

let errorColor = {
  color: "red",
};
class KidReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      program: [],
      oneTimeProg: [],
      oneTimeProgVal: [],
      from_grade: "",
      to_grade: "",
      start_date: "",
      end_date: "",
      ptkids: "allkids",
      moved: "nomoved",
      grade: "yes",
      age: "yes",
      building: "yes",
      street: "yes",
      emergency: "yes",
      sort: "bldg",
      loader: false,
    };
  }

  setStartDate = (date) => {
    this.setState({ start_date: date });
  };

  setEndDate = (date) => {
    this.setState({ end_date: date });
  };

  validate = () => {
    let { program, oneTimeProgVal, start_date, end_date } = this.state;
    let errorProgram = "";
    let errorOneTimeProg = "";
    let errorstartDate = "";
    let errorendDate = "";
    let formIsValid = true;

    if (!program.length) {
      formIsValid = false;
      errorProgram = "(Please select core program *)";
    }
    if (!oneTimeProgVal.length) {
      formIsValid = false;
      errorOneTimeProg = "(Please select one time program *)";
    }
    if (!start_date) {
      formIsValid = false;
      errorstartDate = "(Please select start date *)";
    }
    if (!end_date) {
      formIsValid = false;
      errorendDate = "(Please select end date *)";
    }
    this.setState({
      errorProgram,
      errorOneTimeProg,
      errorstartDate,
      errorendDate,
    });
    return formIsValid;
  };

  async componentDidMount() {
    await this.oneTimePrograms();
  }

  oneTimePrograms = async (page) => {
    try {
      const result = await fetchData("onetime-program");
      if (result.status === 200) {
        this.setState({ oneTimeProg: result.data.data });
      }
    } catch (e) {
      swal("Oops!", "Error in Fetching Events!", "error");
    }
  };

  onChangeHandle = (e) => {
    var options = e.target.options;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({ [e.target.name]: value });
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      ptkids,
      moved,
      to_grade,
      from_grade,
      oneTimeProgVal,
      program,
      start_date,
      end_date,
      age,
      grade,
      building,
      street,
      emergency,
      sort,
    } = this.state;

    let payload = {
      ptkids,
      moved,
      gradestart: from_grade,
      gradeend: to_grade,
      program: program,
      perspectives: oneTimeProgVal,
      start_date: convert(start_date),
      end_date: convert(end_date),
      age,
      grade,
      building,
      street,
      emergency,
      sort,
    };
    console.log("🚀 ~ KidReport ~ handleSubmit= ~ payload:", payload)

    if (this.validate())
      try {
        await this.setState({ loader: true });
        let result = await callApi("grade-report-builder", payload);
        if (result.status === 200) {
          // swal("Report Build Successfully!", "", "success");
          this.setState(
            {
              from_grade: "",
              to_grade: "",
              start_date: "",
              end_date: "",
              ptkids: "allkids",
              moved: "nomoved",
              grade: "yes",
              age: "yes",
              building: "yes",
              street: "yes",
              emergency: "yes",
              sort: "bldg",
              program: [],
              oneTimeProgVal: [],
              loader: false,
            },
            () => {
              console.log("*******", result.data.data);
              this.props.navigate("/kid-report-table", {
                state: {
                  data: result.data.data || [],
                  print: result.data.print,
                },
              });
            }
          );
        } else {
          this.setState({ loader: false });
          swal("Oops!", "Please try again!", "error");
        }
      } catch {
        this.setState({ loader: false });
        swal("Oops!", "Please try again!", "error");
      }
  };

  render() {
    const {
      program,
      oneTimeProg,
      oneTimeProgVal,
      from_grade,
      to_grade,
      start_date,
      end_date,
      ptkids,
      moved,
      sort,
      age,
      grade,
      building,
      street,
      emergency,
    } = this.state;

    return (
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              Kids Based Report Generator
            </h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <p>
            The Kid Based Report Generator delivers custom reports focused on
            building lists of kids.
          </p>
          <div className="row">
            <div className="col-md-12">
              <div className="kt-section">
                <div className="kt-section__content">
                  <h5 className="borderDown">Which Kids</h5>
                  <Container className="mt-4" style={maxWidth}>
                    <Form onSubmit={this.handleSubmit}>
                      <Form.Group
                        className="form-group"
                        as={Row}
                        controlId="formHorizontalEmail"
                      >
                        <Form.Label column sm={4}>
                          Only include students in PT or includes all students?
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            as="select"
                            value={ptkids}
                            name="ptkids"
                            onChange={this.onChangeHandler}
                          >
                            <option value="allkids">All Students</option>
                            <option value="pt">Students in PT only</option>
                          </Form.Control>{" "}
                        </Col>
                      </Form.Group>

                      <Form.Group
                        className="form-group"
                        as={Row}
                        controlId="formHorizontalPassword"
                      >
                        <Form.Label column sm={4}>
                          Include students we've lost touch?
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            as="select"
                            name="moved"
                            value={moved}
                            onChange={this.onChangeHandler}
                          >
                            <option value="nomoved">
                              Exclude students that have moved and we've lost
                              touch
                            </option>
                            <option value="yesmoved">
                              Include all students
                            </option>
                          </Form.Control>{" "}
                        </Col>
                      </Form.Group>
                      <Form.Group
                        className="form-group"
                        as={Row}
                        controlId="formHorizontalPassword"
                      >
                        <Form.Label column sm={4}>
                          Grades to include
                        </Form.Label>
                        <Col sm={4}>
                          <div className="d-flex">
                            {" "}
                            <span>
                              {" "}
                              <Form.Control
                                as="select"
                                name="from_grade"
                                value={from_grade}
                                onChange={this.onChangeHandler}
                              >
                                <option value="">No preference</option>
                                <option value="0">Kindergarten</option>
                                <option value="1">1st Grade</option>
                                <option value="2">2nd Grade</option>
                                <option value="3">3rd Grade</option>
                                <option value="4">4th Grade</option>
                                <option value="5">5th Grade</option>
                                <option value="6">6th Grade</option>
                                <option value="7">7th Grade</option>
                                <option value="8">8th Grade</option>
                                <option value="9">9th Grade</option>
                                <option value="10">10th Grade</option>
                                <option value="11">11th Grade</option>
                                <option value="12">12th Grade</option>
                                <option value="13">Older</option>
                              </Form.Control>
                            </span>
                            <span className="px-4 ms-4 py-2">to</span>
                          </div>
                        </Col>
                        <Col sm={4}>
                          <Form.Control
                            as="select"
                            name="to_grade"
                            value={to_grade}
                            onChange={this.onChangeHandler}
                          >
                            <option value="">No preference</option>
                            <option value="0">Kindergarten</option>
                            <option value="1">1st Grade</option>
                            <option value="2">2nd Grade</option>
                            <option value="3">3rd Grade</option>
                            <option value="4">4th Grade</option>
                            <option value="5">5th Grade</option>
                            <option value="6">6th Grade</option>
                            <option value="7">7th Grade</option>
                            <option value="8">8th Grade</option>
                            <option value="9">9th Grade</option>
                            <option value="10">10th Grade</option>
                            <option value="11">11th Grade</option>
                            <option value="12">12th Grade</option>
                            <option value="13">Older</option>
                          </Form.Control>{" "}
                        </Col>
                      </Form.Group>
                      <Form.Group
                        className="form-group"
                        as={Row}
                        controlId="formHorizontalPassword"
                      >
                        <Form.Label column sm={4}>
                          Involved in core program(hold CTR(Win) or Command(Mac)
                          to select multiple)
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            as="select"
                            multiple
                            name="program"
                            required
                            value={program}
                            onChange={this.onChangeHandle}
                          >
                            <option value="7999">All Programs</option>
                            <option value="12">5 Loaves</option>
                            <option value="6">Kid Power</option>
                            <option value="2">Primetime</option>
                            <option value="4">Primetime Hoops</option>
                            <option value="3">Primetime Junior</option>
                            <option value="10">Primetime Senior</option>
                            <option value="11">XLR8</option>
                            <option value="15">Mitera</option>
                            <option value="8999">Exclude Core Programs</option>
                          </Form.Control>
                          <span style={errorColor}>
                            {this.state.errorProgram}
                          </span>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        className="form-group"
                        as={Row}
                        controlId="formHorizontalPassword"
                      >
                        <Form.Label column sm={4}>
                          Involved in one time program(hold CTR(Win) or
                          Command(Mac) to select multiple)
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            as="select"
                            multiple
                            name="oneTimeProgVal"
                            required
                            value={oneTimeProgVal}
                            onChange={this.onChangeHandle}
                          >
                            <option value="7999">All Programs</option>
                            {oneTimeProg &&
                              oneTimeProg.map((el) => (
                                <option key={el.programID} value={el.programID}>
                                  {el.program}
                                </option>
                              ))}
                            <option value="8999">Exclude Core Programs</option>
                          </Form.Control>{" "}
                          <span style={errorColor}>
                            {this.state.errorOneTimeProg}
                          </span>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        className="form-group"
                        as={Row}
                        controlId="formHorizontalPassword"
                      >
                        <Form.Label column sm={4}>
                          Date range students were involved in program(no data
                          specified includes all dates)
                        </Form.Label>
                        <Col sm={4}>
                          <DatePicker
                            selected={start_date}
                            onChange={(date) => this.setStartDate(date)}
                            selectsStart
                            startDate={start_date}
                            endDate={end_date}
                            placeholderText="Start Date"
                            className="form-control"
                            showYearDropdown
                            scrollableMonthYearDropdown
                            maxDate={new Date()}
                            onChangeRaw={(event) => {
                              if (
                                event.target.value &&
                                !validateInput(event.target.value)
                              ) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <span style={errorColor}>
                            {this.state.errorstartDate}
                          </span>
                        </Col>
                        <Col sm={4}>
                          <DatePicker
                            selected={end_date}
                            onChange={(date) => this.setEndDate(date)}
                            selectsEnd
                            startDate={start_date}
                            endDate={end_date}
                            minDate={start_date}
                            maxDate={new Date()}
                            // maxDate={new Date()}
                            placeholderText="End Date"
                            className="form-control"
                            disabled={start_date ? false : true}
                            showYearDropdown
                            scrollableMonthYearDropdown
                            onChangeRaw={(event) => {
                              if (
                                event.target.value &&
                                !validateInput(event.target.value)
                              ) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <span style={errorColor}>
                            {this.state.errorendDate}
                          </span>
                        </Col>
                      </Form.Group>
                    </Form>
                  </Container>
                  <h5 className="borderDown">What to Print</h5>
                  <Container className="mt-4" style={maxWidth}>
                    <Form>
                      <Form.Group
                        className="form-group"
                        as={Row}
                        controlId="formHorizontalEmail"
                      >
                        <Form.Label column sm={4}>
                          Include grade in report?
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            as="select"
                            name="grade"
                            value={grade}
                            onChange={this.onChangeHandler}
                          >
                            <option value="yes">yes</option>
                            <option value="no">no</option>
                          </Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        className="form-group"
                        as={Row}
                        controlId="formHorizontalEmail"
                      >
                        <Form.Label column sm={4}>
                          Include age in report?
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            as="select"
                            name="age"
                            value={age}
                            onChange={this.onChangeHandler}
                          >
                            <option value="yes">yes</option>
                            <option value="no">no</option>
                          </Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        className="form-group"
                        as={Row}
                        controlId="formHorizontalEmail"
                      >
                        <Form.Label column sm={4}>
                          Include Bldg and Apt in report?
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            as="select"
                            name="building"
                            value={building}
                            onChange={this.onChangeHandler}
                          >
                            <option value="yes">yes</option>
                            <option value="no">no</option>
                          </Form.Control>{" "}
                        </Col>
                      </Form.Group>
                      <Form.Group
                        className="form-group"
                        as={Row}
                        controlId="formHorizontalEmail"
                      >
                        <Form.Label column sm={4}>
                          Include street address in report?
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            as="select"
                            name="street"
                            value={street}
                            onChange={this.onChangeHandler}
                          >
                            <option value="yes">yes</option>
                            <option value="no">no</option>
                          </Form.Control>{" "}
                        </Col>
                      </Form.Group>
                      <Form.Group
                        className="form-group"
                        as={Row}
                        controlId="formHorizontalEmail"
                      >
                        <Form.Label column sm={4}>
                          Include emergency contact in report?
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            as="select"
                            name="emergency"
                            value={emergency}
                            onChange={this.onChangeHandler}
                          >
                            <option value="yes">yes</option>
                            <option value="no">no</option>
                          </Form.Control>{" "}
                        </Col>
                      </Form.Group>
                      <Form.Group
                        className="form-group"
                        as={Row}
                        controlId="formHorizontalEmail"
                      >
                        <Form.Label column sm={4}>
                          Sort order ofreport?
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            as="select"
                            name="sort"
                            value={sort}
                            onChange={this.onChangeHandler}
                          >
                            <option value="bldg">
                              Building and then Apartment
                            </option>
                            <option value="first">First Name</option>
                            <option value="last">Last Name</option>
                            <option value="grade">Grade</option>
                          </Form.Control>{" "}
                        </Col>
                      </Form.Group>
                    </Form>
                  </Container>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="kt-section">
                <div className="kt-section__content text-center">
                  {this.state.loader ? (
                    <div
                      className="spinner-border text-warning loaderStyleMiteraPdf"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <Button
                      type="submit"
                      style={btnColor}
                      onClick={this.handleSubmit}
                    >
                      Build Report
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(KidReport);
