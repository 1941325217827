import React, { Component } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { Button } from "@material-ui/core";
import SubjectReportTable from "./subjectReportTable";
import { fetchDataWithPagination } from "../../../crud/auth.crud";
import swal from "sweetalert";
import Pagination from "react-js-pagination";

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
  color: "#fff",
};
function getItem(key) {
  return JSON.parse(localStorage.getItem(key));
}

const debounce = (fn, delay) => {
  let timer = null;
  return function (...args) {
    const context = this;
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
};

export default class SubjectReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      week: "",
      week_arr: [],
      total: null,
      per_page: null,
      current_page: 1,
      searchTxt: "",
      heading: "",
      loader: true,
    };
    this.getReports = debounce(this.getReports, 200);
  }

  async componentDidMount() {
    await this.getReports();
  }

  getReports = async (page) => {
    const program_id = getItem("progName").id;
    const { searchTxt } = this.state;
    if (program_id)
      try {
        let result = await fetchDataWithPagination(
          "subject-report",
          page ? page : 1,
          { program_id, child_name: searchTxt }
        );
        if (result.status === 200) {
          let DATA = result.data.success;
          this.setState({
            current_page: DATA.current_page,
            tableData: DATA.data,
            per_page: DATA.per_page,
            total: DATA.total,
            loader: false,
          });
        } else {
          this.setState({ loader: false });
          swal("Oops!", "Please try again!", "error");
        }
      } catch {
        this.setState({ loader: false });
        swal("Oops!", "Network Error!", "error");
      }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ current_page: pageNumber });
  };

  searchHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => this.getReports());
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.current_page !== this.state.current_page) {
      window.scrollTo(0, 0);
      this.getReports(this.state.current_page);
    }
  }

  render() {
    return (
      <div className="col-md-8">
        {/* <div className="kt-section"> */}
        <>
          <div className="kt-portlet kt-portlet--height-fluid">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">Subject Reports </h3>
              </div>
            </div>
            <div className="kt-portlet__body">
              <div className="row">
                <div className="col-md-12">
                  <div className="kt-section">
                    <div className="kt-section__content text-center">
                      <Row>
                        <Col md={10}>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search by name"
                              aria-label="Search"
                              name="searchTxt"
                              onChange={this.searchHandle}
                            />
                            <span
                              className="input-group-text amber lighten-3"
                              id="basic-text1"
                            >
                              <i
                                className="fas fa-search text-grey"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                     
                        </Col>
                        <Col md={2}>
                          <Button style={btnColor}>Search</Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="kt-section">
                    <div className="kt-section__content text-center">
                      {this.state.loader ? (
                        <div
                          className="spinner-border text-warning loaderStylegraduatePdf"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <SubjectReportTable
                          tableData={this.state.tableData}
                          {...this.props.history}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {!this.state.loader && this.state.total > 10 ? (
                  <Pagination
                    activePage={this.state.current_page}
                    itemsCountPerPage={10}
                    totalItemsCount={this.state.total}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)}
                    itemclassName="page-item"
                    linkclassName="page-link"
                  />
                ) : null}
              </div>
            </div>
          </div>
        </>
      </div>
    );
  }
}
