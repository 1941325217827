/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
// import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";
import Dashboard from "../pages/home/Dashboard";
import Login from "../pages/auth/Login";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ChildProfile from "../Components/childProfile";
import AddChild from "../Components/addChild/addChild";
import MentorProfile from "../Components/mentorProfile/mentorProfile";
import AddMentor from "../Components/addMentor/addMentor";
import Programs from "../Components/programs/KidPower/programs";
import Activity from "../Components/programs/KidPower/activity";
import ProgReports from "../Components/programs/KidPower/progReports";
import Scoreboard from "../Components/programs/KidPower/scoreboard";
import ProgramSetting from "../Components/programs/KidPower/programSetting";
import KPK from "../Components/programs/KidPower/KPK";
import Report from "../Components/reports/report";
import UserRegistration from "../Components/admin/user-registration";
import SessionManagement from "../Components/admin/session-management";
import List from "../Components/admin/program-dates/list";
import AdminTool from "../Components/admin/admin-tool";
import AddProgram from "../Components/admin/add-program";
import AddPermissionSlip from "../Components/admin/add-permission-slip";
import AddSubject from "../Components/admin/add-subject";
import Hours from "../Components/dashboardPages/hours";
import Mentoring from "../Components/dashboardPages/mentoring";
import Tutoring from "../Components/dashboardPages/tutoring";
import Perspective from "../Components/dashboardPages/perspective";
import Feeding from "../Components/dashboardPages/feeding";
import ChangeDate from "../Components/dashboardPages/changeDate";
import ProfileInfo from "../Components/childProfile/profileInfo";
import ChildSearchByProgram from "../Components/childProfile/childSearchByProgram";
import ChildSearchByDate from "../Components/childProfile/childSearchByDate";
import ChildSearchBySlip from "../Components/childProfile/childSearchBySlip";
import ChildSearchByAddress from "../Components/childProfile/childSearchByAddress";
import Mitera from "../Components/programs/Mitera";
import SpecialEvents from "../Components/programs/specialEvents";
import AttendanceForRPM from "../Components/programs/specialEvents/attendanceForRPM";
import ResetPassword from "../pages/auth/resetPassword";
import TutoringAnalysis from "../Components/reports/tutorigAnalysis";
import myRoutes from "../pages/home/myRoutes";
import Legal from "../pages/terms/Legal";
import Terms from "../pages/terms/Terms";
import ContactUs from "../pages/terms/ContactUs";
import ChurchList from "../Components/church/ChurchList";

// import Summary from "../Components/mentorProfile/Summary/summary";
// import Notes from "../Components/mentorProfile/Notes/notes";

export const RoutesList = ({ location, history }) => {
  console.log("🚀 ~ RoutesList ~ history:", location);
  // const lastLocation = useLastLocation();
  // routerHelpers.saveLastLocation(lastLocation);
  const {
    // isAuthorized,
    menuConfig,
    userLastLocation,
  } = useSelector(
    ({
      auth,
      // urls,
      builder: { menuConfig },
    }) => ({
      menuConfig,
      isAuthorized: auth.user != null,
      userLastLocation: routerHelpers.getLastLocation(),
    }),
    shallowEqual
  );

  const PrivateRoute = ({ children }) => {
    const token = localStorage.getItem("token");
    return token ? children : <Navigate to="/auth/login" />;
  };

  const PublicRoute = ({ children }) => {
    const token = localStorage.getItem("token");
    return !token ? children : <Navigate to="/dashboard" />;
  };

  // basename = "/CDI";

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <BrowserRouter>
      <LayoutContextProvider history={history} menuConfig={menuConfig}>
        <Routes>
          {myRoutes.map((item, i) => {
            return (
              <Route
                path={item.path}
                key={i}
                element={
                  <PrivateRoute>
                    <Layout>
                      <item.component />
                    </Layout>
                  </PrivateRoute>
                }
              />
            );
          })}
          {/* <Route path="/home" element={<Home />} /> */}
          {/* <Route
                    path="/dashboard"
                    element={
                      isLoggedIn ? (
                        <Dashboard />
                      ) : (
                        <Navigate to="/login"  />
                      )
                    }
                  /> */}
          {/* <Route path="" element={<Home />}>
                    <Route path="admin" element={<AdminApp />} />
                  </Route> */}
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route
            path="/dashboard/*"
            element={
              <PrivateRoute>
                <Layout>
                  <Dashboard />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/church"
            element={
              <PrivateRoute>
                <Layout>
                  <ChurchList />
                </Layout>
              </PrivateRoute>
            }
          />

    
          <Route
            path="/childProfile"
            element={
              <PrivateRoute>
                <Layout>
                  <ChildProfile />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/childProfile-2"
            element={
              <PrivateRoute>
                <Layout>
                  <ChildSearchByProgram />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/childProfile-3"
            element={
              <PrivateRoute>
                <Layout>
                  <ChildSearchByAddress />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/childProfile-4"
            element={
              <PrivateRoute>
                <Layout>
                  <ChildSearchBySlip />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/childProfile-5"
            element={
              <PrivateRoute>
                <Layout>
                  <ChildSearchByDate />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/addChild"
            element={
              <PrivateRoute>
                <Layout>
                  <AddChild />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/tutoringAnalysis"
            element={
              <PrivateRoute>
                <Layout>
                  <TutoringAnalysis />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/addMentor"
            element={
              <PrivateRoute>
                <Layout>
                  <AddMentor />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/mentorProfile"
            element={
              <PrivateRoute>
                <Layout>
                  <MentorProfile />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/reports"
            element={
              <PrivateRoute>
                <Layout>
                  <Report />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/adminUserRegistration"
            element={
              <PrivateRoute>
                <Layout>
                  <UserRegistration />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/progList/:tab"
            element={
              <PrivateRoute>
                <Layout>
                  <Mitera />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/sessionManagement"
            element={
              <PrivateRoute>
                <Layout>
                  <SessionManagement />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/adminProgDates/list"
            element={
              <PrivateRoute>
                <Layout>
                  <List />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/adminTool"
            element={
              <PrivateRoute>
                <Layout>
                  <AdminTool />
                </Layout>
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/profileInfo"
            element={
              <PrivateRoute>
                <Layout>
                  <ProfileInfo />
                </Layout>
              </PrivateRoute>
            }
          /> */}

          <Route
            path="/addAdminProgram"
            element={
              <PrivateRoute>
                <Layout>
                  <AddProgram />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/addSlip"
            element={
              <PrivateRoute>
                <Layout>
                  <AddPermissionSlip />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/addSubject"
            element={
              <PrivateRoute>
                <Layout>
                  <AddSubject />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/chruch"
            element={<Layout>{/* <AddChurm /> */}</Layout>}
          />
          <Route
            path="/hours"
            element={
              <PrivateRoute>
                <Layout>
                  {" "}
                  <Hours />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/mentoring"
            element={
              <PrivateRoute>
                <Layout>
                  {" "}
                  <Mentoring />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/specialEvents"
            element={
              <PrivateRoute>
                <Layout>
                  {" "}
                  <SpecialEvents />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/attendance-RPM"
            element={
              <PrivateRoute>
                <Layout>
                  {" "}
                  <AttendanceForRPM />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/tutoring"
            element={
              <PrivateRoute>
                <Layout>
                  {" "}
                  <Tutoring />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/perspective"
            element={
              <PrivateRoute>
                <Layout>
                  {" "}
                  <Perspective />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/feeding"
            element={
              <PrivateRoute>
                <Layout>
                  {" "}
                  <Feeding />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/changeDate/:tab"
            element={
              <PrivateRoute>
                <Layout>
                  {" "}
                  <ChangeDate />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/programs"
            element={
              <PrivateRoute>
                <Layout>
                  <Programs />
                </Layout>
              </PrivateRoute>
            }
          >
            <Route path="activity" element={<Activity />} />
            <Route path="progReports" element={<ProgReports />} />
            <Route path="scoreboard" element={<Scoreboard />} />
            <Route path="programSetting" element={<ProgramSetting />} />
            <Route path="KPK" element={<KPK />} />
          </Route>
          <Route path="/adminUserRegistration" element={<Dashboard />} />
          <Route path="/auth" element={<Navigate to="/auth/login" />} />

          <Route path="/legal" element={<Legal />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route
            path="/auth"
            element={
              <PublicRoute>
                <AuthPage />
              </PublicRoute>
            }
          >
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="contact-us" element={<ContactUs />} />

            <Route path="*" element={<Login />} />
          </Route>
        </Routes>
      </LayoutContextProvider>
    </BrowserRouter>
  );
};
