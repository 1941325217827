import React, { Component } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  withStyles,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { IconButton } from "@material-ui/core";
import axios from "axios";
import { serverUrl } from "../../../../_metronic/utils/api";
import swal from "sweetalert";
import { callApi } from "../../../crud/auth.crud";
import EditUser from "./editUser";

const editColor = {
  color: "#44bb87",
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

export default class UserTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      tableData: [],
      editData: "",
    };
  }
  deleteUser = async (id) => {
    try {
      const result = await callApi("delete-user", { user_id: id });
      if (result.status === 200) {
        swal("User Deleted Successfully!", "", "success");
        this.props.getUser();
      } else {
        swal("Oops!", "Please Try Again!!", "error");
      }
    } catch {
      swal("Oops!", "Network Error!", "error");
    }
  };
  deleteHandlerConfirm = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.deleteUser(id);
        // swal("Data has been deleted!", {
        //   icon: "success",
        // });
      }
    });
  };

  editHandle = async (id) => {
    try {
      const result = await axios.post(
        `${serverUrl}edit-user`,
        { user_id: id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (result.status === 200) {
        this.setState({ editData: result.data.data }, () => this.handleOpen());
      } else {
        swal("Oops!", "Please try again!!", "error");
      }
    } catch {
      swal("Oops!", "Network Error!!", "error");
    }
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  render() {
    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Existing User</StyledTableCell>
              <StyledTableCell>Existing Roles</StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.tableData &&
              this.props.tableData.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>
                    {row.firstName + " " + row.lastName}
                  </StyledTableCell>
                  <StyledTableCell>{row.role}</StyledTableCell>
                  <StyledTableCell align="center">
                    <i
                      className="far fa-edit"
                      style={editColor}
                      onClick={() => this.editHandle(row.userid)}
                    ></i>
                  </StyledTableCell>
                  <StyledTableCell>
                    {" "}
                    <IconButton
                      aria-label="Delete"
                      onClick={() => this.deleteHandlerConfirm(row.userid)}
                    >
                      <DeleteOutlineIcon style={{ color: "red" }} />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>

        {this.state.open ? (
          <EditUser
            handleOpen={this.handleOpen}
            editData={this.state.editData}
            open={this.state.open}
            getUser={this.props.getUser}
            handleClose={this.handleClose}
          />
        ) : null}
      </Paper>
    );
  }
}
