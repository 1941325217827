/* eslint-disable jsx-a11y/anchor-has-content,no-script-url,jsx-a11y/anchor-is-valid */
//import { toAbsoluteUrl } from "../../_metronic/utils/utils";
//import PortletHeaderDropdown from "../partials/content/CustomDropdowns/PortletHeaderDropdown";
import React, { Component } from "react";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { Badge } from "react-bootstrap";
import EditSpecialEvent from "./editSpecialEvent";
import moment from "moment";
import swal from "sweetalert";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { IconButton } from "@material-ui/core";
import { callApi } from "../../../crud/auth.crud";
import "./specialEvent.css";
import withRouter from "../../../router/withRouter";
const editColor = {
  color: "#44bb87",
};

class SpecialEventTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      editData: "",
    };
  }
  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  moveToAdjPage = (pageName, ID) => {
    this.props.navigate("/" + pageName, { state: { id: ID } });
  };

  deleteEvent = async (program_id) => {
    try {
      const result = await callApi("delete-specialevent", { program_id });
      if (result.status === 200) {
        swal("Event Deleted Successfully!", "", "success");
        this.props.getEvents();
      } else {
        swal("Oops!", "Please try again!!", "error");
      }
    } catch {
      swal("Oops!", "Network Error!!", "error");
    }
  };
  deleteHandlerConfirm = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.deleteEvent(id);
      }
    });
  };

  editHandle = async (program_id) => {
    try {
      const result = await callApi("edit-specialevent", { program_id });
      if (result.status === 200) {
        // swal("Permission Slip Deleted Successfully!","", "success");
        this.setState({ editData: result.data.data }, () => this.handleOpen());
      } else {
        swal("Oops!", "Please try again!!", "error");
      }
    } catch {
      swal("Oops!", "Network Error!!", "error");
    }
  };

  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <div className="kt-section">
              <div className="kt-section__content">
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Program</TableCell>
                        <TableCell>Loader</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Participants</TableCell>
                        <TableCell>Child Mentor Time(hours)</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.tableData &&
                        this.props.tableData.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell>
                              {row.program ? row.program : "-"}
                            </TableCell>
                            <TableCell className="colWrap">
                              {row.leader ? row.leader : "-"}
                            </TableCell>
                            <TableCell>
                              {row.startDate
                                ? moment(row.startDate).format("MM-DD-YYYY")
                                : "-"}
                            </TableCell>
                            <TableCell>
                              {row.endDate
                                ? moment(row.endDate).format("MM-DD-YYYY")
                                : "-"}
                            </TableCell>
                            <TableCell>
                              {row.participants ? row.participants : "-"}
                            </TableCell>
                            <TableCell>{row.hours ? row.hours : "-"}</TableCell>
                            <TableCell>
                              <Badge
                                pill
                                variant="warning"
                                style={{ color: "#fff", cursor: "grab" }}
                                onClick={() =>
                                  this.moveToAdjPage(
                                    "attendance-RPM",
                                    row.programID
                                  )
                                }
                              >
                                Attendance
                              </Badge>
                            </TableCell>
                            <TableCell>
                              {" "}
                              <i
                                className="far fa-edit"
                                style={editColor}
                                onClick={() => this.editHandle(row.programID)}
                              ></i>
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                aria-label="Delete"
                                onClick={() =>
                                  this.deleteHandlerConfirm(row.programID)
                                }
                              >
                                <DeleteOutlineIcon style={{ color: "red" }} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Paper>
              </div>
            </div>
            {this.state.open ? (
              <EditSpecialEvent
                editData={this.state.editData}
                handleOpen={this.handleOpen}
                open={this.state.open}
                handleClose={this.handleClose}
                getEvents={this.props.getEvents}
              />
            ) : null}
          </div>
        </div>
        {/* </Container> */}
      </>
    );
  }
}

export default withRouter(SpecialEventTable);
