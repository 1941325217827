import React from 'react';
import {Bar} from 'react-chartjs-2';
import { Typography } from "@material-ui/core";
import '../Components/dashboardPages/dashboard.css'
import 'chartjs-plugin-datalabels';



export default class PerspectiveBarriersChart extends React.Component {
  render() {
    return (
      <div className="kt-portlet kt-portlet--height-fluid">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">Perspective Barriers from Crime and Voilence</h3>
        </div>
        {/* <PortletHeaderDropdown /> */}
      </div>
      <div className="kt-portlet__body">
      <Typography variant="caption" display="block" className="mb-4 dashboardText">
    One of the greatest challanges seen with the kids of PT Barnum is how they handle conflict. Too quickly, the kids of PT will resort to confrontation and voilence. <br/>
    This creates the barriers to the kids thinking bigger, as they are harder to engage.
            </Typography>
        <Bar
          data={{
            labels: this.props.pers_barrier_crime_voilence && this.props.pers_barrier_crime_voilence.length > 0 ? this.props.pers_barrier_crime_voilence.map((val)=>val.location) : ['Connecticut', 'Fairfield', 'PT Barnum Area'],
            datasets: [
              {
                 label: 'Crimes per Square Mile',
                backgroundColor: '#f4b53f',
                // borderColor: 'rgba(0,0,0,1)',
                // borderWidth: 2,
                data: this.props.pers_barrier_crime_voilence && this.props.pers_barrier_crime_voilence.length > 0 ? this.props.pers_barrier_crime_voilence.map((val)=>val.visits) :[]
              },
            ]
          }}
          options={{
            plugins: {
              datalabels: {
                 display: true,
                 color: '#5c5a6d',
                 align:'end',
                 clamp:true
              }
           },
            title:{
              display:true,
              // text:'Average Rainfall per month',
              fontSize:10,
            },
            legend:{
              display:true,
              position:'right',

            }
          }}
        />
      </div>
    </div>
    
      
    );
  }
}

