import React, { Component } from "react";
import { Button, Form, Container, Col, Row } from "react-bootstrap";
import EditIcon from "@material-ui/icons/Edit";
import { IconButton } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./mentor.css";
import axios from "axios";
import { serverUrl } from "../../../_metronic/utils/api";
import swal from "sweetalert";
import DemoLogo from "../../images/demoDp.jpeg";
import {
  updateMentorInfo,
  getPermissionSlip,
  getChruchListRequest,
} from "../../crud/auth.crud";
import withRouter from "../../router/withRouter";
import { validateInput } from "../childProfile/clone";

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

const ethnicityArray = [
  "African American",
  "Latino/Hispanic",
  "Asian",
  "Caucasian",
  "Other",
];

class MentorPersonalInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mentorId: this.props.location.state.detail,
      first_name: "",
      last_name: "",
      date_of_birth: new Date(),
      male: false,
      female: false,
      file: null,
      ethnicity: "",
      slips: [],
      pSlip: "",
      slip_date: "",
      firstSlip: "",
      churchList: [],
    };
  }

  gender = (type) => {
    if (type === "male") {
      this.setState({ male: true, female: false });
    }
    if (type === "female") {
      this.setState({ male: false, female: true });
    }
  };

  getSlip = async () => {
    try {
      const result = await getPermissionSlip();
      if (result.status === 200) {
        this.setState({
          slips: result.data.data,
          firstSlip: result.data.data.length && result.data.data[0].id,
        });
      }
    } catch (err) {
      if (err.response.status == 401) {
        swal("", "Unauthorized!", "error");
        localStorage.clear();
        this.props.navigate("/auth/login");
        swal.close();
      } else {
        swal("Oops!", "Network Error!", "error");
      }
    }
  };
  getChruchList = async () => {
    try {
      const result = await getChruchListRequest();
      if (result.status === 200) {
        this.setState({ churchList: result.data.data, loader: false });
      }
    } catch {
      this.setState({ loader: false });
      swal("Oops!", "Network Error!", "error");
    }
  };

  async componentDidMount() {
    const { mentorId } = this.state;
    await this.getSlip();
    await this.getChruchList();

    try {
      const result = await axios.post(
        `${serverUrl}mentor-profile`,
        { mentor_id: mentorId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (result.status === 200) {
        let DATA = result.data.data;

        this.setState({
          first_name: DATA.firstName,
          last_name: DATA.lastName,
          date_of_birth: DATA.dateOfBirth,
          file: DATA.mentor_image,
          male: DATA.gender === "male" ? true : false,
          female: DATA.gender === "female" ? true : false,
          ethnicity: DATA.ethnicity ? DATA.ethnicity : "African American",
          mentor_id: DATA.id,
          slip_date: DATA.slip_date ? DATA.slip_date : new Date(),
          church: DATA.church,
          pSlip: DATA.permission_slip
            ? DATA.permission_slip
            : this.state.firstSlip,
        });
      } else {
        swal("Oops!", "Please try again!!", "error");
      }
    } catch (err) {
      if (err.response.status == 401) {
        swal("", "Unauthorized!", "error");
        localStorage.clear();
        this.props.navigate("/auth/login");
        swal.close();
      } else {
        swal("Oops!", "Network Error!", "error");
      }
    }
  }

  onSubmit = async (e) => {
    e.preventDefault();

    try {
      const {
        first_name,
        last_name,
        male,
        date_of_birth,
        fileData,
        ethnicity,
        mentor_id,
        pSlip,
        slip_date,
        church,
      } = this.state;

      let result = await updateMentorInfo(
        mentor_id,
        first_name,
        last_name,
        male ? "male" : "female",
        convert(date_of_birth),
        ethnicity,
        fileData,
        pSlip,
        convert(slip_date),
        church
      );
      if (result && result.status === 200) {
        swal("Updated Successfully!", "", "success");
        // this.setState(emptyState);
      } else {
        swal("Oops!", "Network Error!", "error");
      }
    } catch {
      swal(
        "Oops!",
        "Network Error! Please try again after some time.",
        "error"
      );
    }
  };

  imageUpload = (e) => {
    this.setState({
      file: URL.createObjectURL(e.target.files[0]),
      fileData: e.target.files[0],
    });
  };

  setBirthDate = (date) => {
    this.setState({ date_of_birth: date });
  };

  setSlipDate = (date) => {
    this.setState({ slip_date: date });
  };

  onChangeHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const {
      first_name,
      last_name,
      date_of_birth,
      ethnicity,
      pSlip,
      slip_date,
      churchList,
      church,
    } = this.state;

    return (
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__body">
          <div className="row">
            <h5>Mentor Personal Information</h5>
            <div className="kt-separator kt-separator--dashed"></div>
            <div className="col-xl-4">
              <Container style={{ maxWidth: "550px" }}>
                <Form onSubmit={this.onSubmit}>
                  <Form.Group
                    as={Row}
                    controlId="formHorizontalEmail"
                    className="form-group"
                  >
                    <input
                      id="myInput"
                      type="file"
                      accept="image/*"
                      onChange={this.imageUpload}
                      ref={(ref) => (this.upload = ref)}
                      style={{ display: "none" }}
                    />
                    <Form.Label column sm={4}>
                      Photo
                    </Form.Label>
                    <Col sm={8}>
                      <div className="photo">
                        <img
                          src={this.state.file ? this.state.file : DemoLogo}
                          width="50"
                          height="50"
                          alt="logo_img"
                        ></img>
                        <div className="icon">
                          <IconButton
                            aria-label="Delete"
                            onClick={(e) => this.upload.click()}
                          >
                            <EditIcon color="primary" />
                          </IconButton>
                        </div>
                      </div>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="formHorizontalEmail"
                    className="form-group"
                  >
                    <Form.Label column sm={4}>
                      First Name
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        type="text"
                        required
                        name="first_name"
                        value={first_name}
                        onChange={this.onChangeHandle}
                        placeholder="First Name"
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    controlId="formHorizontalPassword"
                    className="form-group"
                  >
                    <Form.Label column sm={4}>
                      Last Name
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        required
                        type="text"
                        name="last_name"
                        value={last_name}
                        onChange={this.onChangeHandle}
                        placeholder="Last Name"
                      />
                    </Col>
                  </Form.Group>
                  <fieldset>
                    <Row className="form-group">
                      <Col sm={4}>
                        <Form.Label>Gender</Form.Label>
                      </Col>

                      <Col sm={8}>
                        <Row className="ml-2">
                          <Col sm={3}>
                            <Form.Check
                              required
                              type="radio"
                              label="Male"
                              name="gender"
                              value="male"
                              checked={this.state.male}
                              id="formHorizontalRadios1"
                              onChange={() => this.gender("male")}
                            />
                          </Col>
                          <Col sm={3}>
                            <Form.Check
                              required
                              type="radio"
                              label="Female"
                              name="gender"
                              value="female"
                              checked={this.state.female}
                              id="formHorizontalRadios2"
                              onChange={() => this.gender("female")}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </fieldset>
                  <Form.Group
                    as={Row}
                    controlId="formHorizontalPassword"
                    className="form-group"
                  >
                    <Form.Label column sm={4}>
                      Date of Birth
                    </Form.Label>
                    <Col sm={8}>
                      <DatePicker
                        required
                        // value={date_of_birth}
                        selected={
                          date_of_birth ? new Date(date_of_birth) : new Date()
                        }
                        onChange={(date) => this.setBirthDate(date)}
                        selectsStart
                        startDate={date_of_birth}
                        // endDate={end_date}
                        placeholderText="DOB"
                        className="dateWidthMentorInfo form-control"
                        showYearDropdown
                        scrollableMonthYearDropdown
                        maxDate={new Date()}
                        onChangeRaw={(event) => {
                          if (
                            event.target.value &&
                            !validateInput(event.target.value)
                          ) {
                            event.preventDefault();
                          }
                        }}
                        // fullWidth
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="exampleForm.ControlSelect1"
                    className="form-group"
                  >
                    <Form.Label column sm={4}>
                      Ethnicity
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        as="select"
                        required
                        name="ethnicity"
                        value={ethnicity}
                        onChange={this.onChangeHandle}
                      >
                        {ethnicityArray.map((el, i) => (
                          <option key={i} value={el}>
                            {el}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="exampleForm.ControlSelect1"
                    className="form-group"
                  >
                    <Form.Label column sm={4}>
                      Church
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        as="select"
                        required
                        name="church"
                        placeholder="Select Church"
                        value={church}
                        onChange={this.onChangeHandle}
                      >
                        <option value="" selected disabled>
                          Select Church
                        </option>
                        {this.state.churchList &&
                          this.state.churchList.map((el, i) => (
                            <option key={el.id} value={el.church}>
                              {el.church}
                            </option>
                          ))}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="exampleForm.ControlSelect1"
                    className="form-group"
                  >
                    <Form.Label column sm={4}>
                      Permission Slip
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        as="select"
                        required
                        name="pSlip"
                        value={pSlip}
                        onChange={this.onChangeHandle}
                      >
                        {this.state.slips &&
                          this.state.slips.map((el, i) => (
                            <option key={el.id} value={el.id}>
                              {el.name}
                            </option>
                          ))}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="formHorizontalPassword"
                    className="form-group"
                  >
                    <Form.Label column sm={4}>
                      Permission Slip Date
                    </Form.Label>
                    <Col sm={8}>
                      <DatePicker
                        required
                        // value={date_of_birth}
                        selected={slip_date ? new Date(slip_date) : new Date()}
                        onChange={(date) => this.setSlipDate(date)}
                        selectsStart
                        startDate={slip_date}
                        // endDate={end_date}
                        placeholderText="Slip Date"
                        className="dateWidthMentorInfo form-control"
                        showYearDropdown
                        scrollableMonthYearDropdown
                        onChangeRaw={(event) => {
                          if (
                            event.target.value &&
                            !validateInput(event.target.value)
                          ) {
                            event.preventDefault();
                          }
                        }}
                        // fullWidth
                        //  maxDate={new Date()}
                      />
                    </Col>
                  </Form.Group>
                  <Row className="text-center form-group">
                    <Col sm={{ span: 10, offset: 2 }}>
                      <Button type="submit" style={btnColor}>
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Container>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(MentorPersonalInfo);
