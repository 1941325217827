import React, { Component } from "react";
import { Card, Form, Col, Row, Container } from "react-bootstrap";
import { Button } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./childProfile.css";
import { updatePersonalInfo } from "../../crud/auth.crud";
import { serverUrl } from "../../../_metronic/utils/api";
import axios from "axios";
import swal from "sweetalert";
import DemoLogo from "../../images/demoDp.jpeg";
import EditIcon from "@material-ui/icons/Edit";
import { IconButton } from "@material-ui/core";
import { validateInput } from "./clone";

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

const gradeArray = [
  "K",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];
const ethnicityArray = [
  "African American",
  "Latino/Hispanic",
  "Asian",
  "Caucasian",
  "Other",
];
export default class personalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      date_of_birth: new Date(),
      grade: "",
      details: "",
      adult: false,
      child: false,
      file: null,
      ethinicity: "",
    };
  }

  onChangeHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  async componentDidMount() {
    await this.seeProfile();
  }

  seeProfile = async () => {
    let id = this.props.childId ? this.props.childId : "";
    if (id)
      try {
        const result = await axios.get(
          `${serverUrl}seeprofile?child_id=${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        let details = result.data.data;

        if (result.status === 200) {
          this.setState({
            first_name: details.child.firstName,
            last_name: details.child.lastName,
            date_of_birth:
              details.child.dateOfBirth !== "0000-00-00"
                ? details.child.dateOfBirth
                : null,
            grade: details.child.grade,
            adult: details.child.adult === "adult" ? true : false,
            child: details.child.adult === "child" ? true : false,
            file: details.child.child_image,
            ethinicity: details.child.ethinicity
              ? details.child.ethinicity
              : "African American",
          });
        }
      } catch {
        swal("Oops!", "Network Error!", "error");
      }
  };
  setBirthDate = (date) => {
    this.setState({ date_of_birth: date });
  };

  imageUpload = (e) => {
    this.setState({
      file: URL.createObjectURL(e.target.files[0]),
      fileData: e.target.files[0],
    });
  };

  gender = (type) => {
    if (type === "adult") {
      this.setState({ adult: true, child: false });
    }
    if (type === "child") {
      this.setState({ adult: false, child: true });
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();

    try {
      const {
        first_name,
        last_name,
        adult,
        date_of_birth,
        grade,
        fileData,
        ethinicity,
      } = this.state;
      if (first_name && last_name) {
        let result = await updatePersonalInfo(
          first_name,
          last_name,
          adult ? "adult" : "child",
          convert(date_of_birth),
          grade,
          fileData,
          ethinicity,
          this.props.childId
        );
        if (result && result.status === 200) {
          swal("Updated Successfully!", "", "success");
          this.props.seeProfile();
          // this.setState(emptyState);
        } else {
          swal("Oops!", "Network Error!", "error");
        }
      }
    } catch {
      swal(
        "Oops!",
        "Network Error! Please try again after some time.",
        "error"
      );
    }
  };

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    const { first_name, last_name, ethinicity, date_of_birth, grade } =
      this.state;
    return (
      <div className="col-xl-9 col-lg-8 col-md-8 col-sm-8 col-8">
        <div className="kt-section">
          <>
            <Card>
              <Card.Body>
                <Card.Title>
                  <Row>
                    <span>Personal Information</span>
                  </Row>
                </Card.Title>

                <Container>
                  <Form onSubmit={this.onSubmit}>
                    <Form.Group
                      as={Row}
                      controlId="formHorizontalEmail"
                      className="form-group"
                    >
                      <input
                        id="myInput"
                        type="file"
                        onChange={this.imageUpload}
                        accept="image/*"
                        ref={(ref) => (this.upload = ref)}
                        style={{ display: "none" }}
                      />
                      <Form.Label column sm={4}>
                        Photo
                      </Form.Label>
                      <Col sm={8}>
                        <div className="photoAddMentor">
                          <img
                            src={this.state.file ? this.state.file : DemoLogo}
                            width="100"
                            height="100"
                            alt="logo_image"
                          ></img>
                          <div className="iconAddMentor">
                            <IconButton
                              aria-label="Delete"
                              onClick={(e) => this.upload.click()}
                            >
                              <EditIcon color="primary" />
                            </IconButton>
                          </div>
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId="formHorizontalEmail"
                      className="form-group"
                    >
                      {/* <Form.Label column sm={2}> */}

                      <span className="col-md-2">First Name</span>
                      {/* </Form.Label> */}
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="first_name"
                          value={first_name}
                          onChange={this.onChangeHandle}
                          placeholder="First Name"
                          required
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="formHorizontalPassword"
                      className="form-group"
                    >
                      <span className="col-md-2"> Last Name</span>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="last_name"
                          value={last_name}
                          onChange={this.onChangeHandle}
                          placeholder="Last Name"
                          required
                        />
                      </Col>
                    </Form.Group>
                    <fieldset>
                      <Row className="align-items-center form-group">
                        <Col sm={2}>
                          <Form.Label sm={2}>Adult or Child &nbsp;</Form.Label>
                        </Col>
                        <Col sm={10}>
                          <Row className="ml-2">
                            <Col sm={8}>
                              <Form.Check
                                required
                                type="radio"
                                label="Adult"
                                name="age"
                                value="adult"
                                checked={this.state.adult}
                                id="formHorizontalRadios1"
                                onChange={() => this.gender("adult")}
                              />
                            </Col>
                            <Col sm={3}>
                              <Form.Check
                                required
                                type="radio"
                                label="Child"
                                name="age"
                                value="child"
                                checked={this.state.child}
                                id="formHorizontalRadios2"
                                onChange={() => this.gender("child")}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </fieldset>
                    <Form.Group
                      as={Row}
                      controlId="exampleForm.ControlSelect1"
                      className="form-group"
                    >
                      <Form.Label column sm={2}>
                        Ethnicity
                      </Form.Label>
                      <Col sm={10}>
                        {" "}
                        <Form.Control
                          as="select"
                          required
                          value={ethinicity}
                          name="ethinicity"
                          onChange={this.onChangeHandle}
                          placeholder="Ethnicity"
                        >
                          {ethnicityArray.map((eth, i) => (
                            <option value={eth} key={i}>
                              {eth}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId="formHorizontalPassword"
                      className="form-group"
                    >
                      <Form.Label column sm={2}>
                        Date of Birth
                      </Form.Label>
                      <Col sm={10}>
                        <DatePicker
                          required
                          // value={date_of_birth}
                          selected={
                            date_of_birth ? new Date(date_of_birth) : new Date()
                          }
                          onChange={(date) => this.setBirthDate(date)}
                          selectsStart
                          startDate={date_of_birth}
                          // endDate={end_date}
                          placeholderText="DOB"
                          className="dateWidthInfo form-control"
                          maxDate={new Date()}
                          showYearDropdown
                          scrollableMonthYearDropdown
                          onChangeRaw={(event) => {
                            if (
                              event.target.value &&
                              !validateInput(event.target.value)
                            ) {
                              event.preventDefault();
                            }
                          }}
                          // fullWidth
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId="exampleForm.ControlSelect1"
                      className="form-group"
                    >
                      <Form.Label column sm={2}>
                        Grade
                      </Form.Label>
                      <Col sm={10}>
                        {" "}
                        <Form.Control
                          ref="templateSelect"
                          as="select"
                          value={grade}
                          name="grade"
                          onChange={this.onChangeHandle}
                        >
                          {gradeArray.map((el, i) => (
                            <option value={el} key={i}>
                              {el}
                            </option>
                          ))}
                        </Form.Control>{" "}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="text-center form-group">
                      <Col sm={{ span: 10, offset: 2 }}>
                        <Button type="submit" style={btnColor}>
                          Submit
                        </Button>
                      </Col>
                    </Form.Group>
                  </Form>
                </Container>
              </Card.Body>
            </Card>
          </>
        </div>
      </div>
    );
  }
}
