import React, { Component } from "react";
import { Form, Col, Dropdown, Row } from "react-bootstrap";
import { Button } from "@material-ui/core";
import ActivityTable from "./activityTable";
import { getSessions, callApi } from "../../../crud/auth.crud";
import swal from "sweetalert";
import withRouter from "../../../router/withRouter";
// import setLoader from '../../../actions/globalLoader';
// import {connect} from 'react-redux'

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
  color: "#fff",
};
function getItem(key) {
  return JSON.parse(localStorage.getItem(key));
}

function removeDuplicates(arr) {
  var i, tmp;
  for (i = 0; i < arr.length; i++) {
    tmp = arr.lastIndexOf(arr[i]);
    if (tmp === i) {
      //Only one of this number
    } else {
      //More than one
      arr.splice(tmp, 1);
      arr.splice(i, 1);
    }
  }
}

function uniqueId() {
  // desired length of Id
  var idStrLen = 32;
  // always start with a letter -- base 36 makes for a nice shortcut
  var idStr = (Math.floor(Math.random() * 25) + 10).toString(36) + "_";
  // add a timestamp in milliseconds (base 36 again) as the base
  idStr += new Date().getTime().toString(36) + "_";
  // similar to above, complete the Id using random, alphanumeric characters
  do {
    idStr += Math.floor(Math.random() * 35).toString(36);
  } while (idStr.length < idStrLen);

  return idStr;
}

function areEqual() {
  var len = arguments.length;
  for (var i = 1; i < len; i++) {
    if (arguments[i] === null || arguments[i] !== arguments[i - 1])
      return false;
  }
  return true;
}
class Activity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sessions: [],
      sessionName: "",
      tableData: [],
      session_dateId: "",
      weekDates: [],
      weekDate: "",
      tutor_bucket: [{ childId: "", mentorid: "", min: "" }],
      attended: [],
      subject_bucket: [{ childId: "", sub: "" }],
    };
  }

  triggerTextChange = (childId, mentorid, min, index, copy, uid) => {
    this.state.tutor_bucket.map((el, i) => {
      if (el.childId && el.childId === childId && el.mentorid === mentorid) {
        if (!uid && !copy) {
          return this.setState({
            tutor_bucket: this.state.tutor_bucket.map((el) =>
              el.childId === childId ? { ...el, mentorid, min } : el
            ),
          });
        }
        if (el.uid === uid) {
          return this.setState({
            tutor_bucket: this.state.tutor_bucket.map((el) =>
              el.uid === uid ? { ...el, mentorid, min } : el
            ),
          });
        }
      } else {
        this.setState({
          tutor_bucket: [
            ...this.state.tutor_bucket,
            { childId, mentorid, min, uid, copy },
          ],
        });
      }
    });
  };

  onChangeSubject = (childId, tableIndex, sub, id, copy, uid) => {
    this.state.subject_bucket.map((el, i) => {
      if (el.childId && el.childId === childId && id === i) {
        this.setState({
          subject_bucket: this.state.subject_bucket.map((el) =>
            el.childId === childId ? { ...el, sub } : el
          ),
        });
      } else {
        this.setState({
          subject_bucket: [
            ...this.state.subject_bucket,
            { childId, sub, uid, copy },
          ],
        });
      }
    });
  };

  copyRow = (childId, mentorId, index) => {
    this.setState({
      tableData: this.state.tableData.map((table, id) => {
        return table.mentor_id === mentorId
          ? {
              ...table,
              data: [
                ...table.data,
                {
                  ...table.data
                    .filter((val) => val.child_id === childId)
                    .find((el) => el.child_id === childId),
                  copy: true,
                  uid: uniqueId(),
                },
              ],
            }
          : table;
      }),
    });
  };

  deleteRow = (childId, mentorId, index, UID) => {
    this.setState({
      tableData: this.state.tableData.map((table, id) => {
        return table.mentor_id === mentorId
          ? {
              ...table,
              data: table.data.filter((val, i) => val.uid !== UID),
            }
          : table;
      }),
      tutor_bucket: this.state.tutor_bucket.filter((el) => el.uid !== UID),
      subject_bucket: this.state.subject_bucket.filter((el) => el.uid !== UID),
    });
    //   await   this.setState({
    //      tutor_bucket: this.state.tutor_bucket.filter((el)=>el.uid !== UID)
    //   });
    //   await   this.setState({
    //     subject_bucket: this.state.subject_bucket.filter((el)=>el.uid !== UID)
    //  });
  };

  onChangeAttendend = (childId, tableIndex) => {
    let cArr = [...this.state.attended];
    cArr.push(childId);
    removeDuplicates(cArr);
    this.setState({ attended: cArr });
  };

  async componentDidMount() {
    await this.getAllSessions();
    await this.getActivities();
    //  await this.getWeekDate()
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  getActivities = async () => {
    const { session_dateId } = this.state;
    if (session_dateId)
      try {
        let result = await callApi("activity-update", {
          program_id: getItem("progName").id,
          date_id: session_dateId,
        });
        if (result.status === 200) {
          this.setState({
            tableData: result.data.data,
          });
        } else {
          swal("Oops!", "Please try again!", "error");
        }
      } catch (err) {
        this.setState({ loading: false });
        if (err.response.status == 401) {
          swal("", "Unauthorized!", "error");
          localStorage.clear();
          this.props.navigate("/auth/login");
          swal.close();
        } else {
          swal("Oops!", "Network Error!", "error");
        }
      }
  };

  getWeekDate = async () => {
    const { session_dateId } = this.state;
    if (session_dateId)
      try {
        let result = await callApi("program-week-dates", {
          date_id: session_dateId,
        });

        if (result.status === 200) {
          this.setState({
            weekDates: result.data.data,
            weekDate: result.data.data.length && result.data.data[0].weekID,
          });
        } else {
          swal("Oops!", "Please try again!", "error");
        }
      } catch (err) {
        swal("Oops!", "Network Error!", "error");
      }
  };

  getAllSessions = async () => {
    try {
      let result = await getSessions();
      if (result.status === 200) {
        this.setState(
          {
            sessions: result.data.data,
            sessionName:
              result.data.data.length && result.data.data[0].dateName,
            session_dateId:
              result.data.data.length && result.data.data[0].dateID,
          },
          () => this.getWeekDate()
        );
      } else {
        swal("Oops!", "Please try again!", "error");
      }
    } catch (err) {
      this.setState({ loading: false });
      if (err.response.status == 401) {
        swal("", "Unauthorized!", "error");
        localStorage.clear();
        this.props.navigate("/auth/login");
        swal.close();
      } else {
        swal("Oops!", "Network Error!", "error");
      }
    }
  };

  callback = async () => {
    await this.getActivities();
    await this.getWeekDate();
  };

  onChangeHandle = (e) => {
    let obj = JSON.parse(e);
    this.setState({ sessionName: obj.name, session_dateId: obj.id }, () =>
      this.callback()
    );
  };

  onChangeHandleDate = (e) => {
    this.setState({ weekDate: e.target.value }, () => this.getActivities());
  };

  submitHandle = async () => {
    const { attended, tutor_bucket, weekDate, subject_bucket } = this.state;
    let filterTutorBucket = tutor_bucket.filter((val) => val.childId !== "");
    let filterSubject_bucket = subject_bucket.filter(
      (val) => val.childId !== ""
    );
    if (filterSubject_bucket.length > 0 && filterTutorBucket.length > 0) {
      if (
        areEqual(
          // attended.length,
          filterSubject_bucket.length,
          filterTutorBucket.length
        )
      ) {
        let splitAttendend = attended.join(",");
        let finalTutor_bucket = JSON.stringify(filterTutorBucket);
        let finalSubject_bucket = JSON.stringify(filterSubject_bucket);
        const payload = {
          attended: splitAttendend,
          tutor_bucket: finalTutor_bucket,
          program_date: weekDate,
          subject_bucket: finalSubject_bucket,
          program_id: getItem("progName").id,
        };
        try {
          let result = await callApi("save-activity-update", payload);
          if (result.status === 200) {
            // swal("","Updated!","success")
            await this.getActivities();
            this.props.navigate("/programs/adddedToCDI", {
              state: {
                data: result.data.data,
                total_min: result.data.total_minutes,
              },
            });
          } else {
            swal("Oops!", "Please try again!", "error");
          }
        } catch (err) {
          swal("Oops!", "Network Error!", "error");
        }
      } else {
        swal("Empty Field!", "Please check all fields are filled!", "error");
      }
    }
  };

  render() {
    const { sessions, sessionName, tableData, weekDates } = this.state;
    return (
      <div className="col-md-8">
        {/* <div className="kt-section"> */}
        <>
          <div className="kt-portlet ">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">
                  Kid Power Activity Update
                </h3>
              </div>
            </div>
            <div className="kt-portlet__body">
              <div className="row">
                <div className="col-md-12">
                  <div className="kt-section">
                    <div className="kt-section__content">
                      <div className="d-flex ">
                        <span className="py-1">Semester</span>
                        <span className="px-4">
                          <Dropdown onSelect={this.onChangeHandle}>
                            <Dropdown.Toggle
                              variant="warning"
                              id="dropdown-basic"
                              size="sm"
                              style={{
                                color: "#fff",
                                backgroundColor: "#ffb822",
                              }}
                            >
                              {sessionName}
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                              // onSelect={this.onChangeHandle}
                              style={{
                                overflowY: "scroll",
                                maxHeight: "240px",
                              }}
                            >
                              {sessions &&
                                sessions.map((el) => (
                                  <Dropdown.Item
                                    eventKey={JSON.stringify({
                                      name: el.dateName,
                                      id: el.dateID,
                                    })}
                                    key={el.dateID}
                                  >
                                    {el.dateName}
                                  </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 text-center">
                  <div className="kt-section">
                    <div className="kt-section__content text-center">
                      <Row>
                        <Col md={4} className="py-2">
                          <h6>Kid Power Date</h6>
                        </Col>
                        <Col md={8}>
                          <Form.Control
                            as="select"
                            name="weekID"
                            onChange={this.onChangeHandleDate}
                          >
                            {weekDates &&
                              weekDates.map((el) => (
                                <option value={el.weekID} key={el.weekID}>
                                  {el.weekDate}
                                </option>
                              ))}
                          </Form.Control>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        {tableData &&
          tableData.map((el, i) => (
            <div className="kt-portlet" key={i}>
              <div className="kt-portlet__body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="kt-section">
                      <div className="kt-section__content">
                        <h5 className="borderDown">{el.mentor_name}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="kt-section">
                      <div className="kt-section__content">
                        <ActivityTable
                          tableIndex={el.mentor_id}
                          idx={i}
                          triggerTextChange={this.triggerTextChange}
                          onChangeAttendend={this.onChangeAttendend}
                          onChangeSubject={this.onChangeSubject}
                          data={el.data}
                          attended={this.state.attended}
                          copyRow={this.copyRow}
                          deleteRow={this.deleteRow}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

        <div className="text-center">
          <Button type="submit" style={btnColor} onClick={this.submitHandle}>
            Add to the CDI
          </Button>
        </div>
      </div>
      // </div>
    );
  }
}

export default withRouter(Activity);
