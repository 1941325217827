import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Form, Container, Col, Row } from "react-bootstrap";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  addChurch,
  addPermissionSlip,
  getChruchListRequest,
  getPermissionSlip,
  getPrograms,
} from "../../crud/auth.crud";
import PermissionSlipTable from "../admin/add-permission-slip/permissionSlipTable";
import ChurchTable from "./ChurchTable";
const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

const emptyState = {
  slip_name: "",
  program_id: "0",
  start_date: "",
  end_date: "",
};

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

export default class ChurchList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      church: "",
      tableData: [],
    };
  }


  async componentDidMount() {
    await this.getAllPrograms();
    await this.getChruchList();
  }

  getAllPrograms = async () => {
    let result = await getPrograms();
    if (result.status === 200) {
      this.setState({ programs: result.data.data });
    } else {
      swal("Oops!", "Network Error!", "error");
    }
  };

  getPermissionSlips = async () => {
    try {
      const result = await getPermissionSlip();
      if (result.status === 200) {
        this.setState({ tableData: result.data.data, loader: false });
      }
    } catch {
      this.setState({ loader: false });
      swal("Oops!", "Network Error!", "error");
    }
  };

  getChruchList = async () => {
    try {
      const result = await getChruchListRequest();
      if (result.status === 200) {
        this.setState({ tableData: result.data.data, loader: false });
      }
    } catch {
      this.setState({ loader: false });
      swal("Oops!", "Network Error!", "error");
    }
  };

  onChangeHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { church } = this.state;
    let result = await addChurch(church);
    if (result.status === 200) {
      swal("Church added Successfully!", "", "success");
      this.setState(emptyState, () => this.getChruchList());
    } else {
      swal("Oops!", "Please try again!", "error");
    }
  };
  render() {
    const { church} =
      this.state;
    return (
      <>
        <div className="kt-portlet kt-portlet--height-fluid">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">All Churches</h3>
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="row">
              {/* <h5>Mentor Personal Information</h5>
            <div className="kt-separator kt-separator--dashed"></div> */}

              <div className="col-md-12">
                <div className="kt-section">
                  <div className="kt-section__content">
                    <Container style={{ maxWidth: "550px" }}>
                      <Form onSubmit={this.handleSubmit}>
                        <Form.Group as={Row} className="form-group">
                          <Form.Label column sm={4}>
                            Church Name
                          </Form.Label>
                          <Col sm={8}>
                            <Form.Control
                              type="text"
                              placeholder="Church Name"
                              required
                              name="church"
                              value={church}
                              onChange={this.onChangeHandle}
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="text-center form-group">
                          <Col sm={{ span: 10, offset: 2 }}>
                            <Button type="submit" style={btnColor}>
                              Add
                            </Button>
                          </Col>
                        </Form.Group>
                      </Form>
                    </Container>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="kt-section">
                  <div className="kt-section__content text-center">
                    <Container style={{ maxWidth: "750px" }}>
                      <ChurchTable
                        tableData={this.state.tableData}
                        programs={this.state.programs}
                        getChruchList={this.getChruchList}
                      />
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
