import React, { useState } from "react";
import { withStyles } from "@material-ui/core";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { Button } from "react-bootstrap";
import { callApi } from "../../crud/auth.crud";
import swal from "sweetalert";
import withRouter from "../../router/withRouter";

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 12,
  },
}))(TableCell);

const KidReportTable = (props) => {
  const [loader, setLoader] = useState(false);
  const download_CSV_Report = async () => {
    setLoader(true);
    try {
      let result = await callApi("grade-report-print", {
        child_list: props.location.state.print,
      });
      if (result.status === 200) {
        setLoader(false);
        window.open(result.data.data, "_blank");
      } else {
        setLoader(false);
        swal("Oops!", "Please try again!", "error");
      }
    } catch {
      setLoader(false);
      swal("Oops!", "Network Error!", "error");
    }
  };

  let objHeader =
    props?.location?.state?.data && props?.location?.state?.data?.length
      ? props?.location?.state?.data[0]
      : {};
  let checkHeader = [];
  Object.keys(objHeader).forEach(function (key) {
    checkHeader.push(key);
  });

  return (
    <Paper>
      {loader ? (
        <div
          className="spinner-border text-warning loaderStyleMiteraPdf"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div className="py-2 px-2 float-right">
          <Button
            type="submit"
            style={btnColor}
            onClick={download_CSV_Report}
            disabled={!props?.location?.state?.print?.length}
          >
            Download CSV
          </Button>
        </div>
      )}

      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>First Name</StyledTableCell>
            <StyledTableCell>Last Name</StyledTableCell>
            {checkHeader.includes("age") && (
              <StyledTableCell>Age</StyledTableCell>
            )}
            {checkHeader.includes("grade") && (
              <StyledTableCell>Grade</StyledTableCell>
            )}
            {checkHeader.includes("building") && (
              <StyledTableCell>Building</StyledTableCell>
            )}
            {checkHeader.includes("apartment") && (
              <StyledTableCell>Apartment</StyledTableCell>
            )}
            {checkHeader.includes("street") && (
              <StyledTableCell>Street</StyledTableCell>
            )}
            {checkHeader.includes("emergencyContact") && (
              <StyledTableCell>Emergency Contact</StyledTableCell>
            )}
            {checkHeader.includes("emergencyRelationship") && (
              <StyledTableCell>Emergency Relationship</StyledTableCell>
            )}
            {checkHeader.includes("emergencyPhone") && (
              <StyledTableCell>Emergency Phone</StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.location?.state?.data &&
          props?.location?.state?.data?.length ? (
            props?.location?.state?.data?.map((row, i) => (
              <TableRow key={i}>
                <TableCell component="th" scope="row">
                  {row.first_name ? row.first_name : "-"}
                </TableCell>
                <TableCell>{row.last_name ? row.last_name : "-"}</TableCell>
                {checkHeader.includes("age") && (
                  <TableCell>{row.age}</TableCell>
                )}
                {checkHeader.includes("grade") && (
                  <TableCell>{row.grade}</TableCell>
                )}
                {checkHeader.includes("building") && (
                  <TableCell>{row.building}</TableCell>
                )}
                {checkHeader.includes("apartment") && (
                  <TableCell>{row.apartment}</TableCell>
                )}
                {checkHeader.includes("street") && (
                  <TableCell>{row.street}</TableCell>
                )}
                {checkHeader.includes("emergencyContact") && (
                  <TableCell>{row.emergencyContact}</TableCell>
                )}
                {checkHeader.includes("emergencyRelationship") && (
                  <TableCell>{row.emergencyRelationship}</TableCell>
                )}
                {checkHeader.includes("emergencyPhone") && (
                  <TableCell>{row.emergencyPhone}</TableCell>
                )}
              </TableRow>
            ))
          ) : (
            <p>No Data</p>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default withRouter(KidReportTable);
