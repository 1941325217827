import React, { Component } from "react";
import LiteracyTable from "./literacyTable";
import { Container } from "react-bootstrap";
import { fetchData } from "../../crud/auth.crud";
import swal from "sweetalert";
import { Form } from "react-bootstrap";

export default class LiteracyReportList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [],
      loader: true,
      name: "",
      childs: [],
    };
  }

  async componentDidMount() {
    await this.getChild();
    // await this.getReport()
  }

  getChild = async () => {
    try {
      let result = await fetchData("children-list");
      if (result.status === 200) {
        this.setState(
          {
            childs: result.data.data,
            child_id: result.data.data.length && result.data.data[0].childID,
          },
          () => this.getReport()
        );
      } else {
        this.setState({ loader: false });
        swal("Oops!", "Please try again!", "error");
      }
    } catch {
      this.setState({ loader: false });
      swal("Oops!", "Network Error!", "error");
    }
  };

  getReport = async () => {
    const { child_id } = this.state;
    if (child_id)
      try {
        let result = await fetchData(`grade-report?child_id=${child_id}`);
        if (result.status === 200) {
          this.setState({
            tableData: result.data.data,
            loader: false,
            name: result.data.name,
          });
        } else {
          this.setState({ loader: false });
          swal("Oops!", "Please try again!", "error");
        }
      } catch {
        this.setState({ loader: false });
        swal("Oops!", "Network Error!", "error");
      }
  };

  onChangeHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => this.getReport());
  };

  render() {
    const { name, tableData, childs, child_id } = this.state;
    return (
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              Literacy Report for {name}
            </h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-section">
                <div className="d-flex ">
                  <span className="py-2 fw-bold">Child :</span>
                  <span className="px-4">
                    <Form.Group>
                      <Form.Control
                        as="select"
                        name="child_id"
                        value={child_id}
                        onChange={this.onChangeHandle}
                      >
                        {childs && childs.length ? (
                          childs.map((el, i) => (
                            <option key={i} value={el.childID}>
                              {el.firstName + " " + el.lastName}
                            </option>
                          ))
                        ) : (
                          <option>Loading Children...</option>
                        )}
                      </Form.Control>
                    </Form.Group>
                  </span>
                </div>
                <div className="kt-section__content">
                  <Container style={{ maxWidth: "720px" }}>
                    {this.state.loader ? (
                      <div
                        className="spinner-border text-warning loaderStyleReport"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <LiteracyTable tableData={tableData} />
                    )}
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
