import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import {Form} from 'react-bootstrap'
import {callApi} from '../../crud/auth.crud'
import swal from "sweetalert";
import withRouter from "../../router/withRouter";

  function comparePasswords(pass1,pass2){
  const isEqaul =  pass1.localeCompare(pass2)
         if(isEqaul===0){
             return true
         }
         return false
  }

class ResetPassword extends Component {

    state={
        email:"",
        otp:"",pwd:"",cpwd:"",pwdError:""
    }

    onChangeHandler=(e)=>{
        this.setState({[e.target.name]:e.target.value})
    }


    changePassword=async(e)=>{
        const {email,otp,pwd,cpwd}=this.state;
        e.preventDefault()
        if(comparePasswords(pwd,cpwd))
        try {
            let result = await callApi("reset-password",{email, otp, password:pwd, conf_password:cpwd});
            if (result.status === 200) {
              this.props.navigate('/auth/login',
                {state: { password_change_msg:"Password has been changed successfully!" }
              })
            }
          }
          catch {
            swal("Oops!", "Something Went Wrong", "error");
          }
          else{
              this.setState({pwdError:"Passwords do not match!"})
          }
      }

  render() {
          const {pwdError}=this.state;
          console.log(this.props)
    return (
      <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
        <div className="kt-login__body">
          <div className="kt-login__form">

          <div className="kt-login__title">
              <h3>Reset Password</h3>
              <h5 className="text-success pt-4">{this.props.location.state.message}</h5>
            </div>
            <Form onSubmit={this.changePassword}>
            <div className="form-group">
                    <TextField
                      type="email"
                      label="Email"
                      margin="normal"
                      fullWidth={true}
                      name="email"
                      onChange={this.onChangeHandler}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      type="text"
                      label="OTP"
                      margin="normal"
                      fullWidth={true}
                      name="otp"
                      required
                      onChange={this.onChangeHandler}
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      type="password"
                      label="New Password"
                      margin="normal"
                      fullWidth={true}
                      name="pwd"
                      onChange={this.onChangeHandler}
                      required
                    />
                    <span className="text-danger">{pwdError}</span>
                  </div>
                  <div className="form-group">
                    <TextField
                      type="password"
                      label="Confirm New Password"
                      margin="normal"
                      fullWidth={true}
                      name="cpwd"
                      onChange={this.onChangeHandler}
                      required
                    />
                  </div>

                  <div className="kt-login__actions">
                    <Link to="/otp">
                      <button
                        type="button"
                        className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                      >
                        Back
                      </button>
                    </Link>

                    <button
                      type="submit"
                      className="btn btn-primary btn-elevate kt-login__btn-primary"

                    >
                      Submit
                    </button>
                  </div>
                  </Form>

          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ResetPassword);
