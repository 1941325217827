import React, { Component } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  withStyles,
  IconButton,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { callApi } from "../../../crud/auth.crud";
import swal from "sweetalert";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

export default class KidPowerTable extends Component {
  deleteDate = async (date_id) => {
    try {
      const result = await callApi("kidpower-delete-date", { date_id });
      if (result.status === 200) {
        swal("Date Deleted Successfully!", "", "success");
        this.props.getAllSessions();
        // this.setState({ tableData: result.data.data });
      } else {
        swal("Oops!", "Please try again!!", "error");
      }
    } catch {
      swal("Oops!", "Network Error!!", "error");
    }
  };
  deleteHandlerConfirm = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.deleteDate(id);
        // swal("Data has been deleted!", {
        //   icon: "success",
        // });
      }
    });
  };

  render() {
    const { tableData } = this.props;
    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Week Date</StyledTableCell>
              <StyledTableCell align="center">Remove Entry</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData &&
              tableData.map((row, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell align="center">
                    {row.weekDate}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {" "}
                    <IconButton
                      aria-label="Delete"
                      onClick={() => this.deleteHandlerConfirm(row.weekID)}
                    >
                      <DeleteOutlineIcon style={{ color: "red" }} />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}
