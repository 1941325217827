import React, { Component } from "react";
import { Button, Row } from "react-bootstrap";
import { Form, Col } from "react-bootstrap";
import AddProgramTable from "./addProgramTable";
import { getPrograms, addPrograms } from "../../../crud/auth.crud";
import swal from "sweetalert";

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

const emptyState = {
  program_name: "",
  assign_to_menu: "0",
};
export default class AddProgram extends Component {
  constructor(props) {
    super(props);

    this.state = {
      programs: [],
      program_name: "",
      assign_to_menu: "0",
    };
  }

  getAllPrograms = async () => {
    let result = await getPrograms();
    if (result.status === 200) {
      this.setState({ programs: result.data.data });
    } else {
      swal("Oops!", "Network Error!", "error");
    }
  };

  async componentDidMount() {
    await this.getAllPrograms();
  }

  onChangeHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { program_name, assign_to_menu } = this.state;
    let result = await addPrograms(program_name, assign_to_menu);
    if (result.status === 200) {
      swal("Program added Successfully!", "", "success");
      this.setState(emptyState, () => this.getAllPrograms());
    } else {
      swal("Oops!", "Program already exist!", "error");
    }
  };

  render() {
    const { programs, assign_to_menu, program_name } = this.state;
    return (
      <>
        <div className="kt-portlet kt-portlet--height-fluid">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">Add Programs</h3>
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="row">
              <div className="col-xl-4">
                <Form onSubmit={this.handleSubmit}>
                  <Row>
                    <Form.Group as={Col} md="6" className="mb-4">
                      <Form.Label>Program Name</Form.Label>
                      <Form.Control
                        required
                        name="program_name"
                        type="text"
                        placeholder="Program Name"
                        value={program_name}
                        onChange={this.onChangeHandle}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="mb-4">
                      <Form.Label>Assign to Menu</Form.Label>
                      <Form.Control
                        as="select"
                        name="assign_to_menu"
                        value={assign_to_menu}
                        onChange={this.onChangeHandle}
                      >
                        <option>0</option>
                        {programs &&
                          programs.map((el, i) => (
                            <option value={el.programID} key={el.id}>
                              {el.program}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group>
                  </Row>
                  <div className="text-center mb-4">
                    <Button type="submit" style={btnColor}>
                      Add Program
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <AddProgramTable
              programs={this.state.programs}
              getAllPrograms={this.getAllPrograms}
            />
          </div>
        </div>
      </>
    );
  }
}
