import React from "react";
import { withStyles, makeStyles } from "@material-ui/core";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    // minWidth: 800,
  },
}));

export default function BackupContactTable(props) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell>Backup Contact</StyledTableCell>
            <StyledTableCell>Backup Phone</StyledTableCell>
            <StyledTableCell>Relationship</StyledTableCell>
            <StyledTableCell>Address</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {rows.map(row => ( */}
          <StyledTableRow>
            <StyledTableCell component="th" scope="row">
              {props.details.backupContact}
            </StyledTableCell>
            <StyledTableCell> {props.details.backupPhone}</StyledTableCell>
            <StyledTableCell>
              {" "}
              {props.details.backupRelationship}
            </StyledTableCell>
            <StyledTableCell> {props.details.backupAddress}</StyledTableCell>
          </StyledTableRow>
          {/* ))} */}
        </TableBody>
      </Table>
    </Paper>
  );
}
