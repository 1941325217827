import React, { Component } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  withStyles,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { IconButton } from "@material-ui/core";
import swal from "sweetalert";
import axios from "axios";
import EditChurch from "./EditChurch";
import { serverUrl } from "../../../_metronic/utils/api";

const editColor = {
  color: "#44bb87",
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

export default class ChurchTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      tableData: [],
      editData: "",
    };
  }
  deleteSlip = async (id) => {
    try {
      const result = await axios.post(
        `${serverUrl}church-delete`,
        { id: id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (result.status === 200) {
        swal("Church Deleted Successfully!", "", "success");
        this.props.getChruchList();
        // this.setState({ tableData: result.data.data });
      } else {
        swal("Oops!", "Please try again!!", "error");
      }
    } catch {
      swal("Oops!", "Network Error!!", "error");
    }
  };
  deleteHandlerConfirm = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.deleteSlip(id);
        // swal("Data has been deleted!", {
        //   icon: "success",
        // });
      }
    });
  };

  editHandle = async (data) => {
        this.setState({ editData: data }, () => this.handleOpen());

    // try {
    //   const result = await axios.post(
    //     `${serverUrl}edit-permissionslip`,
    //     { slip_id: id },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: "Bearer " + localStorage.getItem("token"),
    //       },
    //     }
    //   );
    //   if (result.status === 200) {
    //     // swal("Permission Slip Deleted Successfully!","", "success");
    //     this.setState({ editData: result.data.data }, () => this.handleOpen());
    //   } else {
    //     swal("Oops!", "Please try again!!", "error");
    //   }
    // } catch {
    //   swal("Oops!", "Network Error!!", "error");
    // }
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  renderTable = () => {
    const { tableData } = this.props;
    return (
      tableData &&
      tableData.map((row, index) => (
        <StyledTableRow key={row.id}>
          <StyledTableCell align="center" className="fw-bold">
            {row.church}
          </StyledTableCell>
          <StyledTableCell align="center">{row.insertedBy}</StyledTableCell>
          <StyledTableCell align="center">
            <i
              className="far fa-edit"
              style={editColor}
              onClick={() => this.editHandle(row)}
            ></i>
          </StyledTableCell>
          <StyledTableCell align="center">
            {" "}
            <IconButton
              aria-label="Delete"
              onClick={() => this.deleteHandlerConfirm(row.id)}
            >
              <DeleteOutlineIcon style={{ color: "red" }} />
            </IconButton>
          </StyledTableCell>
        </StyledTableRow>
      ))
    );
  };

  render() {
    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Church Name</StyledTableCell>
              <StyledTableCell align="center">Added By</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.loader ? (
              <div
                className="spinner-border text-warning loaderStyle"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              this.renderTable()
            )}
          </TableBody>
        </Table>
        {this.state.open ? (
          <EditChurch
            handleOpen={this.handleOpen}
            editData={this.state.editData}
            open={this.state.open}
            programs={this.props.programs}
            getChruchList={this.props.getChruchList}
            handleClose={this.handleClose}
          />
        ) : null}
      </Paper>
    );
  }
}
