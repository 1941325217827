import React from "react";
import { Bar } from "react-chartjs-2";
import { Typography } from "@material-ui/core";
import "./dashboard.css";
import "chartjs-plugin-datalabels";

export default class ConcentratedFocusChart extends React.Component {
  render() {
    return (
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              Concentrated Focus of Urban Impact Programs
            </h3>
          </div>
          {/* <PortletHeaderDropdown /> */}
        </div>
        <div className="kt-portlet__body">
          <Typography
            variant="caption"
            display="block"
            className="mb-4 dashboardText"
          >
            In addition to the inherent high intensity of low child-to-mentor
            ratios. Urban Impact invests substantial time with each child.
            Consider the time allocations below (note: the kids shown are
            "active" kids which are kids that have attended a program at least
            four times or once for Hoops/Shepherd )
          </Typography>
          <Bar
            data={{
              labels:
                this.props.concentrated_focus &&
                this.props.concentrated_focus.length > 0
                  ? this.props.concentrated_focus.map((val) => val.programs)
                  : [
                      "PrimeTime Junior",
                      "PrimeTime",
                      "PrimeTime Senior",
                      "XLR8",
                      "Shepherd",
                      "Camp Spofford",
                    ],
              datasets: [
                {
                  label: "Kids in program",
                  backgroundColor: "#f4b53f",
                  data:
                    this.props.concentrated_focus &&
                    this.props.concentrated_focus.length > 0
                      ? this.props.concentrated_focus.map((val) => val.kidcount)
                      : [],
                },
                {
                  label: "Total hours in program",
                  backgroundColor: "#a09999",
                  data:
                    this.props.concentrated_focus &&
                    this.props.concentrated_focus.length > 0
                      ? this.props.concentrated_focus.map((val) => val.hours)
                      : [],
                },
              ],
            }}
            options={{
              plugins: {
                datalabels: {
                  display: "auto",
                  color: "#5c5a6d",
                  clamp: true,
                },
              },
              title: {
                display: true,
                // text:'Average Rainfall per month',
                fontSize: 10,
              },
              legend: {
                display: true,
                position: "right",
              },
            }}
          />
        </div>
      </div>
    );
  }
}
