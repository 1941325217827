import React, { Component } from "react";
import { Typography } from "@material-ui/core";
// import { Modal } from "@material-ui/core"; // import { Button } from "@material-ui/core";import { Button, Form, Col } from "react-bootstrap";
import { updateEmergencyInfo } from "../../crud/auth.crud";
import swal from "sweetalert";
import { Col, Form, Button, Row, Modal } from "react-bootstrap";
import CustomPhoneInput from "../reports/CustomPhoneInput";

const paper = {
  position: "absolute",
  width: "80%",
  backgroundColor: "#fff",
  boxShadow: 5,
  padding: 25,
  outline: "none",
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`,
};

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

export default class EditEmergencyInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      e_contact: props.details.emergencyContact,
      e_phone: props.details.emergencyPhone,
      e_relation: props.details.emergencyRelationship,
      e_address: props.details.emergencyAddress,
      b_contact: props.details.backupContact,
      b_phone: props.details.backupPhone,
      b_relation: props.details.backupRelationship,
      b_address: props.details.backupAddress,
      sb_contact: props.details.secondBackupContact,
      sb_phone: props.details.secondBackupPhone,
      sb_relation: props.details.secondBackupRelationship,
      sb_address: props.details.secondBackupAddress,
    };
  }

  onChangeHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onPhoneInput = (e) => {
    let val = e.target.value;
    e.target.value = val
      .replace(/\D/g, "")
      .replace(/(\d{1,4})(\d{1,3})?(\d{1,3})?/g, function (txt, f, s, t) {
        if (t) {
          return `(${f}) ${s}-${t}`;
        } else if (s) {
          return `(${f}) ${s}`;
        } else if (f) {
          return `(${f})`;
        }
      });
    this.setState({ [e.target.name]: e.target.value });
  };

  formatPhoneNumber = (input) => {
    //  if the input is null, return a null value
    if (!input) return input;
    // remove all characters from the input except number input.
    const numberInput = input.replace(/[^\d]/g, "");
    //  take the length of the value of the input
    const numberInputLength = numberInput.length;
    // if the number length is 1, 2, or 3, then return it as it is.
    if (numberInputLength < 4) {
      return numberInput;
    } else if (numberInputLength < 7) {
      // if the number input length is 4, 5, or 6, format it accordingly.
      return `(${numberInput.slice(0, 3)}) ${numberInput.slice(3)}`;
    } else {
      //  if the number input length is 7, 8, 9, 10, or more, format it like the below.
      return `(${numberInput.slice(0, 3)}) ${numberInput.slice(
        3,
        6
      )}-${numberInput.slice(6, 10)}`;
    }
    // return empty string in case any condition doesn't satisfy.
    return "";
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const {
      e_contact,
      e_phone,
      e_relation,
      e_address,
      b_contact,
      b_phone,
      b_relation,
      b_address,
      sb_contact,
      sb_phone,
      sb_relation,
      sb_address,
    } = this.state;
    let result = await updateEmergencyInfo(
      this.props.child_id,
      e_contact,
      e_phone,
      e_relation,
      e_address,
      b_contact,
      b_phone,
      b_relation,
      b_address,
      sb_contact,
      sb_phone,
      sb_relation,
      sb_address
    );
    if (result && result.status === 200) {
      swal("Updated Successfully!", "", "success");
      this.props.handleClose();
      this.props.getContactData();
    } else {
      swal("Oops!", "Network Error!", "error");
    }
  };

  render() {
    const {
      e_contact,
      e_phone,
      e_relation,
      e_address,
      b_contact,
      b_phone,
      b_relation,
      b_address,
      sb_contact,
      sb_phone,
      sb_relation,
      sb_address,
    } = this.state;
    return (
      <div>
        {/* <Typography gutterBottom>
          Click to get the full Modal experience!
        </Typography> */}
        {/* <Button onClick={this.props.handleOpen}>Open Modal</Button> */}
        <Modal size="lg" show={this.props.open} onHide={this.props.handleClose}>
          <Modal.Body>
            <div className="d-flex justify-content-between">
              <span>
                {" "}
                <Typography variant="h6" id="modal-title">
                  Edit Emergency Info
                </Typography>
              </span>
              <span>
                {" "}
                <i
                  className="fas fa-times"
                  onClick={this.props.handleClose}
                ></i>
              </span>
            </div>

            <Form className="mt-4" onSubmit={this.onSubmit}>
              <Form.Group className="form-group">
                <Row>
                  <Col md={6}>
                    {" "}
                    <Form.Label>Emergency Contact</Form.Label>
                    <Form.Control
                      type="text"
                      name="e_contact"
                      defaultValue={e_contact}
                      onChange={this.onChangeHandle}
                      placeholder=""
                    />
                  </Col>
                  <Col md={6}>
                    {" "}
                    <Form.Label>Emergency Contact's Relationship</Form.Label>
                    <Form.Control
                      type="text"
                      name="e_relation"
                      defaultValue={e_relation}
                      onChange={this.onChangeHandle}
                      placeholder=""
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="form-group">
                <Row>
                  <Col md={6}>
                    {" "}
                    <Form.Label>Emergency Contact's Telephone</Form.Label>
                    {/* <Form.Control
                      type="text"
                      name="e_phone"
                      value={e_phone}
                      onChange={this.onChangePhone}
                      placeholder=""
                    /> */}
                    <CustomPhoneInput
                      value={e_phone}
                      setValue={(val) => this.setState({ e_phone: val })}
                    />
                  </Col>
                  <Col md={6}>
                    {" "}
                    <Form.Label>Emergency Contact's Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="e_address"
                      defaultValue={e_address}
                      onChange={this.onChangeHandle}
                      placeholder=""
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="form-group">
                <Row>
                  <Col md={6}>
                    {" "}
                    <Form.Label>Backup Emergency Contact</Form.Label>
                    <Form.Control
                      type="text"
                      name="b_contact"
                      defaultValue={b_contact}
                      onChange={this.onChangeHandle}
                      placeholder=""
                    />
                  </Col>
                  <Col md={6}>
                    {" "}
                    <Form.Label>Backup Contact's Telephone</Form.Label>
                    <CustomPhoneInput
                      value={b_phone}
                      setValue={(val) => this.setState({ b_phone: val })}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="form-group">
                <Row>
                  <Col md={6}>
                    {" "}
                    <Form.Label>Backup Contact's Relationship</Form.Label>
                    <Form.Control
                      type="text"
                      name="b_relation"
                      defaultValue={b_relation}
                      onChange={this.onChangeHandle}
                      placeholder=""
                    />
                  </Col>
                  <Col md={6}>
                    {" "}
                    <Form.Label>Backup Contact's Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="b_address"
                      defaultValue={b_address}
                      onChange={this.onChangeHandle}
                      placeholder=""
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Row>
                  <Col md={6}>
                    {" "}
                    <Form.Label>2nd Backup Emergency Contact</Form.Label>
                    <Form.Control
                      type="text"
                      name="sb_contact"
                      defaultValue={sb_contact}
                      onChange={this.onChangeHandle}
                      placeholder=""
                    />
                  </Col>
                  <Col md={6}>
                    {" "}
                    <Form.Label>2nd Backup Contact's Telephone</Form.Label>
                    {/* <Form.Control
                      type="tel"
                      name="sb_phone"
                      value={sb_phone}
                      onChange={this.onChangePhone}
                      placeholder=""
                    /> */}
                    <CustomPhoneInput
                      value={sb_phone}
                      setValue={(val) => this.setState({ sb_phone: val })}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Row>
                  <Col md={6}>
                    {" "}
                    <Form.Label>2nd Backup Contact's Relationship</Form.Label>
                    <Form.Control
                      type="text"
                      name="sb_relation"
                      defaultValue={sb_relation}
                      onChange={this.onChangeHandle}
                      placeholder=""
                    />
                  </Col>
                  <Col md={6}>
                    {" "}
                    <Form.Label>2nd Backup Contact's Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="sb_address"
                      defaultValue={sb_address}
                      onChange={this.onChangeHandle}
                      placeholder=""
                    />
                  </Col>
                </Row>
              </Form.Group>
              <div className="text-center">
                <Button type="submit" style={btnColor}>
                  Update and Replace
                </Button>
              </div>
            </Form>

            {/* <AddSpecialEvent /> */}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
