/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter,
  Navigate,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
// import { LastLocationProvider } from "react-router-last-location";
import { RoutesList } from "./app/router/Routes";
import {
  I18nProvider,
  LayoutContextProvider,
  LayoutSplashScreen,
  ThemeProvider,
} from "./_metronic";
import AuthPage from "./app/pages/auth/AuthPage";
import Dashboard from "./app/pages/home/Dashboard";
import Login from "./app/pages/auth/Login";
import ForgotPassword from "./app/pages/auth/ForgotPassword";
import Layout from "./_metronic/layout/Layout";

const getToken = () => {
  return localStorage.getItem("token");
};

const PrivateRoute = ({ children }) => {
  const token = getToken();
  return token ? (
    children
  ) : (
    <>
      <Navigate to="/auth/login" />
    </>
  );
};

const PublicRoute = ({ children }) => {
  const token = getToken();

  return !token ? (
    children
  ) : (
    <>
      <Navigate to="/dashboard" />
    </>
  );
};
const router = createBrowserRouter([
  // {
  //   path: "/",
  //   element: (
  //     <PrivateRoute>
  //       <Dashboard />
  //     </PrivateRoute>
  //   ),
  // },

  // {
  //   path: "/dashboard",
  //   element: (
  //     <PrivateRoute>
  //       <Dashboard />
  //     </PrivateRoute>
  //   ),
  // },

  {
    path: "/login",
    element: (
      // <PublicRoute>
      <AuthPage />
      // </PublicRoute>
    ),
  },

  {
    basename: "/CDI",
  },
]);

export default function App({ store, persistor, basename }) {

  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          {/* <BrowserRouter basename={basename}> */}
          {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
          {/* <LastLocationProvider> */}
          {/* Provide Metronic theme overrides. */}
          <ThemeProvider>
            {/* Provide `react-intl` context synchronized with Redux state.  */}
            <I18nProvider>
              {/* Render routes with provided `Layout`. */}
              <RoutesList history={null}/>
              {/* <RouterProvider router={router} />; */}
            </I18nProvider>
          </ThemeProvider>
          {/* </LastLocationProvider> */}
          {/* </BrowserRouter> */}
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
