import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Form, Container } from "react-bootstrap";
import AdminToolTable from "./adminToolTable";
import { addSchool, getSchools } from "../../../crud/auth.crud";
import swal from "sweetalert";
import withRouter from "../../../router/withRouter";

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

const containWidth = {
  maxWidth: "600px",
};

const textStyle = {
  textDecoration: "underline",
  textDecorationColor: "#f4b53f",
};
class AdminTool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      schoolName: "",
    };
  }

  async componentDidMount() {
    await this.getSchools();
  }
  getSchools = async () => {
    try {
      const result = await getSchools();
      if (result.status === 200) {
        this.setState({ tableData: result.data.data });
      }
    } catch (err) {
      if (err.response.status == 401) {
        swal("", "Unauthorized!", "error");
        localStorage.clear();
        this.props.navigate("/auth/login");
        swal.close();
      } else {
        swal("Oops!", "Network Error!", "error");
      }
    }
  };

  onChangeHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  addSchool = async () => {
    const { schoolName } = this.state;
    if (schoolName !== "") {
      let result = await addSchool(schoolName);
      if (result && result.status === 200) {
        swal("School added Successfully!", "", "success");
        this.setState({ schoolName: "" }, () => this.getSchools());
      } else {
        swal("Oops!", "Please try again with different name!", "error");
      }
    }
  };
  render() {
    return (
      <>
        <div className="kt-portlet kt-portlet--height-fluid">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">
                The Schools Attended by Children of PT Barnum
              </h3>
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="row">
              <div className="col-md-12">
                <div className="kt-section">
                  <div className="kt-section__content">
                    <p className="fw-bold" style={textStyle}>
                      Add School
                    </p>
                    <p>School Name</p>
                    <div className="d-flex">
                      <span className="col-md-4">
                        <Form.Control
                          type="text"
                          name="schoolName"
                          value={this.state.schoolName}
                          onChange={this.onChangeHandle}
                          placeholder=""
                        />
                      </span>
                      <span className="px-4">
                        <Button
                          type="submit"
                          style={btnColor}
                          onClick={this.addSchool}
                        >
                          Add to CDI
                        </Button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="kt-section">
                  <div className="kt-section__content">
                    <Container style={containWidth}>
                      <AdminToolTable
                        tableData={this.state.tableData}
                        getSchools={this.getSchools}
                      />
                    </Container>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="text-center">
                <Button type="submit" style={btnColor}>
                            Add Date Range
                          </Button>
                </div> */}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(AdminTool);
