import React, { Component } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../partials/content/Portlet";
import { Form, Col, Row } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { fetchDataWithPagination } from "../../crud/auth.crud";
import TutoringAnalysisTable from "./tutoringAnalysisTable";
import swal from "sweetalert";
import Pagination from "react-js-pagination";
import moment from "moment";

const fontWeight = {
  fontWeight: "bold",
};

const debounce = (fn, delay) => {
  let timer = null;
  return function (...args) {
    const context = this;
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
};

class TutoringAnalysis extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [],
      total: null,
      per_page: null,
      current_page: 1,
      loader: true,
      total_reports: "",
      searchTxt: "",
    };
    this.getReports = debounce(this.getReports, 200);
  }

  async componentDidMount() {
    await this.getReports();
  }

  getReports = async () => {
    const { current_page, searchTxt } = this.state;
    try {
      let result = await fetchDataWithPagination(
        "tutoring-analysis-report",
        current_page,
        { child_name: searchTxt }
      );
      if (result.status === 200) {
        this.setState({
          tableData: result.data.data.data,
          current_page: result.data.data.current_page
            ? result.data.data.current_page
            : 1,
          total: result.data.data.total,
          per_page: result.data.data.per_page,
          loader: false,
          total_reports: result.data.total_number,
        });
      } else {
        this.setState({ loader: false });
        swal("Oops!", "Please try again!", "error");
      }
    } catch {
      this.setState({ loader: false });
      swal("Oops!", "Network Error!", "error");
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ current_page: pageNumber });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.current_page !== this.state.current_page) {
      window.scrollTo(0, 0);
      this.getReports();
    }
  }

  searchHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => this.getReports());
  };

  render() {
    const { tableData, total_reports } = this.state;
    return (
      <>
        <div className="row">
          <div className="col-xl-12">
            <Portlet fluidHeight={true}>
              <PortletHeader
                title="Tutoring Analysis"
                // toolbar={
                //   <PortletHeaderToolbar>
                //     <PortletHeaderDropdown />
                //   </PortletHeaderToolbar>
                // }
              />

              <PortletBody>
                <div className="row">
                  <div className="col-md-3">
                    <Card
                      style={{
                        width: "13rem",
                        borderLeft: "3px solid #f4b53f",
                        borderWidthLeft: "5px",
                        fontSize: "10px",
                      }}
                    >
                      <Card.Body>
                        <Card.Text>Begining of date range</Card.Text>
                        <Card.Text style={fontWeight}>
                          {moment(
                            new Date(
                              new Date().setFullYear(
                                new Date().getFullYear() - 1
                              )
                            )
                          ).format("MMM Do YYYY")}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-md-4">
                    <Card
                      style={{
                        width: "13rem",
                        fontSize: "10px",
                        borderLeft: "3px solid #f4b53f",
                      }}
                    >
                      <Card.Body>
                        <Card.Text>End of date range</Card.Text>
                        <Card.Text style={fontWeight}>
                          {moment(new Date()).format("MMM Do YYYY")}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </div>

                <p className="py-4">
                  Number of kids involved in XLR8 or Kid Power {total_reports}
                </p>
                <div className="col-md-12">
                  <div className="kt-section">
                    <div className="kt-section__content text-center">
                      <Row>
                        <Col md={12}>
                          <div className="input-group pl-0">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Search by child name"
                              aria-label="Search"
                              name="searchTxt"
                              onChange={this.searchHandle}
                            />
                            <span
                              className="input-group-text amber lighten-3"
                              id="basic-text1"
                            >
                              <i
                                className="fas fa-search text-grey"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  {this.state.loader ? (
                    <div
                      className="spinner-border text-warning loaderStyleReport"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <TutoringAnalysisTable tableData={tableData} />
                  )}
                  {this.state.total > 10 ? (
                    <Pagination
                      activePage={this.state.current_page}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.total}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(this)}
                      itemclassName="page-item"
                      linkclassName="page-link"
                    />
                  ) : null}
                </div>
              </PortletBody>
            </Portlet>
          </div>
        </div>
      </>
    );
  }
}

export default TutoringAnalysis;
