import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import { Modal } from "@material-ui/core"; // import { Button } from "@material-ui/core";import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { callApi } from "../../crud/auth.crud";
import { Form, Button } from "react-bootstrap";

export function validateInput(inputString) {
  if (inputString) {
    var pattern = /^[^a-zA-Z]+\/?[^a-zA-Z]*$/;
    // Use the test method of the regular expression to check if the input matches the pattern
    return pattern.test(inputString);
  }

  // Define a regular expression pattern to match only non-alphabetic characters
}

const paper = {
  position: "absolute",
  width: 400,
  backgroundColor: "#fff",
  boxShadow: 5,
  padding: 25,
  outline: "none",
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`,
};

const btnColor = {
  backgroundColor: "#f4b53f",
  border: "none",
};

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

export default class Clone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      birthDate: "",
      last_name: "",
      first_name: "",
    };
  }
  setBirthDate = (date) => {
    console.log("🚀 ~ Clone ~ date:", date);
    this.setState({ birthDate: date });
  };

  onChangeHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitForm = async (e) => {
    e.preventDefault();
    const { first_name, last_name, birthDate } = this.state;
    let payload = {
      child_id: this.props.id,
      first_name,
      last_name,
      dateofbirth: convert(birthDate),
    };
    if (this.props.id) {
      try {
        const result = await callApi("clone-child", payload);
        if (result.status === 200) {
          this.props.handleClose();
          swal("Child Cloned Successfully", "", "success");
        }
      } catch {
        swal("Oops!", "Network Error!", "error");
      }
    }
  };

  render() {
    const { birthDate, last_name, first_name } = this.state;
    return (
      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.props.open}
          onClose={this.props.handleClose}
        >
          <div style={paper}>
            <div className="d-flex justify-content-between">
              <span>
                {" "}
                <Typography variant="h6" id="modal-title">
                  Make a Clone
                </Typography>
              </span>
              <span>
                {" "}
                <i
                  className="fas fa-times"
                  onClick={this.props.handleClose}
                ></i>
              </span>
            </div>

            <Form className="mt-4" onSubmit={this.submitForm}>
              <Form.Group controlId="formBasicEmail" className="form-group">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="first_name"
                  value={first_name}
                  onChange={this.onChangeHandle}
                />
              </Form.Group>

              <Form.Group controlId="formBasicEmail" className="form-group">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="last_name"
                  value={last_name}
                  onChange={this.onChangeHandle}
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword" className="form-group">
                <Form.Label>Birth Date</Form.Label>
                <div>
                  <DatePicker
                    selected={birthDate}
                    onChange={(date) => this.setBirthDate(date)}
                    selectsStart
                    required
                    startDate={birthDate}
                    // endDate={end_date}
                    placeholderText="DOB"
                    className="form-control cloneDateWidth"
                    showYearDropdown
                    scrollableMonthYearDropdown
                    onChangeRaw={(event) => {
                      if (
                        event.target.value &&
                        !validateInput(event.target.value)
                      ) {
                        event.preventDefault();
                      }
                    }}
                    maxDate={new Date()}

                    // fullWidth
                    //  maxDate={new Date()}
                  />
                </div>
              </Form.Group>
              <div className="text-center form-group">
                <Button type="submit" style={btnColor}>
                  Add Child
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    );
  }
}
