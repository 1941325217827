import React, { Component } from 'react'
import {
    Card,
    Form,
    Row
  } from "react-bootstrap";
import { callApi } from "../../crud/auth.crud";
import swal from "sweetalert";
import PermissionSlipTable from './permissionSlipTable'
export default class PermissionSlip extends Component {

  constructor(props) {
    super(props)

    this.state = {
       tableData:[]
    }
  }

  async componentDidMount() {
    await this.getData();
  }

  getData = async () => {
    let child_id = this.props.childId
    if(child_id)
    try {
      let result = await callApi("child-permissionslip",{child_id});
      if (result.status === 200) {
        const DATA = result.data.data;
        this.setState({
         tableData: DATA
        });
      } else {
        swal("Oops!", "Please try again!", "error");
      }
    } catch (err) {
      swal("Oops!", "Network Error!", "error");
    }
  };

    render() {
      const {tableData}=this.state;
        return (
          <div className="col-xl-9 col-lg-8 col-md-8 col-sm-8 col-8">
            <div className="kt-section">
              <>
                <Card>
                  <Card.Body>
                    <Card.Title>
                      <Row>
                        <span>Permission Slip</span>
                      </Row>
                    </Card.Title>

                    <PermissionSlipTable tableData={tableData} />
                  </Card.Body>
                </Card>
              </>
            </div>
          </div>
        );
    }
}
