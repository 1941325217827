import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import KPKTable from "./KPKTable";
import { getSessions, callApi } from "../../../crud/auth.crud";
import swal from "sweetalert";
import withRouter from "../../../router/withRouter";

class KPK extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sessions: [],
      sessionName: "",
      tableData: [],
      session_dateId: "",
    };
    console.log(
      "🚀 ~ KPK ~ constructor ~ props:",
      this.props.location,
      this.tableData
    );
  }
  async componentDidMount() {
    await this.getAllSessions();
    // await this.getData()
  }

  getData = async () => {
    const { session_dateId } = this.state;
    let grade = this.props?.location?.state?.grade || "";
    console.log("🚀 ~ KPK ~ getData= ~ grade:", grade);
    let program_id = 6;
    if (session_dateId && grade)
      try {
        let result = await callApi("grade-wise-report", {
          grade,
          program_id,
          date_id: session_dateId,
        });
        if (result.status === 200) {
          this.setState({
            tableData: result.data.data,
          });
          console.log(
            "🚀 ~ KPK ~ constructor ~ props:",
            this.props.location,
            this.tableData
          );
        }
      } catch (err) {
        this.setState({ loading: false });
        if (err.response.status == 401) {
          swal("", "Unauthorized!", "error");
          localStorage.clear();
          this.props.navigate("/auth/login");
          swal.close();
        } else {
          swal("Oops!", "Network Error!", "error");
        }
      }
  };

  getAllSessions = async () => {
    try {
      let result = await getSessions();
      if (result.status === 200) {
        this.setState(
          {
            sessions: result.data.data,
            sessionName:
              result.data.data.length && result.data.data[0].dateName,
            session_dateId:
              result.data.data.length && result.data.data[0].dateID,
          },
          () => this.getData()
        );
      }
    } catch (err) {
      this.setState({ loading: false });
      if (err.response.status == 401) {
        swal("", "Unauthorized!", "error");
        localStorage.clear();
        this.props.navigate("/auth/login");
        swal.close();
      } else {
        swal("Oops!", "Network Error!", "error");
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      window.scrollTo(0, 0);
      this.getData();
    }
  }

  onChangeHandle = (e) => {
    let obj = JSON.parse(e);
    this.setState({ sessionName: obj.name, session_dateId: obj.id }, () =>
      this.getData()
    );
  };
  render() {
    const { sessions, sessionName, tableData } = this.state;
    return (
      <div className="col-md-8">
        {/* <div className="kt-section"> */}
        <>
          <div className="kt-portlet ">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">Kid Power</h3>
              </div>
            </div>
            <div className="kt-portlet__body">
              <div className="row">
                <div className="col-md-12">
                  <div className="kt-section">
                    <div className="kt-section__content">
                      <div className="d-flex ">
                        <span className="py-1">Semester</span>
                        <span className="px-4">
                          <Dropdown onSelect={this.onChangeHandle}>
                            <Dropdown.Toggle
                              variant="warning"
                              id="dropdown-basic"
                              size="sm"
                              style={{
                                color: "#fff",
                                backgroundColor: "#ffb822",
                              }}
                            >
                              {sessionName}
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                              // onSelect={this.onChangeHandle}
                              style={{
                                overflowY: "scroll",
                                maxHeight: "240px",
                              }}
                            >
                              {sessions &&
                                sessions.map((el) => (
                                  <Dropdown.Item
                                    eventKey={JSON.stringify({
                                      name: el.dateName,
                                      id: el.dateID,
                                    })}
                                    key={el.dateID}
                                  >
                                    {el.dateName}
                                  </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        {tableData &&
          tableData.map((el, i) => (
            <div className="kt-portlet" key={i}>
              <div className="kt-portlet__body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="kt-section">
                      <div className="kt-section__content">
                        <h5 className="borderDown">{el.mentor_name}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="kt-section">
                      <div className="kt-section__content">
                        <KPKTable data={el.data} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

export default withRouter(KPK);
